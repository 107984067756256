import React, { useEffect, useState } from "react";
import styles from "./styles/DashboardIFD.module.css";
import { deletOfferUser, deletSubscribedUser, fetchSubscribedUser, findStore } from "../../api/AdminApis/IFD";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { toast } from "react-toastify";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import DayGraph from "../../components/_IFD/DayGraph";
import ProductGraph from "../../components/_IFD/ProductGraph";
import Pagination from "../../components/Pagination/Pagination";
import { fetchAllIFDUser } from "../../api/AdminApis/IFD";
import { formatDate } from "../../utils";
import Swal from "sweetalert2";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx';
import { Button } from "react-bootstrap";


// Component definition
const DashboardIFD = () => {
  // state definitions
  const [loader, setLoader] = useState(false);

  const [user, setUser] = useState([]);
  const [totalUsers, setTotalUsers] = useState(1);
  const [isSubscribedUser, setIsSubscribedUser] = useState(false);

  const usersPerPage = 100;

    const location = useLocation();

  const handleDelet = async (id) => {
    let text = "Are you sure for delete?";
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#a5a5a5",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
          deletOfferUser(id)
            .then((res) => {
              toast.success(res.message);
              // setData(data.filter((message) => message._id !== id));
              getAllUser();
            })
            .catch((err) => {
              console.log(err);
            });
      }
    });
  };

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (location.state?.type === "subscribedUser") {
      setIsSubscribedUser(true);
      // getAllSubscribedUser();
    } else {
      getAllUser();
      setIsSubscribedUser(false);
    }
  }, [currentPage, location]);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const getAllUser = async () => {
    setLoader(true);
    const payload = {
      searchStoreID : searchStoreID,
      fromDate : fromDate,
      toDate : toDate,
    }
    // const res = await fetchAllIFDUser(`limit=${usersPerPage}&page=${currentPage}`);
    const res = await fetchAllIFDUser(payload);
    if (res) {
      if (res?.data.no_of_users > 0) {
        setUser(res?.data.users);
        setTotalUsers(res?.data.total_users);
      } else {
        setUser([]);
      }
      setLoader(false);
    }
  };

  const filterresult = () => {
    getAllUser();
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [searchStoreID, setsearchStoreID] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [currentPage1, setCurrentPage1] = useState(1);
  const rowsPerPage = 10;

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(user);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "user_data.xlsx");
  };

  const filteredData = user.filter((row) => {
    return row.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
           row.userPhone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
           row.createdAt?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const columns = [
    {
      name: "Sr.No.",
      selector: (row, index) => (currentPage1 - 1) * rowsPerPage + index + 1,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.userName ?? "-",
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.userPhone ?? "-",
    },
    {
      name: "Store Id",
      selector: (row) => row.storeId ?? "-",
    },
    {
      name: "Prize Won",
      selector: (row) => {
        if (row.offerText === "25,000 Cash Back") {
          return "iPhone 16";
        }
        return row.offerText ?? "-";
      },
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt ?? "-",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="btn" onClick={() => handleDelet(row._id)}>
          <FontAwesomeIcon className="table-icon" icon={faTrashCan} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return loader ? (
    <DashboardLoader />
  ) : (
    <>
        <div className="container">
          <div className="d-flex justify-content-between">
            <h3>All IFD 2024 Users</h3>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: "10px", padding: "5px", width: "20%" }}
              className="h-50"
            />

            <input
              type="text"
              placeholder="Store ID"
              value={searchStoreID}
              onChange={(e) => setsearchStoreID(e.target.value)}
              style={{ marginBottom: "10px", padding: "5px", width: "10%" }}
              className="h-50"
            />
            <input type="date" style={{ marginBottom: "10px", padding: "5px", width: "20%" }} placeholder="From Date"
            onChange={(e) => setFromDate(e.target.value)} 
            value={fromDate}
            />
            
            <input type="date" style={{ marginBottom: "10px", padding: "5px", width: "20%" }} placeholder="To Date" 
            onChange={(e) => setToDate(e.target.value)}
            value={toDate}
            />
            
            <Button className="btn-sm h-50 mt-1" onClick={filterresult} >Find</Button>

            {/* <button className="btn" onClick={downloadExcel} style={{ marginLeft: "10px", padding: "5px" }}>
              Download Excel
            </button> */}
            <Button className="btn-sm h-50 mt-1" onClick={downloadExcel}>Download Excel</Button>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            paginationTotalRows={totalUsers}
            onChangePage={(page) => setCurrentPage1(page)}
            paginationPerPage={rowsPerPage}
          />
          {/* <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sr.No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {user?.length > 0 &&
                  user?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * usersPerPage + index + 1}.</td>
                      <td> {item.userName ?? "-"} </td>
                      <td>{item.userPhone ?? "-"}</td>
                      <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                      <td>
                        <button className="btn" onClick={() => handleDelet(item._id)}>
                          <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div> */}
          {/* <div className="pagination_Container mt-3">
            <Pagination productsPerPage={usersPerPage} totalProducts={totalUsers} pageChange={handlePageChange} currentPage={currentPage} />
          </div> */}
        </div>
    </>
  );
};

export default DashboardIFD;
