import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrashAlt, faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import useAdminCtx from "../../hooks/useAdminCtx";
import { deleteTopBannerProduct, setUpdatedBanners } from "../../api/AdminApis/Banner";
import { toast } from "react-toastify";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";

function SmartPhoneBanner({ smartPhoneBanners }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showBannersArray, setShowBannersArray] = useState({});

  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

  useEffect(() => {
    const topSection = allHomePageSections.find((section) => section.sectionType === "scroll_banner_smartPhone");
    if (topSection) {
      // const updatedProducts = topSection.products.map((product) => ({
      //   ...product,
      //   sectionType: topSection.sectionType,
      // }));

      setShowBannersArray(topSection?.products);
    }
  }, [allHomePageSections]);

  const handleDelete = ({ key, sectionType }) => {
    const cnf = window.confirm("Are you sure?");
    if (!cnf) return;
    deleteTopBannerProduct(key, sectionType, (err, res) => {
      if (err) return toast.error("Something went wrong!");
      if (res.status === 200) {
        getHomePageSections((err, res) => {
          if (err) return console.log(err);
          setAllHomePageSections(res.sections);
        });
      }
    });
  };

  return (
    <div>
      <div className="col-auto">
        <div className=" mb-2"></div>
        <button
          className="btn btn-primary "
          onClick={() =>
            navigate("../admin-home-smartphone-banner", {
              state: {
                updateProduct: false,
              },
            })
          }
        >
          Add Scroll Banner
        </button>
      </div>

      {showBannersArray?.length > 0 ? (
        <>
          {showBannersArray?.map((banner, index) => (
            <>
              <div className="banner-product-card">
                {banner.imgUrl.split(".").pop() === "mp4" ? (
                  <video controls autoPlay loop muted style={{ height: "250px" }}>
                    <source src={banner.imgUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <span className="d-flex flex-column">
                    <img src={banner.imgUrl} alt="" width={500} />
                    Size: 500 X 180
                  </span>
                )}
                <div className="banner-product-card-body" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                  <p>
                    <u>Title</u> - {banner.title}{" "}
                  </p>
                  <p>
                    <u> Image URL</u> - <a href={banner.imgUrl}>{banner.imgUrl}</a>{" "}
                  </p>
                  <p>
                    <u> Target URL</u> - <a href={banner.targetUrl}>{banner.targetUrl}</a>{" "}
                  </p>
                  <p>
                    <u> Index</u> - {index + 1}
                  </p>
                </div>
                <div className="icons">
                  <FontAwesomeIcon
                    onClick={() =>
                      navigate("../admin-home-smartphone-banner", {
                        state: {
                          updateProduct: true,
                          data: banner,
                          from: location.pathname,
                        },
                      })
                    }
                    cursor={"pointer"}
                    icon={faEdit}
                  />
                  <FontAwesomeIcon cursor={"pointer"} onClick={() => handleDelete({ key: banner.imgKey, sectionType: "scroll_banner_smartPhone" })} icon={faTrashAlt} />
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <div className="d-flex mt-5">No Banner Images!</div>
        </>
      )}
    </div>
  );
}

export default SmartPhoneBanner;
