import React from "react";
import { WhatsappShareButton } from "react-share";
import { FaSquareWhatsapp } from "react-icons/fa6";

const ShareButton = ({ store, siteUrl }) => {
  const shareUrl = `Visit Our Site On : ${siteUrl}`;
  const storeName = `Shop Name: ${store.display_name}${store.street_name},`;

  return (
    <WhatsappShareButton url={shareUrl} title={storeName} separator=" ">
      <FaSquareWhatsapp size={30} style={{ color: "green" }} />
    </WhatsappShareButton>
  );
};

export default ShareButton;
