// top level imports
import { useState, useEffect } from "react"

// react-router
import { Link } from "react-router-dom";

// React-toast
import { toast } from "react-toastify";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";

// API Services
import {
    createQueryCategory,
    getQueryCategories,
    deleteQueryCategory,
    updateQueryCategory
} from "../../api/AdminApis/AdminQuiry";

// Component definition
export function AdminQueryCatgories() {
    // Constants
    const queriesPerPage = 10;

    // state definitions
    const [loading, setLoading] = useState(false);
    const [queryCategories, setQueryCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [totalQueries,] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    // ComponentDidMount
    useEffect(() => {
        fetchQueryCategories();
    }, []);

    /** Handler functions - starts */

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const addNewCategory = async () => {

        if (!selectedCategory.category) {
            toast.error('Category cannot be empty');
            return;
        }

        setLoading(true);

        try {
            const response = await createQueryCategory(selectedCategory);
            if (response.status === "success") {
                toast.success('Category added successfully!');
                fetchQueryCategories();
            } else {
                toast.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }

    }

    const fetchQueryCategories = async () => {
        setLoading(true)
        try {
            const response = await getQueryCategories();
            if (response.status === "success") {
                setQueryCategories(response.data);
                setSelectedCategory(null);
            }
        } finally {
            setLoading(false);
        }
    }

    const updateCategory = async () => {
        if (!selectedCategory.category) {
            toast.error('Category cannot be empty');
            return;
        }

        setLoading(true);

        try {
            const response = await updateQueryCategory(selectedCategory);
            if (response.status === "success") {
                toast.success('Category updated successfully!');
                fetchQueryCategories();
            } else {
                toast.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    }

    const saveCategory = () => {
        if (selectedCategory?._id) {
            updateCategory(selectedCategory)    
        } else {
            addNewCategory(selectedCategory)
        }
    }

    const editCategory = (category) => {
        setSelectedCategory(category);
    }

    const deleteCategoryHandler = async (categoryId) => {
        const allowDelete = window.confirm("Are you sure. You want to delete?");
        if (!allowDelete) return;

        setLoading(true);

        try {
            const response = await deleteQueryCategory(categoryId);

            if (response.status === "success") {
                toast.success(response.data);
                fetchQueryCategories();
            } else {
                toast.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        } 
    }

    // handles input change for category
    const handleCategoryChange = (event) => {
        setSelectedCategory(prev => ({...prev, category: event.target.value}))
    }

    /** Handler functions - starts */
    
    if (loading) {
        return <DashboardLoader />
    }

    // main renderer
    return (
        <div className="container">
            <div className="d-flex justify-content-between mb-2">
                <Link to="../admin-query">
                    <FontAwesomeIcon icon={faChevronLeft} /> Queries
                </Link>
                
                <h3>Customer Query Catgories</h3>
                
                <button
                    className="btn btn-primary"
                    onClick={() => setSelectedCategory({ category: '' })}
                >
                    Add Category
                </button>
            </div>

            {selectedCategory && (
                <form className="row mb-4">
                    <h4 className="text-capitalize">{selectedCategory?._id ? 'edit' : 'add'} category</h4>
                    <div className="col-auto">
                        <label for="category" className="visually-hidden">Category</label>
                        <input
                            value={selectedCategory?.category ?? ''}
                            type="text"
                            className="form-control"
                            id="category"
                            placeholder="Enter category"
                            onChange={handleCategoryChange}
                        />
                    </div>
                    <div className="col-auto">
                        <button
                            type="button"
                            className="btn btn-success text-capitalize"
                            onClick={saveCategory}
                        >
                            {selectedCategory?._id ? 'edit' : 'save' }
                        </button>
                    </div>

                    <div className="col-auto">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => setSelectedCategory(null)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}

            <div className="table-responsive mb-2">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Category</th>
                            <th scope="col">Created at</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {queryCategories.map((el, index) => {
                            return (
                                <tr key={el._id}>
                                    <td>{index + 1}</td>
                                    <td className="text-capitalize">{el.category}</td>
                                    <td>{el.createdAt}</td>
                                    <td>
                                        <button className="btn p-0" onClick={() => editCategory(el)} title="Edit Category">
                                            <FontAwesomeIcon className={"table-icon"} icon={faPenToSquare} />
                                        </button>

                                        <button className="btn p-0" onClick={() => deleteCategoryHandler(el._id)} title="Delete Category">
                                            <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
            </div>
            <div className="pagination_Container">
                <Pagination
                    productsPerPage={queriesPerPage}
                    totalProducts={totalQueries}
                    pageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </div>
        </div>
    )
}
