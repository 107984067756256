import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CircleLoading from "../../components/CircleLoading/index";
import CreateAccountBG from "../../assets/productIcons/Create-account-BG.png"
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { t } from "i18next";
import { retailerLogin } from "../../api/RetailerApis/RetailerAuth";
import { useToken } from "../../utils/useToken";

// toast.configure();

// Component Definition
const RetailerAdminLogin = () => {
    
    // state definitions
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [field, setField] = useState("");
    const [password, setPassword] = useState("");

    // hooks
    const location = useLocation();
    const nav = useNavigate();
    const [token, setToken] = useToken(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    useEffect(() => {
        if (location.search) {
            handleLogoutRedirection(location);
        }
    }, []);

    const handleLogoutRedirection = (location) => {
        const searchQuery = new URLSearchParams(location.search.slice(1));
        if (searchQuery.has('action') && searchQuery.get('action') === 'logout') {
            toast.error('Retailer logged out due to token expiration! Please login again.');
            nav('/retailer-login');
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        retailerLogin({ field, password }).then(res => {
            setLoading(true);
            setBtnDisable(true);
            if (res?.token) {
                const { token } = res;
                setToken(token);
                localStorage.setItem('retailer_id', res.id)
                nav("/retailer");
            } else toast.error(res?.data?.status === "error" ? res?.data?.message : "Something Went Wrong")

        }).finally(() => {
            setLoading(false);
            setBtnDisable(false);
            setPassword("");
            setField("");
        })
    };

    return (
        <>
            <HeaderBar alternateWay={t('home')} alternateLink={"/"} />
            <div className="signUpParentClass" style={{
                background: `url(${CreateAccountBG}) no-repeat`,
                backgroundSize: "cover",
            }}>
                <div className="signup-wrapper" style={{
                    backdropFilter: "blur(0)",
                    background: "none",
                    padding: "25px",
                    paddingBottom: "115px"
                }}>
                    <div className="signup-header">
                        <h1 className="page-heading">Retailer Login</h1>
                    </div>
                    <form action="" className={"signup-form"} onSubmit={formSubmit} style={{ gap: "20px" }}>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Brand Store Id</div>
                            <div>
                                <input
                                    type="text"
                                    name="field"
                                    id="field"
                                    required
                                    className="input-field admin_required_field"
                                    value={field}
                                    onChange={(e) => {
                                        setField(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Password</div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    className="input-field admin_required_field"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>


                        <div className={"button-Container"}>
                            {loading ? (
                                <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                                    <CircleLoading />
                                </div>
                            ) : (
                                ""
                            )}
                            <button type="submit" className="submit-button" disabled={field && password ? false : true}>
                                <p>{t("continue")}</p>
                            </button>
                        </div>


                    </form>
                </div>
            </div>

        </>
    );

};

export default RetailerAdminLogin;
