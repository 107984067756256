// top level imports

// React-Router
import { useNavigate } from "react-router-dom"

// reacti18n
import { useTranslation } from "react-i18next";

// Component definition
export function Error404() {
    // hooks
    const nav = useNavigate();
    const { t } = useTranslation();

    // Main renderer
    return (
        <div className="empty_order_sec mb-0 not_found_wrapper">
            <h1>{t('oops')}!</h1>
            <h2>Product not found</h2>
            <p className="empty_order_text">The requested product was not found!</p>
            <button type="submit" className="submit-button" onClick={() => nav("/", { replace: true })}>
                <p>{t('start-shopping')}</p>
            </button>
        </div>
    )
}