import React from "react";

import Location from "../../assets/vector/Cartpage/location22.svg";
import Message from "../../assets/vector/Cartpage/message.svg";
import Phone from "../../assets/vector/Cartpage/phone-call.svg";
import "./CartProductCard.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CartBanner(props) {
  const { t } = useTranslation();
  return (
    <div
      className="cart-banner"
      style={{
        marginTop: "0.2rem",
        width: !props.isMobile ? "100vw" : "",
        height: !props.isMobile ? "" : "70px",
        marginLeft: !props.isMobile ? "-40px" : "",
      }}
    >
      <div className="cart-banner-item" id="sub-banner1">
        <span>{t("NeedHelp")}?</span>
        {!props.isMobile && <span style={{ maxWidth: "200px" }}>{t("supportChannel")}</span>}
      </div>
      <Link to={"/store-finder"} className="cart-banner-item">
        <div>
          <img className="icn-sz" src={Location} alt="location" />
        </div>
        <div className="itm-child">
          <span>{t("StoreLocator")}</span>
          <span className="fw-normal">{props.isMobile ? "" : t("FindStoreNearby")}</span>
        </div>
      </Link>
      <Link to={"customer-support"} className="cart-banner-item">
        <div>
          <img className="icn-sz" src={Message} alt="mesage" />
        </div>
        <div className="itm-child">
          <span>{t("customerSupport")}</span>
          {/* <span className="fw-normal">{t("SendFeedback")}</span> */}
        </div>
      </Link>
      <Link to={"tel:63900 63900"} className="cart-banner-item">
        <div>
          <img className="icn-sz" src={Phone} alt="phone" />
        </div>
        <div className="itm-child">
          <span>{t("Getintouch")}</span>
          {props.isMobile ? "" : <span className="fw-normal">(+91) 63900 63900</span>}
        </div>
      </Link>
    </div>
  );
}

export default CartBanner;
