import React from "react";
import "./AboutUs.css";
import { useMediaQuery } from "@mui/material";

import ABSec2_A from "../../assets/aboutUs/ABSec-2[A].svg";
import ABSecNew1 from "../../assets/aboutUs/ABSecNew1.svg";
import ABSecNew2 from "../../assets/aboutUs/ABSecNew23.svg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSiteCtx from "../../hooks/useSiteCtx";
import SEO from "../../components/SEO/SEO";

function AboutUs() {
  const isMobile = useMediaQuery("(max-width:650px)");
  const location = useLocation();
  const { t } = useTranslation();
  const { userLoggedIn } = useSiteCtx();
  const { seoData } = useSiteCtx();

  const breadCrumbsData = [
    {
      text: t("profile.profile") + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile",
    },
    {
      text: t("aboutUsText"),
      url: "/about-us",
      isActive: location.pathname === "/about-us",
    },
  ];

  return (
    <>
      <SEO seoData={seoData} />
      <div className="absection-1 not_found_wrapper">
        {userLoggedIn && (
          <div className="absec-1sub">
            <span style={{ fontSize: "16px" }}>
              {breadCrumbsData.map((item, index) => (
                <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>
                  {item.text}
                </Link>
              ))}
            </span>
            <span style={{ fontSize: "30px" }}>About Us Page</span>
          </div>
        )}
        {!isMobile && (
          <div>
            <img
              className=""
              style={{
                position: "absolute",
                right: "3px",
                height: "78vh",
                zIndex: "2",
              }}
              src={ABSecNew1}
            />
            {/* <img className='absec-1-img-2' src={} /> */}
          </div>
        )}
      </div>

      <div className="absection-2">
        <div className="editionsec2">
          <span
            style={{
              fontSize: "25px",
            }}
          >
            O-LINE-O – DIGITAL INDIA KI APNI DUKAAN
          </span>
          <span
            style={{
              fontSize: "20px",
              color: "#707070",
            }}
          >
            Launched in 2019 by Mr. Vibhooti Prasad, O-LINE-O is a one-of-a-kind brand that caters to the needs of all multi-brand smartphones & electronic gadgets in India. Starting with our first
            store in _ city name _, today we have become India’s fastest-growing mobile retail chain serving happiness with our 150+ stores located in 00+ cities across Maharashtra, Rajasthan, and
            Karnataka. In our journey of merely 4 years, we have come a long way and achieved many milestones to become one of the leading retail powerhouses in the country.
          </span>
        </div>
        <div style={{ width: "11%", background: "#F8F8F8" }}>
          <img src={ABSec2_A} className="editionsec2Imag" />
        </div>
      </div>

      <div className="absection-3">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            margin: "10px 8%",
            fontWeight: "700",
          }}
        >
          <span style={{ fontSize: "25px" }}>WE DELIVER THE BEST VALUE, SERVICE, & CHOICES UNDER ONE ROOF</span>
          <span style={{ fontSize: "20px", color: "#707070" }}>
            We bring the widest range of the best smartphones, tablets, laptops, TVs, and accessories from all top global brands under one roof. O-LINE-O’s commitment to fulfilling the wishlist of
            every customer has left an indelible mark on the consumers’ minds, making us a trusted name to be reckoned with. It is our team’s deep perseverance, and product knowledge at the market
            level, coupled with the widest range of products available at the best value across all the stores that make us India’s personal favorite.
          </span>
        </div>
        <div>{/* <img src={ABSec3}/> */}</div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "40px 8%",
          background: "#F8F8F8",
          fontWeight: "700",
        }}
      >
        <span style={{ fontSize: "25px" }}> WE’RE MORE THAN JUST A STORE</span>
        <span style={{ fontSize: "20px", color: "#707070" }}>
          We’ve created a trust-driven network of brands, products, and services powered by our in-house distribution system, thus bringing their latest inventories and services directly to you. We
          provide an agglomeration of the most popular products & services at the most affordable prices along with a never-ending series of best offers, schemes, and benefits. Also, we are tirelessly
          working to add better services and options to buy from at our stores. So whether it be 2 years warranty, exchange offer, assured cashback, multiple finance options, 0% down payment, paper
          finance, easy EMI options, or anything else, you wish it, we have it!
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            marginLeft: "8%",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              fontWeight: "700",
            }}
          >
            <span style={{ fontSize: "25px" }}>WE DELIVER THE BEST VALUE, SERVICE, & CHOICES UNDER ONE ROOF</span>
            <span style={{ fontSize: "20px", color: "#707070" }}>
              We bring the widest range of the best smartphones, tablets, laptops, TVs, and accessories from all top global brands under one roof. O-LINE-O’s commitment to fulfilling the wishlist of
              every customer has left an indelible mark on the consumers’ minds, making us a trusted name to be reckoned with. It is our team’s deep perseverance, and product knowledge at the market
              level, coupled with the widest range of products available at the best value across all the stores that make us India’s personal favorite.
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              fontWeight: "700",
            }}
          >
            <span style={{ fontSize: "25px" }}>WE ARE A FAMILY FIRST THAN YOUR STORE</span>
            <span style={{ fontSize: "20px", color: "#707070" }}>
              We bring the widest range of the best smartphones, tablets, laptops, TVs, and accessories from all top global brands under one roof. O-LINE-O’s commitment to fulfilling the wishlist of
              every customer has left an indelible mark on the consumers’ minds, making us a trusted name to be reckoned with. It is our team’s deep perseverance, and product knowledge at the market
              level, coupled with the widest range of products available at the best value across all the stores that make us India’s personal favorite.
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={ABSecNew2} style={{ width: "700px", height: "700px", marginLeft: "14%" }} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "25px",
          fontWeight: "700",
          width: "100vw",
          marginTop: "2%",
        }}
      >
        <span>WE’RE CLOSE TO YOUR HEART</span>
      </div>
    </>
  );
}

export default AboutUs;
