import React, { useEffect, useState } from "react";
import { getAdminIndividualConsignment, getAllConsignments } from "../../api/AdminApis/AdminOrder";
import { formatDate } from "../../utils/index";
import Pagination from "../../components/Pagination/Pagination";
import AdminConsignmentDetailsModal from "../../components/OrderProductModal/AdminConsignmentDetailsModal";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { MdOpenInNew } from "react-icons/md";
import { useLocation } from "react-router-dom";

function Consignments({ getConsignemt, consignments, totalOrders }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [consignmentStatusList, setConsignmentStatusList] = useState(["PLACED", "CONFIRMED", "READY TO SHIP", "SHIPPED", "OUT_FOR_DELIVERY", "DELIVERED", "CANCELLED"]);
  const [modalName, setModalName] = useState(null);
  const [details, setDetails] = useState({});
  const [consignmentSelected, setConsignmentSelected] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");

  useEffect(() => {
    if (searchValue === "") {
      getConsignemt(`status=${statusValue}`, currentPage);
    }
  }, [searchValue, currentPage, statusValue]);

  const getOneConsignment = (id) => {
    getAdminIndividualConsignment(id).then((res1) => {
      if (res1?.details) {
        setDetails(res1.details);
        setShow(true);
      } else toast.error("Something went wrong in fetching Individual Consignment");
    });
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const productsPerPage = 30;

  const handleClickConsignmentDetails = () => {};

  function handleSearchClick(e) {
    e.preventDefault();
    if (searchValue) getSearchConsignment(searchValue);
  }

  const getSearchConsignment = (value) => {
    value && getConsignemt(`search=${value}`, 1);
  };
  const handleSelectedCategory = (e, value) => {
    if (value) {
      let statusValue;
      switch (value) {
        case "PLACED":
          statusValue = "INITIATED".toUpperCase();
          break;
        case "CONFIRMED":
          statusValue = "CONSIGNMENT_ACCEPTED".toUpperCase();
          break;
        case "READY TO SHIP":
          statusValue = "DELIVERY_INITIATED".toUpperCase();
          break;
        case "SHIPPED":
          statusValue = "SHIPPED".toUpperCase();
          break;
        case "DELIVERED":
          statusValue = "DELIVERY_SUCCESS".toUpperCase();
          break;
        default:
          statusValue = value;
      }
      setStatusValue(statusValue);
    } else {
      getConsignemt();
    }
  };

  const location = useLocation();

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <TextField size="small" id="consignmentId" label="Search Consignment ID" variant="outlined" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          <Button variant="contained" onClick={handleSearchClick} disabled={!searchValue}>
            Search
          </Button>
        </div>
        <div className="d-flex gap-2 mt-2 justify-content-center align-items-center">
          <Autocomplete
            id="statusSelected"
            className="form-control p-0"
            size="medium"
            options={consignmentStatusList}
            onChange={(e, value) => handleSelectedCategory(e, value)}
            getOptionLabel={(option) => option || ""}
            // value={status !== "" ? [status] : null}
            // value={stockName }
            clearOnEscape={false}
            style={{ width: "300px" }}
            renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Category" />}
          />
        </div>
      </div>
      <div>
        {consignments.length > 0 && (
          <table className="table ">
            <thead>
              <tr style={{ color: "#7b7b7b", background: "#f3f6f9" }} className="text-uppercase">
                <th scope="col">#</th>
                <th scope="col">consignment ID</th>
                <th scope="col">customer</th>
                {/* <th scope="col">ORDER DATE</th> */}
                <th scope="col">consignment Status</th>
                {/* <th scope="col">Accepted By</th> */}
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {consignments.map((item, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * productsPerPage + index + 1}</td>
                  <td>{item?._id}</td>
                  <td>{item?.consignee?.fullName ? item?.consignee?.fullName : item?.order?.address_customerName}</td>
                  {/* <td>{formatDate(item?.createdAt)}</td> */}
                  <td>
                    <div>
                      {item.status === "INITIATED" ? (
                        "Placed".toUpperCase()
                      ) : item.status === "CONSIGNMENT_ACCEPTED" ? (
                        "Confirmed".toUpperCase()
                      ) : item.status === "DELIVERY_INITIATED" ? (
                        "Ready to Ship".toUpperCase()
                      ) : item.status === "SHIPPED" ? (
                        "Shipped".toUpperCase()
                      ) : item.status === "OUT_FOR_DELIVERY" ? (
                        "Out For Delivery".toUpperCase()
                      ) : item.status === "DELIVERY_SUCCESS" ? (
                        "Delivered".toUpperCase()
                      ) : (
                        <span>{item.status.toUpperCase()}</span>
                      )}
                      <br />
                      <span style={{ fontSize: "small" }}> {item?.consignor?.display_name}</span>
                    </div>
                  </td>
                  <td className="d-flex" style={{ gap: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        getOneConsignment(item._id);
                        setModalName("consignment");
                        setConsignmentSelected(item._id);
                      }}
                    >
                      Details
                    </button>
                    {item.status === "INITIATED" && (
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        aria-label="Accept Consignment"
                        title="Accept Consignment"
                        onClick={() => window.open(`${window.location.origin}/consignments/${item._id}`, "_blank")}
                      >
                        <MdOpenInNew size={17} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination_Container mt-3">
          <Pagination productsPerPage={productsPerPage} totalProducts={totalOrders} pageChange={handlePageChange} currentPage={currentPage} />
        </div>
      </div>

      {show && modalName === "consignment" && <AdminConsignmentDetailsModal show={show} onHide={() => setShow(false)} details={details} consignmentSelected={consignmentSelected} />}
    </div>
  );
}

export default Consignments;
