import { useState, useRef, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";

// XLSX Parser
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
// API
import { deleteSeoData, handleCreateUpdateSeo } from "../../api/CatalogueApi";
import { toast } from "react-toastify";
import { getAllSeoData } from "../../api/AdminApis/HomePageSections";
import Loader from "../../components/Loader/Loader";
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";

// toast.configure();

// Component definition
const AdminAddSeo = () => {
  // Constants
  const requiredMasterFields = ["title", "description", "HSN", "gstpercentage"];

  // state definitions
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState("");
  const [imagesObject, setImagesObject] = useState({});
  const [galleryImagesObject, setGalleryImagesObject] = useState({});
  const [productTable, setProductTable] = useState({ headers: [], rows: [] });
  const [uploadType, setUploadType] = useState("");
  const [selectedProdCategory, setSelectedProdCategoory] = useState("");
  const [selectedEan, setSelectedEan] = useState("");
  const [seoTableData, setSeoTableData] = useState([]);

  // Hooks
  const nav = useNavigate();

  // Refs
  const productPayloadRef = useRef([]);

  const requiredFields = ["title", "description", "keywords", "url"];

  // advance payment, coming soon, complimentiry products, image sequence

  const handleFile = async (event) => {
    // resetting images

    // read file as a binary string
    const [file] = event.target.files;
    const fileReader = new FileReader();
    fileReader.onload = (readEvt) => {
      parseXLSXData(readEvt.target.result);
      event.target.value = "";
    };
    fileReader.readAsBinaryString(file);
  };

  // parses binary string to json data
  const parseXLSXData = (binaryData) => {
    try {
      const workBook = XLSX.read(binaryData, { type: "binary" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, defval: "", blankrows: false });
      processParsedData(data);
    } catch (err) {
      console.log(err);
    }
  };

  // intermediary processing of data from excel
  const processParsedData = (jsonData) => {
    const [headers, ...rowData] = jsonData;
    const validHeaders = headers.filter((h) => h !== "");

    const productsData = rowData.map((columns) => {
      return columns.reduce((acc, col, idx) => {
        return { ...acc, [validHeaders[idx] ?? ""]: col };
      }, {});
    });

    // based on selected upload type tranform the data and upload to api correspondingly
    // if (uploadType === "initialise") {
    //   transformDataForMaster(productsData, headers);
    // } else {
    transformDataForCatalogue(productsData, validHeaders);
    // }
  };

  // validates and transforms the data to be uploaded for cataloguing
  const transformDataForCatalogue = (productRaw, headers = []) => {
    let hasError = false;
    const tProducts = [];

    productRaw.forEach((prod, index) => {
      for (const prop of requiredFields) {
        // Check if the required field is present in the product
        if (!prod[prop]) {
          hasError = true;
          toast.error(`Required - ${prop} in ${prod.title} product!`);
          break;
        }
      }

      const product = generateSEOSchema(prod);
      tProducts.push(product);
      if (!hasError) {
        productPayloadRef.current = tProducts;
        setProductTable({ headers, rows: productRaw });
      }
    });
  };

  useEffect(() => {
    fetchData(); // Call the async function immediately
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAllSeoData();
      if (response) {
        setSeoTableData(response);
      }
    } catch (error) {
      console.error("Error fetching existing EANs:", error);
    }
  };
  // generates a product object that will be stored at the backend
  const generateSEOSchema = (prod) => {
    return {
      title: prod.title,
      description: prod.description,
      keywords: prod.keywords,
      url: prod.url,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (productTable) {
      setLoading(true);
      try {
        const res = await handleCreateUpdateSeo(productTable.rows);
        if (res) {
          if (res.data.seoDataFailed.length === 0) {
            toast.success(res.message);
            setProductTable({ headers: [], rows: [] });
            fetchData();
          } else {
            if (res.data?.productsFailed?.length > 0) {
              const failedEans = res.data?.productsFailed?.map(({ title }) => title).join(", ");
              toast.error(`.Product upload failed for - ${failedEans}`);
              toast.error("Possible issues in product uploads could be duplicate EAN or any missing fields");
            }
          }
          setLoading(false);
        }
      } catch (error) {
        let errorMsg = error?.response?.data?.message ?? "Something went wrong";
        toast.error(errorMsg);
      }
    }
  };

  // resets current state
  const reset = () => {
    const userConfirm = window.confirm("Are you sure you want to reset the form?");
    if (userConfirm) {
      setProductTable({ headers: [], rows: [] });
    }
  };

  function swapFirstElementWithIndex(array, index) {
    if (array.length > 0 && index >= 0 && index < array.length) {
      const temp = array[0];
      array[0] = array[index];
      array[index] = temp;
    }
    return array;
  }

  const deleteAllSeoData = () => {
    let text = "Are you sure for delete?";
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#a5a5a5",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteSeoData();
          if (response.message === "All SEO data deleted successfully!") {
            toast.success(response?.message ?? "SEO Deleated!");
            fetchData();
          }
        } catch (error) {
          console.error("Error deleting coupon:", error);
          // Handle error
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the coupon.",
            icon: "error",
          });
        }
      }
    });
  };
  // Main renderer
  return (
    <>
      <div className="catelogue_Page section_Wrapper">
        <div className="offers_Toggle_Container mb-2" style={{ maxWidth: "unset" }}>
          {/* {uploadTypes.map(({ key, label }) => (
            <p className={uploadType === key ? "offer_Selected" : ""} onClick={() => changeUploadType(key)}>
              {label}
            </p>
          ))} */}
        </div>

        <div className="catelogue_Page_Header">
          {/* <h4 className="catelogue_Page_Heading">{headingTitle[uploadType]}</h4> */}
          <button type="button" className="btn-sm btn btn-danger" disabled={!productTable.headers.length} onClick={reset}>
            Reset
          </button>
        </div>

        <div className="row">
          {requiredFields && (
            <div className="col-md-12 mt-2">
              <div className="alert alert-info alert-dismissible fade show" role="alert">
                Required Fields :{" "}
                <strong>
                  {requiredFields.map((item, index) => (
                    <span>{item}, </span>
                  ))}
                </strong>
                are required!
              </div>
            </div>
          )}
        </div>
        <div>
          <input
            style={{ height: "revert" }}
            type="file"
            className="input-field"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => handleFile(e)}
          />
        </div>

        {productTable.headers.length > 0 && (
          <div>
            {/* <div className="catelogue_Table"> */}
            <table className="table table-bordered" style={{ fontSize: "small" }}>
              <thead>
                <tr>
                  {productTable.headers.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                  {uploadType === "catalogue" && (
                    <>
                      <th>
                        <p>Upload Product Images</p>
                        <p>Add Maximum 5 images</p>
                      </th>
                      <th>Image Files Preview</th>
                      <th>
                        <p>Upload Product Gallery Images</p>
                        <p>Add Maximum 4 images</p>
                      </th>
                      <th>Image Files Preview</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {productTable.rows.map((row, rowIdx) => {
                  return (
                    <tr key={rowIdx}>
                      {productTable.headers.map((col, colIdx) => {
                        return <td key={`${rowIdx}-${colIdx}`}>{row[col] ?? ""}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* </div> */}

            <div className={"button-Container mt-2"}>
              <button id="submitCsvData" disabled={loading} type="submit" className="submit-button" onClick={handleSubmit}>
                <p className="d-flex  align-items-center  " style={{ gap: "10px" }}>
                  Submit
                  {loading && <Loader />}
                </p>
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive px-3 mt-3">
        <div>
          {seoTableData.length > 0 && (
            <button className="btn p-0" onClick={() => deleteAllSeoData()} title="Delete Notification">
              <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
            </button>
          )}
        </div>
        {seoTableData.length > 0 ? (
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <th>Description</th>
                <th>Keywords</th>
                <th>Title</th>
                <th>URL</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>
              {seoTableData.map((item, index) => (
                <tr>
                  <td>{item.description}</td>
                  <td>{item.keywords}</td>
                  <td>{item.title}</td>
                  <td>
                    <a href={item.url} target="_blank">
                      {item.url}
                    </a>
                  </td>
                  <td>{item.__v}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            <p>No Seo Data Found!</p>
          </>
        )}
      </div>
    </>
  );
};

export default AdminAddSeo;
