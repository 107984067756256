// top level imports
import React, { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

// React-Router
import { Link, useNavigate, useParams } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// React-Select
import { default as Select, components } from "react-select";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
//API
import { allUsersForNotificationPage } from "../../api/AdminApis/Users";
import { getNotification, sendNotification } from "../../api/AdminApis/NotificationApi";
import { Spinner } from "react-bootstrap";
import { TextField, TextareaAutosize } from "@mui/material";
import DatePicker from "react-datepicker";

// Email Template component
function EmailTemplate({ notification, onnotificationChange, handleDateChange, isView }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-field mb-3">
        <label className="mb-2">Email Subject: </label>
        <TextField
          type="text"
          name="subject"
          label={`${t("email", "Email")} ${t("subject", "Subject")}`}
          variant="outlined"
          value={notification.subject}
          onChange={onnotificationChange}
          disabled={isView}
          className="w-100"
        />
        {/* <p className="catalogue_Hint">{`${t('email', 'Email')} ${t('subject', 'Subject')}`}</p> */}
      </div>

      <div className="form-field">
        <label className="mb-2">Email Message: </label>
        <TextareaAutosize
          rowsmin={3}
          name="message"
          placeholder={`${t("email", "Email")} ${t("message", "Message")}`}
          className="support_Form_Input support_Form_Query mb-0"
          value={notification.message}
          onChange={onnotificationChange}
          disabled={isView}
          style={{ width: "100%", resize: "vertical" }}
        />
        {/* <p className="catalogue_Hint">{`${t('email', 'Email')} ${t('message', 'Message')}`}</p> */}
      </div>
      <div className="col-lg-3">
        <label className="mb-2">Offer Start Date: </label>
        <DatePicker
          name="startDate"
          dateFormat="dd/MM/y"
          className=" input-field form-control custom-date-picker"
          placeholderText="Select Offer Date"
          selected={notification.offerStartDate}
          minDate={new Date()}
          onChange={(dateVal) => handleDateChange(dateVal)}
        />
      </div>
    </>
  );
}

// Phone Template component
function PhoneTemplate({ notification, onnotificationChange, isView }) {
  const { t } = useTranslation();

  return (
    <div className="form-field">
      <TextareaAutosize
        rowsmin={3}
        name="message"
        placeholder={`${t("email", "Email")} ${t("message", "Message")}`}
        value={notification.message}
        className="support_Form_Input support_Form_Query mb-0"
        onChange={onnotificationChange}
        disabled={isView}
        style={{ width: "100%", resize: "vertical" }}
        type="text"
      />
      {/* <p className="catalogue_Hint">{t('message', 'Message')}</p> */}
    </div>
  );
}

function Option({ isLastUser, lastUserElementRef, ...rest }) {
  return (
    <div className="form-check" ref={isLastUser ? lastUserElementRef : null}>
      <components.Option {...rest}>
        <input id={rest.value} type="checkbox" className="form-check-input" checked={rest.isSelected} onChange={() => null} />
        <div className="d-flex gap-1 flex-column justify-content-start">
          <label className="form-check-label" htmlFor={rest.value}>
            {rest.data.label}
          </label>
          <span>
            <b>Mobile Number::</b> {rest.data.mobileNumber}
          </span>
          <span>
            <b>Email::</b> {rest.data.email}
          </span>
        </div>
      </components.Option>
    </div>
  );
}

// component definition
export function AdminNotificationsCreate() {
  const { t } = useTranslation();
  const nav = useNavigate();

  // Constants
  const notificationMediums = [
    { key: "email", label: t("email") },
    { key: "phone", label: t("phone") },
  ];

  // state definitions
  const [notificationMedia, setNotificationMedia] = React.useState("email");
  const [notification, setNotification] = React.useState({
    subject: "",
    message: "",
    offerStartDate: new Date(),
  });
  const [users, setUsers] = React.useState([]);
  const [recipients, setRecipients] = React.useState([]);
  const [willBroadcast, setWillBroadcast] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [userListPage, setUserListPage] = useState(1);
  const [userListHasMore, setUserListHasMore] = useState(false);
  const userPerPage = 1000;

  //Miscellaneous hooks
  const { id } = useParams();
  const isView = id !== "create";

  // ComponentDidMount
  React.useEffect(() => {
    !willBroadcast && fetchUsers();
  }, [userListPage, willBroadcast]);

  // ComponentDidMount and ComponentDidUpdate
  React.useEffect(() => {
    isView && fetchNotificationDetails(id);
  }, [id]);

  // Computed
  const userOptions = users.map((user) => ({
    value: user._id,
    label: user.fullName,
    email: user.email,
    mobileNumber: user.mobileNumber,
  }));

  /** Handler functions - starts */
  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const response = await allUsersForNotificationPage(`limit=${userPerPage}&page=${userListPage}`);
      setUsers((prev) => [...prev, ...response?.results]);
      setUserListHasMore(response?.next?.page ? true : false);
    } finally {
      setUserLoading(false);
    }
  };

  const handleBroadcastStatusChange = (event) => {
    setWillBroadcast(event.target.checked);
  };

  const onnotificationChange = (event) => {
    const { name, value } = event.target;
    setNotification((prev) => ({ ...prev, [name]: value }));
  };

  const handleReceipentSelection = (selected) => {
    setRecipients(selected);
  };

  // Submit handler for the notification form
  const handleSubmit = async () => {
    // Initialize payload
    const payload = {
      ...notification,
      recipients,
    };

    if (notificationMedia === "phone") delete payload.subject;

    if (willBroadcast) payload.recipients = "all";
    setLoading(true);
    try {
      // call service to send notification
      const response = await sendNotification(payload);

      if (response.status === "success") {
        toast.success(response.data);
        nav("/admin/admin-notifications");
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNotificationDetails = async (id) => {
    setLoading(true);
    try {
      const response = await getNotification(id);
      if (response.status === "success") {
        const notificationRes = response.data;
        setNotification({ message: notificationRes.message, subject: notificationRes.subject, offerStartDate: notificationRes?.offerStartDate });
        setNotificationMedia(notificationRes.medium);
        setRecipients(notificationRes.recipients);
        setWillBroadcast(notificationRes.recipients === "all");
      } else {
        toast.error("Notification not found!");
      }
    } finally {
      setLoading(false);
    }
  };

  const observer = useRef();
  const lastUserElementRef = useCallback(
    (node) => {
      if (userLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && userListHasMore) {
            setUserListPage((prev) => prev + 1);
          }
        },
        {
          root: null,
          threshold: 0.2,
        }
      );
      if (node) observer.current.observe(node);
    },
    [userLoading, userListHasMore]
  );

  const handleDateChange = (date) => {
    setNotification((prev) => ({ ...prev, offerStartDate: date }));
  };
  /** Handler functions - ends */

  // Main renderer

  return (
    <div className="container">
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Link className="me-2" title="Go back to notification list" to="../admin-notifications">
                <FontAwesomeIcon icon={faChevronLeft} />
              </Link>
              <h4>
                {isView ? t("view") : t("create")} {t("notification")}{" "}
              </h4>
            </div>
            {/** Media Togglers */}
            {!isView && (
              <div className="offers_Sort_Header">
                <div className="offers_Toggle_Header">
                  <div className="offers_Toggle_Container">
                    {notificationMediums.map(({ key, label }) => (
                      <p className={`${notificationMedia === key ? "offer_Selected" : ""}`} style={{ cursor: "pointer" }} onClick={() => setNotificationMedia(key)}>
                        {label}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="bg-white rounded mt-3 p-4">
            <div className="row mb-2">
              <div className="col-md-2">
                <h5>{notificationMediums.find((el) => el.key === notificationMedia)?.label}</h5>
              </div>
              <div className="col-md-3">
                <div className="form-check">
                  <input id="broadcast-checkbox" className="form-check-input" type="checkbox" checked={willBroadcast} onChange={handleBroadcastStatusChange} disabled={isView} />
                  <label className="form-check-label" htmlFor="broadcast-checkbox">
                    {t("broadcast")}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                {!willBroadcast && (
                  <>
                    <label>Select users to which notification should be sent</label>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      options={userOptions}
                      closeMenuOnScroll={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option: (optionProps) => {
                          const index = userOptions.findIndex((user) => user.value === optionProps.data.value);
                          return (
                            <React.Fragment>
                              <Option isLastUser={index === userOptions.length - 1} lastUserElementRef={lastUserElementRef} {...optionProps} />
                              {index === userOptions.length - 1 && userLoading && (
                                <div className="d-flex justify-content-center align-items-center my-2">
                                  <Spinner />
                                </div>
                              )}
                            </React.Fragment>
                          );
                        },
                      }}
                      onChange={handleReceipentSelection}
                      value={recipients}
                      isDisabled={isView}
                    />
                  </>
                )}
              </div>
            </div>
            {notificationMedia === "email" ? (
              <EmailTemplate notification={notification} onnotificationChange={onnotificationChange} isView={isView} handleDateChange={handleDateChange} />
            ) : (
              <PhoneTemplate notification={notification} onnotificationChange={onnotificationChange} isView={isView} />
            )}

            {/** Save/Cancel buttons */}
            {!isView && (
              <div className="text-end mt-1">
                <button className="btn btn-primary" onClick={handleSubmit} disabled={(!willBroadcast && !recipients.length) || !notification.message}>
                  {t("save")}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
