import React, { useState } from "react";
import WishlistProductCard from "../../components/WishlistProductCard/WishlistProductCard";
import { toast } from "react-toastify";
import { deleteFromWishlist, getAllWishlistItems } from "../../api/wishlistApi";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart, getCartData, removeAllFromCart } from "../../api/Cart";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";
import ProductCard from "../../components/Cards/ProductCard";
import { suggestedProducts } from "../../api/Product";

const WishlistSection = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userWishlist, setUserWishlist, setOrderInit, cartArray, setCartArray, userContext } = useUserCtx();
  const [isLoadingBuyNow, setIsLoadingBuyNow] = useState(false);
  const [emptyOrdersProductArray, setEmptyOrdersProductArray] = useState([]);

  React.useEffect(() => {
    if (userContext.id) {
      suggestedProducts(userContext.id).then((res) => {
        setEmptyOrdersProductArray(res.data?.status === "success" ? res.data.productsWithRatings : []);
      });
    }
  }, [userContext.id]);

  const setCartFunc = () => {
    getCartData().then((res) => {
      if (res) {
        let prod = [];
        res.cart.forEach((item) => {
          if (item?.productId === null) return;
          let product = { ...item?.productId };
          product["quantity"] = item.qty;
          product["from"] = item.from;
          product["coupon"] = item.couponId ? item.couponId : {};
          prod.push(product);
        });
        setCartArray({
          loaded: true,
          no_of_carts: prod.length,
          cart: prod,
          combo: res.combo,
        });
      }
    });
  };

  const handleAddToCart = (id, fromCart = true) => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      let brand_store_id = "";
      if (location.pathname.split("/")[1] === "store") {
        brand_store_id = location.pathname.split("/")[2];
      }
      addToCart({ id, qty: 1, brand_store_id, onsiteGoSelected: null }).then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res.data.message);
          // if (window.confirm(res.data.message)) {
          //   removeAllFromCart().then((response) => {
          //     if (response) {
          //       setCartFunc();
          //       setOrderInit((prev) => ({ ...prev, type: brand_store_id ? "Manual" : "", brand_store_id }));
          //       toast.success(t("manualAddToCart"));
          //       setIsLoadingBuyNow(false);
          //     }
          //   });
          // }
        } else if (res?.data?.status === "Success") {
          setCartFunc();
          setIsLoadingBuyNow(false);
          if (fromCart) {
            toast.success(t("product-page.added-to-cart"));
          } else navigate("/payment");
          handleRemoveFromWishlist(id);
        } else toast.error(t("writeToUs.error"));
      });
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromWishlist = (id) => {
    deleteFromWishlist(id).then((res) => {
      if (res.status === 200) {
        toast.success(t("notifications.ProductRemovedFromWishlist"));
        getAllWishlistItems(true).then((response) => {
          if (response)
            setUserWishlist((prev) => ({
              ...prev,
              loaded: true,
              no_of_wishlist_items: response?.no_of_wishlist_items,
              wishlist_items: response?.wishlist_items,
            }));
        });
      } else toast.error(t("deleteProductInWishlistError"));
    });
  };

  return (
    <>
      {userWishlist.no_of_wishlist_items === 0 ? (
        <>
          <div className="order_Page_Right w-auto">
            <div className="empty_order_sec">
              <p className="empty_order_text">{t("empty-wishlist")}</p>
              <button type="submit" className="submit-button" onClick={() => navigate("/")}>
                <p>{t("start-shopping")}</p>
              </button>
            </div>

            <div className="card-display-row">
              {emptyOrdersProductArray.map((e, i) => (
                <ProductCard
                  key={`${i}-${e?._id}`}
                  id={e?._id ?? ""}
                  productImg={e?.images?.[0] ?? null}
                  productName={e?.title}
                  productDescription={e?.description?.[0]?.split(" ")?.slice(0, 18)?.join(" ") ?? ""}
                  productRating={e?.rating ?? 0}
                  productPriceObj={e?.price}
                  productDiscountObj={e?.discount}
                  productSlug={e?.slug}
                  wishlist={userWishlist.wishlist_items?.some((obj) => obj?._id?.toString() === e?._id?.toString())}
                  cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj?._id?.toString() === e?._id?.toString())}
                  classification={e?.classification}
                  preBookData={e?.preBook}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        userWishlist.wishlist_items.map((item, index) => (
          <WishlistProductCard key={index} product={item} handleRemoveFromWishlist={handleRemoveFromWishlist} handleAddToCart={handleAddToCart} isLoadingBuyNow={isLoadingBuyNow} />
        ))
      )}
    </>
  );
};

export default WishlistSection;
