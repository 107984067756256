import { useState, useEffect } from "react";
import { useToken } from "./useToken";

export const useAdminOrRetailer = (props) => {
    const [token] = useToken(props);

    const getPayloadFromToken = token => {
        const encodedPayload = token.split(".")[1];
        return JSON.parse(window.atob(encodedPayload));
    }

    const [userType, setUserTye] = useState(() => {
        if(!token) return null
        return getPayloadFromToken(token)
    })

    useEffect(() => {
        if(!token) {
            setUserTye(null)
        } else {
            setUserTye(getPayloadFromToken(token))
        }
    }, [token])

    return userType;
}