// components/SEO.js
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ seoData }) => {
  return (
    <Helmet>
      <title>{seoData?.title}</title>
      <meta name="description" content={seoData?.description} />
      <meta name="keywords" content={seoData?.keywords} />
      <meta name="title" content={seoData?.title} />
      <meta property="og:title" content={seoData?.title} />
      <meta property="og:description" content={seoData?.description} />
      <meta property="og:url" content={seoData?.url} />
      <link rel="canonical" href={seoData?.url} />
    </Helmet>
  );
};

export default SEO;
