import React from "react";

import personTalking from "../../assets/vector/personTalking.svg";
import phoneCall from "../../assets/vector/phoneCall.svg";
import locationIcon from "../../assets/vector/locationIcon.svg";
import messageIcon from "../../assets/vector/messageIcon.svg";
import { Link } from "react-router-dom";

export default function GetinTouchPage({store}) {
  return (
    <div className="gtpContainer">
      <div className="gtpContent">
        <div className="gtpSubContent">
          <img alt="phoneCall" className="gtpSubContentImage" src={phoneCall} />
          <Link style={{textDecoration: "none"}} to={`tel:${store?.contact_person}`} className="gtpSubContentText">
            <h1 className="gtpSubContentTextH1">Get in Touch</h1>
            <p>(+91) {store?.contact_person}</p>
          </Link>
        </div>
        <div className="gtpSubContent">
          <img
            alt="locationIcon"
            className="gtpSubContentImage"
            src={locationIcon}
          />
          <div className="gtpSubContentText">
            <h1 className="gtpSubContentTextH1">{store?.city}</h1>
            <p className="gtpSubContentTextAddress">
              {store?.street_name}
            </p>
          </div>
        </div>
        <Link to={`mailto:${store?.store_email_id}`} style={{textDecoration: "none"}} className="gtpSubContent">
          <img
            alt="messageIcon"
            className="gtpSubContentImage"
            src={messageIcon}
          />
          <div className="gtpSubContentText">
            <h1 className="gtpSubContentTextH1">General Enquiries</h1>
            <p>{store?.store_email_id}</p>
          </div>
        </Link>
      </div>
      <img alt="personOnPhone" className="gtpImage" src={personTalking} />
    </div>
  );
}
