import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import SkeletonElement from "../Skeletons/SkeletonElement";
import { useMediaQuery } from "@mui/material";
import "./Section1.css";
import useSiteCtx from "../../hooks/useSiteCtx";
import { Link, useLocation } from "react-router-dom";

const Section1 = ({ id }) => {
  const isMobile = useMediaQuery("(max-width:568px)");
  const { homePageSections } = useSiteCtx();
  const location = useLocation();

  const [carouselData, setCarouselData] = useState(() => homePageSections.products);
  const [mobileCarouselData, setMobileCarouselData] = useState([]);

  useEffect(() => {
    let topBanners = homePageSections.find((section) => section.sectionType === "scroll_banner");
    if (!topBanners) return;
    let banners = topBanners.products;
    banners = banners.filter((banner) => banner.title.split("_")[1] === "HOMEPAGE");
    let topBannersMob = homePageSections.find((section) => section.sectionType === "scroll_banner_smartPhone");
    let mobbanners = topBannersMob?.products;
    if (mobbanners) {
      // mobbanners = mobbanners.filter((banner) => banner.title.split("_")[1] === "HOMEPAGE");
      setMobileCarouselData(mobbanners);
    }
    setCarouselData(banners);
  }, [homePageSections, location]);

  return (
    <div className="section1_contianer" id={id}>
      {isMobile ? (
        <Carousel interval={5000} className="w-100">
          {mobileCarouselData?.length > 0 ? (
            mobileCarouselData.map((item, index) => (
              <Carousel.Item key={index}>
                {item.imgUrl.split(".").pop() !== "mp4" ? (
                  <Link to={item.targetUrl}>
                    <div className="all-categories-banner">
                      <img
                        src={item.imgUrl}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          maxHeight: "450px",
                        }}
                      />
                    </div>
                  </Link>
                ) : (
                  <div className="video-container">
                    <video autoPlay loop muted className="carousel_video">
                      <source src={item.imgUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </Carousel.Item>
            ))
          ) : (
            <SkeletonElement type={"productBanner"} height="180px" />
          )}
        </Carousel>
      ) : (
        <Carousel interval={5000} className="w-100">
          {carouselData?.length > 0 ? (
            carouselData.map((item, index) => (
              <Carousel.Item key={index}>
                {item.imgUrl.split(".").pop() !== "mp4" ? (
                  <Link to={item.targetUrl}>
                    <div className="all-categories-banner">
                      <img
                        src={item.imgUrl}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          maxHeight: "450px",
                        }}
                      />
                    </div>
                  </Link>
                ) : (
                  <div className="video-container" style={{ height: "280px" }}>
                    <video autoPlay loop muted className="carousel_video">
                      <source src={item.imgUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </Carousel.Item>
            ))
          ) : (
            <SkeletonElement type={"productBanner"} height="280px" />
          )}
        </Carousel>
      )}
    </div>
  );
};

export default Section1;
