import React from 'react'
import { useRetailerContext } from '../../Contexts/RetailerContext';
import { CircularProgress } from "@mui/material";
import { listFormat } from '../../utils';
import { Accordion } from 'react-bootstrap';

const RetailerHome = () => {
    const { store, loading } = useRetailerContext();

    return (
        loading ?
            <div className="loader-div"><CircularProgress color="inherit" /></div>
            : <div className="catelogue_Page section_Wrapper">
                <h3>Welcome, {store?.fc_name}</h3>
                <h4>Store Brand Store ID: {store?.brand_store_id}</h4>
                <h4>Store SAP Code: {store?.sap_code}</h4>
                <h4>Store ID: {store?.store_id}</h4>
                <h5>Store Pincode:  {store?.pincode}</h5>
                <Accordion className="mb-4">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Store Serviceable Pincodes: </Accordion.Header>
                        <Accordion.Body style={{
                            color: "black",
                            background: "white"
                        }}>
                            {Array.isArray(store?.serviceablePincodes) ? listFormat(store.serviceablePincodes) : ''}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
    )
}

export default RetailerHome