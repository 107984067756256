import React, { useEffect, useState } from "react";
import logo from "../../assets/png/logo.png";
import { useLocation } from "react-router-dom";
import { getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";
import { currencyFormat, formatDate } from "../../utils";
import DashboardLoader from "../DashboardContent/DashboardLoader";
import { getIndiProduct } from "../../api/Product";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import numberToWords from "number-to-words";
import { useMediaQuery } from "@mui/material";
import "./Taxinvoice.css";

function TaxInvoice() {
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [onsiteGoPlanDetails, setOnsiteGoPlanDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState({});
  const [truncatedDescription, setTruncatedDescription] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPriceInWords, setTotalPriceInWords] = useState("");
  const [gstDetails, setGstDetails] = useState();
  const [comboGstDetails, setComboGstDetails] = useState();
  const [fromAdmin, setFromAdmin] = useState(false);

  const isMobile = useMediaQuery("(max-width: 1000px)");
  useEffect(() => {
    // Function to parse query parameters
    const getQueryParams = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    // Get state from query parameters
    const id = getQueryParams("id");
    const from = getQueryParams("from");
    // Set state if found
    if (id) {
      getAConsignmentFunc(id);
    }
    if (from === "Admin") {
      setFromAdmin(true);
    } else {
      setFromAdmin(false);
    }
  }, []);

  const getAConsignmentFunc = (id) => {
    setLoading(true);
    getAdminIndividualConsignment(id).then((res1) => {
      if (res1?.details) {
        const data = res1.details;
        const updatedProduct = { ...data.product };
        if (data?.couponeId?.value) {
          updatedProduct.price.mop -= data.couponeId.value;
          data.computed_selling_price -= data.couponeId.value;
        }
        setInvoiceDetails(data);
        setOnsiteGoPlanDetails(data.onsitego_data);
        // for (const i in data) {

        setProductData(updatedProduct);
        var onsiteGoProductPrice = data || 0;
        // getProduct(Id, onsiteGoProductPrice);
        // }
        setLoading(false);
      }
    });
  };

  // const getProduct = async (id) => {
  //   const res = await getIndiProduct(id);
  //   if (res) {
  //     console.log("res ", res);

  //   }
  // };

  useEffect(() => {
    if (productData?.description && invoiceDetails) {
      const description = productData?.description[0] ?? ""; // Get the description or default to an empty string if undefined

      // Split the description into an array of words
      setTruncatedDescription(description.split(".")[0]);
      const paymentMode = invoiceDetails?.order?.paymentId?.paymentMode;
      const mappedPaymentMode = paymentType[paymentMode] || paymentMode;
      setPaymentMode(mappedPaymentMode);

      var netAmount = invoiceDetails?.computed_selling_price * invoiceDetails?.quantity + invoiceDetails?.combo_child_price || 0;
      // if (invoiceDetails?.onsitego_data.length > 0 && fromAdmin) {
      //   invoiceDetails.onsitego_data.forEach((item) => {
      //     if (item?.planDetails?.unitPrice) {
      //       netAmount += item.planDetails.unitPrice;
      //     }
      //   });
      // }

      setTotalPrice(netAmount);
      setTotalPriceInWords(capitalizeFirstLetter(numberToWords.toWords(netAmount)));

      // Calculate GST
      const gstPercentage = productData.gstpercentage ?? 0;
      let cgst = 0,
        sgst = 0,
        igst = 0;
      let combo_cgst = 0,
        combo_sgst = 0,
        combo_igst = 0;

      const isSameState = invoiceDetails?.consignor?.state.trim().toLowerCase() === invoiceDetails?.address_state.trim().toLowerCase();
      let devident = 100 + gstPercentage;
      let combo_devident = 100 + invoiceDetails?.combo?.product?.gstpercentage;
      if (isSameState) {
        // Calculate CGST and SGST
        cgst = (((productData.price?.mop / devident) * gstPercentage) / 2) * invoiceDetails?.quantity;
        sgst = (((productData.price?.mop / devident) * gstPercentage) / 2) * invoiceDetails?.quantity;
        combo_cgst = ((invoiceDetails?.combo_child_price / combo_devident) * invoiceDetails?.combo?.product?.gstpercentage) / 2;
        combo_sgst = ((invoiceDetails?.combo_child_price / combo_devident) * invoiceDetails?.combo?.product?.gstpercentage) / 2;
      } else {
        // Calculate IGST
        igst = (productData.price?.mop / devident) * gstPercentage;
        combo_igst = (productData?.combo_child_price / combo_devident) * invoiceDetails?.combo?.product?.gstpercentage;
      }
      let newUnitPrice = productData.price.mop - (cgst + sgst + igst);
      productData.price.mop = newUnitPrice;
      // Update state with GST calculations
      setGstDetails({ cgst, sgst, igst });
      setComboGstDetails({ combo_cgst, combo_sgst, combo_igst });
    }
  }, [productData.description, invoiceDetails]);

  const downloadPDF = () => {
    const element = document.getElementById("invoice");

    const originalStyles = {
      width: element.style.width,
      height: element.style.height,
      overflow: element.style.overflow,
      transform: element.style.transform,
      position: element.style.position,
      top: element.style.top,
      left: element.style.left,
    };

    element.style.width = "1200px"; // Assuming 1024px is the desktop width
    element.style.height = "auto";
    element.style.overflow = "visible";
    element.style.transform = "none";
    element.style.position = "relative";
    element.style.top = "0";
    element.style.left = "0";

    html2canvas(element, {
      scale: 2, // Increase the scale for better resolution
      useCORS: true, // Enable CORS to handle cross-origin images
      scrollX: 0,
      scrollY: 0, // Ensure the entire page is captured, no offset for scroll
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    }).then((canvas) => {
      Object.assign(element.style, originalStyles);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio of the canvas
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the height in the PDF based on the aspect ratio
      const imgHeight = pdfWidth / aspectRatio;

      // Add the image to the PDF, maintaining aspect ratio
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);

      // If the content is taller than one page, add more pages
      let position = imgHeight;
      while (position < (canvas.height / canvas.width) * pdfWidth) {
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, -position, pdfWidth, imgHeight);
        position += pdfHeight;
      }

      pdf.save("invoice_Olineo.pdf");
    });
  };

  // const downloadPDF = () => {
  //   const element = document.getElementById("invoice");

  //   // Store the original styles
  //   const originalStyles = {
  //     width: element.style.width,
  //     height: element.style.height,
  //     overflow: element.style.overflow,
  //     transform: element.style.transform,
  //     position: element.style.position,
  //     top: element.style.top,
  //     left: element.style.left,
  //   };

  //   // Apply desktop-specific styles
  //   element.style.width = "1024px"; // Assuming 1024px is the desktop width
  //   element.style.height = "auto";
  //   element.style.overflow = "visible";
  //   element.style.transform = "none";
  //   element.style.position = "relative";
  //   element.style.top = "0";
  //   element.style.left = "0";

  //   html2canvas(element, {
  //     scale: 3, // Increase the scale for better resolution
  //     useCORS: true, // Enable CORS to handle cross-origin images
  //     scrollX: 0,
  //     scrollY: 0, // Ensure the entire page is captured, no offset for scroll
  //     windowWidth: 1024, // Set window width to simulate desktop view
  //     windowHeight: element.scrollHeight, // Set height to content height
  //   }).then((canvas) => {
  //     // Restore the original styles
  //     Object.assign(element.style, originalStyles);

  //     const imgData = canvas.toDataURL("image/png");

  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     // Calculate the aspect ratio of the canvas
  //     const aspectRatio = canvas.width / canvas.height;

  //     // Calculate the height in the PDF based on the aspect ratio
  //     const imgHeight = pdfWidth / aspectRatio;

  //     // Add the image to the PDF, maintaining aspect ratio
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);

  //     // Check if the content is taller than one page, add more pages if necessary
  //     let position = imgHeight;
  //     while (position < canvas.height) {
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, -position, pdfWidth, imgHeight);
  //       position += pdfHeight;
  //     }

  //     pdf.save("invoice_Olineo.pdf");
  //   });
  // };

  const printInvoice = () => {
    window.print();
  };

  const paymentType = {
    NB: "NetBanking",
    CC: "CREDIT CARD",
    DC: "DEBIT CARD",
    UPI: "UPI",
    EMI: "EMI",
    CASH: "WALLET CASH",
  };

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="mt-3 pt-4 w-100">
      <div className=" " style={{ paddingLeft: `${"5%"}`, paddingRight: `5%` }} id="invoice">
        <div style={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}>
          <img src={logo} alt="olineo_logo" width="25%" />
          <h3 style={{ marginRight: "50px", marginTop: "5px", fontSize: `${isMobile ? "20px" : "40px"}`, fontWeight: "bold" }}>Tax Invoice</h3>
        </div>
        <hr />
        {loading ? (
          <DashboardLoader />
        ) : (
          <>
            <div className="invoice-container pt-3">
              <div className="d-flex items-center gap-10" style={{ gap: "20px" }}>
                <div className="p-2 rounded" style={{ background: "#f2F4f7", width: "50%" }}>
                  <div>
                    <b>Sold By:</b>
                  </div>
                  <div>{invoiceDetails?.consignor?.display_name}</div>
                  <div>
                    {invoiceDetails?.consignor?.street_no}
                    {invoiceDetails?.consignor?.street_name}
                    <br />
                    {invoiceDetails?.consignor?.city},{invoiceDetails?.consignor?.country} - {invoiceDetails?.consignor?.pincode}
                  </div>
                  <div> {invoiceDetails?.consignor?.contact_no}</div>
                </div>
                <div className="p-2 rounded" style={{ background: "#f2F4f7", width: "50%" }}>
                  <div>
                    <b>Billing Address :</b>
                  </div>
                  <div>{invoiceDetails?.address_customerName}</div>
                  <div>
                    {invoiceDetails?.address_address_line1}
                    <br />
                    {invoiceDetails?.address_city},{invoiceDetails?.address_country} - {invoiceDetails?.address_zip}
                  </div>
                  <div> {invoiceDetails?.address_phone}</div>
                </div>
              </div>
              <div className="d-flex gap-10 mt-3" style={{ gap: "20px" }}>
                <div className="p-2 rounded" style={{ background: "#f2F4f7", width: "50%" }}>
                  <div>
                    <b>Shipping Address :</b>
                  </div>
                  <div>{invoiceDetails?.address_customerName}</div>
                  <div>
                    {invoiceDetails?.address_address_line1}
                    <br />
                    {invoiceDetails?.address_city},{invoiceDetails?.address_country} - {invoiceDetails?.address_zip}
                  </div>
                  <div> {invoiceDetails?.address_phone}</div>
                </div>
                <div style={{ width: "50%" }}>
                  <span>
                    <b>Order Id :</b> <span style={{ color: "#5E6470" }}>{invoiceDetails?.order?.orderId ? invoiceDetails?.order.orderId : invoiceDetails?.order._id}</span>
                  </span>
                  <br />
                  <span>
                    <b>Order Date :</b> <span style={{ color: "#5E6470" }}> {invoiceDetails?.createdAt ? formatDate(invoiceDetails.createdAt) : "-"}</span>
                  </span>
                  <br />
                  <span>
                    <b>Invoice Number :</b> <span style={{ color: "#5E6470" }}>{invoiceDetails?.voucherId}</span>
                  </span>
                  <br />
                  <span>
                    <b>Invoice Date :</b> <span style={{ color: "#5E6470" }}> {invoiceDetails?.acceptedAt ? formatDate(invoiceDetails.acceptedAt) : "-"}</span>
                  </span>
                </div>
              </div>

              <div className="mt-3">
                {productData && (
                  <div className="table-responsive">
                    <table className="table table-bordered" style={{ border: "black", fontSize: "small", lineHeight: "12px" }}>
                      <thead>
                        <tr>
                          <th scope="col">SR.NO.</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">HSN Code</th>
                          <th scope="col">Unit price</th>
                          <th scope="col">QTY</th>
                          <th scope="col">Tax Type</th>
                          <th scope="col">Tax Amount</th>
                          <th scope="col">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td style={{ minWidth: "150px" }}>
                            {productData.title}
                            {invoiceDetails?.imei.length > 0 && <> IMEI/Serial No. - {invoiceDetails.imei.find((imei) => imei.productId === productData._id)?.imei}</>}
                            <br />
                            {invoiceDetails?.couponeId?.value && (
                              <>
                                Coupon Applied - <b> {currencyFormat(invoiceDetails?.couponeId?.value)}</b>{" "}
                              </>
                            )}
                          </td>
                          {/* <td style={{ minWidth: "200px", whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>{truncatedDescription}</td> */}
                          <td>{productData?.hsn}</td>
                          <td>{currencyFormat(productData?.price?.mop)}</td>
                          <td>{invoiceDetails?.quantity}</td>
                          {gstDetails?.cgst || gstDetails?.sgst ? (
                            <>
                              <td>
                                CGST <br />
                                SGST
                              </td>
                              <td>
                                {currencyFormat(gstDetails?.cgst)} <br />
                                {currencyFormat(gstDetails?.sgst)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>IGST</td>
                              <td>{currencyFormat(gstDetails?.igst)}</td>
                            </>
                          )}
                          <td>{currencyFormat(invoiceDetails?.computed_selling_price * invoiceDetails?.quantity)}</td>
                        </tr>
                        {invoiceDetails?.combo?.childEan && (
                          <tr>
                            <th scope="row">2</th>
                            <td style={{ minWidth: "150px" }}>
                              {invoiceDetails?.combo?.product?.title} || {invoiceDetails?.combo?.product?.description[0].split(".")[0]}
                            </td>
                            <td>{productData?.hsn}</td>
                            <td>{currencyFormat(invoiceDetails?.combo_child_price - (comboGstDetails?.combo_igst || 0 + comboGstDetails?.combo_cgst || 0 + comboGstDetails?.combo_sgst || 0))}</td>
                            <td>{invoiceDetails?.quantity}</td>
                            {comboGstDetails?.combo_cgst || comboGstDetails?.combo_sgst ? (
                              <>
                                <td>CGST &nbsp;SGST</td>
                                <td>
                                  {currencyFormat(comboGstDetails?.combo_cgst)} &nbsp; {currencyFormat(comboGstDetails?.combo_sgst)}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>IGST</td>
                                <td>{currencyFormat(comboGstDetails?.combo_igst)}</td>
                              </>
                            )}
                            <td>{currencyFormat(invoiceDetails?.combo_child_price)}</td>
                          </tr>
                        )}
                        {invoiceDetails?.onsitego_data?.length > 0 && (
                          <>
                            {onsiteGoPlanDetails.map((item, key) => (
                              <tr key={key}>
                                <th></th>
                                <td style={{ minWidth: "150px" }}>
                                  {item?.planDetails?.productTitle} | {item?.planDetails?.productType}- {item?.planDetails?.duration} - Free
                                </td>
                                <td>-</td>
                                <td>{fromAdmin ? currencyFormat(item?.planDetails?.unitPrice) : "-"}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            ))}
                          </>
                        )}
                        <tr>
                          <th colSpan="7">Total</th>
                          <td>
                            <b>{currencyFormat(totalPrice)}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="8">
                            Amount in words: &nbsp;&nbsp;
                            <b>{totalPriceInWords} Only</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              {/* )} */}

              <div className="d-flex mt-3 justify-content-end">
                <div>
                  <p className="mb-5">For O-Line-O Nexus IND. PVT. LTD.</p>
                  <p>Authorised Signatory</p>
                </div>
              </div>
              <table className="table table-bordered">
                <thead style={{ background: "#f2F4f7" }}>
                  <tr>
                    <th scope="col" style={{ border: "2px solid" }}>
                      Transaction ID
                    </th>
                    <th scope="col" style={{ border: "2px solid" }}>
                      Date & Time
                    </th>
                    <th scope="col" style={{ border: "2px solid" }}>
                      Invoice Value
                    </th>
                    <th scope="col" style={{ border: "2px solid" }}>
                      Mode of Payment
                    </th>
                  </tr>
                </thead>
                <tbody style={{ background: "#E8E1E1" }}>
                  <tr>
                    <td style={{ border: "2px solid" }}>{invoiceDetails?.order?.paymentId?.mihpayid}</td>
                    <td style={{ border: "2px solid" }}>{invoiceDetails?.createdAt ? formatDate(invoiceDetails.createdAt) : "-"}</td>
                    <td style={{ border: "2px solid" }}>{currencyFormat(totalPrice)}</td>
                    <td style={{ border: "2px solid" }}>{paymentMode}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* <div style={{ background: "#F2F4F7" }}></div> */}
      </div>
      {!loading && (
        <div className="d-flex " style={{ justifyContent: "flex-end", gap: "20px", padding: `${isMobile ? "" : "15px 70px 12px 19px"}` }}>
          <button className="btn btn-success btn-sm hidden-print" onClick={() => printInvoice()}>
            Print
          </button>
          <button className="btn btn-success btn-sm hidden-print" onClick={() => downloadPDF()}>
            Download
          </button>
        </div>
      )}
    </div>
  );
}

export default TaxInvoice;
