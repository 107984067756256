import axios from "./axiosInstance";

// saves gst details for a user
export const saveGstDetails = async (payload) => {
    const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/save-gst`, payload, { headers })
    return response.data;
}

// gets GST details for user
export const getGstDetails = async () => {
    const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get-gst`, { headers })
    return response.data;
}
