import React, { useMemo } from 'react'
import "./HeaderBar2.css"
import { Link, useNavigate } from "react-router-dom";
import myProfileSignInIcon from "../../assets/vector/myProfileSignInIcon.svg";
import myOrdersIconPopup from "../../assets/vector/myOrdersIconPopup.svg";
import myWishListIconPopup from "../../assets/vector/myWishListIconPopup.svg";
import myNotificationIconPopup from "../../assets/vector/myNotificationIconPopup.svg";
import myRewardsIconPopup from "../../assets/vector/myRewardsIconPopup.svg";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { logOutUser } from '../../api/Auth';
import useUserCtx from '../../hooks/useUserCtx';
import { useTranslation } from "react-i18next";

function PopperSignIn(props) {
  const nav = useNavigate();
  const { t } = useTranslation();
  const {
    setUserContext,
    setUserAddress,
    setUserWishlist,
    setCartArray,
    setPriceBoxDetails,
    setUserOrderData,
    userContext
  } = useUserCtx();

  const linkDetails = useMemo(() => {
    return [
      {name: t('header.my-profile'), image: myProfileSignInIcon, link: "/myProfile" },
      {name: t('profile.orders'), image: myOrdersIconPopup, link: "/yourOrders"},
      {name: t('myWishlist'), image: myWishListIconPopup, link: "/wishlist"},
      {name: t('myNotifications'), image: myNotificationIconPopup, link: "/"},
      {name: t('myRewards'), image: myRewardsIconPopup, link: "/myRewards"}
    ]
  }, []);
  const handleLogout = () => {
    logOut();
  };
  const logOut = () => {
    logOutUser().then((res) => {
        setUserContext({
            profilePic: "",
            id: "",
            fullName: "",
            mobileNumber: "",
            email: "",
            JWT: "",
            dob: null,
            pincode: "",
        });
        setUserAddress({
            loaded: false,
            no_of_address: 0,
            address: [],
        });
        setCartArray({
            loaded: false,
            cart: [],
            combo: [],
            no_of_carts: 0,
        });
        setUserOrderData({
            loaded: false,
            no_of_orders: 0,
            orders: []
        });
        setPriceBoxDetails({
            cartItemsNumber: 0,
            cartItemsPrice: 0,
            totalDiscount: 0,
            totalDeliveryCharge: 0,
            totalAmount: 0,
        });
        setUserWishlist({
            loaded: false,
            no_of_wishlist_items: 0,
            wishlist_items: [],
        });
    });
    nav("/")
  };

  return (
    <Popper
      open={props.open}
      anchorEl={props.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      style={{
        zIndex: "100000",
        marginTop: "30px",
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={props.handleClosePOP}>
              <MenuList
                autoFocusItem={props.open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={props.handleListKeyDown}
                className="language_dropdown"
                sx={{
                  width: "fit-content",
                  paddingTop: "0",
                }}
              > 

              {linkDetails.map((details, index) => ( 
                <Link to={details.link} style={{textDecoration: "none"}} key={`links1-${index}`}>
                 <MenuItem
                  onClick={props.handleClosePOP}
                  sx={{
                    paddingTop: props.isMobile ? "0" : "5px",
                    paddingBottom: props.isMobile ? "0" : "10px",
                  }}
                  >
                  <img
                    alt={`links-${index}`}
                    src={details.image}
                    style={{
                      marginRight: "5px",
                      width:'18px',
                      height: "25px"
                    }}
                    
                  />{" "}
                  <span className="signIn_list_item">{details.name}</span>
                  </MenuItem>
                </Link>
                 
              ))}
                <MenuItem
                  onClick={props.handleClosePOP}
                  sx={{
                    paddingTop: props.isMobile ? "0" : "5px",
                    paddingBottom: props.isMobile ? "0" : "10px",
                  }}
                >
                  <div
                    style={{
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                    className="header_sign_in_btn"
                    onClick={handleLogout}
                  >
                    <p className='mb-0' style={{color:"black", display: "block"}}>{t('login.logout')}</p>
                  </div>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default PopperSignIn
