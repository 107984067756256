import React from "react";
import { Button, Paper, Rating } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slider from "@mui/material/Slider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ButtonGroup } from "react-bootstrap";
import useScrollDirection from "../../hooks/useScrollDirection";
import { currencyFormat } from "../../utils";
import { useTranslation } from "react-i18next";

function DrawerSection(props) {
  const scrollDirection = useScrollDirection();
  const { t } = useTranslation();
  function valueLabelFormat(value) {
    return value * props.step;
  }

  const handleFilterClick = (event, element) => {
    props.handleAll2(event, element);
    props.isMobile && props.onClick();
    // document.querySelector(".order_Page_Right_res").scrollIntoView();
  };

  return (
    <Paper
      className="aside_res"
      sx={{
        marginTop: props.isMobile ? "200px" : "0px",
        width: props.isMobile ? "100vw" : "23%",
        paddingLeft: props.isMobile ? "40px" : "",
        height: "max-content",
        // position: "sticky",
        top: scrollDirection === "up" ? "156px" : "100px",
      }}
    >
      {props.isMobile && (
        <h3 className="backBtnDrawer" onClick={props.onClick}>
          <ArrowBackIosIcon sx={{ fontSize: "17px" }} /> {t("back")}
        </h3>
      )}
      <div className="aside_container_res">
        <div className="aside-items">
          <span style={{ textTransform: "capitalize" }}>{props.headingName}</span>
        </div>
        <div className="aside-items">
          <hr />
        </div>
        {props.filterListData != null &&
          props.filterListData.map((filter, index) =>
            filter.filter_heading !== "price" ? (
              <Accordion className="Accordian_res aside-items" key={index} sx={{ outline: "none", border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="accordian-itm" sx={{ height: "40px", minHeight: "30px" }}>
                  <span className="head2xl">{filter.filter_heading === "ram" ? <span className="text-uppercase">{filter.filter_heading}</span> : filter.filter_heading}</span>
                </AccordionSummary>
                <AccordionDetails className="accordian-section1 accordian-items" sx={{ paddingTop: "0", paddingBottom: "0" }}>
                  {filter.filter_data.map((element, index) => (
                    <div className="d-flex align-items-center acoor-sec-item  " key={`${element.data}-${index}`} id={element.id} onClick={(event) => handleFilterClick(event, element)}>
                      <div className="Accor-dot" data-filterselected={props.filterSelected?.some((res) => res.id === element.id) && "selected"}></div>
                      <div className="acoor-sec-item">
                        <div className="accorItemName">
                          {filter.filter_heading !== "rating" ? (
                            element.data
                          ) : (
                            <Rating style={{ width: "80%", fontSize: "1.125rem" }} name="product-rating" readOnly value={element.data} size="small" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <div className="aside-price-range  Accordian_res">
                <div className="aside-price">
                  <span className="head2xl">{t("sortAndFilter.price")}</span>
                </div>
                <Slider
                  sx={{
                    color: "#1C3361",
                    ".MuiSlider-rail": {
                      backgroundColor: "#FFCC0A",
                    },
                  }}
                  min={props.minMaxPrice.lowest}
                  max={props.minMaxPrice.highest}
                  getAriaLabel={() => "Temperature range"}
                  value={props.value}
                  onChange={props.handleChange}
                  valueLabelDisplay={valueLabelFormat}
                  getAriaValueText={valueLabelFormat}
                />
                <div className="price-range" id="">
                  <span>{currencyFormat(props.priceRange[0])}</span>
                  <span>{currencyFormat(props.priceRange[1])}</span>
                </div>
                <ButtonGroup style={{ justifyContent: "space-between", marginTop: "5px", gap: "5px" }}>
                  <Button variant="contained" sx={{ background: "#1B325E" }} onClick={props.handlePriceRangeSubmit} size="small">
                    {t("header.search")}
                  </Button>
                  <Button variant="outlined" sx={{ color: "#1B325E" }} onClick={props.handlePriceReset} size="small">
                    {t("reset")}
                  </Button>
                </ButtonGroup>
              </div>
            )
          )}
      </div>
      {/* props.minMaxPrice.lowest !== props.minMaxPrice.highest && */}
      {/* Rating
      <Accordion className="Accordian_res aside-items" sx={{outline:"none", border:"none", boxShadow:"none"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordian-itm"
        >
          <span id="head2xl">Rating</span>
        </AccordionSummary>
        <AccordionDetails className="accordian-section1 accordian-items">
          <div className="acoor-sec-item acit">
            <div className="Accor-dot acit"></div>
            <div className="acit">
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={StarBlank} alt="" />
            </div>
            <div className="acit">& Up</div>
          </div>
          <div className="acoor-sec-item">
            <div className="Accor-dot acit"></div>
            <div className="acit">
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
            </div>
            <div className="acit">& Up</div>
          </div>
          <div className="acoor-sec-item">
            <div className="Accor-dot acit"></div>
            <div className="acit">
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
            </div>
            <div className="acit">& Up</div>
          </div>
          <div className="acoor-sec-item">
            <div className="Accor-dot acit"></div>
            <div className="acit">
              <img src={Star} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
              <img src={StarBlank} alt="" />
            </div>
            <div className="acit">& Up</div>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </Paper>
  );
}

export default DrawerSection;
