import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import "../styles/AddStockPage.css";
import ProductDetailsCard from "./ProductDetailsCard";
import { addStockQty, getIndividualProductDetailsbyEAN } from "../../../api/AdminApis/StockTracker";

const ProductStockTrakerAdd = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    productEan: "",
    quantity: 0,
  });
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetProductDetails = async () => {
    try {
      if (!details?.productEan) return toast.error("Provide OPIN");
      setLoading(true);
      const data = await getIndividualProductDetailsbyEAN(`ean=${details.productEan}`);
      if (data?.status === "success") {
        setProduct(data?.data);
      } else {
        toast.error(`No Product Found!!!`);
      }
    } catch (err) {
      const errorResponse = err?.response?.data?.status === "error";
      toast.error(errorResponse ? err?.response?.data?.message : `Error while fetching Product. Please try again...`);
    } finally {
      setLoading(false);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!details?.productEan) return toast.error("Provide OPIN");
      setLoading(true);
      const payload = {
        ean: details.productEan,
        quantity: details.quantity,
      };
      const data = await addStockQty(payload);
      if (data?.status === "success") {
        toast.success("Stock Added Successfully");
        navigate("/admin/stock-tracker");
      } else {
        toast.error(`No Product Found!!!`);
      }
    } catch (err) {
      const errorResponse = err?.response?.data?.status === "error";
      toast.error(errorResponse ? err?.response?.data?.message : `Error while fetching Product. Please try again...`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container">
      <h3>Add Stock</h3>
      <div className="d-flex flex-column gap-2 justify-content-start align-items-start my-4">
        <TextField
          //   type="number"
          size="small"
          className="form-control fw-700"
          variant="outlined"
          value={details.productEan}
          label="Enter Product OPIN"
          onChange={(e) => {
            // if (!isNaN(e.target.value))
            setDetails((prev) => ({ ...prev, productEan: e.target.value }));
          }}
          inputMode="numeric"
          style={{
            width: "260px",
          }}
        />
        <Button variant="contained" type="button" disabled={details.productEan === "" || loading} className="d-flex justify-content-center align-items-center gap-2" onClick={handleGetProductDetails}>
          <span>Get OPIN Details</span>
          {loading && (
            <CircularProgress
              size="small"
              sx={{
                width: "20px",
                height: "20px",
              }}
            />
          )}
        </Button>
      </div>
      {product && (
        <>
          <ProductDetailsCard heading={"Product Details"} details={{ product }} editHTML={<></>} />
          <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit} className="your-form">
              <TextField
                label="Product OPIN"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={details.productEan}
              />
              <TextField
                label="Quantity"
                variant="outlined"
                fullWidth
                margin="normal"
                value={details.quantity}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setDetails((prev) => ({ ...prev, quantity: e.target.value }));
                }}
              />

              {loading ? (
                <CircularProgress
                  size={"small"}
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                <Button type="submit" variant="contained" color="primary" className="submit-button">
                  Add to Stock
                </Button>
              )}
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductStockTrakerAdd;
