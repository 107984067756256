// top level imports
import React, { useEffect, useReducer, useState } from "react";

// React-Router
import { useNavigate } from "react-router-dom";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { addCupon, getAllExistingEans } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";

const today = new Date().toISOString().split("T")[0];

const maxDate = new Date();
maxDate.setMonth(maxDate.getMonth() + 1);
const oneMonthFromNow = maxDate.toISOString().split("T")[0];

function reducer(state, action) {
  const existingEANOptions = [...state.eanOptions];
  switch (action.type) {
    case "SET_EAN_VALUE":
      return { ...state, eanValue: action.payload };
    case "ADD_SELECTED_EAN":
      return {
        ...state,
        selectedEans: [...state.selectedEans, action.payload],
        eanOptions: existingEANOptions.map((obj) => {
          if (obj.label === action.payload.label) {
            return {
              ...obj,
              selected: true,
            };
          } else return obj;
        }),
      };
    case "REMOVE_SELECTED_EAN":
      return {
        ...state,
        selectedEans: state.selectedEans.filter((ean) => ean !== action.payload),
        eanOptions: existingEANOptions.map((obj) => {
          if (obj.label === action.payload.label) {
            return {
              ...obj,
              selected: false,
            };
          } else return obj;
        }),
      };
    case "SET_ALL_EANS":
      return { ...state, eanOptions: action.payload.map((ean) => ({ label: ean, value: ean, selected: false })) };
    default:
      return state;
  }
}

function DashboardAddDiscount() {
  const initialState = {
    eanValue: "",
    selectedEans: [],
    eanOptions: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllExistingEans();
        if (response?.length > 0) {
          dispatch({ type: "SET_ALL_EANS", payload: response });
        } else {
          toast.error("Something went wrong in Fetching Product EANs.");
        }
      } catch (error) {
        console.err(error);
        toast.error("Oops! Something went wrong");
      }
    };

    fetchData();
  }, []);

  const cuponSubmit = async (e) => {
    e.preventDefault();

    if (state.selectedEans.length <= 0) {
      return toast.error("Please select EAN(s)");
    }
    let maxAmount = e.target.maxAmount.value;
    let discAmount = e.target.discount.value;
    if (parseInt(maxAmount) <= parseInt(discAmount)) {
      return toast.error("Please Enter Correct Amount");
    }
    const data = {
      discount: e.target.discount.value,
      maxAmount: e.target.maxAmount.value,
      expire: e.target.expire.value,
      products: state.selectedEans.map((e) => e.value),
      code: e.target.code.value,
    };
    try {
      const response = await addCupon(data);
      if (response.status === "success") {
        toast.success("Coupon added successfully");
        navigate("../admin-discounts");
      }
    } catch (err) {
      let message = "Something went wrong!";
      if (err.response?.data?.message) {
        message = err.response?.data?.message;
      }
      toast.error(message);
    }
  };

  const handleEanChange = (event, newValue) => {
    dispatch({ type: "SET_EAN_VALUE", payload: newValue });
  };

  const handleEanSelect = (event, newValue) => {
    if (newValue) {
      dispatch({ type: "ADD_SELECTED_EAN", payload: newValue });
      dispatch({ type: "SET_EAN_VALUE", payload: "" });
    }
  };

  const handleRemoveEan = (eanToRemove) => {
    dispatch({ type: "REMOVE_SELECTED_EAN", payload: eanToRemove });
  };

  return (
    <div className="container">
      <Link to="../admin-discounts" className="mb-2">
        <FontAwesomeIcon icon={faChevronLeft} /> Coupons
      </Link>
      <div className="card-body bg-light shadow-sm mt-2 mb-4">
        <h3>Add Coupon</h3>
      </div>
      <form onSubmit={cuponSubmit} className="d-flex flex-column gap-3">
        <TextField label="Discount (in Rs)" autoFocus placeholder="Discount (in Rs)" type="number" name="discount" required />
        <TextField label="Max Amount" placeholder="Max Amount" type="number" name="maxAmount" required />
        <TextField label="Code (Name should be alphanumeric only)" placeholder="Code" name="code" required />
        <TextField placeholder="Expire in" type="date" inputProps={{ min: today, max: oneMonthFromNow }} name="expire" required />

        <Box>
          <Autocomplete
            value={null}
            onChange={handleEanSelect}
            inputValue={state.eanValue}
            onInputChange={handleEanChange}
            options={state.eanOptions}
            getOptionLabel={(option) => option?.label || ""}
            getOptionDisabled={(option) => option.selected}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
            }}
            renderInput={(params) => <TextField {...params} label="Enter EAN" variant="outlined" />}
            fullWidth
          />
          <Box mt={2}>
            {state.selectedEans.map((ean, index) => (
              <Chip key={index} label={ean.label} onDelete={() => handleRemoveEan(ean)} variant="outlined" style={{ marginRight: "8px", marginBottom: "8px" }} />
            ))}
          </Box>
        </Box>

        <div className="d-flex justify-content-end">
          <Button type="submit" className="btn btn-primary mr-auto float-right mt-2 px-5">
            Add
          </Button>
        </div>
      </form>
    </div>
  );
}

export default DashboardAddDiscount;
