import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faQuestion,
  faUser,
  faTimes,
  faTags,
  faPercent,
  faCartArrowDown,
  faMoneyBill,
  faShop,
  faCancel,
  faDatabase,
  faGift,
  faBell,
  faWarehouse,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineEditRoad } from "react-icons/md";
import logo_desk from "../../assets/png/navbar_logo_desk.png";
import logo from "../../assets/png/O-LINE-Osymbol.png";
import control from "../../assets/png/control.png";
import Chart_fill from "../../assets/png/dashboard (2).png";
import policy from "../../assets/png/policy.png";
import positiveRevie from "../../assets/png/positiveRevie.png";
import calander from "../../assets/png/Calendar.png";
import faq from "../../assets/png/faq.png";
import Chat from "../../assets/png/Chat.png";
import wallet from "../../assets/png/wallet.png";
import Folder from "../../assets/png/Folder.png";
import finance from "../../assets/png/finance.png";
import Setting from "../../assets/png/Setting.png";
import userData from "../../assets/png/userData.png";
import members from "../../assets/png/members.png";
import feedback from "../../assets/png/feedback.png";
import megaphone from "../../assets/png/megaphone.png";
import product from "../../assets/png/product.png";
import funds from "../../assets/png/funds.png";
import seo from "../../assets/png/seo.png";

const SideBar = ({ isOpen, toggle }) => {
  const location = useLocation();
  const handleToggle = () => {
    if (isOpen) {
      toggle();
    }
  };
  const navigate = useNavigate();
  const Menus = [
    { title: "Dashboard", src: Chart_fill, targetLink: "", isActive: location.pathname === "/admin" },
    { title: "Analytics and Sales", src: funds, targetLink: "admin-analytics", isActive: location.pathname === "admin-analytics" },
    { title: "Products", src: product, targetLink: "admin-products", isActive: location.pathname.includes("admin-products") },
    { title: "Promotion", src: megaphone, targetLink: "admin-home-page", gap: true, isActive: location.pathname.includes("admin-home-page") },
    { title: "Payments", src: wallet, targetLink: "admin-payments", isActive: location.pathname.includes("admin-payments") },
    { title: "Coupons", src: finance, targetLink: "walletCoupoons", isActive: location.pathname.includes("walletCoupoons") },
    { title: "Complains & Enquiry", src: feedback, targetLink: "admin-query", isActive: location.pathname.includes("admin-query") },
    { title: "O-Line-O Members", src: members, targetLink: "admin-shops", isActive: location.pathname.includes("admin-shops") },
    { title: "All User's", src: userData, targetLink: "admin-alluser", isActive: location.pathname.includes("admin-alluser") },
    { title: "Reviews and Comments", src: positiveRevie, targetLink: "admin-product-comments", isActive: location.pathname.includes("admin-product-comments") },
    { title: "Policy", src: policy, targetLink: "terms-page", isActive: location.pathname.includes("terms-page") },
    { title: "FAQ", src: faq, targetLink: "admin-faq", isActive: location.pathname.includes("admin-faq") },
    { title: "Notification", src: Chat, targetLink: "admin-notifications", isActive: location.pathname.includes("admin-notifications") },
    { title: "SEO", src: seo, targetLink: "AdminAddSeo", isActive: location.pathname.includes("AdminAddSeo") },
    { title: "Setting", src: Setting, targetLink: "admin-Settings", isActive: location.pathname.includes("admin-Settings") },
  ];

  return (
    <div
      className={`mainNavDiv  p-3  duration-300 text-white `}
      style={{ position: "fixed", height: "100vh", zIndex: "999", transitionDuration: "300ms", backgroundColor: "#081A4F", width: `${isOpen ? "18rem" : "5rem"}` }}
    >
      <img
        src={control}
        className={` cursor-pointer border-dark-purple
           border-2 rounded-full `}
        style={{ position: "absolute", right: "-0.75rem", top: "2.25rem", transform: `${isOpen ? "" : "rotate(180deg)"}`, width: "1.75rem", border: "1px solid #081A4F", borderRadius: "9999px" }}
        onClick={() => toggle()}
      />
      <div className=" items-center d-flex cursor-pointer" style={{ alignItems: "center", columnGap: "1rem", height: "40px" }}>
        <img
          src={logo}
          className={`cursor-pointer duration-500 ${isOpen && "rotate-360"}`}
          style={{ width: "40px", height: "40px", transitionDuration: "500ms", transform: `${isOpen ? "rotate(360deg)" : ""}` }}
        />
        <h1
          className={`text-white origin-left font-medium text-xl duration-200 ${!isOpen && "scale-0"}`}
          style={{ marginBottom: "0rem", transform: `${!isOpen ? "scale(0)" : ""}`, transitionDuration: "200ms", fontWeight: "500", fontSize: "1.25rem", lineHeight: "1.75rem" }}
        >
          O-LINE-O
        </h1>
      </div>
      <ul className="sideBarList" style={{ paddingTop: "1.5rem", paddingLeft: "0rem", overflowY: "auto", height: "100%" }}>
        {Menus.map((Menu, index) => (
          <Link className="no-underline" to={Menu.targetLink} onClick={() => handleToggle()} style={{ textDecoration: "none" }}>
            <li
              key={index}
              className={`  adminNavLink ${Menu.isActive ? "bgActive" : ""} flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white text-sm items-center gap-x-4`}
              style={{ display: "flex", color: "grey", fontSize: "0.875rem", lineHeight: "1.25rem", borderRadius: "0.375rem", width: `${!isOpen ? "40px" : "100%"}`, transitionDuration: "200ms" }}
            >
              <img src={Menu.src} style={{ height: "24px" }} />
              <span className={`${!isOpen ? "hidden" : ""} origin-left `}>{Menu.title}</span>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
