import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { completeOffer } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";
import SVGLoader from "./SVGLoader";
import "./step1.css";
import Select from "react-select";
import { TextField } from "@mui/material";

function Page3() {
  const { OfferId, IFDUserId } = useContext(OfferContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (OfferId === null) {
  //     navigate("/ifd");
  //   }
  // }, [OfferId]);

  useEffect(() => {
    if (IFDUserId === null) {
      navigate("/indianfestivaldays2024");
    }
  }, [IFDUserId]);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: "Which flagship models Realme have launched recently?",
      options: ["GT 6T", "GT 6", "Above All"],
      answer: "Paris",
    },
    {
      id: 2,
      question: "Which model of Realme mobile you would love to buy?",
      options: ["GT 6", "GT 6T", "C65 5G"],
      answer: "Jupiter",
    },
    {
      id: 3,
      question: "Give a reason to recommend your friends to buy Realme mobile ?",
      options: ["Affordable", "Best In Quality", "Above All"],
      answer: "Vatican City",
    },
  ]);

  const [answers, setAnswers] = useState({});

  const handlecompleteQuiz = async () => {
    if (imeinumber && !isValidIMEI(imeinumber)) {
      toast.error("Enter Valid IMEI Number");
    } else {
      const payload = {
        IFDUserId : IFDUserId,
        productCategory : productDetails.selectedCategory,
        productsubCategory : productDetails.selectedSubCategory,
        imeinumber : imeinumber,
        productPrice : productPrice,
      };
      if(IFDUserId){
        setLoading(true);
        const res = await completeOffer(payload);
        if (res.status === "success") {
          toast.success(res?.message);
          if(res.productAmount >= 9990){
            navigate("/indianfestivaldays2024/4");
          } else{
            toast.error("You are not eligible for the offer");
            navigate("/indianfestivaldays2024");
          }

        }
      }
      // navigate("/indianfestivaldays2024/4");

    }
    // if (!answers[1] || !answers[2] || !answers[3]) return toast.error("Please Select All Answers");
    // if (OfferId) {
    //   setLoading(true);
    //   const res = await completeOffer(OfferId);
    //   if (res.status === "success") {
    //     toast.success(res?.message);
    //     navigate("/quiz-Offer/4");
    //   }
    // }
  };

  const handleOptionChange = (questionId, option) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: option }));
  };


  // ifd 2024 changes added
  const [categoryErr, setCategoryErr] = useState(false);
  const [modelNumber, setmodelNumber] = useState("");
  const [imeinumber, setimeinumber] = useState("");
  const [productPrice, setproductPrice] = useState("");
  const [productDetails, setProductDetails] = useState({
    selectedCategory: "",
    selectedSubCategory: "",
  });
  const [erMesg, setErMesg] = useState("");
  
  const categoryList = [
    { value: "smartphone", label: "Smartphone" },
    { value: "accessories", label: "Accessories" },
    { value: "feature_phone", label: "Feature Phone" },
    { value: "laptop", label: "Laptop" },
    { value: "tablet", label: "Tablet" },
    { value: "tv", label: "TV" }
];

const SubCategoryList = [
  { value: "realme", label: "Realme" },
  { value: "oppo", label: "Oppo" },
  { value: "vivo", label: "Vivo" },
  { value: "samsung", label: "Samsung" },
  { value: "apple", label: "Apple" },
  { value: "motorola", label: "Motorola" },
  { value: "redmi", label: "Redmi" },
  { value: "oneplus", label: "OnePlus" },
  { value: "others", label: "Others" }
];

  const categoryListStyles = {
    menu: (provided, state) => ({
      ...provided,
      padding: 2,
      textAlign: "initial",
      cursor: "pointer",
      fontFamily: "Montserrat",
    }),

    control: (_, { selectProps: { width } }) => ({
      width: width,
      background: "transparent",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      height: "48px",
      fontFamily: "Montserrat",
      maxWidth: "100%",
      maxHeight: "48px",
      border: categoryErr && "2px solid red",
      color: categoryErr && "red",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color:"#000000",
    }),
    singleValue: (provided, state) => {
      const fontFamily = "Montserrat";

      return { ...provided, fontFamily };
    },
  };

  const handlePrice = (value) => {
    setLoading(true);
    setproductPrice(value);
    if(value >= 9990){
      setErMesg("");
      setLoading(false);
    } else{
      setErMesg("#Price not eligible");
    }
  }

  const isValidIMEI = (imei) => {
    if (imei.length !== 15 || !/^\d+$/.test(imei)) return false;
  
    let sum = 0;
    for (let i = 0; i < 15; i++) {
      let digit = parseInt(imei[i], 10);
      if (i % 2 !== 0) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  };

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const handleIMEIChange = (e) => {
    const value = e.target.value;
    setimeinumber(value);

    if (value && !isValidIMEI(value)) {
      setError(true);
      setHelperText("Invalid IMEI number. Must be a 15-digit number.");
    } else {
      setError(false);
      setHelperText("");
    }
  };

  return (
    <div className="container mb-4 sub-container text-center position-relative"
        style={{
          maxWidth: "400px",
        }}>
      <h3>3. Enter the product details here:</h3>

      <Select
          className="ifd24text"
          autoComplete="off"
          defaultValue={productDetails.selectedCategory}
          onChange={(e) => {
            setCategoryErr(false);
            setProductDetails((prev) => ({ ...prev, selectedCategory: e.label }));
            setProductDetails((prev) => ({ ...prev, selectedSubCategory: "" }));
          }}
          isLoading={false}
          isClearable={false}
          placeholder="Category"
          isSearchable={false}
          menuColor="#381867"
          options={categoryList}
          styles={categoryListStyles}
        />

        {
          productDetails.selectedCategory == "Smartphone" && (
            <>
              <Select
                className="ifd24text mt-3"
                autoComplete="off"
                defaultValue={productDetails.selectedSubCategory}
                onChange={(e) => {
                  setCategoryErr(false);
                  setProductDetails((prev) => ({ ...prev, selectedSubCategory: e.label }));
                }}
                isLoading={false}
                isClearable={false}
                placeholder="Sub-Category"
                isSearchable={false}
                menuColor="#381867"
                options={SubCategoryList}
                styles={categoryListStyles}
              />
            </>
          )
          
          
        }
        {
          productDetails.selectedSubCategory == "Realme" && (
            <>
              <TextField
                size="small"
                label="IMEI Number"
                variant="filled"
                inputMode="text"
                className="form-control mt-3 store-input"
                type="text"
                required
                placeholder="IMEI Number"
                style={{ width: "100%", position:"relative",zIndex:"1" }}
                value={imeinumber}
                // onChange={(e) => setimeinumber(e.target.value)}
                onChange={handleIMEIChange}
                error={error}
                helperText={helperText}
              />
            </>
          )
        }


        {
          productDetails.selectedCategory && (
            <>
              {/* <TextField
                size="small"
                label="Model Number"
                variant="filled"
                inputMode="text"
                className="form-control mt-3 store-input"
                type="text"
                required
                placeholder="Model Number"
                style={{ width: "100%", zIndex: "999" }}
                value={modelNumber}
                onChange={(e) => setmodelNumber(e.target.value)}
              /> */}

              <div className="d-flex align-items-center">
                <TextField
                  style={{position:"relative",zIndex:"1"}}
                  size="small"
                  label="Product Price"
                  variant="filled"
                  inputMode="text"
                  className="form-control mt-3 store-input"
                  type="text"
                  disabled
                  placeholder="Product Price"
                  value=""
                />
                <span className="mx-2" style={{fontSize:"30px"}}>₹</span>
                <input
                  size="small"
                  variant="filled"
                  inputMode="text"
                  className="form-control ifd24text mt-3 store-input"
                  type="text"
                  required
                  placeholder="0000"
                  style={{ width: "100%", position:"relative",zIndex:"1", height:"46px" }}
                  value={productPrice}
                  onChange={(e) => handlePrice(e.target.value) }
                />
              </div>
              <p style={{position:"relative",zIndex:"1"}}>{erMesg}</p>

            </>
          )
        }

        <button style={{position:"relative",zIndex:"1"}} type="button" onClick={() => handlecompleteQuiz()} className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" disabled={loading}>
          {loading ? <SVGLoader /> : <span>Submit</span>}
        </button>

      {/* <h1 className="text-center">Quiz Contest</h1> */}
      {/* <form>
        {questions.map((question) => (
          <div key={question.id} className="mb-3 questionContainer">
            <h3>{question.question}</h3>
            {question.options.map((option, index) => (
              <div key={index} className="form-check optionLabel" style={{ padding: "0px 15px" }} onClick={() => handleOptionChange(question.id, option)}>
                <label className="d-flex" style={{ gap: "20px", fontSize: "25px" }}>
                  <input style={{ width: "17px" }} type="checkbox" name={question.id} value={option} checked={answers[question.id] === option} />
                  {option}
                </label>
              </div>
            ))}
          </div>
        ))}
        <button type="button" onClick={() => handlecompleteQuiz()} className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" disabled={loading}>
          {loading ? <SVGLoader /> : <span>Submit</span>}
        </button>
      </form> */}
    </div>
  );
}

export default Page3;
