export const FilterListData = {
        "all": [
    ],
    "Smartphone": [
    ],"True%20Wireless%20Earbuds": [
    ],
    "Laptop": [
    ],
    "Tablet": [
    ],
    "Smart%20TV": [  
    ],
    "Powerbank": [
    ],
    "Bluetooth%20Headphones": [],
    "Bluetooth%20Speaker": [],
    "Soundbar": [],
    "Bluetooth%20Neckband": [],
    "Wired%20headphones": [],
    "Wifi%20Smart%20Speaker": [],
    "Wired%20Earphones": [],
    "Security%20Camera": [],
    "Feature%20Phone": [],
    "Smart%20Band": [],
    "Wall%20Adapter": [],
    "Charging%20Cable": [],
    "Wall%20Adapter%20and%20Cable%20Combo": [],
    "HDMI%20Cable": [],
    "3.5mm%20Headphone%20Adapter": [],
    "Miscellaneous": [],
    "Smartwatch": []
}

export default FilterListData;

