import React, { useEffect, useState } from "react";
import "./CartProductCard.css";

//images
import addIcon from "../../assets/vector/add_outline_blue.svg";
import deleteIconBlack from "../../assets/vector/delete_outline_black.svg";
import deleteIcon from "../../assets/vector/delete_outline_blue.svg";
import saveLaterIcon from "../../assets/vector/save_later_outline.svg";
import addToCartIcon from "../../assets/vector/cart_outline_blue.svg";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

// Utils
import { formatNumber } from "../../utils";

const CartProductCard = ({
  product,
  handleQuantityInc,
  handleQuantityDec,
  handleRemoveFromCart,
  comboProduct = false,
  saveForLaterItem = false,
  handleAddItemToSaveForLater,
  handleAddToCart,
  handleRemoveFromSaveForLater,
  deliveryEstDays,
}) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const matches = width >= 768;

  const [finalPrice, setFinalPrice] = useState(0);
  const [prodData, setProdData] = useState({
    id: "",
    ean: "",
    name: "",
    color: "",
    price: {
      mrp: "",
      mop: "",
      discountPrice: "",
    },
    discount: "",
    productOffersAvailable: "",
    productQuantityAvailable: "",
    productAvailabilty: "",
    image: "",
    quantity: "",
    deliveryEst: {
      days: "",
      amount: "",
      loaded: false,
      msg: "",
      error: false,
    },
    slug: "",
    hierarchyL2: "",
  });
  useEffect(() => {
    if (product) {
      const cartProduct = {
        id: product?._id,
        ean: product?.ean,
        name: product?.title,
        color: product?.color,
        price: product?.price,
        productOffersAvailable: product?.productOffersAvailable,
        productAvailabilty: product?.productAvailabilty,
        productQuantityAvailable: product?.productQuantityAvailable,
        image: product?.images?.[0],
        quantity: product?.quantity,
        slug: product?.slug,
        hierarchyL2: product?.hierarchyL2,
      };
      setProdData((prev) => ({
        ...prev,
        ...cartProduct,
      }));
    }
  }, [product]);

  useEffect(() => {
    const flatDiscount = product?.discount?.flatDiscount;
    if (flatDiscount.value > 0 && flatDiscount.discountType === "flat") {
      const calcPrice = formatNumber(product?.price?.mop - flatDiscount?.value);
      setFinalPrice(!isNaN(calcPrice) ? calcPrice : 0);
    } else if (flatDiscount.value > 0 && flatDiscount.discountType === "percentage") {
      const calcPrice = formatNumber(product?.price?.mrp - flatDiscount?.value / 100);
      setFinalPrice(!isNaN(calcPrice) ? calcPrice : 0);
    } else {
      setFinalPrice(formatNumber(product?.price?.mop));
    }
  }, [product]);

  useEffect(() => {
    const discountVal = +product?.discount?.flatDiscount?.value + (product.price.mrp - product.price.mop);

    if (discountVal > 0) {
      const discountMsg = product?.discount?.flatDiscount?.discountType === "flat" ? `₹${formatNumber(discountVal)}` : `${formatNumber(discountVal)}%`;
      setProdData((prev) => ({
        ...prev,
        discount: discountMsg,
      }));
    } else {
      const mrp = parseInt(product?.price?.mrp);
      const mop = parseInt(product?.price?.mop);
      const discount = formatNumber(((mrp - mop) / mrp) * 100);
      const discountMsg = `${discount}%`;
      setProdData((prev) => ({ ...prev, discount: discountMsg }));
    }
  }, [product]);

  useEffect(() => {
    var prodDelDay;
    var prodDelPrice;
    if (prodData && prodData.id && deliveryEstDays && deliveryEstDays.loaded) {
      let prodDelEst = deliveryEstDays.value.find((obj) => obj.skuId === prodData.ean);
      if (prodDelEst && prodDelEst.deliverymodes.length > 0) {
        prodDelPrice = prodDelEst.deliverymodes[0].deliveryCost.value;
        let time = prodDelEst.deliverymodes[0].deliveryTime;
        prodDelDay = formatNumber(parseInt(time) / 24);
        setProdData((prev) => ({
          ...prev,
          deliveryEst: {
            loaded: true,
            amount: formatNumber(prodDelPrice),
            days: prodDelDay,
            msg: "",
            error: false,
          },
        }));
      } else {
        setProdData((prev) => ({
          ...prev,
          deliveryEst: {
            loaded: true,
            error: true,
            msg: "Product Not Available",
          },
        }));
      }
    }
  }, [deliveryEstDays]);

  return (
    <div className="cart_Product_Contianer section_Wrapper">
      <div className={`cart_Product_Wrapper ${comboProduct ? "border-0" : ""}`} style={{ flexDirection: "row-reverse", justifyContent: "flex-end" }}>
        <div className="product_Details cart_product_card">
          <div className="cart_Product_Left">
            {prodData.name && (
              <Link to={`/product/${prodData.slug}`} target="_blank">
                <p className="cart_Product_Name">{prodData.name}</p>
              </Link>
            )}
            {prodData.color && (
              <p className="cart_Product_Color">
                {t("color")} : {prodData.color}
              </p>
            )}
            {comboProduct && (
              <div className="cart_Product_Left">
                <p className="cart_Product_Discount" style={{ color: "#f1c40f" }}>
                  {t("cart.combo-worth")}
                </p>
              </div>
            )}
            <div className="cart_Product_Price_Section">
              <div className="d-flex flex-column">
                <p className="cart_Product_Discount_Price">₹{finalPrice}</p>
                {prodData.price.mrp && <span className="cart_Product_Original_Price">₹{prodData?.price?.mrp}</span>}
              </div>
              {prodData.discount && (
                <p className="cart_Product_Discount " style={{ fontSize: "small" }}>
                  {prodData?.discount} off
                </p>
              )}
            </div>
            {prodData.productOffersAvailable && <p className="cart_Product_Offers">{prodData.productOffersAvailable}</p>}
            {matches && <p className={`cart_Product_Availability ${prodData.productQuantityAvailable <= 10 ? "color_Red" : ""} `}>{prodData.productAvailabilty}</p>}
          </div>
          <div className="cart_Product_Delivery_Info">
            {prodData.deliveryEst.loaded ? (
              <>
                {prodData.deliveryEst.error ? (
                  <>
                    <p className="cart_Product_Delivery_Estimate">{prodData.deliveryEst.msg}</p>
                  </>
                ) : (
                  <>
                    <p className="cart_Product_Delivery_Estimate">
                      {t("cart.delivery-in")} {prodData.deliveryEst.days} {t("cart.days")}
                    </p>{" "}
                    | <p className="cart_Product_Delivery_Charge">₹{prodData.deliveryEst.amount}</p>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {!matches && <p className={`cart_Product_Availability ${prodData.productQuantityAvailable <= 1 ? "color_Red" : ""}`}>{prodData.productAvailabilty}</p>}
        </div>
        <div className="cart_Product_Card_Right">
          <div className="cart_Product_Image_Container">
            <img style={{ height: "80px", width: "110px", objectFit: "contain" }} src={prodData.image} alt="" />
          </div>
          {!comboProduct && !saveForLaterItem ? (
            <div className="cart_Product_Counter_Container">
              <div className="counter_Icon_Container" onClick={() => handleQuantityDec(prodData)}>
                <img src={deleteIconBlack} alt="Delete" />
              </div>
              <p className="cart_Product_Counter">{prodData.quantity}</p>
              <div className="counter_Icon_Container" onClick={() => handleQuantityInc(prodData)}>
                <img src={addIcon} alt="Add" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!comboProduct ? (
        <div className="combined_Button_Container">
          <>
            {saveForLaterItem ? (
              <div className="combined_Button_One" onClick={() => handleAddToCart(prodData.id, prodData)}>
                <img src={addToCartIcon} alt="Add To Cart" />
                <p>{t("product-page.add-to-cart")}</p>
              </div>
            ) : (
              <div className="combined_Button_One" onClick={() => handleAddItemToSaveForLater(prodData.id)}>
                <img src={saveLaterIcon} alt="Save For Later" />
                <p>{t("cart.saveForLater")}</p>
              </div>
            )}
          </>
          <>
            {saveForLaterItem ? (
              <div className="combined_Button_Two" onClick={() => handleRemoveFromSaveForLater(prodData.id)}>
                <img src={deleteIcon} alt="Remove Save For later Item" />
                <p>{t("remove")}</p>
              </div>
            ) : (
              <div className="combined_Button_Two" onClick={() => handleRemoveFromCart(prodData.id)}>
                <img src={deleteIcon} alt="Remove Cart Item" />
                <p>{t("remove")}</p>
              </div>
            )}
          </>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CartProductCard;
