import React from "react";
import { useMediaQuery } from "@mui/material";
import AboutUs from "./AboutUs";
import MobileAboutUs from "./mobileAboutUs";

function MainAboutUs() {
  const isMobile = useMediaQuery("(max-width:770px)");
  return isMobile ? <MobileAboutUs /> : <AboutUs />;
}

export default MainAboutUs;
