import React, { useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { formatDate } from "../../utils";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { initPreBookRefund } from "../../api/AdminApis/Refund";
import { toast } from "react-toastify";

function PreBookOrder({ preBookOrder, getOrders, totalOrders, loading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoading, setLoading] = useState(false);

  const productsPerPage = 30;

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const initRefund = async (preBookId) => {
    try {
      setLoading(true);
      const response = await initPreBookRefund(preBookId);
      const payload = response.data;
      if (payload?.status === 200) {
        toast.success(payload.message);
        getOrders();
      } else {
        toast.error(payload.message);
      }
    } catch {
      // handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-between mb-3">
          {loading ? (
            <DashboardLoader />
          ) : (
            <>
              {preBookOrder && (
                <div className="w-100">
                  {preBookOrder.length > 0 && (
                    <table className="table ">
                      <thead>
                        <tr style={{ color: "#7b7b7b", background: "#f3f6f9" }} className="text-uppercase">
                          <th scope="col">#</th>
                          <th scope="col">PreBook ID</th>
                          <th scope="col">customer</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Product OPIN</th>
                          <th scope="col">PreBooked Amount</th>
                          <th scope="col">Remaining Amount</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {preBookOrder.map((item, index) => (
                          <tr key={index}>
                            <td>{(currentPage - 1) * productsPerPage + index + 1}</td>
                            <td>{item?._id}</td>
                            <td>{item?.userId?.fullName}</td>
                            <td>{item?.status}</td>
                            <td>{formatDate(item?.createdAt)}</td>
                            <td>{item?.productId?.title}</td>
                            <td>
                              {item?.productId?.ean}
                              <br />
                              Product Status : {item?.productId?.classification}
                            </td>
                            <td>{item?.amount}</td>
                            <td>
                              {item?.userId?.preBooked.find((preBookedItem) => preBookedItem.productId === item?.productId?._id)
                                ? item?.productId?.price?.mop - item?.userId?.preBooked.find((preBookedItem) => preBookedItem.productId === item?.productId?._id).amount
                                : item?.productId?.price?.mop}
                            </td>
                            <td>
                              {item?.status === "PREBOOKING_CANCELLED" ? (
                                <button className="btn btn-danger" onClick={() => initRefund(item._id)} disabled={btnLoading}>
                                  Refund
                                </button>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <div className="pagination_Container mt-3">
                    <Pagination productsPerPage={productsPerPage} totalProducts={totalOrders} pageChange={handlePageChange} currentPage={currentPage} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreBookOrder;
