import axios from "../axiosInstance";

let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

const headers = {
  "Access-Control-Allow-origin": "*",
  "Content-Type": "application/json",
  Authorization: `Bearer ${adminToken}`,
};

export const fetchAllQuizUser = async (query = "") => {
  let response;
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };
  try {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/offerUsers?${query}`, { headers }).then((res) => {
      response = res.data;
    });
  } catch (error) {
    response = error?.response;
    console.log("error ", error);
  }
  return response;
};

// export const fetchAllIFDUser = async (query = "") => {
export const fetchAllIFDUser = async (body) => {
  let response;
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };
  try {
    // await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/IFDUsers?${query}`, { headers }).then((res) => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/admin/IFDUsers`, body , { headers }).then((res) => {
      response = res.data;
    });
  } catch (error) {
    response = error?.response;
    console.log("error ", error);
  }
  return response;
};

export const fetchSubscribedUser = async (query = "") => {
  let response;
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };
  try {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/fethSubscribedUser?${query}`, { headers }).then((res) => {
      response = res.data;
    });
  } catch (error) {
    response = error?.response;
    console.log("error ", error);
  }
  return response;
};
export const createSubscribeuser = async (email) => {
  let response;
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/admin/createSubscribedUser`, { email }, { headers }).then((res) => {
      response = res;
    });
  } catch (error) {
    response = error?.response;
    console.log("error ", error);
  }
  return response;
};

export const deletOfferUser = async (id = "") => {
  let dltuser;
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/admin/offerUsers/${id}`, { headers }).then((res) => {
      dltuser = res.data;
    });
  } catch (error) {
    dltuser = error?.response;
    console.log("error ", error);
  }
  return dltuser;
};

export const deletSubscribedUser = async (id = "") => {
  let dltuser;
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/admin/deletesubscribedUsers/${id}`, { headers }).then((res) => {
      dltuser = res.data;
    });
  } catch (error) {
    dltuser = error?.response;
    console.log("error ", error);
  }
  return dltuser;
};

export const getDayGraph = async (query, cb) => {
  await axios
    .get(`https://olineospinandwin.tk/day-graph?${query}`, { headers })
    .then((res) => {
      return cb(null, res.data.data);
    })
    .catch((err) => cb(err, null));
};

export const getProductGraph = async (query, cb) => {
  await axios
    .get(`https://olineospinandwin.tk/product-graph?${query}`, { headers })
    .then((res) => {
      return cb(null, res.data.data);
    })
    .catch((err) => cb(err, null));
};
