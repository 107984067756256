import React, { useEffect, useRef, useState } from "react";
import MapWrapper from "../../components/MapComp/MapWrapper";

export default function FindStoreMap({ stores, zoomLevel }) {
  const firstDiv = document.getElementById('outermostDiv');
  const width = firstDiv?.offsetWidth;
  const mapContainerRef = useRef(null);
  const [mapSize, setMapSize] = useState({
    width: width,
    height: 0,
  });

  const latitudes = stores.map(
    ({ latitude, longitude, display_name }) => {
      return { lat: +latitude, lng: +longitude, title: display_name };
    }
  );

  useEffect(() => {
    const updateMapSize = () => {
      if (mapContainerRef.current) {
        setMapSize({
          width: width,
          height: mapContainerRef.current.offsetHeight,
        });
      }
    };

    updateMapSize();

    window.addEventListener("resize", updateMapSize);

    return () => {
      window.removeEventListener("resize", updateMapSize);
    };
  }, []);

  const centerPos = stores?.filter(ele => ele?.city === "Mumbai")?.map(ele => ({ lat: +ele?.latitude, lng: +ele?.longitude }))[0] ?? latitudes[2];

  return (
    <div className="map" style={{ width: "100%", height: "500px" }}>
      <MapWrapper
        zoomLevel={zoomLevel}
        center={centerPos}
        markerPositions={latitudes}
      />
    </div>
  );
}