import React from 'react'
import "./ContactUs.css"
import ContactUsMainImage from "../../assets/aboutUs/ContactUsMainImage.svg"
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

function ContactUs() {
    const location = useLocation();
    const { t } = useTranslation();

    const breadCrumbsData = [
        {
            text: t('profile.profile') + " / ",
            url: "/myProfile",
            isActive: location.pathname === "/myProfile"
        },
        {
            text: t('footer.contact'),
            url: "/write-to-us",
            isActive: location.pathname === "/write-to-us"
        },
    ];

  return (
    <div className="parentClassCustomerSupport">
        <div className="header">
              <span className='fs-6'>{breadCrumbsData.map((item, index) =>
                  <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
                  </Link>)}</span>
            <span className="HeadingText">Contact Us </span>
        </div>
        <div className="contactusMain">
            <div className='contactusMainsub'>
                <span>Contact Us</span>
                <div className="contactphonesection">
                    <span>Get in Touch</span>
                    <span style={{fontWeight:"400"}}>(+91) 63900 63900</span>
                </div>
            </div>
            <img style={{
                width:"100%",
                height:"600px",
                objectFit:"contain",
                marginBottom:"-160px"
            }} src={ContactUsMainImage}/>
        </div>
    </div>
  )
}

export default ContactUs