import axios from "../axiosInstance";

/**
 * @desc - Sends notifications (email | phone) to users
 * @param {Object} payload 
 * @returns {Object | null} - Response from api
 */
export const sendNotification = async (payload) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notification/admin`,
        payload,
        {headers}
    );

    return response?.data ?? null;
}

/**
 * @desc - Fetches a paginated list of notifications
 * @param {String} query 
 * @returns { Object | null }
 */
export const getNotifications = async (query) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/notification/admin?${query}`, {headers}
    );

    return response?.data ?? null;
}

/**
 * @desc - fetches notification details by id
 * @param {String} notificationId 
 * @returns { Object | null }
 */
export const getNotification = async (notificationId) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/notification/${notificationId}/admin`, {headers}
    );

    return response?.data ?? null;
}

/**
 * @desc - deletes a notification by its id
 * @param {String} notificationId 
 * @returns { Object | null }
 */
export const deleteNotification = async (notificationId) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/notification/${notificationId}/admin`, {headers}
    );

    return response?.data ?? null;
}