import React from "react";
import { Modal } from "react-bootstrap";

//CSS
import "./Modal.css";

//Images

const CommonModal = ({ ModalHeaderComp, ModalBodyComp, ModalFooterComp, modalShow, setModalShow }) => {
  // console.log(ModalHeaderComp);

  const handleClose = () => setModalShow(false);
  return (
    <>
      <Modal show={modalShow} onHide={handleClose} backdrop="true" keyboard={false} backdropClassName="backdrop_active">
        {ModalHeaderComp && (
          <Modal.Header closeButton>
            <ModalHeaderComp />
          </Modal.Header>
        )}
        {ModalBodyComp && (
          <Modal.Body>
            <ModalBodyComp />
          </Modal.Body>
        )}
        {ModalFooterComp && (
          <Modal.Footer closeButton>
            <ModalFooterComp />
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default CommonModal;
