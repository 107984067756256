import { Box, Button, Fade, TextField } from "@mui/material";
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import Backdrop from '@mui/material/Backdrop';
import { toast } from "react-toastify";
import { getUser, updateUser } from "../../api/Auth";

function EditDetailsModal({ open, handleClose, setOpenModal, isMobile, data }) {
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "360px" : 420,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    padding: 0,
    paddingBottom: "20px",
    outline: "none",
  };

  const [btnDisable, setBtnDisable] = useState(true);
  const [email, setEmail] = useState(() => data.email);
  const [emailError, setEmailError] = useState(() => data.email ? false : true);
  const {
    setUserDefaultAddress,
    setUserContext,
  } = useUserCtx();


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }

  useEffect(() => {
    const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(EmailRegex.test(email)) {
      setEmailError(false);
      setBtnDisable(false);
    } 
    else {
      setEmailError(true);
      setBtnDisable(true);
    } 
  }, [email]);

  function handleFormSubmit(e) {
    e.preventDefault();
    updateUser({
      email
    }).then((res) => {
      if(res.status === "success") {
        toast.success("Email Updated Successfully");
        getUser().then((res) => {
          if (res) {
              let user = res;
              setUserContext((prev) => ({
                  ...prev,
                  ...user
              }));
          }
      });
      } else toast.error(t('writeToUs.error'))
    }).finally(() => handleCloseModal())
    
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ zIndex: "1000000" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          component="form"
          sx={{
            ...style,
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off">
          <h1 className="locationModalHeader" >Edit Your Details</h1>
          <div style={{
            display: "grid",
            placeContent: "center",
            gap: isMobile ?  "0.4rem" : "0.8rem"
          }}>
            <TextField
              id="filled-name"
              InputProps={{
                readOnly: true,
              }}
              helperText="Cannnot be Edited"
              label="Your Name"
              defaultValue={data?.fullName ?? ""}
              variant="filled"
            />
            <TextField
              error={emailError}
              autoFocus
              id="filled-email"
              label="Your Email"
              defaultValue={email ?? ""}
              helperText={!email ? "No Email Found" : (emailError ?  "Invalid Email Format" : "")}
              variant="filled"
              onChange={handleEmailChange}
            />
            {/* <TextField
              error
              id="filled-shipping-address"
              label="Error"
              defaultValue="Your Shipping Address"
              helperText="Incorrect entry."
              variant="filled"
            /> */}
            <TextField
              id="filled-phone"
              label="Your Phone Number"
              defaultValue={data?.phone}
              helperText="Cannnot be Edited"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
            <Button variant="contained" type="submit" sx={{background: "var(--primaryDarkBlue)"}} disabled={btnDisable} onClick={handleFormSubmit}>Update</Button>
          </div>
            <CloseIcon onClick={() => setOpenModal(false)} className="locationmodalCloseIcon" />
        </Box>
      </Fade>
    </Modal>
  );
}

export default EditDetailsModal;
