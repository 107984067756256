import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PlusMinus = ({ handleQuantityInc, handleQuantityDec, quantity }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <div
        style={{
          border: "1px solid #F8F9FA",
          backgroundColor: "#F8F9FA",
          borderRadius: "3px",
        }}
        className="cursor-pointer"
        onClick={handleQuantityDec}
      >
        <RemoveIcon sx={{ color: "#3C96DB" }} fontSize="medium" />
      </div>
      <div
        style={{
          border: "1px solid #F8F9FA",
        }}
      >
        <span>{quantity}</span>
      </div>
      <div
        style={{
          border: "1px solid #F8F9FA",
          backgroundColor: "#F8F9FA",
          borderRadius: "3px",
        }}
        className="cursor-pointer"
        onClick={handleQuantityInc}
      >
        <AddIcon sx={{ color: "#3C96DB" }} fontSize="medium" />
      </div>
    </div>
  );
};

export default PlusMinus;
