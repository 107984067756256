import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { saveUser } from "../api/Auth";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import CircleLoading from "../components/CircleLoading";
import useUserCtx from "../hooks/useUserCtx";
import SignInBg from "../assets/productIcons/SignUpBG.svg"

// Component definition
const AddUser = () => {
    const nav = useNavigate();
    const { setUserContext } = useUserCtx();

    // state definitions
    const [userData, setUserData] = useState({
        user_Full_Name: "",
        user_Email: "",
        user_Pin_Code: "",
    });
    const [selectedDay, setSelectedDay] = useState(null);
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let userDT = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : "";
        if (userDT) {
            setUserData({
                user_Full_Name: userDT.fullName,
            });
        }
    }, []);
    // console.log(selectedDay);

    const handleInput = (prop, e) => {
        e.target ? setUserData({ ...userData, [prop]: e.target.value }) : setUserData({ ...userData, [prop]: e.label });
    };

    useEffect(() => {
        if (userData.user_Full_Name && userData.user_Email && userData.user_Pin_Code && selectedDay) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
    }, [userData]);

    const formSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setBtnDisable(true);
        setUserContext((prev) => ({
            ...prev,
            fullName: userData.user_Full_Name,
            email: userData.user_Email,
            dob: selectedDay,
            pincode: userData.user_Pin_Code,
        }));
        saveUser(userData, selectedDay).then((res) =>
            res ? (nav("/"), setLoading(false), setBtnDisable(false), toast.success("Registration Successful")) : (setLoading(false), setBtnDisable(false), toast.error("Incomplete Data"))
        );
    };

    return (
        <>
            <HeaderBar />
            <div className="signUpParentClass" style={{
                background: `url(${SignInBg}) no-repeat`,
                backgroundSize: "cover"
            }}>

                <div className="signup-wrapper">
                    <div className="signup-header">
                        <h1 className="page-heading">Complete your profile</h1>
                        <p className={"page-desc"}>And you’re good to go</p>
                    </div>
                    <form action="" className={"signup-form"} onSubmit={formSubmit}>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Full Name<span className="text-danger">*</span></div>
                            <div>
                                <input type="text" name="Name" id="name" className="input-field" placeholder="Name" value={userData.user_Full_Name} onChange={(value) => handleInput("user_Full_Name", value)} />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Email<span className="text-danger">*</span></div>
                            <div>
                                <input type="email" name="Email" id="email" className="input-field" placeholder="Email" value={userData.user_Email} onChange={(value) => handleInput("user_Email", value)} />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">DOB<span className="text-danger">*</span></div>
                            <div>
                                <DatePicker maxDate={new Date()} value={selectedDay} onChange={setSelectedDay} format="dd/MM/y" className={"input-field custom-date-picker"} />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Pincode<span className="text-danger">*</span></div>
                            <div>
                                <input
                                    type="text"
                                    name="Pincode"
                                    id="pincode"
                                    maxLength={6}
                                    className="input-field"
                                    placeholder="Pin code"
                                    value={userData.user_Pin_Code}
                                    onChange={(value) => handleInput("user_Pin_Code", value)}
                                />
                            </div>
                        </div>
                        <div className={"button-Container"}>
                            {loading ? (
                                <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                                    <CircleLoading />
                                </div>
                            ) : (
                                ""
                            )}
                            <button className="submit-button" disabled={btnDisable}>
                                <p>Continue</p>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddUser;
