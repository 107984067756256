import React from "react";
import { useEffect, useReducer, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function TermsAndCondtionModal(props) {
  return (
    <Dialog {...props} onClose={props.onHide} fullWidth className="terms-dialog-box">
      <div className="d-flex justify-content-between">
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogActions>
          <CloseIcon onClick={props.onClose} className="cursor-pointer" />
        </DialogActions>
      </div>

      <DialogContent>
        <div>
          {/* <li className="container-li"> */}
          <div className="" dangerouslySetInnerHTML={{ __html: props.termData.points }} />
          {/* </li> */}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TermsAndCondtionModal;
