import React, { useEffect, useState } from "react";
// import moment from 'moment-timezone';
import { getAdminAllOrder, getAdminIndOrder, getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import { Card, ListGroup } from "react-bootstrap";
import AdminConsignmentDetailsModal from "../../components/OrderProductModal/AdminConsignmentDetailsModal";
import AdminUserDetailsModal from "../../components/OrderProductModal/AdminUserDetailsModal";
import { Button, TextField } from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import { currencyFormat, formatDate, isOfferActive } from "../../utils";

import "../../pages/MyCart/MyCart.css";
import { Link, useNavigate } from "react-router-dom";
import { IoShareSocial } from "react-icons/io5";
import { RWebShare } from "react-web-share";
import NothingImage from "../../assets/png/your_order_image.png";
import { getIndiProduct } from "../../api/Product";
import * as moment from "moment";

// Component definition
function DashboardOrders({ handleTabChange, getConsignemt }) {
  // state definitions
  const [order, setOrder] = useState([]);
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(1);
  const [details, setDetails] = useState(null);
  const [modalName, setModalName] = useState(null);
  const [consignmentSelected, setConsignmentSelected] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [consignmentDetails, setConsignmentDetails] = useState([]);
  const [notPlacedOrder, setNotPlacedOrder] = useState(false);
  const [onsiteGodata, setOnsiteGodata] = useState([]);

  const productsPerPage = 30;

  const handleClick = (event) => {
    const value = event.target.value;
    handleTabChange("allconsignment"); // Replace with the actual value you want to pass
    getConsignemt(`search=${value}`, 1);
  };

  const handleSingleOrderClick = (id) => {
    handleTabChange("allconsignment"); // Replace with the actual value you want to pass
    getConsignemt(`search=${id}`, 1);
  };

  useEffect(() => {
    if (searchValue === "") {
      getOrders("", "Something went wrong in fetching All Orders", currentPage);
    }
  }, [currentPage, searchValue]);
  useEffect(() => {
    getOrders("", "Something went wrong in fetching All Orders", currentPage);
  }, []);

  function getOrders(value = "", errorMessage, pageNumber) {
    setLoader(true);
    getAdminAllOrder(`limit=${productsPerPage}&page=${pageNumber}&${value}`).then((res) => {
      if (res.orders) {
        const orders = res.orders;
        setOrder(orders);
        setTotalOrders(res.total_orders);
        setLoader(false);
      } else {
        setTotalOrders(0);
        setLoader(false);
        setOrder([]);
        toast.error(errorMessage);
      }
    });
  }

  const seeProduct = (id) => {
    let seeProdBtn = document.querySelectorAll("#seeProducts");
    seeProdBtn.forEach((btn) => {
      btn.style.pointerEvents = "none";
    });
    getAdminIndOrder(id)
      .then((res1) => {
        if (res1?.details) {
          setDetails(res1.details);
          setShow(true);
        } else toast.error("Something went wrong in fetching User Details");
      })
      .finally(() =>
        seeProdBtn.forEach((btn) => {
          btn.style.pointerEvents = "auto";
        })
      );
  };

  const getOneConsignment = (id) => {
    getAdminIndividualConsignment(id).then((res1) => {
      if (res1?.details) {
        setDetails(res1.details);
        setShow(true);
      } else toast.error("Something went wrong in fetching Individual Consignment");
    });
  };

  const getSearchConsignment = (value) => {
    value && getOrders(`search=${value}`, "Something went wrong in fetching Searched Orders", 1);
  };

  function handleSearchClick(e) {
    e.preventDefault();
    if (searchValue) getSearchConsignment(searchValue);
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleClickDetails = (data) => {
    if (data.consignments.length > 0) {
      console.log("data.consignments ", data.consignments);
      setConsignmentDetails(data.consignments);
      if (data.has_onsitego.length > 0) {
        const promises = data.has_onsitego.map(async (item) => {
          const prod = await getIndiProduct(item.product);
          return { ...item, product: prod }; // Destructure and spread to preserve other item properties
        });

        // Wait for all product fetches to complete before updating state
        Promise.all(promises).then((updatedOnsitegoData) => {
          setOnsiteGodata(updatedOnsitegoData);
        });
      }
      setNotPlacedOrder(false);
    } else {
      setNotPlacedOrder(true);
      setConsignmentDetails(data.productId);
    }
  };

  const getFinalDisplayPrice = (productData) => {
    let prod = productData;
    if (productData?.product) {
      prod = productData?.product;
    }
    const mop = parseFloat(prod?.price?.mop);
    if (!mop) return 0;
    const flatDiscount = prod?.discount?.flatDiscount;
    const couponAmt = productData?.couponeId?.value;

    let result = mop;

    if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
      if (flatDiscount?.discountType === "flat") {
        result -= flatDiscount?.value;
      } else {
        result -= (result * flatDiscount?.value) / 100;
      }
    }

    if (couponAmt > 0) {
      result -= couponAmt;
    }

    return result;
  };

  const getTotalPriceOfProduct = (price, qty) => {
    if (price && qty) {
      return currencyFormat(price * qty);
    }
    return "-";
  };

  const getTotalPrice = (consignment) => {
    let totalPrice = 0;

    consignment.forEach((cons) => {
      if (cons.computed_selling_price) {
        totalPrice += cons.computed_selling_price * cons.quantity;

        // if (cons.onsitego_data.length > 0) {
        //   cons.onsitego_data.forEach((item) => {
        //     totalPrice += item.planAmount * item.quantity;
        //   });
        // }
        if (cons.couponeId?.value) {
          totalPrice -= cons.couponeId?.value;
        }
      } else {
        totalPrice += cons.price.mop;
      }
    });

    return totalPrice;
  };

  const getComboProdPrice = (comboProdMop, comboOffer) => {
    let result = comboProdMop;
    const discountVal = parseFloat(comboOffer.value);

    if (comboOffer.discountType !== "flat") {
      result = result - (result * discountVal) / 100;
    } else {
      result = result - discountVal;
    }
    return result;
  };

  const DATE_FORMAT = "dddd, MMM DD, YYYY, hh:mm A";
  const getShippedDate = (item) => {
    if (item?.shipment_track_activities) {
      const dasta = moment(
        item?.shipment_track_activities?.find((activity) => {
          return activity["sr-status"] === 6; // Consignment shipped
        })?.date ?? null
      ).format(DATE_FORMAT);
      return dasta;
    } else {
      return null;
    }
  };

  const getOutForDeliveryDate = (item) => {
    if (item?.shipment_track_activities) {
      return moment(
        item?.shipment_track_activities?.find((activity) => {
          return activity["sr-status"] === 17; // Consignment out for delivery
        })?.date ?? null
      ).format(DATE_FORMAT);
    } else {
      return null;
    }
  };
  const navigate = useNavigate();

  const closeModal = (slug) => {
    document.getElementById("closeModalBtn").click();
    navigate(`/product/${slug}`);
  };
  return loader ? (
    <DashboardLoader />
  ) : (
    <>
      <div className="container">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-2 justify-content-center align-items-center">
            <TextField size="small" id="outlined-basic" label="Search Order ID" variant="outlined" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <Button variant="contained" onClick={handleSearchClick} disabled={!searchValue}>
              Search
            </Button>
          </div>
        </div>
        <div>
          {order.length > 0 ? (
            <table className="table ">
              <thead>
                <tr style={{ color: "#7b7b7b", background: "#f3f6f9" }} className="text-uppercase">
                  <th scope="col">#</th>
                  <th scope="col">ORDER ID</th>
                  <th scope="col">CUSTOMER</th>
                  <th scope="col">No. of PRODUCT</th>
                  <th scope="col">ORDER DATE</th>
                  <th scope="col">AMOUNT</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {order.map((item, index) => (
                  <tr key={index} style={{ background: `${item.isPaid ? "rgb(180, 240, 197)" : ""}` }}>
                    <td>{(currentPage - 1) * productsPerPage + index + 1}</td>
                    <td>{item?.orderId ? item?.orderId : item?._id}</td>
                    <td>{item?.userId?.fullName ? item?.userId?.fullName : item?.address_customerName}</td>
                    <td>{item.consignments.length}</td>
                    <td>{formatDate(item.createdAt)}</td>
                    <td>{currencyFormat(item.totalPrice)}</td>
                    <td>
                      {item.isPlaced ? (
                        <>
                          {item.consignments.length === 1 ? (
                            <span className="cursor-pointer" onClick={() => handleSingleOrderClick(item?.consignments[0]?._id)}>
                              {item.consignments.map((data, i) => (
                                <span>
                                  {data.status === "INITIATED" ? (
                                    "Placed".toUpperCase()
                                  ) : data.status === "CONSIGNMENT_ACCEPTED" ? (
                                    "Confirmed".toUpperCase()
                                  ) : data.status === "DELIVERY_INITIATED" ? (
                                    "Ready to Ship".toUpperCase()
                                  ) : data.status === "SHIPPED" ? (
                                    "Shipped".toUpperCase()
                                  ) : data.status === "OUT_FOR_DELIVERY" ? (
                                    "Out For Delivery".toUpperCase()
                                  ) : data.status === "DELIVERY_SUCCESS" ? (
                                    "Delivered".toUpperCase()
                                  ) : (
                                    <span>{data.status.toUpperCase()}</span>
                                  )}
                                </span>
                              ))}
                            </span>
                          ) : (
                            <select onChange={handleClick} style={{ width: "100%" }}>
                              {item.consignments.map((data, i) => (
                                <option key={data._id} value={data._id}>
                                  {data.status === "INITIATED" ? (
                                    "Placed".toUpperCase()
                                  ) : data.status === "CONSIGNMENT_ACCEPTED" ? (
                                    "Confirmed".toUpperCase()
                                  ) : data.status === "DELIVERY_INITIATED" ? (
                                    "Ready to Ship".toUpperCase()
                                  ) : data.status === "SHIPPED" ? (
                                    "Shipped".toUpperCase()
                                  ) : data.status === "OUT_FOR_DELIVERY" ? (
                                    "Out For DELIVERY".toUpperCase()
                                  ) : data.status === "DELIVERY_SUCCESS" ? (
                                    "Delivered".toUpperCase()
                                  ) : (
                                    // default case
                                    <span>{data.status.toUpperCase()}</span>
                                  )}
                                </option>
                              ))}
                            </select>
                          )}
                        </>
                      ) : (
                        "Not Placed".toUpperCase()
                      )}
                    </td>
                    <td>
                      <button type="button" className="btn btn-success  btn-sm " data-toggle="modal" data-target="#consignmerntModal" onClick={() => handleClickDetails(item)}>
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No Order Found!</div>
          )}
        </div>

        <div className="pagination_Container mt-3">
          <Pagination productsPerPage={productsPerPage} totalProducts={totalOrders} pageChange={handlePageChange} currentPage={currentPage} />
        </div>
      </div>
      {show &&
        (modalName === "consignment" ? (
          <AdminConsignmentDetailsModal show={show} onHide={() => setShow(false)} details={details} consignmentSelected={consignmentSelected} />
        ) : modalName === "user" ? (
          <AdminUserDetailsModal show={show} onHide={() => setShow(false)} details={details} />
        ) : null)}

      <div className="modal fade" id="consignmerntModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order Details
              </h5>
              <button type="button" className="close" data-dismiss="modal" id="closeModalBtn" aria-label="Close" style={{ border: "none", background: "white", fontSize: "large" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  {consignmentDetails.length > 0 && (
                    <div style={{ width: "100%", overflowX: "auto", marginTop: "10px" }}>
                      {consignmentDetails[0].address_customerName && (
                        <div className="user-details-card">
                          <h5>User Details</h5>
                          <hr />
                          <div className="user-details d-flex w-100">
                            <div className="w-50">
                              <p>
                                <strong>Name:</strong> {consignmentDetails[0]?.address_customerName}
                              </p>
                              <p>
                                <strong>Address:</strong>{" "}
                                <span>
                                  {consignmentDetails[0]?.address_address_line1} {consignmentDetails[0]?.address_city}, {consignmentDetails[0].address_state}
                                </span>
                              </p>
                              <p>
                                <strong>Zip Code:</strong> {consignmentDetails[0]?.address_zip}
                              </p>
                            </div>
                            <div className="w-50">
                              <p>
                                <strong>Country:</strong> {consignmentDetails[0]?.address_country}
                              </p>
                              <p>
                                <strong>Phone:</strong> {consignmentDetails[0]?.address_phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      <h4>Products</h4>
                      <hr />
                      <table className="table table-bordered" style={{ width: "100%" }}>
                        <thead className="tHead">
                          <tr>
                            <td style={{ width: "5%", backgroundColor: "rgb(224, 224, 224)" }}>#</td>
                            <td style={{ width: "10%" }}>Image</td>
                            <td style={{ width: "40%" }}>Product</td>
                            <td style={{ width: "10%" }}>Price</td>
                            <td style={{ width: "10%" }}>Quantity</td>
                          </tr>
                        </thead>
                        <tbody>
                          {consignmentDetails.map((item, index) => (
                            <React.Fragment key={`cart_section_${index}`}>
                              <tr>
                                <td style={{ alignContent: "center" }} className="text-center">
                                  {index + 1}
                                </td>
                                <td style={{ alignContent: "center" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Link
                                      to={`/product/${item.product?.slug ? item.product?.slug : item?.slug}`}
                                      className="normal-link"
                                      onClick={() => closeModal(item.product?.slug ? item.product?.slug : item?.slug)}
                                    >
                                      <img
                                        className=" rounded-3"
                                        style={{ width: "100px", height: "70px", objectFit: "contain" }}
                                        alt="product-1image"
                                        src={item?.product?.images?.length ? item.product.images[0] : item?.images[0]}
                                      />
                                    </Link>
                                  </div>
                                </td>
                                <td style={{ alignContent: "center", width: "300px" }}>
                                  <div className="d-flex flex-wrap text-justify text-wrap flex-column">
                                    <div>
                                      <Link
                                        to={`/product/${item.product?.slug ? item.product?.slug : item?.slug}`}
                                        onClick={() => closeModal(item.product?.slug ? item.product?.slug : item?.slug)}
                                        style={{ color: "black" }}
                                        className="normal-link"
                                      >
                                        <span className="prodTitle">{item.product?.title ? item.product?.title : item?.title}</span>
                                      </Link>
                                    </div>

                                    {Object.entries(item.product?.productInfo ? item.product?.productInfo : item?.productInfo)
                                      .slice(0, 3)
                                      .map(([key, value]) => (
                                        <div key={key} className="Productdetails" style={{ marginTop: "0px" }}>
                                          <b>{key.replace(/_/g, " ").replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase())} :</b> {value}
                                        </div>
                                      ))}
                                    <div className="d-flex  delSave">
                                      <RWebShare
                                        data={{
                                          text: item.product?.description ? item.product?.description : item?.description,
                                          url: "product/" + item.product?.slug ? item.product?.slug : item?.slug,
                                          title: item.product?.title ? item.product?.title : item?.title,
                                        }}
                                      >
                                        <button className="border-0 bg-transparent">
                                          <IoShareSocial />
                                        </button>
                                      </RWebShare>
                                    </div>
                                    <div className="mt-2">
                                      <span>
                                        {notPlacedOrder ? "Product ID : " : "Consignment ID : "}
                                        <b>{item._id}</b>
                                      </span>
                                    </div>
                                    {item.status && (
                                      <div className="mt-2">
                                        <span>
                                          Consignment Stauts :<b>{item.status}</b>
                                        </span>
                                      </div>
                                    )}
                                    {item.couponeId && (
                                      <div className="mt-2">
                                        <span>
                                          Coupon Applied :<b> {item.couponeId.code}</b>
                                          <br />
                                          Coupon Amount :<b> {currencyFormat(item.couponeId.value)}</b>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td style={{ alignContent: "center", textAlign: "center" }}>
                                  <span className="perProductPrice">{currencyFormat(getFinalDisplayPrice(item))}</span>
                                </td>
                                <td style={{ alignContent: "center", textAlign: "center" }}>
                                  <span>{item.quantity ? item.quantity : 1}</span>
                                </td>
                              </tr>
                              {item.combo?.product && (
                                <>
                                  <tr
                                    key={`cart_section_row_${index}`}
                                    style={{
                                      "& > *": {
                                        borderBottom: "1px solid #E0E0E0",
                                      },
                                      "& > :first-child": {
                                        borderLeft: "1px solid #E0E0E0",
                                      },
                                      "& > :last-child": {
                                        borderRight: "1px solid #E0E0E0",
                                      },
                                      backgroundColor: "transparent",
                                      // fontFamily: "Qui  cksand",
                                    }}
                                  >
                                    <td
                                      align="center"
                                      colSpan={1}
                                      style={{
                                        border: "1px solid #E0E0E0",
                                      }}
                                    ></td>
                                    <td
                                      align="center"
                                      style={{
                                        borderRight: "none",
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center">
                                        <div>
                                          <img
                                            className=" "
                                            style={{ width: "110px", height: "80px", objectFit: "contain" }}
                                            alt="product-1image"
                                            src={item.combo?.product?.images?.length ? item.combo?.product?.images[0] : NothingImage}
                                          />
                                          <span>Combo Product</span>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <div>
                                          <span>{item.combo?.product?.title}</span>
                                          <span className="cart-product-desc">{item.combo?.product?.description[0]?.split("\n")[0]}</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td align="center">
                                      <span className="perProductPrice">{currencyFormat(getComboProdPrice(item.combo?.product?.price?.mop, item?.combo)) ?? ""}</span>
                                    </td>

                                    <td
                                      align="center"
                                      colSpan={1}
                                      style={{
                                        borderRight: "1px solid #E0E0E0",
                                      }}
                                    >
                                      -
                                    </td>
                                  </tr>
                                </>
                              )}
                              {onsiteGodata.map((data) => (
                                <>
                                  {item?.product?._id === data?.product?._id && (
                                    <tr>
                                      <td></td>
                                      <td>{/* <img style={{ width: "110px", height: "80px", objectFit: "contain" }} src={data?.product?.images[0]} alt="image" /> */}</td>
                                      <td>
                                        <div>
                                          {data.planTitle} For {data?.product?.title}
                                          <br />
                                          SKU : <b>{data.plan}</b>
                                        </div>
                                      </td>
                                      <td className="perProductPrice" style={{ textAlign: "center" }}>
                                        {currencyFormat(data.planAmount * data.quantity)}
                                      </td>
                                      <td style={{ textAlign: "center" }}>{data.quantity}</td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end">
                        <span>
                          <b>Total Amount : </b> <span>{currencyFormat(getTotalPrice(consignmentDetails))}</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardOrders;
