import { createContext, useEffect, useState } from "react";

const SmartBandhanContext = createContext(null);

const SmartBandhanState = (props) => {
    const [SmartBandhanId, setSmartBandhanId] = useState(null);
    const [storecode, setStorecode] = useState("");

    function changeSmartBandhanId(id) {
        setSmartBandhanId(() => id)
    }
    function changeStoreCode(sap_code) {
        setStorecode(() => sap_code)
    }

    useEffect(() => {
        if(SmartBandhanId) {
            localStorage.removeItem("SmartBandhanId");
            localStorage.setItem("SmartBandhanId", SmartBandhanId);
        }
    }, [SmartBandhanId]);

    useEffect(() => {
        if(storecode) {
            localStorage.removeItem("storecode");
            localStorage.setItem("storecode", storecode);
        }
    }, [storecode]);

    return (
        <SmartBandhanContext.Provider value={{
            SmartBandhanId,
            changeSmartBandhanId,
            changeStoreCode,
            storecode
        }}>
            {props.children}
        </SmartBandhanContext.Provider>
    )
}

export { SmartBandhanState };
export default SmartBandhanContext;