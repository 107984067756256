// top level imports
import { useState, useEffect } from "react";

// React-Router
import { useParams, Link, useNavigate } from "react-router-dom";

// React-toast
import { toast } from "react-toastify";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
// - API
import { getQueryById, submitQueryResponse } from "../../api/AdminApis/AdminQuiry";

// Component definition
export function QueryResponse() {
    // Hooks
    const { id } = useParams();
    const navigate = useNavigate();

    // state definitions
    const [loading, setLoading] = useState(false);
    const [customerQuery, setCustomerQuery] = useState(null);

    // ComponentDidMount / ComponentDidUpdate
    useEffect(() => {
        fetchQueryDetails(id)
    }, [id]);

    /** Handler functions - starts */

    const fetchQueryDetails = async (queryId) => {
        setLoading(true)
        try {
            const response = await getQueryById(queryId);
            if (response.status === "success") {
                setCustomerQuery(response.data.query)
            }
        } finally {
            setLoading(false)
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCustomerQuery(prev => ({ ...prev, [name]: value }));
    }

    const submitResponse = async () => {
        setLoading(true)
        try {
            const { _id, responseText } = customerQuery;
            const response = await submitQueryResponse({ _id, responseText });

            if (response.status) {
                toast.success(response.message);
                navigate("../admin-query")
            } else {
                toast.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    }

    /** Handler functions - ends */

    if (loading) {
        return <DashboardLoader />
    }
    // Main renderer
    return (
        <div className="container">
            <div className="d-flex justify-content-between mb-2">
                <Link to="../admin-query">
                    <FontAwesomeIcon icon={faChevronLeft} /> Queries
                </Link>

                <h4>Reply to Query</h4>
            </div>

            <div className="bg-white rounded mt-3 p-4">
                <div className="row mb-2">
                    <div className="col-md-3">
                        <h5>Query : <b>{customerQuery?.queryId}</b></h5>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-md-12">
                        <p>Query Message : {customerQuery?.query}</p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-md-12">
                        <textarea
                            type="text"
                            name="responseText"
                            placeholder="Query Response"
                            className="support_Form_Input support_Form_Query mb-0"
                            value={customerQuery?.responseText ?? ''}
                            onChange={handleInputChange}
                        />
                        <p className="catalogue_Hint">Response message for the query that will be seen by issuer</p>
                    </div>
                </div>

                <div className="text-end mt-1">
                    <button
                        disabled={!customerQuery?.responseText}
                        className="btn btn-primary"
                        onClick={submitResponse}
                    >
                        Send Response
                    </button>
                </div>

            </div>
        </div>
    )
};
