import React, { useEffect, useMemo, useRef, useState } from "react";
import { createorUpdateFaq, getFaqById } from "../../api/AdminApis/Admin.terms";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function AddFaq() {
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqId, setFaqId] = useState(null); // Optional for updates
  const [faqItemId, setFaqItemId] = useState(null); // Optional for updates
  const [loading, setLoading] = useState(false); // Optional for updates

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = { category, question, answer };
      if (!category || !question || !answer) {
        toast.warning("Enter All required Fields!");
        return;
      }
      if (faqItemId) data.faqItemId = faqItemId;
      const response = await createorUpdateFaq(faqId, data);
      if (response) {
        if (faqId || faqItemId) {
          toast.success("FAQ Updated successfully!");
        } else {
          toast.success("FAQ Created successfully!");
        }
        setCategory("");
        setQuestion("");
        setAnswer("");
        setFaqItemId(null);
        setFaqId(null);
        setLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while submitting the FAQ");
    }
  };
  const location = useLocation();
  useEffect(() => {
    console.log(location);
    if (location.state?.faqId) {
      const faqId = location.state.faqId;
      const faqItemId = location.state?.faqItemId;
      setFaqId(faqId);
      if (faqItemId) {
        setFaqItemId(faqItemId);
      }
      loadFaq(faqId, faqItemId);
    }
  }, [location.state]);

  const loadFaq = async (faqId, faqItemId) => {
    const res = await getFaqById(faqId);
    if (res.faqs) {
      const faq = res.faqs;
      setCategory(res.faqs.category);
      if (faqItemId) {
        const faqData = faq.faq.find((item) => item._id === faqItemId);
        setQuestion(faqData.question);
        setAnswer(faqData.answer);
      }
    }
  };

  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Start typing...",
    }),
    []
  );

  return (
    <div className="" style={{ padding: "0px 20px" }}>
      <button
        onClick={() => navigate(-1)}
        style={{
          all: "unset",
          marginBottom: "25px",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Category:</label>
          <input className="form-control" type="text" placeholder="Enter FAQ Category" disabled={faqId} value={category} onChange={(e) => setCategory(e.target.value)} />
        </div>
        <div>
          <label>Question:</label>
          <input className="form-control" placeholder="Enter Question" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required />
        </div>
        <div>
          <label>Answer:</label>
          <JoditEditor
            ref={editor}
            value={answer}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onChange={(newContent) => setAnswer(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>
        <button className="mt-3 btn btn-sm btn-success" type="submit" disabled={loading}>
          {faqItemId || faqId ? "Update" : "Create"} FAQ &nbsp;&nbsp;
          {loading && (
            <div className="spinner-border" role="status" style={{ height: "1rem", width: "1rem" }}>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </button>
      </form>
    </div>
  );
}

export default AddFaq;
