import { createContext, useState } from "react";

const ProductContext = createContext(null);

const ProductContextProvider = (props) => {
    const [allProducts, setAllProducts] = useState({
        loaded: false,
        no_of_products: 0,
        products: [],
    });
    const [searchedProduct, setSearchedProduct] = useState({
        loaded: false,
        products: [],
        no_of_products: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);

    const [payuOffers, setPayuOffers] = useState([]);

    return (
        <ProductContext.Provider
            value={{
                allProducts,
                setAllProducts,
                searchedProduct,
                payuOffers,
                setPayuOffers,
                setSearchedProduct,
                currentPage, setCurrentPage
            }}
        >
            {props.children}
        </ProductContext.Provider>
    );
};

export { ProductContext };
export default ProductContextProvider;
