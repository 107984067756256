import React, { useEffect, useState } from "react";
import useUserCtx from "../../hooks/useUserCtx";
import { getProductComment, addLikeProductComment, removeLikeProductComment, replyToProductComments, getNestedComments } from "../../api/Product";
import { toast } from "react-toastify";
import ReviewsCard from "./ReviewsCard";
import { Button, FormControl, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function Review({ productId }) {
  const [reviewsList, setReviewsList] = useState([]);
  const { userContext } = useUserCtx();
  const { t } = useTranslation();
  const [isMore, setIsMore] = useState(false);
  const [totalReviews, setTotalReviews] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = React.useState("default");
  const reviewsPerPage = 5;
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchProductReviewsBySort(sortBy);
  }, [sortBy]);

  const fetchProductReviews = async (page = currentPage) => {
    try {
      let url = `?limit=${reviewsPerPage}&page=${page}&productId=${productId}&sortBy=${sortBy}`;
      const response = await getProductComment(url);
      if (response.data?.status === "success" && response.data?.data?.results) {
        if (response.data?.data?.next) {
          setIsMore(true);
          setCurrentPage(response.data.data.next.page);
        } else {
          setIsMore(false);
        }
        setReviewsList((prev) => (page === 1 ? [...response.data.data.results] : [...prev, ...response.data.data.results]));
        setTotalReviews(response.data.data.total);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
  };

  const fetchProductReviewsBySort = async (sort = "default") => {
    try {
      setSortBy(sort);
      let url = `?limit=${reviewsPerPage}&page=${1}&productId=${productId}&sortBy=${sort}`;
      const response = await getProductComment(url);
      if (response.data?.status === "success" && response.data?.data?.results) {
        if (response.data?.data?.next) {
          setIsMore(true);
          setCurrentPage(response.data.data.next.page);
        } else {
          setIsMore(false);
        }
        setReviewsList((prev) => [...response.data.data.results]);
        setTotalReviews(response.data.data.total);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
  };

  const onLikeReviews = async (review) => {
    try {
      const payload = {
        commentId: review._id,
        userId: userContext.id,
      };
      if (review.likes.includes(userContext.id)) {
        const response = await removeLikeProductComment(payload);
        if (response.data?.status === "success") {
          toast.success(t("notifications.LikeRemovedForThatComment"));
          fetchProductReviews(1);
        }
      } else {
        const response = await addLikeProductComment(payload);
        if (response.data?.status === "success") {
          toast.success(t("notifications.LikeAddedForThatComment"));
          fetchProductReviews(1);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
  };

  const onReplyToReview = async (reviewId, text, parentReviewId) => {
    try {
      const payload = {
        productReview: text,
        userId: userContext.id,
        productId: productId,
        productRating: 1,
        storeRating: 1,
      };
      if (parentReviewId) {
        payload.commentId = parentReviewId;
        payload.repliedTo = reviewId;
      } else {
        payload.commentId = reviewId;
      }
      const response = await replyToProductComments(payload);
      if (response.data.status === "success") {
        toast.success("Reply sent!");
        fetchProductReviews();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
  };

  const onShowMoreClick = async (commentId) => {
    try {
      const response = await getNestedComments(commentId);
      if (response.data.status === "success") {
        return response.data.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
    return [];
  };

  useEffect(() => {
    fetchProductReviews();
  }, []);

  return (
    <div className="flex-container d-flex justify-content-between w-100 gap-3 flex-wrap " style={{ paddingLeft: isMobile ? "8px" : "", paddingRight: isMobile ? "8px" : "" }}>
      {/* Reviews */}
      <div className="child review-child-1">
        <div className="d-flex justify-content-start align-items-center my-2">
          <span className="reviewHead">
            {t("Reviews")} <span style={{ color: "#3C96DB" }}>{`(${totalReviews})`}</span>
          </span>
          <div
            style={{
              background: "#EEEEEE",
              height: "2px",
              flexBasis: isMobile ? "20%" : "76%",
            }}
          ></div>

          {reviewsList.length > 0 && (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="sort-by">{t("sortAndFilter.sort-by")}</InputLabel>
              <Select labelId="sort-by" id="demo-controlled-open-select" open={open} onClose={handleClose} onOpen={handleOpen} value={sortBy} label="Sort By" onChange={handleChange} size="small">
                <MenuItem value="default">
                  <em>{t("all")}</em>
                </MenuItem>
                <MenuItem value={"mostLiked"}>{t("MostLiked")}</MenuItem>
                <MenuItem value={"highestRating"}>{t("HighesttoLowestRating")}</MenuItem>
                <MenuItem value={"lowestRating"}>{t("LowesttoHighestRating")}</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>

        {reviewsList.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginBottom: "1rem" }}>
            {reviewsList.map((review, index) => (
              <>
                <ReviewsCard review={review} onLikeReviews={onLikeReviews} onReplyToReview={onReplyToReview} onShowMoreClick={onShowMoreClick} />
                {index !== reviewsList.length - 1 && <hr className="horizontal-line" style={{ margin: 0 }} />}
              </>
            ))}
          </div>
        ) : (
          <h6 className="my-4 text-center">{t("NoReviewsadded")}</h6>
        )}
        {isMore && (
          <Button variant="contained" sx={{ background: "#1b325e" }} className="mb-3" size="small" onClick={() => fetchProductReviews()}>
            {t("ShowMore")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Review;
