import { useContext, useEffect, useState } from "react";
import OfferContext from "../../Contexts/OffersContext";
import { getOfferImageURLAPI } from "../../api/OfferAPIs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FB from "../../assets/IFD/fb.png";
import INSTA from "../../assets/IFD/insta.png";
import TWITTER from "../../assets/IFD/twitter.png";
import YOUTUBE from "../../assets/IFD/youtube.png";
import LINKEDIN from "../../assets/IFD/linkedin.png";

function ClaimPage() {

    const { OfferId, getOfferDetails, IFDUserId } = useContext(OfferContext);
    const [receivedPrize, setReceivedPrize] = useState('');
    const navigate = useNavigate();
    const [isIconClicked, setIsIconClicked] = useState(false);

    
    useEffect(() => {
        (async () => {
            try {
                const data = await getOfferImageURLAPI(IFDUserId,"no");
                if (data?.status === "error") {
                    toast.error(data?.message ?? "Something went wrong");
                    navigate("/indianfestivaldays2024");
                }
                else {
                    if (data?.imageURL) {
                        const offerURL = data.imageURL;
                        setReceivedPrize(offerURL);
                    }
                    else toast.error("Something went wrong");
                }
            } catch (error) {
                toast.error("Something went wrong");
            }
        })();
    }, []);

    function handleIconCLick() {
        setIsIconClicked(true);
    }


    return (
        <>
            <div className="container sub-container text-center">
                <p className="m-0" style={{color:"#FBCF11", fontSize:"35px"}}><b>CONGRATULATIONS</b></p>
                <p style={{color:"#FBCF11", fontSize:"25px"}}>Claim Your Deal & Enjoy </p>

                <img src={receivedPrize} alt="stage_circle" style={{width:"200px"}} className="prize-img relative" />

 
                <div style={{position:"relative",zIndex:"1"}} className="mb-2 d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex gap-2 justify-content-center align-items-center my-2">
                        <a onClick={handleIconCLick} href="https://twitter.com/olineoindia" target="_blank" className="pl-2 " rel="noreferrer">
                            <img alt="twitter" src={TWITTER} width={40} />
                        </a>
                        <a href="https://www.facebook.com/OLineOIndia/" target="_blank" rel="noreferrer" onClick={handleIconCLick}>
                            <img alt="facebook" src={FB} width={40} />
                        </a>
                        <a href="https://www.linkedin.com/company/olineoindia/" target="_blank" rel="noreferrer" onClick={handleIconCLick}>
                            <img alt="facebook" src={LINKEDIN} width={40} />
                        </a>
                        <a onClick={handleIconCLick} href="https://www.youtube.com/@OLineOIndia" target="_blank" className="pl-2 icon-container" rel="noreferrer">
                            <img alt="youtube" src={YOUTUBE} width={40} />
                        </a>
                        <a onClick={handleIconCLick} className="pl-2 " href="https://www.instagram.com/olineoindia/" target="_blank" rel="noreferrer">
                            <img alt="insta" src={INSTA} width={40} />
                        </a>
                    </div>
                </div>

                <p style={{position:"relative",zIndex:"1"}}><b>Share your screenshot and tag us on social media!</b></p>
                <p style={{fontSize:"20px",position:"relative",zIndex:"1"}}><b>Thank you For Visiting O-LINE-O India</b></p>

            </div>
        </>
    );
}

export default ClaimPage;