import axios from "../axiosInstance";

//Get all cancelation data

export const getAllCancelation = async () => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `bearer ${adminToken}`,
    };

  let allCancelation;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/cancel/admin`, { headers }).then((res) => {
    allCancelation = res.data?.data;
  });

  return allCancelation;
};

//Approve Cancel by admin
export const approveCancelation = async (id, charges) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    Authorization: `bearer ${userToken}`,
  };

  let approveCancelRes;

  let cancelBody = {
    cancellationId: id,
    charges: charges,
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/cancel/admin/refund`, JSON.stringify(cancelBody), { headers }).then((res) => {
    approveCancelRes = res;
  });

  return approveCancelRes;
};
