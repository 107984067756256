import axios from "./axiosInstance";

// function update(value) {
//   let prevData = JSON.parse(localStorage.getItem('user'));
//   Object.keys(value).forEach(function (val, key) {
//     prevData[val] = value[val];
//   })
//   localStorage.setItem('user', JSON.stringify(prevData));
// }

//GET CART DATA
export const getCartData = async (token) => {
  let cartDataResponse;

  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  let JWT = token ? token : userToken;
  if (JWT) {
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${JWT}`,
    };

    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/product/cart/`, { headers })
      .then((res) => {
        if (res) {
          cartDataResponse = res.data.data;
        }
      })
      .catch((err) => console.log("Error:", err));
  }
  return cartDataResponse;
};

export const addToCart = async ({ id, qty, brand_store_id, onsiteGoSelected, appliedCouponId }) => {
  try {
    let addToCartResponse;

    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/product/cart/`,
        {
          productId: id,
          qty: qty,
          brand_store_id,
          onsiteGoSelected,
          appliedCouponId,
        },
        { headers }
      )
      .then((res) => {
        if (res) {
          addToCartResponse = res;
        }
      })
      .catch((err) => {
        addToCartResponse = err?.response;
        console.log("Error:", err);
      });

    return addToCartResponse;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const getOnsiteGoProd = async (id) => {
  try {
    let skuProd;

    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };

    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/product/getOnsiteGoprod/${id}`, { headers })
      .then((res) => {
        if (res) {
          skuProd = res.data;
        }
      })
      .catch((err) => {
        skuProd = err?.response;
        console.log("Error:", err);
      });

    return skuProd;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const removeFromCart = async (id, fromId = "", planId = "") => {
  let removeFromCartResponse;

  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
  const obj = {
    id,
    fromId,
    planId,
  };
  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/cart`, obj, { headers })
    .then((res) => {
      if (res) {
        removeFromCartResponse = res;
      }
    })
    .catch((err) => console.log("Error:", err));

  return removeFromCartResponse;
};

export const removeAllFromCart = async (id) => {
  let removeFromCartResponse;

  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
  await axios
    .patch(`${process.env.REACT_APP_BASE_URL}/product/cart/all`, {}, { headers })
    .then((res) => {
      if (res) {
        removeFromCartResponse = res;
      }
    })
    .catch((err) => console.log("Error:", err));

  return removeFromCartResponse;
};
