import React, { useEffect, useRef, useState } from "react";
import "./TermsAndCondtion.css";
import BG from "../../assets/productIcons/T&CBG.svg";
import { useLocation } from "react-router";
import { getAllTerms } from "../../api/AdminApis/Admin.terms";

import useSiteCtx from "../../hooks/useSiteCtx";
import SEO from "../../components/SEO/SEO";

function TermsAndCondtion() {
  const location = useLocation();
  const cancellationSectionRef = useRef(null);
  const exchnageSectionRef = useRef(null);
  const disclaimerSectionRef = useRef(null);
  const [termData, setTermData] = useState([]);
  const [termOfuse, setTermOfUse] = useState({});
  const { seoData } = useSiteCtx();
  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const getTermsAndCondition = async () => {
    const terms = await getAllTerms();
    if (terms) {
      for (const i in terms?.terms) {
        if (terms.terms[i].heading === "TERMS OF USE") {
          setTermOfUse(terms.terms[i]);
        }
      }
      const filteredTerms = terms.terms.filter((term) => term.heading !== "TERMS OF USE" && term.heading !== "Coupons Terms");

      setTermData(filteredTerms);
    }
  };

  const sectionRefs = {
    "#order-cancellation": cancellationSectionRef,
    "#return-exchange": exchnageSectionRef,
    "#disclaimer": disclaimerSectionRef,
  };
  useEffect(() => {
    if (location.pathname === "/terms-condition" && termData.length > 0) {
      const scrollToSection = location.hash;

      const targetRef = sectionRefs[scrollToSection];
      if (targetRef) {
        const { offsetTop } = targetRef.current;
        window.scrollTo({
          top: offsetTop - 200,
          behavior: "smooth",
        });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [location, termData]);

  return (
    <div className="parentClassTC" id="parent_container">
      <SEO seoData={seoData} />
      <section className="firstsection">
        <img src={BG} className="imageStyle" alt="main_illustration" />
        <div className="headerSection">
          <span>O-LINE-O </span>
          <span>Terms and Conditions </span>
        </div>
      </section>
      {termOfuse?.heading && (
        <li className="container-li">
          <span className="secondSectionHeader" dangerouslySetInnerHTML={{ __html: termOfuse?.heading }} />
          <p className="secondSection" dangerouslySetInnerHTML={{ __html: termOfuse?.points }} />
        </li>
      )}

      {/* Main Section */}
      <ol>
        {termData.map((item, index) => (
          <li className="container-li" key={index}>
            <span className="secondSectionHeader"> {item.heading}</span>
            <div className="secondSection" ref={sectionRefs[item.scrollToSection]} dangerouslySetInnerHTML={{ __html: item.points }} />
          </li>
        ))}
      </ol>
    </div>
  );
}

export default TermsAndCondtion;
