import verifyBtn from "../../assets/bharatmahostav/verify2.svg";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import OfferContext from "../../Contexts/OffersContext";

import { sendOTPToStore, verifyOTPForStore } from "../../api/OfferAPIs";
import SVGLoader from "./SVGLoader";

function Page2() {
  const { changeOfferId, OfferId, changeStoreCode, IFDUserId } = useContext(OfferContext);
  const navigate = useNavigate();
  const [storeId, setStoreId] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storePassword, setstorePassword] = useState("");
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);

  const [verifiedForStore, setVerifiedForStore] = useState(null);

  useEffect(() => {
    if (IFDUserId === null) {
      navigate("/indianfestivaldays2024");
    }
  }, [IFDUserId]);

  const sendOtpToStoreCall = async () => {
    if (!isVerified) {
      try {
        setLoadingFirst(true);
        const payload = {
          storecode: storeId,
          IFDUserId : IFDUserId,
          // OfferUserID: OfferId,
        };
        const data = await sendOTPToStore(payload);
        if (data?.status === "error") {
          toast.error(data?.message ?? "Something went wrong");
        } else {
          setVerifiedForStore(payload.storecode);
          setShowVerify(true);
          setStoreName(data?.display_name);
          toast.info(data?.message);
          data?.OfferUserID && changeOfferId(data.OfferUserID);
          data?.store_code && changeStoreCode(data?.store_code);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      } finally {
        setLoadingFirst(false);
      }
    }
  };

  const onVerifyOtp = async () => {
    try {
      setLoadingSecond(true);
      const payload = {
        storecode: storeId,
        IFDUserId : IFDUserId,
        storePassword: storePassword,
        // otp: storeId,
        // OfferUserID: OfferId,
      };

      const data = await verifyOTPForStore(payload);
      if (data?.status === "error") {
        toast.error(data?.message ?? "Something went wrong");
      } else {
        setShowVerify(false);
        setIsVerified(true);
        toast.success(data?.message);
        if(data?.isofferremaining === true){
          navigate("/indianfestivaldays2024/3");
        } else {
          toast.error("Store dont have any offer.");
          navigate("/indianfestivaldays2024");
        }
        // navigate("/quiz-Offer/3");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoadingSecond(false);
    }
  };

  return (
    <>
      <div
        className="container sub-container text-center position-relative"
        style={{
          maxWidth: "400px",
        }}
      >
        {/* <h3>2. Enter the store ID</h3> */}
        {/* <TextField
          size="small"
          label="OTP"
          variant="filled"
          inputMode="text"
          className="form-control mt-3 store-input"
          type="text"
          required
          placeholder="OTP"
          style={{ width: "300px", zIndex: "999" }}
          value={storeId}
          onChange={(e) => setStoreId(e.target.value)}
          // InputProps={{
          //   readOnly: isVerified,
          // }}
        /> */}


        {/* store id input to validate IFD 2024 */}
        {showVerify ? (
          <>
            {/* <TextField
              size="small"
              label="Store Code"
              variant="filled"
              inputMode="text"
              className="form-control mt-3 store-input"
              type="text"
              disabled
              placeholder="Store Code"
              style={{ width: "330px", zIndex: "999" }}
              value={verifiedForStore}
            /> */}
            {/* <TextField
              size="small"
              label="Store Name"
              variant="filled"
              inputMode="text"
              className="form-control mt-3 store-input"
              type="text"
              disabled
              placeholder="Store Name"
              style={{ width: "300px", zIndex: "999" }}
              value={storeName}
            /> */}

            {/* <TextField
              size="small"
              label="Password"
              variant="filled"
              inputMode="text"
              className="form-control mt-3 store-input"
              type="text"
              placeholder="Password"
              style={{ width: "330px", zIndex: "999" }}
              value={storePassword}
              onChange={(e) => setstorePassword(e.target.value)}
            /> */}

            {/* <button onClick={onVerifyOtp} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={loadingSecond}>
              {loadingSecond ? <SVGLoader /> : <span>NEXT</span>}
            </button>


            <p style={{color:"#ffffff",fontSize:"20px",fontWeight:"bolder",marginTop:"20px"}}>{storeName}</p> */}




          </>
        ) : (
          <>
            
          </>
        )}
        {/* store id input to validate IFD 2024 */}

        {/* <button onClick={onVerifyOtp} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={loadingSecond}>
          {loadingSecond ? <SVGLoader /> : <span>Continue</span>}
        </button> */}




            <TextField
              size="small"
              label="Store ID"
              variant="filled"
              inputMode="text"
              className="form-control mt-3 store-input"
              type="text"
              required
              placeholder="Store ID"
              style={{ width: "330px", zIndex: "999" }}
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
            />
            <button onClick={sendOtpToStoreCall} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={loadingFirst}>
              {loadingFirst ? <SVGLoader /> : <span>VERIFY</span>}
            </button>


            {
              showVerify ? (<>
                
                <p style={{color:"#ffffff",fontSize:"20px",fontWeight:"bolder",marginTop:"20px", position:"relative",zIndex:"1"}}>{storeName}</p>
                
                <button style={{position:"relative",zIndex:"1"}} onClick={onVerifyOtp} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn mb-4" id="submit-otp-btn" disabled={loadingSecond}>
                  {loadingSecond ? <SVGLoader /> : <span>NEXT</span>}
                </button>

              </>) : (<></>)
            }






      </div>
    </>
  );
}

export default Page2;
