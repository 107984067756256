import React from "react";
import { useTranslation } from "react-i18next";
import findStoreBurgerIcon from "../../assets/vector/findStoreBurgerIcon.svg";
import FindStoreContactPerson from "./findStoreContactPerson";

function FindStoreContact({ stores, totalStores, storeSearchKey, lastStoreRef, showStore }) {
  const { t } = useTranslation();

  return (
    <div className="" style={{ flexGrow: "1", overflowY: "auto", padding: "0px 20px" }}>
      <div className="findStoreContactHeader">
        {stores.map((store, index) => (
          <div key={`store_list_${index}`}>
            <FindStoreContactPerson reference={index + 1 === stores.length ? lastStoreRef : null} key={`store_list_${index}`} store={store} />
            {index + 1 !== stores.length && <div className="horizontal-row"></div>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(FindStoreContact);
