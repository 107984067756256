import React, {  useContext, useState } from "react";

const RetailerContext = React.createContext();

export function useRetailerContext() {
    return useContext(RetailerContext)
}
export const RetailerContextProvider = ({ children }) => {

    //State to store information about store
    const [store, setStore] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <RetailerContext.Provider value={{ store, setStore, loading, setLoading }}>
            {children}
        </RetailerContext.Provider>
    )
}