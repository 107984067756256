import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllWishlistItems } from "../../api/wishlistApi";
import useUserCtx from "../../hooks/useUserCtx";
import "./MyWishlist.css";
import WishlistSection from "./WishlistSection";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import WishListUserDetails from "./WishListUserDetails";
import { getItemFromStorage } from "../../utils";
import useSiteCtx from "../../hooks/useSiteCtx";
import SEO from "../../components/SEO/SEO";

// Component definition
const MyWishlist = () => {
  const { t } = useTranslation();

  const { seoData } = useSiteCtx();

  const { setUserWishlist } = useUserCtx();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    getAllWishlistItems().then((res) => {
      if (res) {
        setUserWishlist({
          loaded: true,
          no_of_wishlist_items: res.no_of_wishlist_items,
          wishlist_items: [...res.wishlist_items],
        });
      }
    });
  }, []);

  useEffect(() => {
    const user = JSON.parse(getItemFromStorage("user") ?? {});
    if (!user.JWT) {
      toast.error(t("wishlistNotLoginError"));
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="newWishListContainer page_Wrapper">
      <SEO seoData={seoData} />
      <WishListUserDetails isMobile={isMobile} />

      <WishlistSection isMobile={isMobile} />
    </div>
  );
};

export default MyWishlist;
