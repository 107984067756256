import React, { useEffect, useState } from "react";
import styles from "./styles/DashboardIFD.module.css";
import { deletOfferUser, deletSubscribedUser, fetchSubscribedUser, findStore } from "../../api/AdminApis/IFD";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { toast } from "react-toastify";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import DayGraph from "../../components/_IFD/DayGraph";
import ProductGraph from "../../components/_IFD/ProductGraph";
import Pagination from "../../components/Pagination/Pagination";
import { fetchAllQuizUser } from "../../api/AdminApis/IFD";
import { formatDate } from "../../utils";
import Swal from "sweetalert2";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

// Component definition
const DashboardQuiz = () => {
  // state definitions
  const [loader, setLoader] = useState(false);

  const [user, setUser] = useState([]);
  const [totalUsers, setTotalUsers] = useState(1);
  const [isSubscribedUser, setIsSubscribedUser] = useState(false);

  const usersPerPage = 100;

  const handleDelet = async (id) => {
    let text = "Are you sure for delete?";
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#a5a5a5",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isSubscribedUser) {
          deletSubscribedUser(id)
            .then((res) => {
              toast.success(res.message);
              // setData(data.filter((message) => message._id !== id));
              getAllSubscribedUser();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          deletOfferUser(id)
            .then((res) => {
              toast.success(res.message);
              // setData(data.filter((message) => message._id !== id));
              getAllUser();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state?.type === "subscribedUser") {
      setIsSubscribedUser(true);
      getAllSubscribedUser();
    } else {
      getAllUser();
      setIsSubscribedUser(false);
    }
  }, [currentPage, location]);

  const getAllUser = async () => {
    setLoader(true);
    const res = await fetchAllQuizUser(`limit=${usersPerPage}&page=${currentPage}`);
    if (res) {
      if (res?.data.no_of_users > 0) {
        setUser(res?.data.users);
        setTotalUsers(res?.data.total_users);
      } else {
        setUser([]);
      }
      setLoader(false);
    }
  };

  const getAllSubscribedUser = async () => {
    setLoader(true);
    const res = await fetchSubscribedUser(`limit=${usersPerPage}&page=${currentPage}`);
    if (res) {
      if (res?.data.no_of_users > 0) {
        setUser(res?.data.users);
        setTotalUsers(res?.data.total_users);
      } else {
        setUser([]);
      }
      setLoader(false);
    }
  };

  return loader ? (
    <DashboardLoader />
  ) : (
    <>
      {/* <EditUserModal show={modalShow} onHide={() => setModalShow(false)} user={user} setUser={setUser} setModalShow={setModalShow} />
      {userAddressId && <UserAddressModal details={addresses} open={userAddressModalShow} onClose={() => setUserAddressModalShow(false)} />} */}
      {!isSubscribedUser ? (
        <div className="container">
          <div className="d-flex justify-content-between">
            <h3>All Offer Users</h3>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sr.No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {user?.length > 0 &&
                  user?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * usersPerPage + index + 1}.</td>
                      <td> {item.userName ?? "-"} </td>
                      <td>{item.userPhone ?? "-"}</td>
                      <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                      <td>
                        <button className="btn" onClick={() => handleDelet(item._id)}>
                          <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination_Container mt-3">
            <Pagination productsPerPage={usersPerPage} totalProducts={totalUsers} pageChange={handlePageChange} currentPage={currentPage} />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between">
            <h3>All Subscribed Users</h3>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sr.No.</th>
                  <th scope="col">Email</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {user?.length > 0 &&
                  user?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * usersPerPage + index + 1}.</td>
                      <td> {item.email ?? "-"} </td>
                      <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                      <td>
                        <button className="btn" onClick={() => handleDelet(item._id)}>
                          <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination_Container mt-3">
            <Pagination productsPerPage={usersPerPage} totalProducts={totalUsers} pageChange={handlePageChange} currentPage={currentPage} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardQuiz;
