import React, { useState } from "react";
import { verifyOtpUpdatePhone } from "../../api/Auth";
import { toast } from "react-toastify";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// toast.configure();

// Component definitions
const Update = ({
    oldInfo,
    newInfo,
    handleClose,
    otpVerificationId,
    setModalData
}) => {

    const { width } = useWindowDimensions();

    const matches = width >= 768;

    // state definitions
    const [, setDisabled] = useState(true);
    const [oldOTP, setOldOTP] = useState("");
    const [newOTP, setNewOTP] = useState("");

    const { setUserContext } = useUserCtx();

    const validateForm = () => {
        oldOTP !== "" && newOTP !== "" ? setDisabled(false) : setDisabled(true);
    };

    const handleSubmit = () => {
        if (!otpVerificationId) return toast.error("something went wrong!"), handleClose();

        const data = {
            currentOtp: oldOTP,
            updateOtp: newOTP,
        };

        verifyOtpUpdatePhone(otpVerificationId, data, (err, res) => {
            if (err) return toast.error("Pelase try again. Something went wrong!");
            setUserContext((prev) => {
                return { ...prev, mobileNumber: newInfo };
            });
            setModalData((prev) => {
                return { ...prev, otpVerificationId: null };
            });
            handleClose();
        });

    };

    return (
        <div className="page_Wrapper update_Page_Wrapper">
            <div className="update_Container">
                <form className="update_Wrapper" onChange={validateForm}>
                    <div className="update_New_Info">
                        <p className="update_Info_Details">Enter OTP sent to {oldInfo} </p>
                        <div className="edit_input_container">
                            <label className="edit_input_label">Enter OTP</label>
                            <input type="text" className="input-field" placeholder="Enter OTP" name="old OTP" autoComplete="off" autoCorrect="off" value={oldOTP} onChange={(e) => setOldOTP(e.target.value)} />
                            {/* <span className="edit_input_update">Resend</span> */}
                        </div>
                        {/* <p className="otp_Alternate">Send OTP using {number ? "Email" : "Phone"}</p> */}
                    </div>
                    <div className="update_Old_Info">
                        <p className="update_Info_Details">Enter OTP sent to {newInfo} </p>
                        <div className="edit_input_container">
                            <label className="edit_input_label">Enter OTP</label>
                            <input type="text" className="input-field" placeholder="Enter OTP" name="new OTP" autoComplete="off" autoCorrect="off" value={newOTP} onChange={(e) => setNewOTP(e.target.value)} />
                            {/* <span className="edit_input_update">Resend</span> */}
                        </div>
                    </div>
                </form>
            </div>
            <div className="address_Footer update_Footer">
                {/* <span className="">Resend</span> */}
                <button type="submit" className="submit-button" onClick={handleSubmit} disabled={!oldOTP || !newOTP}>
                    {matches ? <p>Update</p> : <p>SAVE DETAILS</p>}
                </button>
            </div>
        </div>
    );
};

export default Update;
