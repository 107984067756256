import React, { useEffect, useState } from "react";
import AddressImage from "../../assets/productIcons/AddressImage.svg";
import InputAddress from "../../assets/productIcons/InputAddress.svg";
import "./AddAddress.css";
import { deleteAddress, getAddress, setAddressDefault } from "../../api/Address";
import useUserCtx from "../../hooks/useUserCtx";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";

function AddAddress() {
  const { setUserAddress, userAddress } = useUserCtx();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const breadCrumbsData = [
    {
      text: t("profile.profile") + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile",
    },
    {
      text: t("yourAddresses"),
      url: "/yourAddresses",
      isActive: location.pathname === "/yourAddresses",
    },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      getAddress().then((res) => {
        if (res) {
          setUserAddress({
            loaded: true,
            no_of_address: res.no_of_address,
            address: res.address,
          });
        }
      });
    } catch (err) {
      toast.error("Something went wrong... Try Again");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteAddress = (address) => {
    if (address.isDefault) {
      localStorage.removeItem("setLocation");
    }
    deleteAddress(address._id).then((res) => {
      if (res) {
        toast.success(t("notifications.AddressDeletedSuccessfully"));
        getAddress().then((res) => {
          if (res) {
            setUserAddress({
              loaded: true,
              no_of_address: res.no_of_address,
              address: res.address,
            });
          }
        });
      }
    });
  };

  const handleSetAsDefaultAddress = (id) => {
    setAddressDefault(id).then((res) => {
      getAddress().then((res) => {
        if (res) {
          setUserAddress({
            loaded: true,
            no_of_address: res.no_of_address,
            address: res.address,
          });
        }
      });
    });
  };

  return (
    <div className="parentAddAddress">
      <div className="headerSection">
        <span className="breadcrumbs address-breadcrumbs">
          {breadCrumbsData.map((item, index) => (
            <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>
              {item.text}
            </Link>
          ))}
        </span>
        {/* <span className="HeadingSent">Your Addresses</span> */}
      </div>
      <div className="bodySection">
        <div className="headerSection">
          <span>Add New Address</span>
          <img className="inputAddress" src={InputAddress} alt="Customer support-1" onClick={() => navigate("/profile/yourAddresses/newAddress")} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "95%",
              marginTop: "10px",
            }}
          >
            <span>Present Addresses</span>
            {!loading ? (
              userAddress?.address?.length > 0 ? (
                userAddress?.address?.map((address, index, self) => (
                  <div
                    style={{
                      borderBottom: index + 1 !== self.length ? "1px solid #00000054" : "none",
                      paddingBottom: index + 1 !== self.length ? "1rem" : "0",
                      marginBottom: index + 1 !== self.length ? "0" : "1rem",
                    }}
                  >
                    <div className="d-flex justify-content-end">
                      <Link to={"/editaddress"} state={address} className="address_Footer_Edit justify-content-center" style={{ marginLeft: "-2.5rem", width: "15%" }}>
                        <span className="instructionCss">Change</span>
                      </Link>
                    </div>

                    <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-center justify-content-between">
                      <div style={{ display: "flex", flexDirection: "column", fontSize: "15px", fontWeight: "500", lineHeight: "20px" }}>
                        <span>{address?.customerName}</span>
                        <span>{address?.address_line1}</span>
                        <span>{`${address?.city},${address?.state}-${address?.zip},`}</span>
                        <span>{`${address?.country}`}</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <button style={{ minWidth: "130px" }} className={`submit-button py-1 px-2 h-auto fs-6`} disabled={address.isDefault} onClick={() => handleSetAsDefaultAddress(address._id)}>
                          {address.isDefault ? "Default" : "Set as default"}
                        </button>
                        <span className="deleteIcon" onClick={() => handleDeleteAddress(address)}>
                          <DeleteOutlineIcon
                            sx={{
                              color: "#ff0000",
                              fontWeight: "300",
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    {/* <span className="instructionCss">Add delivery instructions</span> */}
                  </div>
                ))
              ) : (
                <div className="no-address-found-text">No Address Found</div>
              )
            ) : (
              <div className="loader-div">
                <CircularProgress color="inherit" />
              </div>
            )}
          </div>
        </div>
        <div>
          <img className="addAddreesRightimg" src={AddressImage} alt="Customer support-2" />
        </div>
      </div>
      {location.state && location.state?.from === "payment" && (
        <div className="fromPayment-section my-4">
          <button className="fromPayment-checkout-button submit-button " onClick={() => navigate("/payment")}>
            <h4 className="submit-button-textPop fromPayment-checkout-text">Proceed to Checkout</h4>
            <EastIcon />
          </button>
        </div>
      )}
    </div>
  );
}

export default AddAddress;
