import React, { useCallback, useEffect, useState } from "react";

import "./styles.css";

import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";

import Person from "../../assets/png/personAddress.png";
import { checkAddressStatus, editAddress, fetchPinCode, fetchStateAndDisName, getAddress, saveAddress } from "../../api/Address";
import useUserCtx from "../../hooks/useUserCtx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewAddressForm = ({ addressProp, setProfileState, fromProfile = false }) => {
  const isMobile = useMediaQuery("(max-width: 500px)");
  const { setUserAddress, userContext, setUserContext } = useUserCtx();
  const nav = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [addressStatusCount, setAddressStatusCount] = useState(false);
  const [fetchedFromPinCode, setFetchedFromPinCode] = useState(false);
  const [addressCount, setAddressCount] = useState(0);

  const breadCrumbsData = [
    {
      text: t("profile.profile") + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile",
    },
    {
      text: t("yourAddresses") + " / ",
      url: "/yourAddresses",
      isActive: location.pathname === "/yourAddresses",
    },
    {
      text: location.pathname === "/editaddress" ? t("editAddress") : t("newAddresses"),
      url: "/profile/yourAddresses/newAddress",
      isActive: location.pathname === "/profile/yourAddresses/newAddress",
    },
  ];

  const mobileBreadCrumbsData = [
    {
      text: t("profile.profile") + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile",
    },
    {
      text: t("addNewAddress"),
      url: "/profile/yourAddresses/newAddress",
      isActive: location.pathname === "/profile/yourAddresses/newAddress",
    },
  ];

  const [address, setAddress] = useState({
    customerName: "",
    phone: "",
    zip: "",
    state: "",
    city: "",
    address_line1: "",
    landmark: "",
    placeholder: "",
  });
  const [defaultAdd, setDefaultAdd] = useState(false);

  useEffect(() => {
    if (addressProp) {
      setAddress({
        customerName: addressProp.customerName,
        phone: addressProp.phone,
        zip: addressProp.zip,
        state: addressProp.state,
        city: addressProp.city,
        address_line1: addressProp.address_line1,
        landmark: addressProp.landmark,
      });
      setDefaultAdd(addressProp.isDefault);
    }
  }, [addressProp]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user.id) {
      getAddressCountStatus(user.id);
    }
  }, [userContext]);

  const getAddressCountStatus = useCallback(async (userId) => {
    try {
      const response = await checkAddressStatus(userId);
      if (response.data.status === "success" && response.data.userHasAddress > 0) {
        setAddressStatusCount(true);
        setAddressCount(response.data.userHasAddress);
      }
    } catch (error) {
      console.error("Error fetching address status:", error);
      toast.error("Error fetching address status.");
    }
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (value !== "" && (name === "phone" || name === "zip")) {
      const re = /^[0-9\b]+$/;
      if (!re.test(value)) return;
    }
    e.target ? setAddress({ ...address, [name]: value }) : setAddress({ ...address, [name]: e.label });
    if (name === "zip" && value.length === 6) {
      fetchStateAndDisName(value)
        .then((res) => {
          setAddress({ ...address, [name]: value, city: res.District, state: res.Circle, placeholder: res.Name });
          if (res.Circle) {
            setFetchedFromPinCode(true);
          }
        })

        .catch((error) => {
          setFetchedFromPinCode(false);
          console.error("Error fetching state and district names:", error);
        });
    }
  };

  const fetchZipCode = (e) => {
    const { name, value } = e.target;
    if (name === "city") {
      fetchPinCode(value)
        .then((res) => {
          setAddress({ ...address, state: res.Circle, zip: res.Pincode, placeholder: res.Name });
          if (res.Circle) {
            setFetchedFromPinCode(true);
          }
        })
        .catch((error) => {
          setFetchedFromPinCode(false);
          console.error("Error fetching state and district names:", error);
        });
    }
  };

  function validateName(name) {
    let regex = /^[a-zA-Z ]{2,30}$/;
    return !regex.test(name);
  }

  function validatePhone(phone) {
    return !/^\d{10}$/.test(phone);
  }

  function validatePinCode(pinCode) {
    const isValid = /^\d{6}$/.test(pinCode);
    return isValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address.state) {
      return toast.error("Invalid State");
    }
    if (!address.city) {
      return toast.error("Invalid City");
    }
    if (!validatePinCode(address.zip)) {
      return toast.error("Invalid Pincode");
    }
    if (validateName(address.customerName)) {
      return toast.error("Invalid Name");
    }
    if (validatePhone(address.phone)) {
      return toast.error("Invalid Phone Number");
    }
    if (!address.address_line1) {
      return toast.error("Invalid Address");
    }

    if (addressProp) {
      editAddress(addressProp?._id, address, addressCount === 1 ? true : defaultAdd).then((res) => {
        if (res) toast.success(t("notifications.AddressUpdatedSuccessfully"));
        getAddress().then((res) => {
          if (res) {
            setUserAddress({
              loaded: true,
              no_of_address: res.no_of_address,
              address: res.address,
            });
          }
        });
        nav(-1);
        //get address call
        //set address props
      });
    } else {
      saveAddress(address, defaultAdd).then((res) => {
        getAddress().then((res) => {
          if (res) {
            setUserAddress({
              loaded: true,
              no_of_address: res.no_of_address,
              address: res.address,
            });
          }
        });
        if (fromProfile) {
          // setProfileState(5);
        } else {
          nav(-1);
        }
        //get address call
        //set address props
      });
    }
  };

  return (
    <div className="newAddressWrapper">
      <div className="newAddressContainer">
        <div className="newAddressContent">
          <h1 className="newAddressTitle">
            {!isMobile
              ? breadCrumbsData.map((item, index) => (
                  <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>
                    {item.text}
                  </Link>
                ))
              : mobileBreadCrumbsData.map((item, index) => (
                  <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>
                    {item.text}
                  </Link>
                ))}
          </h1>
          <h1 className="newAddressHeading">{location.pathname === "/editaddress" ? t("editAddress") : t("addNewAddress")}</h1>
          <div className="newAddressFormContainer">
            <form>
              <h1 className="newAddressInputTitle">Pincode</h1>
              <div className="newAddressInputDiv">
                <input type="text" className="newAddressInputField" name="zip" maxLength={6} required value={address.zip} onChange={handleInput} />
              </div>
              <h1 className="newAddressInputTitle">State</h1>
              <select id="state" className="newAddressStateInput" name="state" value={address.state} onChange={handleInput} disabled={fetchedFromPinCode} required>
                <option value="">Select state</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
              <h1 className="newAddressInputTitle">City</h1>
              <div className="newAddressInputDiv">
                <input type="text" className="newAddressInputField" name="city" value={address.city} onChange={handleInput} onBlur={fetchZipCode} required />
              </div>

              <h1 className="newAddressInputTitle">Full Name</h1>
              <div className="newAddressInputDiv">
                <input type="text" className="newAddressInputField" name="customerName" value={address.customerName} onChange={handleInput} placeholder="Your Full Name" required />
              </div>
              <h1 className="newAddressInputTitle">Mobile phone number</h1>
              <div className="newAddressDoubleInputDiv">
                <div className="smallInputDiv">
                  <input type="text" name="countryCode" className="newAddressSmallInputField" placeholder="IN +91" readOnly />
                </div>
                <div className="bigInputDiv">
                  <input type="text" className="newAddressBigInputField" placeholder="9000000000" name="phone" value={address.phone} onChange={handleInput} maxLength={10} required />
                </div>
              </div>
              <h1 className="newAddressInputTitle">Address</h1>
              <div className="newAddressInputDiv">
                <textarea
                  rows="5"
                  cols="33"
                  className="newAddressInputField textArea"
                  name="address_line1"
                  placeholder={`e.g.${address.placeholder}`}
                  value={address.address_line1}
                  onChange={handleInput}
                  required
                ></textarea>
              </div>
              <h1 className="newAddressInputTitle">Landmark</h1>
              <div className="newAddressInputDiv">
                <textarea rows="2" cols="33" className="newAddressInputField landmark" placeholder="E.g. near" name="landmark" value={address.landmark} onChange={handleInput}></textarea>
              </div>
              {addressStatusCount && (
                <div className="newAddresscheckBoxDiv">
                  <input
                    checked={defaultAdd}
                    disabled={addressProp ? (addressCount === 1 ? true : false) : false}
                    onChange={(e) => setDefaultAdd(e.target.checked)}
                    type="checkbox"
                    className="newAddressCheckbox"
                  />
                  <span className="newAddressConfirmCheckbox">Make this my default address</span>
                </div>
              )}
              <button
                type="submit"
                className="newAddresssubmitButton"
                style={{
                  background: "#1B325E",
                  maxWidth: "250px",
                  minWidth: isMobile ? "130px" : "250px",
                  padding: isMobile ? "" : "20px 30px",
                }}
                onClick={handleSubmit}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: isMobile ? "12px" : "22px",
                    fontWeight: isMobile ? "" : "600",
                  }}
                >
                  {addressProp?._id ? "Edit Address" : "Add Address"}
                </p>
              </button>
            </form>
          </div>
        </div>
        <div className="newAddressImageContainer">
          <img alt="person" src={Person} />
        </div>
      </div>
    </div>
  );
};

export default NewAddressForm;
