import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { currencyFormat, formatDate } from "../../utils";
import { Autocomplete, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import { updateCouponStatus } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";

function UserAddressModal(props) {
  const { details, ...otherProps } = props;
  const [status, setStatus] = useState("");
  const handleStatusChange = (event, value) => {
    setStatus(value);
  };
  const statusOptions = ["used", "active"];

  const updateCoupon = async (Id) => {
    if (props.userId && Id) {
      const res = await updateCouponStatus(props.userId, Id, status);
      if (res) {
        toast.success("Coupon Updated!");
      }
    }
  };

  return (
    <Dialog {...otherProps} fullWidth maxWidth="md">
      <DialogTitle>Address</DialogTitle>
      <DialogContent>
        {details.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Customer Name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Address Line 1</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>State</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Pincode</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Landmark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((address, index) => (
                  <TableRow key={`user_address_${index}`}>
                    <TableCell>{`${index + 1}.`}</TableCell>
                    <TableCell>{address?.customerName ?? "-"}</TableCell>
                    <TableCell>{address?.phone ?? "-"}</TableCell>
                    <TableCell>{address?.address_line1 ?? "-"}</TableCell>
                    <TableCell>{address?.city ?? "-"}</TableCell>
                    <TableCell>{address?.state ?? "-"}</TableCell>
                    <TableCell>{address?.zip ?? "-"}</TableCell>
                    <TableCell>{address?.landmark ?? "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>No Address Found</p>
        )}
        <div>
          {props?.userCoupons.length > 0 && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Expiry Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props?.userCoupons.map((coupon) => (
                  <tr key={coupon?._id}>
                    <td>{coupon.code}</td>
                    <td>{currencyFormat(coupon?.value)}</td>
                    <td>
                      <Autocomplete
                        defaultValue={coupon.userStatus[0]?.status}
                        onChange={handleStatusChange}
                        options={statusOptions}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                      />
                    </td>
                    <td>{formatDate(coupon?.expiresAt)}</td>
                    <td>
                      <button className="btn btn-sm btn-success" onClick={() => updateCoupon(coupon?._id)}>
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserAddressModal;
