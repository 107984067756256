// top level imports
import { useState, useEffect, useRef } from "react";

// React-Toastify
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Font-awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

// Moment
import Moment from "react-moment";

// Socket IO reference
import { io } from "socket.io-client";

// API
import { validateEan, getAcceptedCnmts, getMissedCnmts, getCanceledCnmts, initCnmtRefund, createShipment, genShippingLbl } from "../../api/Retailer";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
import { APP_DATE_TIME_FORMAT, SOCKET } from "../../utils/Constants";
import { useRetailerContext } from "../../Contexts/RetailerContext";
import { listFormat } from "../../utils";
import { cancelOrderItem } from "../../api/OrdersApi";
import PickUpFromStoreModal from "./PickupFromStore";
import { Button, useMediaQuery } from "@mui/material";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Component definition
export function Retailer() {
  const { store } = useRetailerContext();
  const storeId = localStorage.getItem("retailer_id");

  // Init sweetalert
  const MySwal = withReactContent(Swal);

  // Constants
  const SOCKET_SERVER_ENDPOINT = process.env.REACT_APP_SOCKET_BASE;
  const consignmentTypes = [
    { key: "accepted", label: "Accepted Consignments" },
    { key: "missed", label: "Missed Consignments" },
    { key: "canceled", label: "Canceled Consignments" },
  ];

  // state definitions
  // const [brandStoreId, setBrandStoreId] = useState(null);
  const [consignmentType, setConsignmentType] = useState("accepted");
  const [newConsignments, setNewConsignments] = useState([]);
  const [missedCnmts, setMissedCnmts] = useState([]);
  const [consignmentAccepted, setConsignmentAccepted] = useState([]);
  const [consignmentCanceled, setConsignmentCanceled] = useState([]);
  const [manualConsignments, setManualConsignments] = useState([]);
  const [manualDeliveryId, setManualDeliveryId] = useState(null);
  const [pickupConsignmentId, setPickupConsignmentId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [orderMissed, setOrderMissed] = useState([]);

  // refs
  const socket = useRef(null);
  const renderedOnce = useRef(false);
  const selectConsignment = useRef(null);

  const isMobile = useMediaQuery(`(max-width: 500px)`);

  // componentDidMount / ComponentDidUpdate
  /* useEffect(() => {
        if (brandStoreId !== null) {
            fetchAcceptedCnmts(brandStoreId);
        }

    }, [brandStoreId]) */

  useEffect(() => {
    if (!renderedOnce.current) initSocketConnection(storeId);
    fetchAcceptedCnmts(storeId);
    fetchMissedCnmts(storeId);
    fetchCanceledCnmts(storeId);
    renderedOnce.current = true;
  }, [storeId]);

  useEffect(() => {
    if (manualDeliveryId != null) {
      setConsignmentAccepted((prev) => prev.filter((el) => el._id !== manualDeliveryId));
    }
  }, [manualDeliveryId]);

  /* useEffect(() => {
        socket.current?.on(SOCKET.ORDER_ACCEPT_ID, (orderDetails) => {
             setNewConsignments((prev) =>
                prev.filter(
                    (order) => order.toString() !== orderDetails._id.toString()
                )
            );
            setOrderMissed((prev) => [...new Set([...prev, orderDetails])]);
        });
    }, [])*/

  /** Handler functions - starts */

  const initSocketConnection = (storeId) => {
    // Initiate connection
    socket.current = io.connect(SOCKET_SERVER_ENDPOINT, {
      query: `storeId=${storeId}`,
    });
    // register event handlers
    socket.current.on(SOCKET.NEW_CNMT_PLACED, (newOrdersPayload) => {
      setNewConsignments((prev) => {
        const uniqueIds = new Set(prev.map((obj) => obj._id));

        const updatedConsignments = [
          ...newOrdersPayload.filter((item) => {
            if (!uniqueIds.has(item._id) && item.product.hierarchyL2 !== "Warranties") {
              uniqueIds.add(item._id);
              return true;
            }
            return false;
          }),
          ...prev,
        ];

        return updatedConsignments;
      });
    });
    socket.current.on(SOCKET.CNMT_ACCEPTED, (consgimentId) => {
      cnmtAccept(consgimentId._id);
    });
    socket.current.on(SOCKET.CNMT_ACCEPT_ERR, (err) => {
      onCnmtAcceptError(err);
    });

    socket.current.on(SOCKET.STORE_MANUAL_CNMT, (manualConsignmentsArr) => {
      setManualConsignments([...manualConsignmentsArr]);
    });

    socket.current.on(SOCKET.CNMT_MISSED, (missed) => {
      setMissedCnmts((prev) => [missed, ...prev]);
      setNewConsignments((prev) => prev.filter((el) => el._id !== missed._id));

      // handler for consignment missed
      onCnmtMissed();
    });
  };

  const fetchAcceptedCnmts = async (storeId) => {
    showLoader();
    try {
      const data = await getAcceptedCnmts(storeId);
      setConsignmentAccepted(data);
    } catch (err) {
      console.error(`Error while fetching accepted consignments :: ${err}`);
    } finally {
      hideLoader();
    }
  };

  const fetchMissedCnmts = async (storeId) => {
    showLoader();
    try {
      const data = await getMissedCnmts(storeId);
      setMissedCnmts(data);
    } catch (err) {
      console.error(`Error while fetching missed consignments :: ${err}`);
    } finally {
      hideLoader();
    }
  };

  const fetchCanceledCnmts = async (storeId) => {
    showLoader();
    try {
      const data = await getCanceledCnmts(storeId);
      setConsignmentCanceled(data);
    } catch (err) {
      console.error(`Error while fetching canceled consignments :: ${err}`);
    } finally {
      hideLoader();
    }
  };

  // Consignment Rejected handler
  const onCnmtReject = (cnmtId) => {
    if (!window.confirm(`Are you sure you want to reject Consignment with ID: ${cnmtId} ?`)) return;
    setNewConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    setManualConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    toast.success("Consignment rejected successfully!");
    socket.current.emit(SOCKET.CNMT_REJECTED, cnmtId);
  };

  // Consignment Accepted handler
  const cnmtAccept = (cnmtId) => {
    setNewConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    setManualConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    toast.success("Consignment accepted successfully!");
    setTimeout(() => fetchAcceptedCnmts(storeId), 100);
  };

  // changes tab region for consignment types
  const changeConsignmentType = (key) => {
    setConsignmentType(key);
  };

  function isValidIMEI(imei) {
    // Check if the input is a 15-digit number
    if (!/^\d{15}$/.test(imei)) {
      return false;
    }

    let sum = 0;

    // Iterate over the digits from right to left
    for (let i = 0; i < 15; i++) {
      let digit = parseInt(imei.charAt(i), 10);

      // Double every second digit from the right
      if (i % 2 === 1) {
        digit *= 2;
      }

      // If doubling the digit results in a number greater than 9, subtract 9 from it
      if (digit > 9) {
        digit -= 9;
      }

      // Add the digit to the sum
      sum += digit;
    }

    // The IMEI is valid if the sum is divisible by 10
    return sum % 10 === 0;
  }

  // initializes a consignment and shows a popup for QC
  const initQC = (consignment) => {
    selectConsignment.current = consignment;
    // inputs variable
    let ean = "",
      combo_ean = "",
      imei = "",
      combo_batch_name = "";

    MySwal.fire({
      title: "Perform QC",
      html: (
        <>
          <strong>Enter OPIN</strong>
          <input type="text" placeholder="Product OPIN" className="swal2-input d-block" style={{ width: "-webkit-fill-available" }} onBlur={(e) => (ean = e.target.value)} />

          {selectConsignment.current.product?.hierarchyL2 === "Smartphone" && (
            <input type="text" placeholder="Serial Number / IMEI" className="swal2-input d-block" style={{ width: "-webkit-fill-available" }} onBlur={(e) => (imei = e.target.value)} />
          )}
          {selectConsignment.current.product?.hierarchyL2 === "Smartphone" && (
            <small className="px-4" style={{ marginLeft: "unset" }}>
              Please enter the IMEI for
              <strong className="text-bold fs-6 ms-1">{selectConsignment.current.product?.title}</strong>
            </small>
          )}

          {selectConsignment.current.combo && (
            <>
              <input type="text" placeholder="Combo Product OPIN" className="swal2-input d-block" style={{ width: "-webkit-fill-available" }} onBlur={(e) => (combo_ean = e.target.value)} />

              <input type="text" placeholder="Combo store batch" className="swal2-input d-block" style={{ width: "-webkit-fill-available" }} onBlur={(e) => (combo_batch_name = e.target.value)} />
              <small className="px-4" style={{ marginLeft: "unset" }}>
                Please enter the Product Batch Name for
                <strong className="text-bold fs-6 ms-1">{selectConsignment.current.combo?.product?.title}</strong>
              </small>
            </>
          )}
        </>
      ),
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        // Validate IMEI if provided
        if (imei && !isValidIMEI(imei)) {
          toast.error("Provide Valid IMEI!");
          return false; // Prevents the SweetAlert2 from closing
        }

        try {
          // Validate EAN with the provided parameters
          await validateEan({
            ean,
            combo_ean,
            consignmentId: selectConsignment.current._id,
            storeId: store?._id ?? "",
            imei,
            combo_batch_name,
            hasComboProd: !!selectConsignment.current.combo?.childEan,
            brand_store_id: store?.brand_store_id,
          });
        } catch (error) {
          // Show a validation message if EAN validation fails
          MySwal.showValidationMessage(`EAN validation failed due to - ${error.response?.data?.message ?? "Internal Server Error"}`);
          return false; // Prevents the SweetAlert2 from closing
        }
        return true; // Allows the SweetAlert2 to close
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          const consignmentId = selectConsignment.current._id;
          socket.current.emit(SOCKET.CNMT_ACCEPTED, consignmentId);
        }
      })
      .finally(() => {
        selectConsignment.current = null;
      });
  };

  // handles consignment missed event
  const onCnmtMissed = () => {
    toast.warn("Consignment missed!");
    MySwal.close();
    // reset selected consignment ref
    selectConsignment.current = null;
  };

  // Initiates refund for a consignment
  const initRefund = async (consignment) => {
    try {
      const response = await initCnmtRefund(consignment?._id);
      const payload = response.data;
      if (payload?.status === "success") {
        toast.success(payload.message);
        fetchCanceledCnmts(storeId);
        setConsignmentType("canceled");
      }
    } catch {
      // handle error
    }
  };

  // invokes an API to cancel consignment
  const cancelConsignment = async (consignment) => {
    let orderProdId = "";
    if (typeof consignment.order === "string") {
      orderProdId += consignment.order;
    } else {
      orderProdId += consignment.order._id;
    }
    orderProdId += "-";
    if (typeof consignment.product === "string") {
      orderProdId += consignment.product;
    } else {
      orderProdId += consignment.product._id;
    }

    const query = "Product Stock not available";
    const cancelled_by = "consignor";
    showLoader();
    try {
      const response = await cancelOrderItem(orderProdId, query, cancelled_by);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success("Consignment cancelled successfully!");
        toast.info(payload.message);
        setConsignmentType("accepted");
        fetchAcceptedCnmts(storeId);
        fetchCanceledCnmts(storeId);
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      hideLoader();
    }
  };

  const initShipment = async (consignment) => {
    const consgimentId = consignment._id;
    if (!consignment.voucherId) {
      toast.error("Cannot create shipment, Invoice/Voucher not generated!");
      return;
    }

    if (consignment.combo && !consignment.comboVoucherId) {
      toast.error("Cannot create shipment, Invoice/Voucher not generated for child product in combo offer!");
      return;
    }

    // transforms and shows appropriate error message
    const showShipmentError = (err) => {
      const serverResError = err.response?.data?.message?.message ?? err.response?.data?.message ?? "";
      if (serverResError) {
        toast.error(serverResError);
      } else {
        toast.error(err.toString());
      }
    };

    showLoader();
    try {
      const data = {
        storeId: store?._id,
        brand_store_id: store?.brand_store_id,
        consgimentId,
      };
      const response = await createShipment(data);
      if (response instanceof Error) {
        showShipmentError(response);
      } else {
        const payload = response.data;
        if (payload.status === "success") {
          toast.success("Shipment requested successfully!");
          fetchAcceptedCnmts(storeId);
        }
      }
    } catch (err) {
      showShipmentError(err);
    } finally {
      hideLoader();
    }
  };

  const cancelShipment = (consignment) => {
    console.log(consignment);
  };

  const onCnmtAcceptError = (err) => {
    let message = "Something went wrong while accepting consignment";
    if (err.message.message.includes("Wrong Pickup location entered")) {
      message = "Store pickup location not active on Shiprocket";
    }
    toast.error(message);
  };

  const onPickUpAtStore = (cnmtId) => {
    setPickupConsignmentId(cnmtId);
    handleOpen();
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenModal(false);
    }
  };

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  const generateShiprocketLabel = async (consg) => {
    const consignmentId = consg._id;
    showLoader();
    try {
      const data = {
        storeId: store?._id,
        brand_store_id: store?.brand_store_id,
        consignmentId,
      };
      const response = await genShippingLbl(data);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success("Label generated successfully!");
        fetchAcceptedCnmts(storeId);
      }
    } finally {
      hideLoader();
    }
  };

  /** Handler functions - ends */

  // Main renderer
  return (
    <div className="container">
      <div className="catelogue_Page section_Wrapper">
        <div className="flex justify-content-end">
          <FontAwesomeIcon color="#0d6efd" title="Reload if consignments are not visible" onClick={() => window.location.reload()} cursor={"pointer"} size="lg" icon={faRefresh} />
        </div>
        <h3>Welcome, {store?.fc_name}</h3>
        <h4>Store Brand Store ID: {store?.brand_store_id}</h4>
        <h4>Store SAP Code: {store?.sap_code}</h4>
        <h4>Store ID: {store?.store_id}</h4>
        <h5>Store Pincode: {store?.pincode}</h5>
        <Accordion className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Store Serviceable Pincodes: </Accordion.Header>
            <Accordion.Body
              style={{
                color: "black",
                background: "white",
              }}
            >
              {Array.isArray(store?.serviceablePincodes) ? listFormat(store.serviceablePincodes) : ""}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr />

        {manualConsignments.length > 0 && (
          <>
            <h4>Manual Orders</h4>
            <div style={{ overflowX: "auto", width: "100%" }}>
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Consignment ID</th>
                    <th>Product OPIN</th>
                    <th>Product Title</th>
                    <th>Quantity</th>
                    <th>Combo Product EAN</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {manualConsignments.map((consg, index) => (
                    <tr key={consg?._id}>
                      <td>{index + 1}</td>
                      <td>{consg?._id}</td>
                      <td>{consg?.product?.ean}</td>
                      <td>{consg?.product?.title}</td>
                      <td>{consg?.quantity}</td>
                      <td>{consg?.combo ? consg?.combo.childEan : "-"}</td>
                      <td>{<Moment date={consg?.createdAt} format={APP_DATE_TIME_FORMAT} />}</td>
                      <td>
                        <div className="d-inline-flex justify-content-between w-100 gap-2">
                          {consg?.order?.manualCnmtReceive ? (
                            <button className="btn btn-info" onClick={() => initQC(consg)}>
                              QC and Accept Manual Delivery
                            </button>
                          ) : (
                            <button className="btn btn-success" onClick={() => initQC(consg)}>
                              QC and Accept
                            </button>
                          )}
                          <button className="btn btn-danger" onClick={() => onCnmtReject(consg?._id)}>
                            Reject
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <hr />

        <h4 className="mt-2">Recent Consignments</h4>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Consignment ID</th>
                <th>Product OPIN</th>
                <th>Product Title</th>
                <th>Quantity</th>
                <th>Combo Product EAN</th>
                <th>Created at</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {!loading &&
                newConsignments.length > 0 &&
                newConsignments.map((consg, index) => (
                  <tr key={consg?._id}>
                    <td>{index + 1}</td>
                    <td>{consg?._id}</td>
                    <td>{consg?.product?.ean}</td>
                    <td>{consg?.product?.title}</td>
                    <td>{consg?.quantity}</td>
                    <td>{consg?.combo ? consg?.combo.childEan : "-"}</td>
                    <td>
                      <Moment date={consg?.createdAt} format={APP_DATE_TIME_FORMAT} />
                    </td>
                    <td>
                      <div className="d-inline-flex justify-content-between w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", gap: "12px" }}>
                        <button className="btn btn-success" onClick={() => initQC(consg)}>
                          QC and Accept
                        </button>
                        <button className="btn btn-danger" onClick={() => onCnmtReject(consg?._id)}>
                          Reject
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>

            {!loading && !newConsignments.length && (
              <tbody>
                <tr className="text-center">
                  <td colSpan={7}>No records found!</td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody>
                <tr className="text-center">
                  <td colSpan={7}>
                    <DashboardLoader />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <hr />

        <div className="offers_Toggle_Container mb-2" style={{ maxWidth: "unset" }}>
          {consignmentTypes.map(({ key, label }) => (
            <p className={consignmentType === key ? "offer_Selected" : ""} onClick={() => changeConsignmentType(key)}>
              {label}
            </p>
          ))}
        </div>

        {/** Selected tab header */}
        <div className="catelogue_Page_Header">
          <h4 className="catelogue_Page_Heading">{consignmentTypes.find((el) => el.key === consignmentType)?.label ?? ""}</h4>
        </div>

        {/** Selected tab content */}
        <div>
          {consignmentType === "accepted" && (
            <Accepted
              accepted={consignmentAccepted}
              initShipment={initShipment}
              cancelConsignment={cancelConsignment}
              cancelShipment={cancelShipment}
              onPickUpAtStore={onPickUpAtStore}
              generateShiprocketLabel={generateShiprocketLabel}
              loading={loading}
            />
          )}
        </div>
        <div>{consignmentType === "missed" && <Missed missed={missedCnmts} loading={loading} />}</div>
        <div>{consignmentType === "canceled" && <Canceled canceled={consignmentCanceled} initRefund={initRefund} loading={loading} />}</div>
      </div>
      <PickUpFromStoreModal
        open={openModal}
        handleClose={handleClose}
        setOpenModal={setOpenModal}
        isMobile={isMobile}
        pickupConsignmentId={pickupConsignmentId}
        manualDeliveryId={manualDeliveryId}
        setManualDeliveryId={setManualDeliveryId}
      />
    </div>
  );
}

// Displays Accepted Consignments
function Accepted({ accepted, cancelConsignment, initShipment, cancelShipment, onPickUpAtStore, generateShiprocketLabel, loading = false }) {
  const navigate = useNavigate();
  const handleCreateInvoice = (index) => {
    const queryParams = new URLSearchParams();
    queryParams.set("id", accepted[index]._id);
    const url = `/invoice?${queryParams.toString()}`;
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      // Fallback to navigating in the same tab if opening in new tab is blocked
      navigate("/invoice");
    }
  };
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Consignment ID</th>
            <th>Invoice ID</th>
            <th>Product OPIN</th>
            {/* <th>Combo Invoice ID</th> */}
            <th>Combo Product EAN</th>
            <th>Status</th>
            <th>Accepted at</th>
            <th>Shiprocket Label/Manifest</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {!loading &&
            accepted.length > 0 &&
            accepted.map((consg, index) => (
              <tr key={consg?._id}>
                <td>{index + 1}</td>
                <td>{consg?._id}</td>
                <td>
                  {" "}
                  {consg?.voucherId ? (
                    <span
                      className="voucher-ids"
                      onClick={
                        () => handleCreateInvoice(index)

                        // window.open(`${process.env.REACT_APP_PDFURL}/${details.voucherId}.pdf`, "_blank");

                        // window.open(`${process.env.REACT_APP_PDFURL}/${consg.voucherId}.pdf`, "_blank");
                      }
                    >
                      {consg.voucherId}
                    </span>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{consg?.product?.ean}</td>
                {/* <td>
                  {consg?.comboVoucherId ? (
                    <span
                      className="voucher-ids"
                      onClick={() => {
                        window.open(`${process.env.REACT_APP_PDFURL}/${consg.comboVoucherId}.pdf`, "_blank");
                      }}
                    >
                      {consg.comboVoucherId}
                    </span>
                  ) : (
                    "-"
                  )}
                </td> */}
                <td>{consg?.combo?.childEan ?? "-"}</td>
                <td>{consg?.status ?? "-"}</td>
                <td>{<Moment date={consg?.acceptedAt} format={APP_DATE_TIME_FORMAT} />}</td>
                <td>
                  {!consg?.shiprocket_label ? (
                    <button className="btn btn-success me-2" disabled={consg.status !== "DELIVERY_INITIATED"} onClick={() => generateShiprocketLabel(consg)}>
                      Generate Label
                    </button>
                  ) : (
                    <a download href={consg.shiprocket_label} className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                      Download Shipping Label
                    </a>
                  )}
                </td>
                <td className="d-flex justify-content-between gap-2">
                  {consg.status === "CONSIGNMENT_ACCEPTED" && consg.order.manualCnmtReceive ? (
                    <Button variant="contained" size="medium" color="secondary" onClick={() => onPickUpAtStore(consg?._id)}>
                      Deliver from store
                    </Button>
                  ) : consg.status !== "DELIVERY_INITIATED" && consg.status !== "DELIVERY_SUCCESS" ? (
                    <button className="btn btn-info me-2" onClick={() => initShipment(consg)} disabled={!consg.voucherId}>
                      Create Shipment
                    </button>
                  ) : (
                    // <button className="btn btn-danger me-2" onClick={() => cancelShipment(consg)}>
                    //     Cancel Shipment
                    // </button>
                    <></>
                  )}
                  <button className="btn btn-danger me-2" onClick={() => cancelConsignment(consg)}>
                    Cancel Consignment
                  </button>
                </td>
              </tr>
            ))}
        </tbody>

        {!loading && !accepted.length && (
          <tbody>
            <tr className="text-center">
              <td colSpan={7}>No records found!</td>
            </tr>
          </tbody>
        )}

        {loading && (
          <tbody>
            <tr className="text-center">
              <td colSpan={7}>
                <DashboardLoader />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

// Displays Missed Consignments
function Missed({ missed, loading = false }) {
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Consignment ID</th>
            <th>Product EAN</th>
            <th>Combo Product EAN</th>
            <th>Accepted at</th>
            <th>Accepted by</th>
          </tr>
        </thead>

        <tbody>
          {!loading &&
            missed.length > 0 &&
            missed.map((consg, index) => (
              <tr key={consg?._id}>
                <td>{index + 1}</td>
                <td>{consg?._id}</td>
                <td>{consg?.product?.ean}</td>
                <td>{consg?.combo?.childEan ?? "-"}</td>
                <td>{<Moment date={consg?.acceptedAt} format={APP_DATE_TIME_FORMAT} />}</td>
                <td>{consg.consignor?.brand_store_id ?? "N.A"}</td>
              </tr>
            ))}
        </tbody>

        {!loading && !missed.length && (
          <tbody>
            <tr className="text-center">
              <td colSpan={6}>No records found!</td>
            </tr>
          </tbody>
        )}

        {loading && (
          <tbody>
            <tr className="text-center">
              <td colSpan={6}>
                <DashboardLoader />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

// Displays Missed Consignments
function Canceled({ canceled, initRefund, loading = false }) {
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Consignment ID</th>
            <th>Product EAN</th>
            <th>Combo Product EAN</th>
            <th>Credit Note ID</th>
            <th>Canceled at</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {!loading &&
            canceled.length > 0 &&
            canceled.map((consg, index) => (
              <tr key={consg?._id}>
                <td>{index + 1}</td>
                <td>{consg?._id}</td>
                <td>{consg?.product?.ean}</td>
                <td>{consg?.combo?.childEan ?? "-"}</td>
                <td>{consg?.creditNoteId ?? "N.A"}</td>
                <td>{<Moment date={consg?.cancelled_at} format={APP_DATE_TIME_FORMAT} />}</td>
                <td>{consg?.status ?? "-"}</td>
              </tr>
            ))}
        </tbody>

        {!loading && !canceled.length && (
          <tbody>
            <tr className="text-center">
              <td colSpan={6}>No records found!</td>
            </tr>
          </tbody>
        )}

        {loading && (
          <tbody>
            <tr className="text-center">
              <td colSpan={6}>
                <DashboardLoader />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}
