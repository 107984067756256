import axios from "../axiosInstance";

//GET ALL ADMIN ORDERS +++++++++++++++++++++++++++++++++++++++++++
export const getAdminAllOrder = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allOrderResponse;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/admin/order?${query}`, { headers })
    .then((res) => {
      allOrderResponse = res.data.data;
    })
    .catch((err) => (allOrderResponse = err.response));
  return allOrderResponse;
};

export const getAllConsignments = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allOrderResponse;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/admin/getAllConsignments?${query}`, { headers })
    .then((res) => {
      allOrderResponse = res.data.data;
    })
    .catch((err) => (allOrderResponse = err.response));
  return allOrderResponse;
};

//GET INDIV ORDERS +++++++++++++++++++++++++++++++++++++++++++
export const getAdminIndOrder = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let indOrderResponse;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/admin/order/${id}`, { headers })
    .then((res) => {
      indOrderResponse = res.data.data;
    })
    .catch((err) => console.log(err));

  return indOrderResponse;
};

//GET All orders count +++++++++++++++++++++++++++++++++++++++++++
export const getAllOrderCounts = async () => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let indOrderResponse;

  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/orders/getordercounts`, { headers });
    if (res) {
      indOrderResponse = res.data;
    }

    return indOrderResponse;
  } catch (error) {
    console.log("error ", error);
  }
};

//GET INDIV Consignments +++++++++++++++++++++++++++++++++++++++++++
export const getAdminIndividualConsignment = async (id) => {
  // let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    // Authorization: `Bearer ${adminToken}`,
  };

  let indOrderResponse;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/admin/order/consignment/${id}`, { headers })
    .then((res) => {
      indOrderResponse = res.data.data;
    })
    .catch((err) => console.log(err));

  return indOrderResponse;
};

//DELET INDIV ORDERS +++++++++++++++++++++++++++++++++++++++++++
export const deletAdminIndOrder = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  let deletOrderResponse;
  let text = "Are you sure for delete?";
  if (window.confirm(text) === true) {
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/product/admin/order/${id}`, { headers })
      .then((res) => {
        deletOrderResponse = res;
      })
      .catch((err) => console.log(err));
  } else {
    alert("User save!");
  }

  return deletOrderResponse;
};
