/* eslint-disable react-hooks/exhaustive-deps */

//Dependencies
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import { getUser, getUserPic, getWalletData } from "./api/Auth";
import { getAllProducts, getSearchedProduct, getUsersSubProds } from "./api/Product";
import { getAddress } from "./api/Address";
// import { getOfferDetails } from "./api/Product";

import useWindowDimensions from "./hooks/useWindowDimensions";
import useUserCtx from "./hooks/useUserCtx";
import useSiteCtx from "./hooks/useSiteCtx";
import useProductCtx from "./hooks/useProductCtx";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HeaderBar2 from "./components/HeaderBar2/HeaderBar2";
import RouterConfig from "./navigation/RouterConfig";
import PreLoader from "./components/_IFD/PreLoader";
import Footer from "./components/Footer/Footer";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";

// Utils
import { isOfferActive } from "./utils";
import { getAllWishlistItems } from "./api/wishlistApi";
import { getCartData, addToCart as updateCart } from "./api/Cart";
import { getHomePageSections, getSeoData } from "./api/AdminApis/HomePageSections";
// const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
// const DashboardAddBannerImage = React.lazy(() => import("./pages/Dashboard/DashboardAddBannerImage"));
// const DashboardAddDiscount = React.lazy(() => import("./pages/Dashboard/DashboardAddDiscount"));
// const DashboardAddProduct = React.lazy(() => import("./pages/Dashboard/DashboardAddProduct"));
// const DashboardAddProductCsvFile = React.lazy(() => import("./pages/Dashboard/DashboardAddProductCsvFile"));
// const DashboardAddShop = React.lazy(() => import("./pages/Dashboard/DashboardAddShop"));
// const DashboardAlluser = React.lazy(() => import("./pages/Dashboard/DashboardAlluser"));
// const DashboardHomePage = React.lazy(() => import("./pages/Dashboard/DashboardBanner"));
// const DashboardDiscount = React.lazy(() => import("./pages/Dashboard/DashboardDiscount"));
// const DashBoardHome = React.lazy(() => import("./pages/Dashboard/DashBoardHome"));
// const DashboardOrders = React.lazy(() => import("./pages/Dashboard/DashboardOrders"));
// const DashboardProducts = React.lazy(() => import("./pages/Dashboard/DashboardProducts"));
// const DashboardShop = React.lazy(() => import("./pages/Dashboard/DashBoardShop"));
// const Cancellation = React.lazy(() => import("./pages/Dashboard/Cancellation"));
// const Payments = React.lazy(() => import("./pages/Dashboard/Payments"));
// const Quirys = React.lazy(() => import("./pages/Dashboard/Quirys"));

// const MyCart = React.lazy(() => import("./pages/MyCart/MyCart"));
// const DeliveryOptions = React.lazy(() => import("./pages/MyCart/DeliveryOptions"));
// const EditAccont = React.lazy(() => import("./pages/EditAccount/EditAccount"));
// const CustomerSupport = React.lazy(() => import("./pages/CustomerSupport/CustomerSupport"));
// const WriteToUS = React.lazy(() => import("./pages/CustomerSupport/WriteToUs"));
// const BankOffer = React.lazy(() => import("./pages/BankOffer/BankOffer"));
// const AddOffers = React.lazy(() => import("./pages/CataloguePage/AddOffers"));
// const AddProduct = React.lazy(() => import("./pages/CataloguePage/AddProduct"));
// const CataloguePage = React.lazy(() => import("./pages/CataloguePage/CataloguePage"));
// const BulkUpload = React.lazy(() => import("./pages/CataloguePage/BulkUpload"));
// const Address = React.lazy(() => import("./pages/Address/Address"));
// const HomeDelivery = React.lazy(() => import("./pages/Address/HomeDelivery"));
// const NewAddress = React.lazy(() => import("./pages/Address/NewAddress"));
// const EditAddress = React.lazy(() => import("./pages/Address/EditAddress"));
// const AboutUs = React.lazy(() => import("./pages/AboutContact/AboutUs"));
// const StoreFinder = React.lazy(() => import("./pages/StoreFinder/StoreFinder"));
// const ProductCategory = React.lazy(() => import("./pages/ProductCategory/ProductCategory"));
// const OfflineProductCategory = React.lazy(() => import("./pages/ProductCategory/OfflineProductCategory"));
// const OrderDetails = React.lazy(() => import("./pages/OrderDetails/OrderDetails"));
// const MyOrders = React.lazy(() => import("./pages/MyOrders/MyOrders"));
// const DashboardIFD = React.lazy(() => import("./pages/Dashboard/DashboardIFD"));
// const MyWishlist = React.lazy(() => import("./pages/Wishlist/MyWishlist"));
// const IFDHome = React.lazy(() => import("./pages/IFD_Home"));
// const Profile = React.lazy(() => import("./pages/profile/Profile"));

function App() {
  const { width } = useWindowDimensions();
  const matches768 = width >= 768;
  const loc = useLocation();

  const { setUserLoggedIn } = useSiteCtx();
  const { setAllProducts, setPayuOffers } = useProductCtx();
  const {
    userLocation,
    userContext,
    setUserContext,
    userAddress,
    setUserAddress,
    userComboCart,
    setUserComboCart,
    cartArray,
    setCartArray,
    setWalletData,
    setRemainngAmt,
    userDefaultAddress,
    setUserDefaultAddress,
    deliveryEstDays,
    deliveryCharges,
    setDeliveryCharges,
    setUserZip,
    setUserLocation,
    appliedCoupon,
    setPriceBoxDetails,
    payuOffers,
    setUserWishlist,
    paymentProductArr,
    setUserSubscribed,
    setUserPreBooked,
    userPreBooked,
  } = useUserCtx();

  const { setHomePageSections, setSeoData } = useSiteCtx();
  // --------------Temporary Chanegs for IFD --------------

  // useEffect(() => {
  //   if (loc.pathname !== "/indian-festival-days") {
  //     navigate("/indian-festival-days");
  //   }
  // }, [loc.pathname]);

  // --------------Temporary Chanegs for IFD --------------

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUserContext(user);

    // fetchOfferDetails();
  }, []);

  useEffect(() => {
    let cartData = JSON.parse(localStorage.getItem("cart"));

    if (cartData) {
      (async () => {
        await fetchNAddComboProducts(cartData);
        setCartArray(cartData);
      })();
    }
  }, []);

  let userToken = userContext?.JWT;
  useEffect(() => {
    if (userToken) {
      setTimeout(() => {
        getUser(userToken).then((res) => {
          if (res) {
            let user = res;
            setUserContext((prev) => ({
              ...prev,
              id: user._id,
              fullName: user.fullName,
              mobileNumber: user.mobileNumber,
              email: user.email,
              dob: user.dob,
            }));
          }
        });

        getUserPic(userToken).then((res) => {
          if (res) {
            setUserContext((prev) => ({
              ...prev,
              profilePic: res,
            }));
          }
        });
        if (!loc.pathname.includes("/admin")) {
          getCartData().then((res) => {
            if (res) {
              let prod = [];
              res.cart.forEach((item) => {
                if (item?.productId === null) return;
                let product = { ...item?.productId };
                product["quantity"] = item.qty;
                product["from"] = item.from;
                product["coupon"] = item.couponId ? item.couponId : {};
                prod.push(product);
              });
              setCartArray({
                loaded: true,
                no_of_carts: prod.length,
                cart: prod,
                combo: res.combo,
              });
            }
          });
          getWalletData().then((res) => {
            if (res) {
              setWalletData(res?.allCoupons);
              setRemainngAmt(res?.remainingAmount);
            } else {
              setWalletData([]);
            }
          });
        }
      }, 500);
    }
  }, [userToken]);

  useEffect(() => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  }, [userContext]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(userContext));
  }, [userContext]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartArray));
  }, [cartArray]);

  // useEffect(() => {
  //     getAllProducts().then((res) => {
  //         setAllProducts((prev) => ({
  //             ...prev,
  //             loaded: true,
  //             no_of_products: res.no_of_products,
  //             products: res.products,
  //         }));
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (userContext?.JWT) {
        const cartData = JSON.parse(localStorage.getItem("cart")).cart;
        if (cartData.length > 0) {
          for (const prod of cartData) {
            await updateCart(prod._id, prod.quantity, "");
          }
        }
      }
    };

    fetchData();
  }, [userContext]);

  useEffect(() => {
    if (userContext && userContext.JWT) {
      getAddress().then((res) => {
        if (res) {
          setUserAddress({
            loaded: true,
            no_of_address: res.no_of_address,
            address: res.address,
          });
        }
      });
    }
  }, [userContext?.JWT]);

  useEffect(() => {
    if (cartArray.combo && cartArray.combo.length > 0) {
      cartArray.combo.map((product) => {
        let searchTerm = "ean=" + product;
        getSearchedProduct(searchTerm).then((res) => {
          if (res.no_of_products > 0) {
            let product = res.products[0];
            let ind = userComboCart.findIndex((obj) => obj._id === product._id);
            if (ind === -1) {
              setUserComboCart([...userComboCart, product]);
            }
          }
        });
      });
    }
  }, [cartArray]);

  // Price Box Details Calculation===========================
  useEffect(() => {
    if (paymentProductArr.cart.length > 0 && loc.pathname !== "/mycart") {
      const productNumbers = paymentProductArr?.cart.reduce((accumulator, current) => {
        // count combo products
        if (Array.isArray(current.comboProductDetails)) {
          accumulator = accumulator + current.comboProductDetails.length;
        }
        return accumulator + current?.quantity;
      }, 0);

      const productPrice = paymentProductArr?.cart?.reduce((accumulator, current) => {
        // count combo products price
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              return acc + curr?.price?.mrp;
            }, 0);
        }
        return accumulator + current?.price?.mrp * current?.quantity;
      }, 0);

      const totalPreBookAmt = userPreBooked.reduce((acc, item) => {
        const cart = paymentProductArr.cart;
        for (const i in cart) {
          if (cart[i]._id === item.productId) {
            acc += item.amount;
          }
        }
        return acc;
      }, 0);

      const totalMop = paymentProductArr.cart.reduce((accumulator, current) => {
        if (current?.hierarchyL2 === "Warranties") {
          return accumulator;
        }
        const mop = parseFloat(current?.price?.mop);
        // count combo products mop
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              if (!curr?.price) return 0;
              const comboMop = parseFloat(curr.price?.mop);
              return acc + comboMop;
            }, 0);
        }
        return (accumulator += mop * current.quantity);
      }, 0);

      const flatDiscountSum = paymentProductArr.cart.reduce((accumulator, current) => {
        const currentMop = current?.price?.discountPrice ? current?.price?.discountPrice : current?.price?.mop;
        if (current.discount?.flatDiscount?.value > 0 && isOfferActive(current.discount?.flatDiscount)) {
          if (current.discount?.flatDiscount?.discountType === "flat") {
            accumulator += current.discount?.flatDiscount?.value * current.quantity;
          } else {
            // mop * flatDiscountInPercentage / 100
            accumulator += (currentMop * current.discount?.flatDiscount?.value) / 100;
          }
        }

        // deduct any discount applied on the combo product
        if (Array.isArray(current.comboProductDetails)) {
          const comboOffer = current.discount?.combo;
          accumulator += current.comboProductDetails.reduce((acc, curr) => {
            const currComboMop = curr?.price?.discountPrice ? curr?.price?.discountPrice : curr?.price?.mop;
            if (comboOffer?.value > 0 && isOfferActive(comboOffer)) {
              if (comboOffer?.discountType === "flat") {
                acc += parseFloat(comboOffer?.value);
              } else {
                acc += (currComboMop * comboOffer?.value) / 100;
              }
            }
            return acc;
          }, 0);
        }
        return accumulator;
      }, 0);

      const giftCouponAmount = paymentProductArr.cart.reduce((acc, current) => {
        const couponAmount = current.coupon?.value || 0;
        return acc + couponAmount;
      }, 0);

      // apply coupon discount that was entered by user on checkout
      const couponDiscount = !isNaN(+appliedCoupon?.discount) ? +appliedCoupon?.discount : 0;

      const discountedPrice = productPrice - totalMop + flatDiscountSum + couponDiscount + giftCouponAmount;
      const totalDeliveryCharge = isNaN(+deliveryCharges) ? 0 : +deliveryCharges;

      const totalAmount = productPrice - discountedPrice + totalDeliveryCharge;

      setPriceBoxDetails({
        cartItemsNumber: productNumbers,
        cartItemsPrice: productPrice,
        totalAmount: totalAmount,
        totalDiscount: discountedPrice,
        totalDeliveryCharge: totalDeliveryCharge,
        totalPreBookAmount: totalPreBookAmt,
        giftCouponAmount,
      });
    }
  }, [payuOffers, paymentProductArr, deliveryCharges, appliedCoupon, userPreBooked]);

  useEffect(() => {
    if (cartArray.cart && loc.pathname === "/mycart") {
      const productNumbers = cartArray?.cart.reduce((accumulator, current) => {
        // count combo products
        if (Array.isArray(current.comboProductDetails)) {
          accumulator = accumulator + current.comboProductDetails.length;
        }
        return accumulator + current?.quantity;
      }, 0);

      const totalPreBookAmt = userPreBooked.reduce((acc, item) => {
        const cart = cartArray.cart;
        for (const i in cart) {
          if (cart[i]._id === item.productId) {
            acc += item.amount;
          }
        }
        return acc;
      }, 0);

      const productPrice = cartArray?.cart?.reduce((accumulator, current) => {
        // count combo products price
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              return acc + curr?.price?.mrp;
            }, 0);
        }
        return accumulator + current?.price?.mrp * current?.quantity;
      }, 0);

      let totalMop = cartArray.cart.reduce((accumulator, current) => {
        if (current?.hierarchyL2 === "Warranties") {
          return accumulator;
        }
        const mop = parseFloat(current?.price?.mop);
        // count combo products mop
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              if (!curr?.price) return 0;
              const comboMop = parseFloat(curr.price?.mop);
              return acc + comboMop;
            }, 0);
        }
        return (accumulator += mop * current.quantity);
      }, 0);

      const flatDiscountSum = cartArray.cart.reduce((accumulator, current) => {
        const currentMop = current?.price?.discountPrice ? current?.price?.discountPrice : current?.price?.mop;
        if (current.discount?.flatDiscount?.value > 0 && isOfferActive(current.discount?.flatDiscount)) {
          if (current.discount?.flatDiscount?.discountType === "flat") {
            accumulator += current.discount?.flatDiscount?.value * current.quantity;
          } else {
            // mop * flatDiscountInPercentage / 100
            accumulator += (currentMop * current.discount?.flatDiscount?.value) / 100;
          }
        }

        // deduct any discount applied on the combo product
        if (Array.isArray(current.comboProductDetails)) {
          const comboOffer = current.discount?.combo;
          accumulator += current.comboProductDetails.reduce((acc, curr) => {
            const currComboMop = curr?.price?.discountPrice ? curr?.price?.discountPrice : curr?.price?.mop;
            if (comboOffer?.value > 0 && isOfferActive(comboOffer)) {
              if (comboOffer?.discountType === "flat") {
                acc += parseFloat(comboOffer?.value);
              } else {
                acc += (currComboMop * comboOffer?.value) / 100;
              }
            }
            return acc;
          }, 0);
        }
        return accumulator;
      }, 0);

      // if (userPreBooked.length > 0) {
      //   for (let i = 0; i < cartArray.cart.length; i++) {
      //     const productId = cartArray.cart[i]._id;
      //     const prebookedItem = userPreBooked.find((el) => el.productId === productId);
      //     if (prebookedItem) {
      //       totalMop -= prebookedItem.amount;
      //     }
      //   }
      // }

      // apply coupon discount that was entered by user on checkout
      const giftCouponAmount = cartArray.cart.reduce((acc, current) => {
        const couponAmount = current.coupon?.value || 0;
        return acc + couponAmount;
      }, 0);

      const couponDiscount = !isNaN(+appliedCoupon?.discount) ? +appliedCoupon?.discount : 0;

      const discountedPrice = productPrice - totalMop + flatDiscountSum + couponDiscount + giftCouponAmount;
      const totalDeliveryCharge = isNaN(+deliveryCharges) ? 0 : +deliveryCharges;

      const totalAmount = productPrice - discountedPrice + totalDeliveryCharge - totalPreBookAmt;

      setPriceBoxDetails({
        cartItemsNumber: productNumbers,
        cartItemsPrice: productPrice,
        totalAmount: totalAmount,
        totalDiscount: discountedPrice,
        totalDeliveryCharge: totalDeliveryCharge,
        totalPreBookAmount: totalPreBookAmt,
        giftCouponAmount,
      });
    }
  }, [payuOffers, cartArray, deliveryCharges, appliedCoupon, userPreBooked]);

  //Calculate delivery total delivery charges of all items in cart
  useEffect(() => {
    let totalDelPrice = 0;
    if (deliveryEstDays && deliveryEstDays.loaded && deliveryEstDays.value.length > 0) {
      deliveryEstDays.value.forEach((order) => {
        if (order.deliverymodes.length > 0) {
          const delMode = order.deliverymodes.find((mode) => {
            return mode.deliveryType === "standard" && mode.paymentType === "Prepaid";
          });
          let delPrice = delMode ? parseInt(delMode?.deliveryCost.value) : 0;
          totalDelPrice = totalDelPrice + delPrice;
        }
      });
    }
    setDeliveryCharges(totalDelPrice);
  }, [deliveryEstDays]);

  //Set default Address========================================
  useEffect(() => {
    if (userAddress && userAddress.loaded) {
      if (userAddress.no_of_address === 0) {
        setUserDefaultAddress({
          loaded: true,
          address: {},
          no_of_address: 0,
        });
      } else if (userAddress.no_of_address === 1) {
        setUserDefaultAddress({
          loaded: true,
          useThis: true,
          address: userAddress.address[0],
          no_of_address: 1,
        });
      } else if (userAddress.no_of_address > 1) {
        let useAdd = userAddress.address.filter((add) => add.isDefault === true);
        setUserDefaultAddress({
          loaded: true,
          useThis: true,
          address: useAdd[0],
          no_of_address: 1,
        });
      }
    }
  }, [userAddress]);

  //Set Zip from Default Address or from input=============================
  useEffect(() => {
    if (userDefaultAddress?.useThis) {
      if (userDefaultAddress.no_of_address > 0) {
        setUserZip({
          loaded: true,
          value: userDefaultAddress?.address?.zip,
        });
      } else {
        setUserZip({
          loaded: false,
          value: "",
        });
      }
    } else if (userLocation?.useThis) {
      setUserZip({
        loaded: true,
        value: userLocation?.address?.zip,
      });
    } else {
      setUserZip({
        loaded: false,
        value: "",
      });
    }
  }, [userDefaultAddress, userLocation]);
  useEffect(() => {
    const address = localStorage.getItem("setLocation");
    if (address && !userContext?.JWT) {
      var data = JSON.parse(address);
      setUserLocation((prev) => ({ ...prev, useThis: true, address: { zip: data?.zip } }));
    }
  }, []);

  // fetches offer from payu
  /* const fetchOfferDetails = async () => {
        const response = await getOfferDetails('bank_offer');
        const payuOffers = response?.data?.result?.offers?.map(offer => {
            return {
                offerAvail: `Step 1 ~ Step 2`,
                offerType: offer.offerType,
                offerName: offer.title,
                offerHeading: offer.title,
                discountDetail: offer.discountDetail
            }
        });
        
        // aggregate discount of payu offers
        const payuOfferDiscount = payuOffers?.reduce((acc, currentOffer) => {
            if (!isNaN(currentOffer.discountDetail?.discount)) {
                return acc + currentOffer.discountDetail?.discount;
            }
            return acc;
        }, 0);

        setPayuOffers(payuOffers);
        setPriceBoxDetails((prev) => ({
            ...prev,
            totalDiscount: prev.totalDiscount + payuOfferDiscount,
            totalAmount: prev.totalAmount - payuOfferDiscount,
        }));
    } */

  const fetchNAddComboProducts = async (cartData) => {
    const cartItems = cartData.cart;
    for (let i = 0; i < cartItems.length; i++) {
      const combo = cartItems[i]?.discount?.combo;
      const comboProds = [];
      if (combo?.value > 0 && isOfferActive(combo)) {
        const comboEan = combo.childEan;
        const res = await getSearchedProduct(`ean=${comboEan}`);
        const product = res.products[0];
        comboProds.push(product);
      }
      cartData.cart[i] = {
        ...cartData.cart[i],
        comboProductDetails: comboProds,
      };
    }
  };

  useEffect(() => {
    if (userContext && userContext.JWT) {
      getAllWishlistItems(true).then((res) => {
        if (res) {
          if (res) setUserWishlist((prev) => ({ ...prev, loaded: true, no_of_wishlist_items: res?.no_of_wishlist_items, wishlist_items: res?.wishlist_items }));
        }
      });
    }
  }, [userContext?.JWT]);

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);
      setHomePageSections(res.sections);
    });
  }, []);

  useEffect(() => {
    if (!loc.pathname.includes("/admin")) {
      const getSEODATA = async () => {
        let urlData = {
          url: window.location.href,
        };
        const data = await getSeoData(urlData);
        if (data) {
          setSeoData(data);
        }
      };
      getSEODATA();
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!userContext?.JWT) return;
    fetchUserSubscribedProds();
  }, [userContext]);

  const fetchUserSubscribedProds = async () => {
    // if (!loc.pathname.includes("/product/")) {
    try {
      const response = await getUsersSubProds();
      const payload = response.data;
      if (payload.status === "success") {
        setUserSubscribed(payload?.subscribedProducts ?? []);
        setUserPreBooked(payload?.preBooked ?? []);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  // Main renderer
  return (
    <>
      <ScrollToTop />
      <PreLoader />
      <div className="App">
        {loc.pathname === "/login" ||
        loc.pathname === "/signup" ||
        loc.pathname === "/otp" ||
        loc.pathname === "/adduser" ||
        loc.pathname.includes("/admin") ||
        loc.pathname.includes("/quiz-Offer") ||
        loc.pathname === "/career" ||
        loc.pathname === "/invoice" ||
        loc.pathname.includes("/consignments") ||
        loc.pathname.includes("/retailer") ||
        loc.pathname.includes("/indianfestivaldays2024") ? (
          ""
        ) : (
          <HeaderBar2 />
        )}
        <RouterConfig />
        {loc.pathname.includes("/admin") ||
        loc.pathname.includes("/retailer") ||
        loc.pathname.includes("/quiz-Offer") ||
        loc.pathname.includes("/indianfestivaldays2024") ||
        loc.pathname.includes("/invoice") ||
        loc.pathname.includes("/consignments") ? null : (
          <Footer />
        )}
      </div>

      <ToastContainer
        position={matches768 ? "bottom-right" : "top-center"}
        autoClose={5000}
        hideProgressBar
        newestOnTop={matches768}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />

      {/* {matches768 ? (
                <ToastContainer position={matches768 ?"bottom-right":"top-center"} autoClose={5000} hideProgressBar newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover transition={Bounce} />
            ) : (
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover transition={Bounce} />
            )} */}
    </>
  );
}

export default App;
