import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//Components
import Section2 from "../../components/Section2/Section2";
import { addToCart as updateCart, getCartData, removeFromCart } from "../../api/Cart";
import { getAllCouonTerms, getIndiProduct, getProductServiceability, getSearchedProduct } from "../../api/Product";
import { addSaveForLaterItem, deleteSaveForLaterItem, getSaveForLater } from "../../api/SaveForLaterApi";
import getMixedProducts from "../../hooks/getMixedProducts";
import useUserCtx from "../../hooks/useUserCtx";
import useProductCtx from "../../hooks/useProductCtx";

//icons
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoShareSocial } from "react-icons/io5";
import { RWebShare } from "react-web-share";

// utils
import { currencyFormat, isOfferActive } from "../../utils";
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";

import PlusMinus from "../../components/PlusMinus/PlusMinus";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NothingImage from "../../assets/png/your_order_image.png";
import PriceDetailsBox from "../../components/PriceDetailsBox/PriceDetailsBox";
import CartProductCard from "../../components/CartProductCard/CartProductCard";
import TermsAndCondtionModal from "../../components/TermsAndCondtionModal";

// Component definitions
const CartSection = ({ comboProducts, resetCartDetails, addCoupon, removeCoupon }) => {
  const { allProducts, setAllProducts } = useProductCtx();
  const { setUserComboCart, cartArray, userContext, setCartArray, userSaveForLater, setUserSaveForLater, userDefaultAddress, setDeliveryEstDays, setOrderInit, walletData } = useUserCtx();
  const { t } = useTranslation();
  const nav = useNavigate();
  const mounted = useRef(false);
  const isMobile = useMediaQuery("(max-width: 540px)");

  // state definitions
  const [cartSuggestions, setCartSuggestions] = useState([]);
  const [cartSuggestProducts, setCartSuggestProducts] = useState({
    loaded: false,
    products: [],
  });
  const [suggesProducts, setSuggesProducts] = useState([]);
  const [emptyCartFeaturedProducts, setEmptyCartFeaturedProducts] = useState([]);
  const [cartSuggestionArray, setCartSuggestionArray] = useState([]);
  // products that are added as combo offer to product in cart
  // const [comboProducts, setComboProducts] = useState([])
  useEffect(() => {
    setAllProducts({
      ...allProducts,
      products: allProducts.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN),
    });
  }, []);

  useEffect(() => {
    if (cartArray && cartArray.no_of_carts > 0) {
      cartArray.cart.forEach((prod) => {
        let immediatRecom = prod?.complimentoryCatgories?.immediate ? [...prod?.complimentoryCatgories?.immediate] : [];
        immediatRecom?.forEach((recom) => {
          let ind = cartSuggestions.findIndex((prod) => prod === recom);
          if (ind === -1) {
            setCartSuggestions([...cartSuggestions, recom]);
          }
        });

        // check the product has combo offer and if yes fetch that product
        /* const comboOffer = prod.discount?.combo;
                if (comboOffer?.value && isOfferActive(comboOffer)) {
                    fetchComboProduct(comboOffer.childEan);
                } */
      });
    }
  }, [cartArray]);

  useEffect(() => {
    setEmptyCartFeaturedProducts(getMixedProducts(allProducts.products, allProducts.np1, 10));
    setCartSuggestionArray(getMixedProducts(allProducts.products, cartSuggestProducts.products, 10));
  }, [allProducts]);

  //suggestions
  useEffect(() => {
    if (cartSuggestions && cartSuggestions.length > 0) {
      cartSuggestions.map((category) => {
        let searchTerm = "hierarchyL2=" + category;
        getSearchedProduct(searchTerm).then((res) => {
          if (res.no_of_products > 0) {
            let prod = {};
            prod = res.products[0];
            if (prod && res) {
              setSuggesProducts([...suggesProducts, prod]);
            }
          }
        });
      });
    }
  }, [cartSuggestions]);

  useEffect(() => {
    if (suggesProducts && suggesProducts.length > 0) {
      setCartSuggestProducts((prev) => ({
        ...prev,
        loaded: true,
        products: suggesProducts,
      }));
    }
  }, [suggesProducts]);

  const handleQuantityInc = (product) => {
    if (userContext && userContext?.JWT) {
      // const storeId = cartArray.cart[0]?.from[0];
      updateCart({ id: product._id, qty: product.quantity + 1 }).then((res) => {
        if (res?.data?.status !== "Success") return toast.error(res?.data?.message || t("writeToUs.error"));
        resetCartDetails();
      });
    } else {
      setCartArray((prevCartArray) => {
        // Create a copy of the previous cartArray
        const updatedCartArray = { ...prevCartArray };
        updatedCartArray.cart = updatedCartArray.cart.map((prod) => {
          if (prod._id === product._id) {
            // Increment the quantity of the matching product

            const newQuantity = prod.quantity + 1;
            if (newQuantity >= 6) {
              // If new quantity is zero, remove the product
              return prod;
            } else {
              // Otherwise, update the quantity
              return { ...prod, quantity: newQuantity };
            }
          }
          return prod;
        });
        updatedCartArray.no_of_carts = updatedCartArray.cart.length;

        // Return the updated cartArray
        return updatedCartArray;
      });
    }
  };

  const handleQuantityDec = (product) => {
    if (userContext && userContext?.JWT) {
      // const storeId = cartArray.cart[0]?.from[0];
      if (product.quantity === 1) return handleRemoveFromCart(product);
      updateCart({ id: product._id, qty: product.quantity - 1 }).then((res) => {
        if (!res) return toast.error(t("writeToUs.error"));

        resetCartDetails();
      });
    } else {
      setCartArray((prevCartArray) => {
        // Create a copy of the previous cartArray
        const updatedCartArray = { ...prevCartArray };

        updatedCartArray.cart = updatedCartArray.cart
          .map((prod) => {
            if (prod._id === product._id) {
              const newQuantity = prod.quantity - 1;
              if (newQuantity === 0) {
                // If new quantity is zero, remove the product
                return null;
              } else {
                // Otherwise, update the quantity
                return { ...prod, quantity: newQuantity };
              }
            }
            return prod;
          })
          .filter((prod) => prod !== null);
        // Return the updated cartArray
        updatedCartArray.no_of_carts = updatedCartArray.cart.length;
        return updatedCartArray;
      });
    }
  };

  //ORDER INITIALIZATION CODE+++++++++++++++++++++++++++++++++++++++++
  const handleOrderInit = (e) => {
    e.preventDefault();
    if (cartArray.no_of_carts > 0) {
      let productId = [];
      let quantity = [];
      cartArray.cart.forEach((item) => productId.push(item._id));
      cartArray.cart.forEach((item) => quantity.push(parseInt(item.quantity)));
      setOrderInit((prev) => ({
        ...prev,
        productId: productId,
        quantity: quantity,
      }));
      nav("/delivery-option");
    } else {
      toast.error(t("cart.add-to-cart"));
    }
  };

  //Remove Product from cart
  const handleRemoveFromCart = (product) => {
    let id = product._id;
    if (userContext && userContext?.JWT) {
      if (product?.from) {
        var fromId = product?.from?.product;
        var planId = product?.from?.plan;
      }
      removeFromCart(id, fromId, planId).then((res) =>
        res
          ? (setUserComboCart([]),
            toast.error(t("cart.remove-from-cart")),
            getCartData().then((res) => {
              if (res) {
                let prod = [];
                // if (product.from)
                res.cart.forEach((item, idx) => {
                  if (item?.productId === null) return;
                  let product = { ...item?.productId };
                  product["quantity"] = item?.qty;
                  product["from"] = item?.from;
                  prod.push(product);
                });
                setCartArray({
                  loaded: true,
                  no_of_carts: prod.length,
                  cart: prod,
                  combo: res.combo,
                });
              }
            }))
          : ""
      );
    } else {
      setCartArray((prevCartArray) => {
        // Create a copy of the previous cartArray
        const updatedCartArray = { ...prevCartArray };

        updatedCartArray.cart = updatedCartArray.cart
          .map((prod) => {
            if (prod._id === id) {
              const newQuantity = prod.quantity - 1;
              if (newQuantity === 0) {
                // If new quantity is zero, remove the product
                return null;
              } else {
                // Otherwise, update the quantity
                return { ...prod, quantity: newQuantity };
              }
            }
            return prod;
          })
          .filter((prod) => prod !== null);
        // Return the updated cartArray
        updatedCartArray.no_of_carts = updatedCartArray.cart.length;
        return updatedCartArray;
      });
    }
  };

  const handleAddItemToSaveForLater = (id) => {
    addSaveForLaterItem(id).then((res) =>
      res
        ? (toast.success(t("cart.addedToSaveForLater")),
          removeFromCart(id).then((res) =>
            res
              ? (setUserComboCart([]),
                toast.error(t("cart.remove-from-cart")),
                getCartData().then((res) => {
                  if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                      if (item?.productId === null) return;
                      let product = { ...item?.productId };
                      product["quantity"] = item.qty;
                      product["from"] = item.from;
                      prod.push(product);
                    });
                    setCartArray({
                      loaded: true,
                      no_of_carts: prod.length,
                      cart: prod,
                      combo: res.combo,
                    });
                  }
                }))
              : ""
          ),
          getSaveForLater().then((res) => {
            setUserSaveForLater({
              loaded: true,
              no_of_save_for_later_items: res.no_of_save_for_later_items,
              save_for_later_items: res.save_for_later_items,
            });
          }))
        : toast.error(t("writeToUs.error"))
    );
  };

  useEffect(() => {
    if (userContext && userContext?.JWT) {
      getSaveForLater().then((res) => {
        setUserSaveForLater({
          loaded: true,
          no_of_save_for_later_items: res.no_of_save_for_later_items,
          save_for_later_items: res.save_for_later_items,
        });
      });
    }
  }, []);

  const pageSwitch = (e) => {
    e.preventDefault();
    nav("/");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  //Get Product Delivery Estimate in cart
  useEffect(() => {
    if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
      let itemArray = cartArray.cart.map((item) => {
        let itemObj = {
          skuId: item.ean,
          quantity: item.quantity,
        };
        return itemObj;
      });
      getProductServiceability(userDefaultAddress?.address?.zip, itemArray).then((res) => {
        if (res) {
          setDeliveryEstDays({
            loaded: true,
            value: res,
          });
        }
      });
    }
  }, [cartArray, userDefaultAddress, mounted.current]);

  // invoke combo products
  /* const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        setComboProducts(prev => {
            return [
                ...prev.filter((prevProd) => prevProd._id !== product._id),
                { ...product, isComboProduct: true }
            ];
        })

    } */

  const [termData, setTermData] = useState({});
  const getTotalPriceOfProduct = (price, qty) => {
    if (price && qty) {
      return currencyFormat(price * qty);
    }
    return "-";
  };

  useEffect(() => {
    getCoupnTerms();
  }, []);

  const getCoupnTerms = async () => {
    const data = await getAllCouonTerms();
    if (data.status === 200) {
      setTermData(data.data);
    }
  };

  const getComboProdPrice = (comboProdMop, comboOffer) => {
    let result = comboProdMop;
    const discountVal = parseFloat(comboOffer.value);

    if (comboOffer.discountType !== "flat") {
      result = result - (result * discountVal) / 100;
    } else {
      result = result - discountVal;
    }
    return result;
  };

  const getFinalDisplayPrice = (productData) => {
    const mop = parseFloat(productData.price?.mop);
    if (!mop) return 0;
    const flatDiscount = productData.discount?.flatDiscount;

    let result = mop;

    if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
      if (flatDiscount?.discountType === "flat") {
        result -= flatDiscount?.value;
      } else {
        result -= (result * flatDiscount?.value) / 100;
      }
    }

    return result;
  };

  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
  };

  // Main renderer
  return (
    <>
      {cartArray.no_of_carts === 0 ? (
        <div className=" ">
          <div className="empty_order_sec">
            <p className="empty_order_text">{t("cart.empty-cart")}</p>
            <div>
              <button type="submit" className="submit-button" onClick={() => nav("/")}>
                <p>{t("start-shopping")}</p>
              </button>
            </div>
          </div>
          {/* <Section2 id={"Top-sellers-sec"} heading={t("top-sellers")} productData={allProducts} productArray={emptyCartFeaturedProducts} /> */}
        </div>
      ) : (
        <>
          {cartArray.no_of_carts > 0 && (
            <div className="cart-content">
              <div className="save_Later_Header section_Wrapper" style={{ width: "100%" }}>
                {/* style={{ display: "flex", position: "relative", fontWeight: "500", fontSize: "20px" }} */}
                <p style={{ fontSize: "20px" }}>My Cart</p>
              </div>
              <div style={{ width: "100%", overflowX: "auto", marginTop: "10px" }}>
                <table className="table table-bordered" style={{ width: "100%", fontSize: `${isMobile ? "small" : ""}` }}>
                  <thead className="tHead">
                    <tr>
                      <td style={{ width: "5%", backgroundColor: "rgb(224, 224, 224)" }}>Sr. No.</td>
                      <td style={{ width: "10%" }}>Image</td>
                      <td style={{ width: "40%" }}>Product</td>
                      <td style={{ width: "10%" }}>Prize</td>
                      <td style={{ width: "10%" }}>Quantity</td>
                      <td style={{ width: "10%" }}>Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cartArray.cart.map((product, i) => (
                      <React.Fragment key={`cart_section_${i}`}>
                        <tr id={product._id}>
                          <td style={{ alignContent: "center" }} className="text-center">
                            {i + 1}
                          </td>
                          <td style={{ alignContent: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {product.hierarchyL2 === "Warranties" ? (
                                <img
                                  className=" rounded-3"
                                  style={{ width: "110px", height: "80px", objectFit: "contain" }}
                                  alt="product-1image"
                                  src={product.images?.length ? product.images[0] : NothingImage}
                                />
                              ) : (
                                <Link to={`/product/${product?.slug}`} className="normal-link">
                                  <img
                                    className=" rounded-3"
                                    style={{ width: "110px", height: "80px", objectFit: "contain" }}
                                    alt="product-1image"
                                    src={product.images?.length ? product.images[0] : NothingImage}
                                  />
                                </Link>
                              )}
                            </div>
                          </td>
                          <td style={{ alignContent: "center", display: `${isMobile ? "flex" : ""}`, width: "300px", lineHeight: `${isMobile && "14px"}` }}>
                            <div className="d-flex flex-wrap text-justify text-wrap flex-column w-100">
                              <div>
                                {product.hierarchyL2 === "Warranties" ? (
                                  <>
                                    <span className="prodTitle">{product?.title}</span>
                                    {/* <p>{getIndiProduct(product?.from?.product).title}</p> */}
                                  </>
                                ) : (
                                  <Link to={`/product/${product?.slug}`} style={{ color: "black" }} className="normal-link">
                                    <span className="prodTitle">{product?.title}</span>
                                  </Link>
                                )}
                              </div>
                              {/* <span className="cart-product-desc" style={{ fontWeight: 500, width: "70%", color: "" }}>
                                  {product?.description?.[0]?.split("\n")[0]}
                                </span> */}
                              {/* <div  >
                                <div>
                                  <b>Processor :</b> {product?.productInfo.processor}
                                </div>
                                <div>
                                  <b>Display Size :</b> {product?.productInfo.display_size}
                                </div>
                                <div>
                                  <b>Battery Capacity :</b> {product?.productInfo.battery_capacity}
                                </div>
                              </div> */}
                              {product?.hierarchyL2 === "Warranties" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-between">
                                    {product?.productInfo && (
                                      <div className="d-flex flex-column">
                                        {Object.entries(product?.productInfo)
                                          .slice(0, 3)
                                          .map(([key, value]) => (
                                            <div key={key} className="Productdetails">
                                              <b>{key.replace(/_/g, " ").replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase())} :</b> {value}
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                    {walletData.length > 0 && (
                                      <div className="d-flex align-items-center">
                                        {product?.brand === "OnePlus" || product?.brand === "Apple" ? (
                                          <></>
                                        ) : (
                                          <>
                                            {product?.coupon?.value ? (
                                              <div className="coupon-container">
                                                <div className="text-center">
                                                  <span className="font-bold" style={{ color: "green" }}>
                                                    Coupon Collected
                                                  </span>
                                                  <br />
                                                  {currencyFormat(product?.coupon?.value)}
                                                  <br />
                                                  <span className="cursor-pointer" onClick={() => removeCoupon(product?._id)} style={{ color: "red" }}>
                                                    Remove
                                                  </span>
                                                </div>
                                                <div className="coupon-tooltip cursor-pointer" onClick={() => setShow(true)}>
                                                  <span className="" style={{ color: "orange" }}>
                                                    Terms & Conditions
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {userContext?.JWT ? (
                                                  <button type="button" class="btn btn-success btn-sm" onClick={() => addCoupon(product)}>
                                                    Apply Coupon
                                                  </button>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              {product?.hierarchyL2 !== "Warranties" && (
                                <div className="d-flex  delSave">
                                  <RWebShare
                                    data={{
                                      text: product?.description ?? "",
                                      url: "product/" + product?.slug,
                                      title: product?.title,
                                    }}
                                  >
                                    <button className="border-0 bg-transparent">
                                      <IoShareSocial />
                                    </button>
                                  </RWebShare>
                                  <span className="cursor-pointer" style={{ color: "#ff7200", fontSize: "14px", marginTop: "5px" }} onClick={() => handleRemoveFromCart(product)}>
                                    <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                  </span>
                                  {userContext?.JWT ? (
                                    <span className="delSaveContent" style={{ marginTop: "5px" }} onClick={() => handleAddItemToSaveForLater(product._id)}>
                                      Save for later
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                          <td style={{ alignContent: "center", textAlign: "center" }}>
                            {product.hierarchyL2 === "Warranties" ? "-" : <span className="perProductPrice">{currencyFormat(getFinalDisplayPrice(product))}</span>}
                          </td>
                          {product.hierarchyL2 !== "Warranties" ? (
                            <td style={{ alignContent: "center" }}>
                              <PlusMinus quantity={product.quantity} handleQuantityInc={() => handleQuantityInc(product)} handleQuantityDec={() => handleQuantityDec(product)} />
                            </td>
                          ) : (
                            <td style={{ alignContent: "center", textAlign: "center" }}>
                              <span>{product?.quantity}</span>
                            </td>
                          )}
                          <td style={{ alignContent: "center", textAlign: "center" }}>
                            {product.hierarchyL2 === "Warranties" ? "-" : <span className="TotalPrice">{getTotalPriceOfProduct(getFinalDisplayPrice(product), product?.quantity)}</span>}
                          </td>
                        </tr>
                        {comboProducts
                          .filter((CProduct) => product?.discount?.combo?.childEan === CProduct?.ean)
                          .map((comboProd, i) => (
                            <tr
                              key={`cart_section_row_${i}`}
                              style={{
                                "& > *": {
                                  borderBottom: "1px solid #E0E0E0",
                                },
                                "& > :first-child": {
                                  borderLeft: "1px solid #E0E0E0",
                                },
                                "& > :last-child": {
                                  borderRight: "1px solid #E0E0E0",
                                },
                                backgroundColor: "transparent",
                                // fontFamily: "Qui  cksand",
                              }}
                            >
                              <td
                                align="center"
                                colSpan={1}
                                style={{
                                  border: isMobile ? "none" : "1px solid #E0E0E0",
                                }}
                              ></td>
                              <td
                                colSpan={1}
                                align="center"
                                style={{
                                  borderRight: "none",
                                }}
                              >
                                <div className="d-flex flex-column align-items-center">
                                  <div
                                    style={{
                                      width: isMobile ? "100px" : "130px",
                                      height: "100px",
                                      border: "1px solid #B7B7B7",
                                      borderRadius: "10px",
                                      marginRight: isMobile ? "10px" : "2rem",
                                      marginLeft: isMobile ? "10px" : "2rem",
                                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                  >
                                    <img className="w-100 h-100 rounded-3" style={{ objectFit: "contain" }} alt="product-1image" src={comboProd.images?.length ? comboProd.images[0] : NothingImage} />
                                  </div>
                                  <div className="tag-item tag-best-seller fs-6 mt-2">Combo Product</div>
                                </div>
                              </td>

                              <td colSpan={1}>
                                <div>
                                  <div>
                                    <span style={{ fontWeight: "500" }}>{comboProd?.title}</span>
                                    <span className="cart-product-desc">{comboProd?.description[0]?.split("\n")[0]}</span>
                                  </div>
                                </div>
                              </td>
                              {!isMobile && (
                                <td
                                  align="center"
                                  colSpan={1}
                                  className="perProductPrice"
                                  // style={{
                                  //   borderRight: "1px solid #E0E0E0",
                                  //   fontWeight: "700",
                                  //   fontSize: "19px",
                                  //   lineHeight: "26px",
                                  //   color: "#262626",
                                  // }}
                                >
                                  {currencyFormat(getComboProdPrice(comboProd?.price?.mop, product?.discount?.combo)) ?? ""}
                                </td>
                              )}
                              {!isMobile && (
                                <td
                                  align="center"
                                  colSpan={1}
                                  style={{
                                    borderRight: "1px solid #E0E0E0",
                                  }}
                                >
                                  -
                                </td>
                              )}
                              {!isMobile && <td align="center"> {currencyFormat(getComboProdPrice(comboProd?.price?.mop, product?.discount?.combo)) ?? ""}</td>}
                              {/* {!isMobile && (
                                <td
                                  align="center"
                                  colSpan={1}
                                  className="perProductPrice"
                                  rowSpan={comboProducts.filter((CProduct) => product?.discount?.combo?.childEan === CProduct?.ean).length + 1}
                                  // style={{
                                  //   borderRight: "1px solid #E0E0E0",
                                  //   fontWeight: "700",
                                  //   fontSize: "19px",
                                  //   lineHeight: "26px",
                                  //   color: "#262626",
                                  // }}
                                >
                                  {currencyFormat(getComboProdPrice(comboProd?.price?.mop, product?.discount?.combo)) ?? ""}
                                </td>*/}
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* <div className="empty_order_sec">
                <p className="empty_order_text">{t("cart.empty-cart")}</p>
                <button type="submit" className="submit-button" onClick={pageSwitch}>
                  <p>{t("start-shopping")}</p>
                </button>
              </div> */}
              {/* )} */}
            </div>
          )}
        </>
      )}
      {show && <TermsAndCondtionModal open={show} onClose={() => handleCloseModal()} termData={termData} />}
    </>
  );
};

export default CartSection;
