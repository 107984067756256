import axios from "../axiosInstance";

export const getRefundRequests = async () => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/refund/request`, { headers });

  return response;
};

// fetches
export const getRefunds = async () => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/refunds/initiated`, { headers });

  return response;
};

// initiate a refund for consignment
export const initCnmtRefund = async (consignmentId) => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/payment/consignment/refund?consignmentId=${consignmentId}`, {}, { headers });
  return response;
};

// initiate a refund for consignment
export const initPreBookRefund = async (preBookId) => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/payment/preBook/refund?preBookId=${preBookId}`, {}, { headers });
  return response;
};
