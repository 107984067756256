import React, { useEffect, useState } from "react";
import { deleteFaqQuestions, deleteFaqs, getALLFAQS, updateFaqCategoryName } from "../../api/AdminApis/Admin.terms";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { FaHandPointRight } from "react-icons/fa";

function FaqAdmin() {
  const [faqs, setFaqs] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [expandedCategory, setExpandedCategory] = useState(null);

  useEffect(() => {
    getAllFAQs();
  }, []);

  const getAllFAQs = async () => {
    const res = await getALLFAQS();
    if (res.faqs.length > 0) {
      setFaqs(res.faqs);
    } else {
      setFaqs([]);
    }
  };

  const toggleCategory = (category) => {
    setExpandedCategory((prev) => (prev === category ? null : category));
  };

  const navigate = useNavigate();

  const deleteFaq = async (id) => {
    const res = await deleteFaqs(id);
    if (res) {
      if (res?.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.success(res.message);
        getAllFAQs();
      }
    }
  };
  const location = useLocation();

  const deleteFaqQuestion = async (faqId, questionIndex) => {
    const params = new URLSearchParams(location.search);
    params.append("faqId", faqId);
    params.append("questionIndex", questionIndex);

    deleteFaqQuestions(params).then((res) => {
      if (res?.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.success(res.message);
        getAllFAQs();
      }
    });
  };

  const handleCategoryNamechange = (faq) => {
    if (faq) {
      setCategoryName(faq.category);
      setCategoryId(faq._id);
    }
  };
  const updateCategoryName = async () => {
    if (!categoryName) {
      toast.warning("Enter Category Name!");
      return;
    }
    const trimmedCategoryName = categoryName.trim();
    const res = await updateFaqCategoryName(categoryId, trimmedCategoryName);
    if (res) {
      toast.success(res.message);
      document.getElementById("closeCategoryNameModal").click();
      setCategoryName("");
      setCategoryId("");
      getAllFAQs();
    }
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="d-flex  align-items-center justify-content-between">
            <h5>FAQs</h5>
            <button
              className="btn btn-sm btn-primary"
              onClick={() =>
                navigate("../AddorEditFaq", {
                  state: {
                    faqId: null,
                  },
                })
              }
            >
              Add New FAQ
            </button>
          </div>
          {faqs.length > 0 ? (
            <ul style={{ padding: "0px" }}>
              {faqs.map((faq, i) => (
                <div className="d-flex  align-items-center rounded">
                  <li className="w-100" key={faq._id} style={{ cursor: "pointer", listStyleType: "none", margin: "10px 0" }}>
                    <div className=" rounded" style={{ padding: "10px  ", background: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div className="d-flex align-items-center  " style={{ width: "60%" }}>
                        <FaHandPointRight size={22} style={{ position: "relative", color: "rgb(8, 26, 79)", marginRight: "20px" }} />
                        <div className="d-flex flex-column" style={{ gap: "10px" }}>
                          <div className="align-self-start">
                            <span style={{ fontSize: "12px", color: "gray" }}>No. of Question: {faq.faq.length}</span>
                            <div>
                              {faq.category}{" "}
                              <span data-toggle="modal" className="cursor-pointer" data-target="#exampleModal" onClick={() => handleCategoryNamechange(faq)}>
                                <CiEdit size={18} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer w-100" onClick={() => toggleCategory(faq.category)}>
                        <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
                          <IoIosAdd
                            onClick={() =>
                              navigate("../AddorEditFaq", {
                                state: {
                                  faqId: faq._id,
                                },
                              })
                            }
                            size={25}
                          />
                          <span>{expandedCategory === faq.category ? <FaChevronUp /> : <FaChevronDown />}</span>
                          <span className="text-danger cursor-pointer" onClick={() => deleteFaq(faq._id)}>
                            <FaTrashAlt />
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* {expandedCategories[faq.category] && ( */}
                    <div className={`faq-content ${expandedCategory === faq.category ? "expanded" : "collapsed"}`}>
                      {faq.faq.map((item, index) => (
                        <div key={index} className="my-3">
                          <div className="d-flex p-2 rounded" style={{ background: "#516663", color: "white" }}>
                            <span className="mr-3 font-weight-bold">
                              {index + 1}
                              {")"}
                            </span>
                            <div className="w-100">
                              <div className="form-group">
                                <label htmlFor={`question-${index}`} className="font-weight-bold">
                                  Question:
                                </label>
                                <input type="text" className="form-control" id={`question-${index}`} value={item.question} />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`answer-${index}`} className="font-weight-bold">
                                  Answer:
                                </label>
                                <div className="" dangerouslySetInnerHTML={{ __html: item.answer }} />
                              </div>
                            </div>
                            <span className="d-flex " style={{ position: "relative", gap: "10px" }}>
                              <span className="text-danger cursor-pointer" onClick={() => deleteFaqQuestion(faq._id, index)}>
                                <FaTrashAlt />
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate("../AddorEditFaq", {
                                    state: {
                                      faqItemId: item._id,
                                      faqId: faq._id,
                                      data: item,
                                    },
                                  })
                                }
                              >
                                <CiEdit style={{ color: "white" }} size={18} />
                              </span>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          ) : (
            <p>No FAQ Added</p>
          )}
        </div>
      </div>

      {/* Modal for change Category Name */}
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeCategoryNameModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label for="recipient-name" className="col-form-label">
                  Category Name:
                </label>
                <input type="text" className="form-control" id="categoryName" placeholder="Enter Category Name" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => updateCategoryName()}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqAdmin;
