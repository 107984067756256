import { createContext, useEffect, useState } from "react";
import defaultUserImage from "../assets/png/default_user_image.png";

const UserContext = createContext(null);

const UserContextProvider = (props) => {
  const [userLocation, setUserLocation] = useState({
    loaded: false,
    useThis: false,
    coordinates: { lat: 19.076, lng: 72.8777 }, // default center - Mumbai
    address: { city: "", state: "", zip: "" },
  });

  const [userContext, setUserContext] = useState({ profilePic: defaultUserImage, id: "", fullName: "", mobileNumber: "", email: "", JWT: "", dob: null, pincode: "" });
  const [userAddress, setUserAddress] = useState({
    loaded: false,
    no_of_address: 0,
    address: [],
  });
  const [paymentProductArr, setPaymentProductArr] = useState({
    loaded: false,
    cart: [],
    combo: [],
  });

  const [userComboCart, setUserComboCart] = useState([]);
  const [cartArray, setCartArray] = useState({
    loaded: false,
    cart: [],
    combo: [],
    no_of_carts: 0,
  });
  const [userOrderData, setUserOrderData] = useState({
    loaded: false,
    no_of_orders: 0,
    orders: [],
  });
  const [userWishlist, setUserWishlist] = useState({
    loaded: false,
    no_of_wishlist_items: 0,
    wishlist_items: [],
  });
  const [userSaveForLater, setUserSaveForLater] = useState({
    loaded: false,
    no_of_save_for_later_items: 0,
    save_for_later_items: [],
  });
  const [userSubscribed, setUserSubscribed] = useState([]);
  const [userPreBooked, setUserPreBooked] = useState([]);
  const [userDefaultAddress, setUserDefaultAddress] = useState({
    loaded: false,
    useThis: false,
    address: {},
    no_of_address: 0,
  });
  const [userZip, setUserZip] = useState({
    loaded: false,
    value: 0,
  });
  const [orderInit, setOrderInit] = useState({
    productId: [],
    quantity: [],
    shippingAddressId: "",
    coupon: "",
    type: "",
    storeId: "",
    brand_store_id: "",
    manualCnmtReceive: false,
  });
  const [priceBoxDetails, setPriceBoxDetails] = useState({
    cartItemsNumber: 0,
    cartItemsPrice: 0,
    totalDiscount: 0,
    totalDeliveryCharge: 0,
    totalAmount: 0,
    totalPreBookAmount: 0,
    giftCouponAmount: 0,
  });
  const [deliveryEstDays, setDeliveryEstDays] = useState({
    loaded: false,
    value: [],
  });
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const [editID, setEditID] = useState(0);
  const [addressSelected, setAddressSelected] = useState(0);
  const [filterSelected, setFilterSelected] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [remainngAmt, setRemainngAmt] = useState([]);

  return (
    <UserContext.Provider
      value={{
        userLocation,
        setUserLocation,
        userContext,
        walletData,
        setWalletData,
        setUserContext,
        userAddress,
        setUserAddress,
        userComboCart,
        setUserComboCart,
        userWishlist,
        setUserWishlist,
        cartArray,
        remainngAmt,
        setRemainngAmt,
        setCartArray,
        userSaveForLater,
        setUserSaveForLater,
        userDefaultAddress,
        setUserDefaultAddress,
        deliveryEstDays,
        setDeliveryEstDays,
        deliveryCharges,
        setDeliveryCharges,
        userZip,
        setUserZip,
        appliedCoupon,
        setAppliedCoupon,
        orderInit,
        setOrderInit,
        priceBoxDetails,
        setPriceBoxDetails,
        userOrderData,
        setUserOrderData,
        editID,
        setEditID,
        addressSelected,
        setAddressSelected,
        userSubscribed,
        setUserSubscribed,
        userPreBooked,
        setUserPreBooked,
        setFilterSelected,
        filterSelected,
        paymentProductArr,
        setPaymentProductArr,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserContextProvider;
