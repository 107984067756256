import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CircleLoading from "../components/CircleLoading/index";
import CreateAccountBG from "../assets/productIcons/Create-account-BG.png"
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { t } from "i18next";
import { adminLogin } from "../api/AdminApis/AdminAuth";
import { useToken } from "../utils/useToken";
import { AdminLogout, calculateTimeUntilExpiration } from "../utils";

// toast.configure();

// Component Definition
const AdminLogin = () => {
    // state definitions
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [email, SetEmail] = useState("");
    const [password, setPassword] = useState("");

    // hooks
    const nav = useNavigate();
    const location = useLocation();
    const [token, setToken] = useToken(process.env.REACT_APP_ADMIN_TOKEN_KEY);

    useEffect(() => {
        if (location.search) {
            handleLogoutRedirection(location);
        }
    }, []);

    const handleLogoutRedirection = (location) => {
        const searchQuery = new URLSearchParams(location.search.slice(1));
        if (searchQuery.has('action') && searchQuery.get('action') === 'logout') {
            logoutAdmin();
        }
    }

    function logoutAdmin() {
        AdminLogout("admin", nav);
        toast.error("Admin Token expired! Logging out! Please login again")
    }

    const formSubmit = (e) => {
        e.preventDefault();
        adminLogin({ email, password }).then(res => {
            setLoading(true);
            setBtnDisable(true);
            if (res?.token) {
                const { token } = res;
                setToken(token);
                // Retrieve the remaining time until JWT expiration
                const remainingTime = calculateTimeUntilExpiration(token);
                // Set up a timer or setTimeout to trigger the logout function when the JWT is about to expire
                setTimeout(() => {
                    logoutAdmin();
                }, remainingTime);
                nav("/admin");
            } else toast.error(res.data)
        }).catch(err => {
            toast.error(err?.response?.data ?? 'Login failed')
        }).finally(() => {
            setLoading(false);
            setBtnDisable(false);
            setPassword("");
            SetEmail("");
        })
    };

    return (
        <>
            <HeaderBar alternateWay={t('home')} alternateLink={"/"} />
            <div className="signUpParentClass" style={{
                background: `url(${CreateAccountBG}) no-repeat`,
                backgroundSize: "cover",
            }}>
                <div className="signup-wrapper" style={{
                    backdropFilter: "blur(0)",
                    background: "none",
                    padding: "25px",
                    paddingBottom: "115px"
                }}>
                    <div className="signup-header">
                        <h1 className="page-heading">Admin Login</h1>
                    </div>
                    <form action="" className={"signup-form"} onSubmit={formSubmit} style={{ gap: "20px" }}>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Email</div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    required
                                    className="input-field admin_required_field"
                                    value={email}
                                    onChange={(e) => {
                                        SetEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Password</div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    className="input-field admin_required_field"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>


                        <div className={"button-Container"}>
                            {loading ? (
                                <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                                    <CircleLoading />
                                </div>
                            ) : (
                                ""
                            )}
                            <button type="submit" className="submit-button" disabled={email && password ? false : true}>
                                <p>{t("continue")}</p>
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </>
    );

};

export default AdminLogin;

