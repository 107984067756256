import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//Images
import addIcon from "../../assets/vector/add_outline_blue.svg";

//Components
import AddressBox from "../../components/AddressBox/AddressBox";
import { deleteAddress, getAddress, setAddressDefault } from "../../api/Address";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const MyAddress = ({ setProfileState, border }) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const matches = width >= 768;

  const { userAddress, setUserAddress, setEditID } = useUserCtx();

  useEffect(() => {
    getAddress().then((res) => {
      if (res) {
        setUserAddress({
          loaded: true,
          no_of_address: res.no_of_address,
          address: res.address,
        });
      }
    });
  }, []);

  const handleDeleteAddress = (id) => {
    deleteAddress(id).then((res) => {
      if(res){
        toast.success(t("notifications.AddressDeletedSuccessfully"));
        getAddress().then((res) => {
          if (res) {
            setUserAddress({
              loaded: true,
              no_of_address: res.no_of_address,
              address: res.address,
            });
          }
        });
      }
    });
  };

  const handleSetAsDefaultAddress = (id) => {
    setAddressDefault(id).then((res) => {
      getAddress().then((res) => {
        if (res) {
          setUserAddress({
            loaded: true,
            no_of_address: res.no_of_address,
            address: res.address,
          });
        }
      });
    });
  };

  return (
      <div className="page_Wrapper edit_Page_Wrapper">
          {matches ? (
            <Link className="text-decoration-none" to={"/profile/yourAddresses/newAddress"}>
              <div className="add_New_Address section_Wrapper">
                  <img src={addIcon} alt="" />
                  <p>{t('address.new')}</p>
              </div>
            </Link>
          ) : (
              <Link to={"/profile/yourAddresses/newAddress"} className="add_New_Address section_Wrapper">
                  <img src={addIcon} alt="" />
                  <p>{t('address.new')}</p>
              </Link>
          )}
          <div className="address_List">
              {userAddress.address.map((address, index) => (
                  <AddressBox
                      key={index}
                      address={address}
                      setEditID={setEditID}
                      setProfileState={setProfileState}
                      border={border}
                      handleDeleteAddress={handleDeleteAddress}
                      handleSetAsDefaultAddress={handleSetAsDefaultAddress}
                  />
              ))}
              {/* Add New Address */}
          </div>
          <div className="address_Footer tab_None">
              <button type="submit" className="submit-button">
                  <p>{t('continue')}</p>
              </button>
          </div>
      </div>
  );
};

export default MyAddress;
