import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import CircleLoading from "../components/CircleLoading";
import { userLogin } from "../api/Auth";
import { toast } from "react-toastify";
import useUserCtx from "../hooks/useUserCtx";
import useSiteCtx from "../hooks/useSiteCtx";
import { useTranslation } from "react-i18next";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FacebookIcon from "../assets/productIcons/facebookIcon.svg"
// import GoogleIcon from "../assets/productIcons/googleIcon.svg"
// import Checkbox from '@mui/material/Checkbox';
import SignInBg from "../assets/productIcons/SignUpBG.svg";
import SEO from "../components/SEO/SEO";

// toast.configure();

// Component Definition
const Login = () => {
  const { setLoginRedirect } = useSiteCtx();
  const { seoData } = useSiteCtx();

  const { t } = useTranslation();
  const { setUserContext } = useUserCtx();
  const loc = useLocation();
  const nav = useNavigate();
  // state definitions
  const [phone, setPhone] = useState("");
  const [, setValidLength] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [emailLogin, setEmailLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user"))?.JWT;
    if (userToken) {
      nav("/", { replace: true });
    }
  }, []);
  useEffect(() => {
    if (loc.state) {
      if (loc.state.emailLogin) {
        setEmailLogin(true);
      }
    }
  }, [loc.state]);

  const handleLength = (length) => {
    if (length === 10) {
      setValidLength(true);
      return setBtnDisable(false);
    }
    setValidLength(false);
    setBtnDisable(true);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setBtnDisable(true);
    userLogin(phone).then((res) => {
      if (res?.success) {
        setLoading(false);
        setBtnDisable(false);
        setLoginRedirect(true);
        setUserContext((prev) => ({
          ...prev,
          id: res.userId,
        }));
        nav("/otp", {
          state: { ...loc.state, otpSentSuccess: true, mobileNumber: phone },
        });
      } else {
        setLoading(false);
        setBtnDisable(false);
        toast.error(res?.data?.message ?? t("writeToUs.error"));
      }
    });
  };

  const validateNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  return (
    <>
      <SEO seoData={seoData} />
      <HeaderBar alternateWay={t("login.signup")} alternateLink={"/signup"} />
      <div
        className="signUpParentClass"
        style={{
          background: `url(${SignInBg}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="signup-wrapper">
          <div className="signup-header">
            <h1 className="page-heading">{t("login.signin")}</h1>
          </div>
          <form action="" className={"signup-form"} onSubmit={formSubmit}>
            <div
              className="inputfield-Container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <div className="SignUpInputLable">{t("login.phoneInputLabel")}</div>
              <div>
                <input
                  type="tel"
                  name="Phone"
                  id="phone"
                  maxLength={10}
                  className="input-field"
                  value={phone}
                  placeholder={t("login.phoneInputLabel")}
                  onChange={(e) => {
                    validateNumber(e);
                    handleLength(e.target.value.length);
                  }}
                />
              </div>
            </div>
            <div className={"button-Container"}>
              {loading ? (
                <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                  <CircleLoading />
                </div>
              ) : (
                ""
              )}
              <button type="submit" className="submit-button" disabled={btnDisable}>
                <p>{t("login.signin")}</p>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="TCStyle">
                <Link to="/terms-condition" className="text-decoration-none" style={{ color: "#0066C0" }}>
                  {t("login.termsCondition")}{" "}
                </Link>
                <Link className="text-decoration-none" style={{ color: "#0066C0" }} to="/privacy-policy">
                  {t("login.extraTC")}
                </Link>
              </div>
              {/* <div > <FormControlLabel className="TCStyle" control={<Checkbox defaultChecked />} label={t("login.keepSigned")} /></div> */}
              <div className="TCStyle" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span style={{ color: "#707070" }}>{t("login.newToOnlineO")}</span>
                <Link
                  style={{
                    background: "white",
                    color: "black",
                    fontSize: "0.95rem",
                    width: "98%",
                    borderRadius: "10px",
                    padding: "15px",
                    fontWeight: "700",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  className="text-decoration-none"
                  to="/signup"
                >
                  {t("login.new")}
                </Link>
              </div>
            </div>
          </form>
        </div>
        <div
          className="pb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
            fontWeight: "700",
            fontSize: "14px",
            color: "#0066C0",
          }}
        >
          <Link className="normal-link" to="/terms-condition">
            <span>{t("login.termsText")}</span>
          </Link>
          <Link to="/privacy-policy" className="text-decoration-none">
            {t("footer.privacy")}
          </Link>
        </div>
        {/* <div className="signup-wrapper" style={{margin:0,display:"flex",flexDirection:"column",alignItems:"center",gap:"10px"}}>
                    <div className="socialParent">
                        <img className="imagestyle" src={FacebookIcon} />
                        <span style={{
                            background:"#4A67B7",
                            color:"white",
                            fontSize:"12px",
                            width:"90%",
                            borderRadius:"10px",
                            padding:"16px",
                            fontWeight:"700",
                            textAlign:"center",
                            cursor:"pointer"
                            }}>Login with Facebook</span>
                    </div>
                    <div  className="socialParent">
                        <img className="imagestyle" src={GoogleIcon} style={{
                            background:"white",
                            padding:"10px"
                        }} />
                        <span style={{
                            background:"#167EE6",
                            color:"white",
                            fontSize:"12px",
                            width:"90%",
                            borderRadius:"10px",
                            padding:"16px",
                            fontWeight:"700",
                            textAlign:"center",
                            cursor:"pointer"
                            }}>Login with Google</span>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default Login;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import HeaderBar from "../components/HeaderBar/HeaderBar";
// import CircleLoading from "../components/CircleLoading";
// import { userLogin, userLoginEmail } from "../api/Auth";
// import { toast } from "react-toastify";
// import useUserCtx from "../hooks/useUserCtx";
// import useWindowDimensions from "../hooks/useWindowDimensions";
// import useSiteCtx from "../hooks/useSiteCtx";
// import { useTranslation } from "react-i18next";

// toast.configure();
// const Login = () => {
//     const { loginRedirect, setLoginRedirect, userLoggedIn, setUserLoggedIn } = useSiteCtx();
//     const { t } = useTranslation()
//     const [phone, setPhone] = useState("");
//     const [email, setEmail] = useState("");
//     const [validLength, setValidLength] = useState(false);
//     const [btnDisable, setBtnDisable] = useState(true);
//     const { width } = useWindowDimensions();

//     const matches = width >= 768;
//     const [emailLogin, setEmailLogin] = useState(false);
//     const {
//         userLocation,
//         setUserLocation,
//         userContext,
//         setUserContext,
//         userAddress,
//         setUserAddress,
//         userComboCart,
//         setUserComboCart,
//         userWishlist,
//         setUserWishlist,
//         cartArray,
//         setCartArray,
//         userSaveForLater,
//         setUserSaveForLater,
//         userDefaultAddress,
//         setUserDefaultAddress,
//         deliveryEstDays,
//         setDeliveryEstDays,
//         deliveryCharges,
//         setDeliveryCharges,
//         userZip,
//         setUserZip,
//         appliedCoupon,
//         setAppliedCoupon,
//         orderInit,
//         setOrderInit,
//         priceBoxDetails,
//         setPriceBoxDetails,
//         userOrderData,
//         setUserOrderData,
//     } = useUserCtx();
//     const loc = useLocation();

//     const [loading, setLoading] = useState(false);

//     const nav = useNavigate();
//     // console.log(phone);
//     useEffect(() => {
//         if (loc.state) {
//             if (loc.state.emailLogin) {
//                 setEmailLogin(true);
//             }
//         }
//     }, [loc.state]);

//     // console.log(emailLogin);

//     const handleLength = (length) => {
//         // if (length === 10) {
//         //   console.log('valid lenght', length);
//         //   setValidLength(true)
//         //   setBtnDisable(false)
//         // } else {
//         //   console.log('invalid lenght', length);
//         //   setValidLength(false)
//         //   setBtnDisable(false)
//         // }
//         if (length === 10) {
//             setValidLength(true);
//             return setBtnDisable(false);
//         }
//         setValidLength(false);
//         setBtnDisable(true);
//     };

//     const formSubmit = (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setBtnDisable(true);
//         if (emailLogin) {
//             userLoginEmail(email).then((res) =>
//                 res
//                     ? (setLoading(false),
//                         setBtnDisable(false),
//                         setLoginRedirect(true),
//                         nav("/otp", { state: {} }),
//                         setUserContext((prev) => ({
//                             ...prev,
//                             id: res.userId,
//                         })))
//                     : (setLoading(false), setBtnDisable(false), toast.error("Email Not Registered"))
//             );
//         } else {
//             userLogin(phone).then((res) =>
//                 res
//                     ? (setLoading(false),
//                         setBtnDisable(false),
//                         setLoginRedirect(true),
//                         nav("/otp", { state: loc.state }),
//                         setUserContext((prev) => ({
//                             ...prev,
//                             id: res.userId,
//                         })))
//                     : (setLoading(false), setBtnDisable(false), toast.error("Mobile number not registered!"))
//             );
//         }
//     };

//     const pageSwitch = (e) => {
//         e.preventDefault();
//         nav("/signup");
//     };

//     const validateNumber = (e) => {
//         const re = /^[0-9\b]+$/;
//         if (e.target.value === "" || re.test(e.target.value)) {
//             setPhone(e.target.value);
//         }
//     };

//     return (
//         <>
//             <HeaderBar alternateWay={t("login.signup")} alternateLink={"/signup"} />
//             <div className="signup-wrapper">
//                 <div className="signup-header">
//                     <h1 className="page-heading">{t("login.greetings")}!</h1>
//                     <p className={"page-desc"}>{t('login.login-here')}</p>
//                 </div>
//                 <form action="" className={"signup-form"} onSubmit={formSubmit}>
//                     <div className="inputfield-Container">
//                         {emailLogin ? (
//                             <input type="email" name="Email" id="email" className="input-field" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
//                         ) : matches ? (
//                             <input
//                                 type="tel"
//                                 name="Phone"
//                                 id="phone"
//                                 maxLength={10}
//                                 className="input-field"
//                                 value={phone}
//                                 placeholder={t('login.phone')}
//                                 onChange={(e) => {
//                                     validateNumber(e);
//                                     handleLength(e.target.value.length);
//                                 }}
//                             />
//                         ) : (
//                             <input
//                                 type="number"
//                                 name="Phone"
//                                 id="phone"
//                                 maxLength={10}
//                                 className="input-field"
//                                 value={phone}
//                                 placeholder={t('login.phone')}
//                                 onChange={(e) => {
//                                     setPhone(e.target.value);
//                                     handleLength(e.target.value.length);
//                                 }}
//                             />
//                         )}
//                     </div>
//                     <div className={"button-Container"}>
//                         {loading ? (
//                             <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
//                                 <CircleLoading />
//                             </div>
//                         ) : (
//                             ""
//                         )}
//                         <button type="submit" className="submit-button" disabled={btnDisable}>
//                             <p>{t("continue")}</p>
//                         </button>
//                         <p className="extra-btn" onClick={pageSwitch}>
//                             {t('login.new')}
//                         </p>
//                     </div>
//                 </form>
//                 <div className="tc-Footer">
//                     <p className="footer-Text">
//                         {/* By Signing In, I agree to <span>terms & conditions</span> */}
//                         {t('login.footer-text')}
//                     </p>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Login;
