import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";


const OfferItem = ({ offer }) => {
    const [totalCount, setTotalCount] = useState(offer.offerTotalCount);
    const [remaining, setRemaining] = useState(offer.offerRemaining);
  
    const handleTotalCountChange = (e) => {
      const newTotalCount = parseInt(e.target.value);
      setTotalCount(newTotalCount);
      setRemaining(newTotalCount - (offer.offerTotalCount - offer.offerRemaining));
    };
  
    return (
      <div key={offer._id} className="offer-item">
        <div className="row">
          <div className="col-sm-3"><strong>{offer.offerName}</strong></div>
          <div className="col-sm-4">
            Total Count: 
            <input type="number" value={totalCount} onChange={handleTotalCountChange} />
          </div>
          <div className="col-sm-4">Remaining: {remaining}</div>
        </div>
      </div>
    );
  };

const UpdateStoreCoupon = (props) => {

    useEffect(() => {
        console.log("props", props);
    });

    const [editcoupswd, setEditcoupswd] = useState(false);
    const [showeditpannel, setshoweditpannel] = useState(false);
    const [pswd, setpswd] = useState("");
    const [errmesg, setErrmesg] = useState("");

    const editcouponpswd = () => {
        setEditcoupswd(!editcoupswd);
    }

    const submitpswd = () => {
        // 67D39qU7Ba
        if(pswd === "1") {
            setshoweditpannel(true);
            setEditcoupswd(!editcoupswd);
            setErrmesg("");
        } else {
            setErrmesg("Invalid Password");
        }
    }

    // const [originalOffers, setOriginalOffers] = useState([]);
    // const [offers, setOffers] = useState([]);

    // useEffect(() => {
    //     setOriginalOffers(props.singleShopData.offers || []);
    //     setOffers(props.singleShopData.offers || []);
    //     setEditcoupswd(false);
    //     setshoweditpannel(false);
    // }, [props.singleShopData.offers]);

    // const handleSave = () => {
    //     // console.log('Original offers:', originalOffers);
    //     // console.log('Updated offers:', offers);

    //     // You can send both originalOffers and offers to your server/API here
    //     // Example: API call to save updates
    //     // saveOffers({ originalOffers, updatedOffers: offers });
    // };

    const [originalOffers, setOriginalOffers] = useState(props.singleShopData.offers ? [...props.singleShopData.offers] : []);
    const [updatedOffers, setUpdatedOffers] = useState([]);
  
    const updateOffer = (id, updatedOffer) => {
        const existingIndex = updatedOffers.findIndex((offer) => offer._id === id);
        if (existingIndex !== -1) {
          setUpdatedOffers((prevUpdatedOffers) => {
            prevUpdatedOffers[existingIndex] = updatedOffer;
            return [...prevUpdatedOffers];
          });
        } else {
          setUpdatedOffers((prevUpdatedOffers) => [...prevUpdatedOffers, updatedOffer]);
        }
      };

      const handleSubmit = () => {
        console.log('Original Offers:', originalOffers);
        console.log('Updated Offers:', updatedOffers);
        // Make API call or perform submission logic here
      };

    //   const OfferItem = ({ offer, updateOffer }) => {
    //     const [totalCount, setTotalCount] = useState(offer.offerTotalCount);
    //     const [remaining, setRemaining] = useState(offer.offerRemaining);
      
    //     const handleTotalCountChange = (e) => {
    //       const newTotalCount = parseInt(e.target.value);
    //       setTotalCount(newTotalCount);
    //       setRemaining(newTotalCount - (offer.offerTotalCount - offer.offerRemaining));
    //       updateOffer(offer._id, { offerTotalCount: newTotalCount, offerRemaining: newTotalCount - (offer.offerTotalCount - offer.offerRemaining) });
    //     };
      
    //     return (
    //       <div key={offer._id} className="offer-item">
    //         <div className="row">
    //           <div className="col-sm-3"><strong>{offer.offerName}</strong></div>
    //           <div className="col-sm-4">
    //             Total Count: 
    //             <input type="number" value={totalCount} onChange={handleTotalCountChange} />
    //           </div>
    //           <div className="col-sm-4">Remaining: {remaining}</div>
    //         </div>
    //       </div>
    //     );
    //   };

    return (
        <div>
          {!props.singleShopData ? (
            <p>Loading...</p>
          ) : (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">IFD 2024 Coupons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="catalogue_Hint">Shop Brand Id</p>
                    <input className="input-field" type="text" placeholder="Shop Brand Id" defaultValue={props.singleShopData?.brand_store_id} name="brand_store_id" id="Shop Brand Id" />
                    <p className="catalogue_Hint">Shop Name</p>
                    <input className="input-field mt-2" type="text" placeholder="Shop Name" defaultValue={props.singleShopData?.fc_name} name="fc_name" id="" />
                    <p className="catalogue_Hint d-flex justify-content-between">
                        All IFD Coupons
                        {/* <span className="btn btn-primary btn-sm" onClick={editcouponpswd}>Edit Coupons</span> */}
                    </p>
                    {/* {editcoupswd && (
                        <>
                            <hr />
                            <label>Enter Password</label>
                            <input className="input-field" type="password" placeholder="Enter Password" onChange={(e)=>{ setpswd(e.target.value) }} />
                            <p className="m-0 text-danger">{errmesg}</p>
                            <span className="btn btn-sm btn-primary mt-2" onClick={submitpswd}>Submit</span>
                            <hr />
                        </>
                    )} */}

                    {
                    showeditpannel ? (
                        <>
                            {/* <p><b>Note:</b> Change in Offer Total Count will affect the Remaining Count.</p>
                            {props.singleShopData.offers && props.singleShopData.offers.length > 0 ? (
                                props.singleShopData.offers.map((offer) => (
                                    <OfferItem key={offer._id} offer={offer} updateOffer={updateOffer} />
                                ))
                            ) : (
                                <p>No offers available</p>
                            )}
                            <button onClick={handleSubmit}>Submit</button> */}
                        </>
                    ) : (<>
                            {props.singleShopData.offers && props.singleShopData.offers.length > 0 ? (
                                props.singleShopData.offers.map((offer) => (
                                <div key={offer._id} className="offer-item">
                                    <div className="row">
                                        {/* <div className="col-sm-3"><strong>{offer.offerName === "25,000 Cash Back" ? "iPhone 16" : offer.offerName}</strong></div> */}
                                        <div className="col-sm-3"><strong>{offer.offerName}</strong></div>
                                        <div className="col-sm-4">Total Count: {offer.offerTotalCount}</div>
                                        <div className="col-sm-4">Remaining: {offer.offerRemaining}</div>
                                    </div>
                                </div>
                                ))
                            ) : (
                                <p>No offers available</p>
                            )}
                        </>)
                    }
                </Modal.Body>
            </Modal>
        )}
        </div>
      );

}

export default UpdateStoreCoupon;