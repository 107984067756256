import React, { useEffect, useRef, useState } from "react";
import faqbanner from "../../assets/png/faqbanner.png";
import hd from "../../assets/png/hd.jpg";
import { getALLFAQS } from "../../api/AdminApis/Admin.terms";
import SearchIcon from "@mui/icons-material/Search";
import creditCard from "../../assets/png/credit-card.png";
import discount from "../../assets/png/discount.png";
import deliverybox from "../../assets/png/deliverybox.png";
import deliveryman from "../../assets/png/deliveryman.png";
import helpdesk from "../../assets/png/helpdesk.png";
import profileuser from "../../assets/png/profileuser.png";
import down from "../../assets/png/down.png";
import up from "../../assets/png/up.png";
import { useNavigate } from "react-router-dom";

function FAQ() {
  const [categorySelected, setCategorySelected] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [faq, setFaq] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const toggleCategory = (category) => {
    setExpandedCategory((prev) => (prev === category ? null : category));
  };

  useEffect(() => {
    allFaq();
  }, [searchValue]);

  const allFaq = async () => {
    const res = await getALLFAQS(`search=${searchValue}`);
    if (res) {
      setFaq(res.faqs);
    }
  };
  const targetRef = useRef();
  const handleCategorySelected = (item) => {
    const { offsetTop } = targetRef.current;
    window.scrollTo({
      top: offsetTop - 200,
      behavior: "smooth",
    });

    setCategorySelected(item);
  };
  const navgate = useNavigate();
  return (
    <div className="homepage_wrapper">
      <div className="banner" style={{ width: "100%" }}>
        <img src={faqbanner} alt="faq" width="100%" style={{ objectFit: "contain" }} />
        <div className="your_search_container mt-2 justify-content-center d-flex " style={{ width: "100%", padding: "", position: "absolute" }}>
          <div className="your_order_search " style={{ width: "380px", background: "white" }}>
            <SearchIcon className="your_ordersearch_icon" />
            <input type="search" className="your_order_searchbar" placeholder="search FAQ ? " value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          </div>
        </div>
      </div>
      <div className="d-flex cursor-pointer justify-content-center" style={{ flexWrap: "wrap", padding: "20px", gap: "70px" }}>
        {faq.map((item, index) => (
          <div
            key={index}
            className="card d-flex align-items-center flex-row"
            style={{ gap: "20px", padding: "0px 20px", width: "40%", border: "none", height: "80px", background: "#dcdcdc", borderRadius: "0" }}
            onClick={() => handleCategorySelected(item)}
          >
            <div className="d-flex justify-content-center">
              {item.category.toLowerCase() === "payments" && <img style={{ width: "50px", height: "50px" }} src={creditCard} alt="logo" />}
              {item.category.toLowerCase() === "product & order" && <img style={{ width: "50px", height: "50px" }} src={deliverybox} alt="logo" />}
              {item.category.toLowerCase() === "shipping & delivery" && <img style={{ width: "50px", height: "50px" }} src={deliveryman} alt="logo" />}
              {item.category.toLowerCase() === "offer" && <img style={{ width: "50px", height: "50px" }} src={discount} alt="logo" />}
              {item.category.toLowerCase() === "my account" && <img style={{ width: "50px", height: "50px" }} src={profileuser} alt="logo" />}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <b>{item.category}</b>
            </div>
          </div>
        ))}
        <div
          className="card d-flex align-items-center flex-row"
          style={{ gap: "20px", padding: "0px 20px", width: "40%", border: "none", height: "80px", background: "#dcdcdc", borderRadius: "0" }}
          onClick={() => navgate("/customer-support")}
        >
          <div className="d-flex justify-content-center">
            <img style={{ width: "50px", height: "50px" }} src={helpdesk} alt="logo" />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <b>Customer Support</b>
          </div>
        </div>
      </div>
      <div ref={targetRef}>
        {categorySelected?.faq?.length > 0 && (
          <>
            <hr />
            <div className="w-100 d-flex justify-content-center mt-1 font-weight-bold">
              <p>Frequently asked question </p>{" "}
            </div>
            <div className="" style={{ padding: "0px 30px" }}>
              {/* <div>
                <p style={{}}>
                  FAQ For : <b>{categorySelected.category}</b>
                </p>
              </div> */}
              <div className="m-aout d-flex justify-content-center mt-3">
                <div className="w-75">
                  {categorySelected.faq.map((item, index) => (
                    <div key={index} className="cursor-pointer mb-4" style={{ background: "#dcdcdc" }} onClick={() => toggleCategory(index)}>
                      <div className="question d-flex justify-content-between  align-items-center  " style={{ padding: "10px 20px", border: "1px solid #cecece" }}>
                        <span className="d-flex " style={{ flexWrap: "wrap", wordWrap: "normal" }}>
                          {item.question}
                        </span>
                        <span>{expandedCategory === index ? <img src={up} alt="close" height="18px" width="18px" /> : <img src={down} alt="show" height="18px" width="18px" />}</span>
                      </div>

                      <div style={{ background: "aliceblue" }} className={` faq-content Answers ${expandedCategory === index ? "expanded" : "collapsed"}`}>
                        <ul className="" style={{ listStyle: "none", paddingRight: "2rem" }}>
                          <li className="mt-3 mb-3">
                            <div className="" dangerouslySetInnerHTML={{ __html: item.answer }} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FAQ;
