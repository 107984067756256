import "./step1.css";
// import Happy from "../../assets/IFD/Happy.png"
// import MainLogo from "../../assets/IFD/main-logo.png";
import MainLogo from "../../assets/IFD/IFD_logo.png";
// import crackers from "../../assets/IFD/crackers.svg"
import { Box } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import GiveawayContest from "../../assets/png/GiveawayContest.png";
import TermsAndConditionModal from "./TermsAndConditionModal";

import realmelogo from "../../assets/IFD/powered_by_realme.png";
import downbg from "../../assets/IFD/downbg.png";
import Thankyoulogo from "../../assets/IFD/Thank_you_for.png";

function IFDIndex() {
  const location = useLocation();
  const page_number = location.pathname.split("/");

  useEffect(() => {
    document.title = "IFD | O-LINE-O";

    return () => {
      document.title = "O-LINE-O Nexus";
    };
  }, []);

  function handleUnLoad(event) {
    event.returnValue = null;
  }
  const [modalName, setModalName] = useState(null);

  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnLoad);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      const confirmationMessage = "Are you sure you want to leave?";

      e.returnValue = confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="w-100 Main2" >
      <div className="main-logo" style={{ marginTop: "0px", height: "245px" }}>
        <img className="mb-3" src={realmelogo} alt="powered-logo" style={{width:"60px"}} />
        <img src={MainLogo} alt="main-logo" />
      </div>
      
      <div className="main-logo">
        <img src={Thankyoulogo} alt="thank-logo" />
      </div>

      <div className="mt-5" style={{background:"#fff",color:"#000"}}>
        <p style={{textAlign:"center",margin:"0",fontSize:"30px"}}><b>We Loved Your <br /> Responses!</b></p>
      </div>

      <p className="mt-5" style={{textAlign:"center",margin:"0",fontSize:"20px"}}>For more offers and discounts, <br /> visit the O-LINE-O store today!</p>
      
      <div className="mt-5" style={{display:"flex",justifyContent:"center"}}>
        <a href="https://olineoindia.com/store-finder"><button type="button" style={{width:"330px"}} className="btn btn-warning mt-3 mb-2 fw-bolder page_1_submit_btn" id="submit-otp-btn">
          <span> Store Locator </span>
        </button></a>
      </div>

      <div>
        <img style={{width:"100%",height:"250px"}} src={downbg} alt="down-bg" />
      </div>

    </div>
    // <div
    //   className="w-100 Main2"
    //   // style={{
    //   //     position: "relative",
    //   //     //CSS for ThankYou Image (Main Page)
    //   //     minHeight: "87ch"
    //   // }}
    // >
    //   <Box
    //     component="form"
    //     sx={{
    //       "& .MuiTextField-root": { m: 1 },
    //     }}
    //     style={{ marginTop: page_number[2] === "3" ? "26px" : "50px" }}
    //     noValidate
    //     autoComplete="off"
    //     className="sub-container"
    //     data-pagenumber={
    //       // ["/quiz-Offer/", "/quiz-Offer"].includes(location.pathname)
    //       ["/indianfestivaldays2024/", "/indianfestivaldays2024"].includes(location.pathname)
    //         ? "first"
    //         : page_number[2] === "2"
    //         ? "second"
    //         : page_number[2] === "3"
    //         ? "third"
    //         : page_number[2] === "4"
    //         ? "fourth"
    //         : page_number[2] === "5"
    //         ? "fifth"
    //         : page_number[2] === "6"
    //         ? "sixth"
    //         : ""
    //     }
    //   >
    //     <div className="main-logo" style={{ marginTop: "0px", height: "245px" }}>
    //       <img className="mb-3" src={realmelogo} alt="powered-logo" style={{width:"60px"}} />
    //       <img src={MainLogo} alt="main-logo" />
    //     </div>
    //     <Outlet />
    //     <div className="down-bg">
    //       {/* <img src={downbg} alt="down-bg" className="down-bg" /> */}
    //     </div>
    //     <Link
    //       onClick={() => {
    //         // setModalName("terms");
    //         // setShow(true);
    //         window.open("https://olineoindia.com/terms-condition", "_blank");
    //       }}
    //     >
    //       <div className="tnc-apply">T&C Apply</div>
    //     </Link>
    //   </Box>

      

    //   {show && modalName === "terms" && <TermsAndConditionModal show={show} onHide={() => setShow(false)} />}
    // </div>
  );
}

export default IFDIndex;
