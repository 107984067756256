import React from "react";

import person_outline from "../../assets/vector/person_outline.svg";
import PopperSignIn from "./PopperSignIn";
function MobileSignInButton(props) {
  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="right_login_comp_res">
      <div className="personIcon">
        <img
          alt="personIcon"
          src={person_outline}
          style={{
            width: "30px",
            height: "24px",
          }}
        />
      </div>
      <PopperSignIn isMobile={props.isMobile} open={open} current={anchorRef.current} handleClosePOP={handleClosePOP} handleListKeyDown={handleListKeyDown} />
    </div>
  );
}

export default MobileSignInButton;
