import axios from "../axiosInstance";

// get All payments
export const getAllPayment = async (filterQuery = '') => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `bearer ${adminToken}`,
    };
        
    let allPayments;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/payment${filterQuery}`, { headers }).then((res) => {
        allPayments = res.data?.data;
    });

    return allPayments;
};

// delete payments
export const updatePayment = async (id, body) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `bearer ${adminToken}`,
    };
        

    let updatePayments;
    await axios
        .patch(`${process.env.REACT_APP_BASE_URL}/payment/${id}`, body, { headers })
        .then((res) => {
            updatePayments = res.data?.data;
        })
        .catch((err) => console.log(err));

    return updatePayments;
};

// delete payments
export const deletPayment = async (id) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
    
    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `bearer ${adminToken}`,
    };
        
    let dltPayments;
    await axios
        .delete(`${process.env.REACT_APP_BASE_URL}/payment/${id}`, { headers })
        .then((res) => {
            dltPayments = res;
        })
        .catch((err) => console.log(err));

    return dltPayments;
};
