import React from "react";
import "./DropDownItemModal.css";

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../utils/ScreenSizeCalculation";

const styles = {
  position: "absolute",
  top: "10%",
  left: "100%",
  minWidth: "40vw",
  border: "none",
  outline: "none",
  boxShadow: 24,
  borderRadius: "5px",
  overflow: "hidden",
};

const DropDownItemModal = ({ open, handleClose, index, item, logos }) => {
  const navigate = useNavigate();
  const screenType = useScreenSize();

  function handleNavigate(name) {
    navigate(`/brand/${name}?hierarchyL2=${item}`);
    handleClose();
  }

  return (
    open &&
    Array.from(logos).length > 0 && (
      <Box style={styles} data-sequence={`sequence_${index}`}>
        <div
          className="logoModalContainer"
          style={{
            background: "linear-gradient(90deg,#ffffff96,rgb(213 213 213 / 26%))",
            filter: "drop-shadow(2px 4px 6px black)",
            backdropFilter: "blur(1px)",
          }}
        >
          {logos.map((logoArr, index) => (
            <Box className="logoModalItems" key={`logosArr_${index}`}>
              {logoArr.map((logo, i) => (
                <img
                  src={logo.image}
                  alt={logo.name}
                  key={`logo_${index}_${i}`}
                  onClick={screenType === "desktop" ? () => handleNavigate(logo.name) : () => {}}
                  onPointerEnter={screenType !== "desktop" ? () => handleNavigate(logo.name) : () => {}}
                />
              ))}
            </Box>
          ))}
        </div>
      </Box>
    )
  );
};

export default DropDownItemModal;
