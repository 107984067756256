import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { getAdminAllOrder, getAdminIndOrder, getAdminIndividualConsignment, getAllConsignments } from "../../api/AdminApis/AdminOrder";
import { approveCancelation, getAllCancelation } from "../../api/AdminApis/Cancel";
import CancelAdminApprove from "../../components/CancelationProductModal/CancelAdminApprove";
import CancelationProductModal from "../../components/CancelationProductModal/CancelationProductModal";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { listFormat } from "../../utils";
import { toast } from "react-toastify";
import "./styles/adminAnalytics.css";
import { getOrderStatus } from "../../api/OrdersApi";
import DashboardOrders from "./DashboardOrders";
import Consignments from "./Consignments";
import Cancellation from "./Cancellation";
import DeliveredConsignments from "../Retailer/DeliveredConsignments";
import * as moment from "moment";
import PreBookOrder from "./PreBookOrder";

function AdminAnalytics() {
  const nav = useNavigate();

  const [preBookOrder, setPreBookOrder] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setCancellation] = React.useState([]);
  const [orderItem, setOrderItem] = React.useState();
  const [cancelObj, setCancelObj] = React.useState();
  const [show, setShow] = React.useState(false);
  const [showSecModal, setShowSecModal] = React.useState(false);
  const [delCharges, setDelCharges] = React.useState("");
  const [cancelRequest, setCancelRequest] = React.useState([]);
  const [cancelProcessed, setCancelProcessed] = React.useState([]);
  const [consignmentSelected, setConsignmentSelected] = React.useState(null);
  const [details, setDetails] = useState(null);
  const productsPerPage = 30;
  const [activeTab, setActiveTab] = useState("allOrders");

  const [totalOrders, setTotalOrders] = useState(1);
  const [totalPreBookOrder, setTotalPreBookOrder] = useState(1);
  useEffect(() => {
    setLoader(true);
    getAllCancelation()
      .then((res) => {
        if (res) {
          setCancellation(res.cancellation);
          if (res.no_of_cancellation > 0) {
            let reqCancel = [];
            let processCancel = [];
            res.cancellation.forEach((item) => {
              if (item.approved) {
                processCancel.push(item);
              } else {
                reqCancel.push(item);
              }
            });
            setCancelRequest(reqCancel);
            setCancelProcessed(processCancel);
          }
        }
      })
      .finally(() => setLoader(false));
  }, []);

  useEffect(() => {
    getOrders("", "Something went wrong in fetching All Orders", currentPage);
  }, [currentPage]);

  function getOrders(value = "", errorMessage, pageNumber) {
    setLoader(true);
    getAdminAllOrder(`limit=${productsPerPage}&page=${pageNumber}&${value}`).then((res) => {
      if (res) {
        setPreBookOrder(res.preBookOrder);
        setTotalPreBookOrder(res.totalPreBookOrder);
        setTotalOrders(res.total_orders);
        setLoader(false);
      } else toast.error(errorMessage);
    });
  }

  const handleTabChange = (value) => {
    if (!value) return;
    setActiveTab(value); // This is correct!
  };

  const [consignments, setConsignments] = useState([]);

  const getConsignemt = async (value = "", pageNumber) => {
    const res = await getAllConsignments(`limit=${productsPerPage}&page=${pageNumber ? pageNumber : currentPage}&${value}`);
    if (res) {
      const consignments = res.consignments;
      setConsignments(consignments);
      setTotalOrders(res.total_consignments);
    }
  };

  return (
    <div className="container" style={{ fontSize: "small" }}>
      {loader ? (
        <DashboardLoader />
      ) : (
        <>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className={`nav-link ${activeTab === "allOrders" ? "active" : ""}`} onClick={() => setActiveTab("allOrders")}>
                All Orders
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activeTab === "deliveredOrders" ? "active" : ""}`} onClick={() => setActiveTab("deliveredOrders")}>
                Delivered
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activeTab === "cancled" ? "active" : ""}`} onClick={() => setActiveTab("cancled")}>
                Cancelled
              </a>
            </li>
            <li className="nav-item" onClick={() => getConsignemt()}>
              <a className={`nav-link ${activeTab === "allconsignment" ? "active" : ""}`} onClick={() => setActiveTab("allconsignment")}>
                All Consignment
              </a>
            </li>
            <li className="nav-item" onClick={() => getConsignemt()}>
              <a className={`nav-link ${activeTab === "prebookorder" ? "active" : ""}`} onClick={() => setActiveTab("prebookorder")}>
                Pre Book Order
              </a>
            </li>
          </ul>

          <div className="tab-content mt-3">
            {/* Tab 1: All Orders */}
            <div id="allOrders" className={`tab-pane fade ${activeTab === "allOrders" ? "show active" : ""}`}>
              <DashboardOrders handleTabChange={handleTabChange} getConsignemt={getConsignemt} />
            </div>

            {/* Tab 2: Delivered Orders */}
            <div id="deliveredOrders" className={`tab-pane fade ${activeTab === "deliveredOrders" ? "show active" : ""}`}>
              <h3>Delivered Orders</h3>
              <DeliveredConsignments />
            </div>

            <div id="cancelOrders" className={`tab-pane fade ${activeTab === "cancled" ? "show active" : ""}`}>
              <Cancellation />
            </div>
            <div id="allconsignment" className={`tab-pane fade ${activeTab === "allconsignment" ? "show active" : ""}`}>
              <Consignments getConsignemt={getConsignemt} consignments={consignments} totalOrders={totalOrders} />
            </div>
            <div id="prebookorder" className={`tab-pane fade ${activeTab === "prebookorder" ? "show active" : ""}`}>
              <PreBookOrder preBookOrder={preBookOrder} getOrders={getOrders} totalOrders={totalPreBookOrder} loading={loader} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdminAnalytics;
