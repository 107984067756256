import axios from "./axiosInstance";

const headers = {
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
  "Content-Type": "application/json",
  "Access-Control-Allow-origin": "*",
};

//Get Store Location based on user position
export const getStoreLocation = async (ean, quantity, pin) => {
  let locationResponse;
  // let locationBody = {
  //   latitude: loc.coords.latitude,
  //   longitude: loc.coords.longitude
  // }

  const findStoreBody = {
    eancode: ean,
    qty: quantity,
    pincode: pin,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/store/findstore`, JSON.stringify(findStoreBody), { headers })
    .then((res) => {
      locationResponse = res.data.data;
    })
    .catch((err) => {
      console.log("Error:", err);
    });

  return locationResponse;
};

//Search store using Pincodes
export const getStoreUsingSearchKey = async (zip) => {
  let result = null;

  let serviceBody = {
    location: zip,
    items: [],
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/order/checkServiceability/`, JSON.stringify(serviceBody), { headers }).then((res) => {
    result = res.data;
  });

  return result;
};

export const getStoreUsingPinCode = async (zip) => {
  let result = null;

  let serviceBody = {
    location: zip,
    items: [],
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/order/checkServiceabilitywithPin/`, JSON.stringify(serviceBody), { headers }).then((res) => {
    result = res.data;
  });

  return result;
};

//Get Store Inventory
export const getStoreInventory = async (id) => {
  let getStoreInventoryRes;

  if (id) {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/store/getStoreInventory/${id}`, { headers }).then((res) => {
      getStoreInventoryRes = res.data;
    });
  }

  return getStoreInventoryRes;
};

//GET PRODUCTS FOR STORE
export const getSearchedProductForStore = async (id, query) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let searchedProductRes;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/store/getStoreInventory/${id}?${query}`, { headers }).then((res) => {
    if (res.status === 200) searchedProductRes = res.data;
  });
  return searchedProductRes;
};

//GET FILTERS FOR STORE
export const getFiltersForStoreProducts = async (storeCode) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let searchedProductRes;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/storeInventory/filters/${storeCode}`, { headers }).then((res) => {
    searchedProductRes = res.data;
  });
  return searchedProductRes;
};

//GET ALL STORES
export const getAllStores = async (storeLimitPerPage, pageNumber) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let storesRsponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/store/all?limit=${storeLimitPerPage}&page=${pageNumber}`, { headers }).then((res) => {
    storesRsponse = res.data;
  });
  return storesRsponse;
};

//GET ALL STORES LAT AND LONG
export const getAllStoresLatAndLong = async () => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let storesResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/store/get-lat-long`, { headers }).then((res) => {
    storesResponse = res.data;
  });
  return storesResponse;
};

//GET ALL STORES FOR SPECIFIC LOCATION
export const getAllStoresForLocation = async (locationName) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let storesRsponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/store/location-wise/${locationName}`, { headers }).then((res) => {
    storesRsponse = res.data;
  });
  return storesRsponse;
};
