import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { currencyFormat } from "../../../utils";

function ProductDetailsCard({ heading, details, editHTML }) {
  return (
    <Card className="my-3">
      <Card.Header className="d-flex justify-content-between align-items-center">{heading}</Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Card.Text
            style={{
              width: "100%",
            }}
          >
            <ListGroup as="ol">
              {details?.product?.images[0] && (
                <ListGroup.Item as="li" className="d-flex justify-content-center align-items-center">
                  <img
                    src={details?.product?.images[0]}
                    alt="product-img-1"
                    style={{
                      width: "250px",
                    }}
                  />
                </ListGroup.Item>
              )}
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product OPIN : <span className="fw-bold">{details?.product?.ean}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Name: <span className="fw-bold">{details?.product?.title}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Brand: <span className="fw-bold">{details?.product?.brand}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Hierarchy L1: <span className="fw-bold">{details?.product?.hierarchyL1}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Hierarchy L2: <span className="fw-bold">{details?.product?.hierarchyL2}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Hierarchy L3: <span className="fw-bold">{details?.product?.hierarchyL3}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product Color: <span className="fw-bold">{details?.product?.color ?? "-"}</span>
              </ListGroup.Item>

              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product MRP: <span className="fw-bold">{currencyFormat(details?.product?.price?.mrp)}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                Product MOP: <span className="fw-bold">{currencyFormat(details?.product?.price?.mop)}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center">
                Product URL:{" "}
                <a href={`/product/${details?.product?.slug}`} target="_blank" rel="noreferrer" className="fw-bold">
                  Link
                </a>
              </ListGroup.Item>
              {editHTML}
            </ListGroup>
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductDetailsCard;
