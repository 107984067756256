import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";
// React-Toastify
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { io } from "socket.io-client";
import { APP_DATE_TIME_FORMAT, SOCKET } from "../../utils/Constants";
import { validateEan, validateStoreNumber } from "../../api/Retailer";
import DashboardLoader from "../DashboardContent/DashboardLoader";
import { useTranslation } from "react-i18next";
import Correct from "../../pages/ThankyouPage/correct.svg";

function isValidIMEI(imei) {
  if (!/^\d{15}$/.test(imei)) {
    return false;
  }

  let sum = 0;

  for (let i = 0; i < 15; i++) {
    let digit = parseInt(imei.charAt(i), 10);

    if (i % 2 === 1) {
      digit *= 2;
    }

    if (digit > 9) {
      digit -= 9;
    }

    sum += digit;
  }

  return sum % 10 === 0;
}

const AcceptConsignment = () => {
  const [ean, setEan] = useState("");
  const [comboEan, setComboEan] = useState("");
  const [comboBatchName, setComboBatchName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [justAccepted, setJustAccepted] = useState(false);
  const [consignemt, setConsignment] = useState({});
  const [imeiArray, setImeiArray] = useState([]);

  const [retailerData, setRetailerData] = useState({});
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [loading, setLoading] = useState(true);
  const SOCKET_SERVER_ENDPOINT = process.env.REACT_APP_SOCKET_BASE;

  const { t } = useTranslation();

  const location = useLocation();

  const urlParams = useParams();
  useEffect(() => {
    if (urlParams && urlParams.id) {
      setLoading(true);
      getConsignment(urlParams.id);
    }
  }, [location]);

  const socket = useRef(null);

  const MySwal = withReactContent(Swal);

  const getConsignment = async (id) => {
    const res = await getAdminIndividualConsignment(id);
    if (res) {
      setConsignment(res.details);
      setImeiArray(Array(res.details.quantity).fill(""));
      setEan(res.details.product?.ean);
      setComboEan(res.details?.combo?.childEan);
      setComboBatchName(res.details?.combo_batch_name);
      setLoading(false);
    }
  };

  // Consignment Accepted handler
  const cnmtAccept = (cnmtId) => {
    // setNewConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    // setManualConsignments((prev) => prev.filter((el) => el._id !== cnmtId));
    toast.success("Consignment accepted successfully!");
    // setTimeout(() => fetchAcceptedCnmts(storeId), 100);
  };

  const onCnmtAcceptError = (err) => {
    console.log("err ", err);
    let message = "Something went wrong while accepting consignment";
    if (err.message.message.includes("Wrong Pickup location entered")) {
      message = "Store pickup location not active on Shiprocket";
    }
    toast.error(message);
  };

  const initSocketConnection = (storeId) => {
    // Initiate connection
    socket.current = io.connect(SOCKET_SERVER_ENDPOINT, {
      query: `storeId=${storeId}`,
    });
    // register event handlers

    socket.current.on(SOCKET.CNMT_ACCEPTED, (consgimentId) => {
      cnmtAccept(consgimentId._id);
    });
    socket.current.on(SOCKET.CNMT_ACCEPT_ERR, (err) => {
      onCnmtAcceptError(err);
    });
  };

  const isValidMobileNumber = async (number) => {
    if (number.length === 10) {
      const res = await validateStoreNumber(number);
      if (res) {
        if (res.message) {
          toast.error(res.message);
          setRetailerData({});
          setDisableSubmitBtn(true);
          return;
        }
        setRetailerData(res.store);
        initSocketConnection(res.store._id);
        setDisableSubmitBtn(false);
      }
      return /^\d{10}$/.test(number);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await validateEan({
        ean,
        combo_ean: comboEan,
        consignmentId: consignemt._id,
        storeId: retailerData._id,
        imei: imeiArray,
        combo_batch_name: comboBatchName,
        hasComboProd: !!consignemt.combo?.childEan,
        brand_store_id: retailerData.brand_store_id,
      });
      if (result) {
        toast.success("Validation Successful!");
        socket.current.emit(SOCKET.CNMT_ACCEPTED, consignemt._id);
        setJustAccepted(true);
        setLoading(false);
      }
      // window.location.reload();
    } catch (error) {
      setLoading(false);
      console.log("error ", error.response);
      toast.error(`EAN validation failed due to - ${error.response?.data?.message ?? "Internal Server Error"}`);
    }
  };

  const handleImeiChange = (index, value, productId) => {
    const newImeiArray = [...imeiArray];
    newImeiArray[index] = { productId, imei: value };
    setImeiArray(newImeiArray);
  };

  return (
    <div className="container d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          {justAccepted ? (
            <div className="thankyou-sec-2">
              <div className="thk2">
                <div className="thk2-sub color-font">Thanks for accepting the order. Please keep the product ready. O-LINE-O Team.</div>
                <div className="thk2-sub">
                  <div className="tick-thk">
                    <img src={Correct} alt="" />
                  </div>
                  {/* <div className="color-font2">{t("orderPlacedSuccess")}</div> */}
                </div>
              </div>
            </div>
          ) : (
            <>
              {consignemt.status !== "INITIATED" ? (
                <>
                  {consignemt.status === "CONSIGNMENT_ACCEPTED" ? (
                    <div>
                      This Order Is Accepted By <b> {consignemt?.consignor?.display_name}</b>
                    </div>
                  ) : (
                    <>{consignemt.status === "DELIVERY_SUCCESS" || consignemt.status === "DELIVERED" ? <div>This Order Is successfully Delivered</div> : <div>This Order Is Cancelled</div>}</>
                  )}
                </>
              ) : (
                <form className="border p-3 rounded" noValidate onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="text-danger mb-3 font-italic">Please Validate phone number for Accepting Order!!</div>
                    <label htmlFor="mobileNumber" className="form-label">
                      Enter Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobileNumber"
                      placeholder="Phone Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        if (!isValidMobileNumber(e.target.value)) {
                          toast.error("Provide Valid Mobile Number!");
                          setDisableSubmitBtn(true);
                        } else {
                          setDisableSubmitBtn(false);
                        }
                      }}
                      required
                    />
                    {retailerData?._id && <span className="text-success">{retailerData.fc_name}</span>}
                  </div>

                  {consignemt?.product?.hierarchyL2 === "Smartphone" ? (
                    <>
                      <div>
                        <small className=" text-muted" style={{ margin: "0px" }}>
                          Please enter the Serial Number / IMEI for <strong>{consignemt.product?.title}</strong>
                          <br />
                          Quantity : {consignemt.quantity}
                        </small>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="imei" className="form-label">
                          Serial Number / IMEI
                        </label>
                        {imeiArray.map((imei, index) => (
                          <input
                            key={index}
                            type="text"
                            className="form-control"
                            id={`imei-${index}`}
                            placeholder="Serial Number / IMEI"
                            value={imei.imei}
                            onChange={(e) => handleImeiChange(index, e.target.value, consignemt.product?._id)}
                            onBlur={() => {
                              if (imei && !isValidIMEI(imei.imei)) {
                                toast.error("Provide Valid IMEI!");
                                setDisableSubmitBtn(true);
                              } else {
                                setDisableSubmitBtn(false);
                              }
                            }}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <small className=" text-muted" style={{ margin: "0px" }}>
                          Please enter the Serial Number for <strong>{consignemt.product?.title}</strong>
                        </small>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="imei" className="form-label">
                          Serial Number
                        </label>
                        {imeiArray.map((imei, index) => (
                          <input
                            type="text"
                            key={index}
                            className="form-control"
                            id={`imei-${index}`}
                            placeholder="Serial Number"
                            value={imei.imei}
                            onChange={(e) => handleImeiChange(index, e.target.value, consignemt.product?._id)}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  {consignemt?.combo && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="comboEan" className="form-label">
                          Combo Product OPIN
                        </label>
                        <input type="text" className="form-control" id="comboEan" placeholder="Combo Product OPIN" value={comboEan} onChange={(e) => setComboEan(e.target.value)} />
                      </div>
                      <small className=" text-muted">
                        Combo Product <strong>{consignemt.combo?.product?.title}</strong>
                      </small>
                      {/* 
                      <div className="mb-3">
                        <label htmlFor="comboBatchName" className="form-label">
                          Combo Store Batch
                        </label>
                        <input type="text" className="form-control" id="comboBatchName" placeholder="Combo Store Batch" value={comboBatchName} onChange={(e) => setComboBatchName(e.target.value)} />
                      </div> */}
                      {/* <small className=" text-muted">
                        Please enter the Product Batch Name for <strong>{consignemt.combo?.product?.title}</strong>
                      </small> */}
                    </>
                  )}
                  <div>
                    <button type="submit" className="btn btn-success mt-3" style={{ width: "100%" }} disabled={disableSubmitBtn}>
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AcceptConsignment;
