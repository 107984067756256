import React, { useState, useEffect } from "react";
import useAdminCtx from "../../hooks/useAdminCtx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteTopBannerProduct } from "../../api/AdminApis/Banner";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";
import { toast } from "react-toastify";

function HomePageStaticPosters({ activeTab }) {
  const [showTopPagePosters, setShowTopPagePosters] = useState([]);
  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) toast.error("Unable to Fetch the Sections properly");
      setAllHomePageSections(res.sections);
    });
  }, []);

  const handleDelete = (key, sectionType) => {
    const cnf = window.confirm("Are you sure?");
    if (!cnf) return;
    deleteTopBannerProduct(key, sectionType, (err, res) => {
      if (err) return toast.error("Something went wrong!");
      if (res.status === 200) {
        getHomePageSections((err, res) => {
          if (err) return console.log(err);
          setAllHomePageSections(res.sections);
        });
      }
    });
  };

  useEffect(() => {
    if (location?.state?.for === "homepageposter") {
      const arr2 = [];
      const topSectionPosters = allHomePageSections.find((section) => section.sectionType === "static_poster");
      if (topSectionPosters) {
        const updatedProducts = topSectionPosters.products
          .filter((product) => !product.title.includes("_"))
          .map((product) => ({
            ...product,
            sectionType: topSectionPosters.sectionType,
          }));
        arr2.push(...updatedProducts);
        setShowTopPagePosters(arr2);
      }
    } else {
      const arr2 = [];
      const topSectionPosters = allHomePageSections.find((section) => section.sectionType === "static_poster");
      if (topSectionPosters) {
        const updatedProducts = topSectionPosters.products
          .filter((product) => product.title.includes("_"))
          .map((product) => ({
            ...product,
            sectionType: topSectionPosters.sectionType,
          }));
        arr2.push(...updatedProducts);
        setShowTopPagePosters(arr2);
      }
    }
  }, [allHomePageSections, location]);

  const handleToggle = () => {
    navigate("../admin-home-add-section", {
      state: {
        type: "static_poster",
        updateProduct: false,
        from: location.pathname,
        for: location?.state?.for !== "homepageposter" ? "homepageposter" : "brandPage",
      },
    });
  };

  return (
    <div className="px-4">
      {location?.state?.for === "homepageposter" && (
        <button onClick={() => navigate("../admin-home-page")} style={{ all: "unset", marginBottom: "25px", cursor: "pointer" }}>
          <FontAwesomeIcon icon={faChevronLeft} /> Page Sections
        </button>
      )}
      <div className="row px-3">
        <div className="col-auto">
          <div className=" mb-2"></div>
          <button className="btn btn-primary " onClick={() => handleToggle()}>
            Add Static Banner {location?.state?.for !== "homepageposter" && "For Brand"}
          </button>
        </div>
        {showTopPagePosters?.length > 0 ? (
          showTopPagePosters?.map((banner, index) => (
            <>
              <div className="banner-product-card" key={index}>
                {banner.imgUrl.split(".").pop() === "mp4" ? (
                  <video controls autoPlay loop muted>
                    <source src={banner.imgUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={banner.imgUrl} alt="" width={500} />
                )}
                <div className="banner-product-card-body" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                  <p>
                    <u>Title</u> - {banner.title}{" "}
                  </p>
                  <p>
                    <u> Image URL</u> - <a href={banner.imgUrl}>{banner.imgUrl}</a>{" "}
                  </p>
                  <p>
                    <u> Target URL</u> - <a href={banner.targetUrl}>{banner.targetUrl}</a>{" "}
                  </p>
                  <p>Size: 1400px X 1000px</p>
                  {index - 3 !== 0 && <p>Mobile Banner - {index + 1}</p>}
                </div>
                <div className="icons">
                  <FontAwesomeIcon
                    onClick={() =>
                      navigate("../admin-home-add-section", {
                        state: {
                          type: location?.state?.for !== "homepageposter" && activeTab !== "brandPage" ? "scroll_banner" : "static_poster",
                          updateProduct: true,
                          data: banner,
                          from: location.pathname,
                          for: location.state?.for,
                        },
                      })
                    }
                    cursor={"pointer"}
                    icon={faEdit}
                  />
                  <FontAwesomeIcon cursor={"pointer"} onClick={() => handleDelete(banner.imgKey, banner.sectionType)} icon={faTrashAlt} />
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div className="d-flex mt-5">No Banner Images!</div>
          </>
        )}
      </div>
    </div>
  );
}

export default HomePageStaticPosters;
