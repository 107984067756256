import { Box, Checkbox, Fade, FormControlLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
// import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import useGeolocation from "../../hooks/useGeolocation";
import useUserCtx from "../../hooks/useUserCtx";
import navigationBlue from "../../assets/vector/navigation_outline_blue.svg";
import Backdrop from "@mui/material/Backdrop";
import useSiteCtx from "../../hooks/useSiteCtx";
import { updateUserPincode } from "../../api/Auth";
import { toast } from "react-toastify";

function LocationModal({ open, handleClose, setOpenModal, isMobile }) {
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "360px" : 420,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    padding: 0,
    paddingBottom: "20px",
    outline: "none",
  };

  const [pincode, setPincode] = useState("");
  const [validLength, setValidLength] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const { location, locationFetch } = useGeolocation();
  const [enterPinClicked, setEnterPinClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const { userLoggedIn } = useSiteCtx();
  const { setUserLocation, setUserDefaultAddress, setUserContext, setUserZip } = useUserCtx();

  const getLocation = () => {
    locationFetch();
    handleCloseModal();
  };

  const handleLength = (length) => {
    if (length === 6) {
      setValidLength(true);
    } else {
      setValidLength(false);
    }
  };

  const validateForm = () => (pincode !== "" && validLength ? setBtnDisable(false) : setBtnDisable(true));
  useEffect(() => {
    validateForm();
  }, [validLength]);

  const validateNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPincode(e.target.value);
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (pincode !== "") {
      setUserLocation((prev) => ({
        ...prev,
        loaded: true,
        useThis: true,
        address: { city: "", state: "", zip: pincode },
      }));
      setUserDefaultAddress((prev) => ({
        ...prev,
        useThis: false,
      }));
      if (checked)
        updateUserPincode(pincode).then((res) => {
          if (res?.data?.status === "success") {
            toast.success(res.data?.message ?? "Updated!!");
            setUserContext((prev) => ({ ...prev, pincode }));
            setUserZip((prev) => ({ ...prev, value: pincode }));
          } else toast.error(res?.data?.status === "error" ? res.data?.message : "Something Went Wrong!!");
        });
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (location.loaded && location?.coordinates && location?.coordinates?.lat !== "") {
      setUserLocation({
        loaded: true,
        useThis: true,
        coordinates: location.coordinates,
        address: location.address,
      });
      setUserDefaultAddress((prev) => ({
        ...prev,
        useThis: false,
      }));
    }
  }, [location, setUserLocation, setUserDefaultAddress]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setPincode("");
    setBtnDisable(true);
    setEnterPinClicked(false);
  };

  function handleCheckBoxChange(event) {
    setChecked(event.target.checked);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ zIndex: "1000000" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="locationModalContainer" id="modal-modal-title">
            <h1 className="locationModalHeader">{t("location.choose-loc")}</h1>
            <p className="locationModalpara" id="modal-modal-description">
              Select a delivery location to see product availability and {isMobile ? "delivery options" : ""}
            </p>
            {!isMobile && <p className="locationModalpara">delivery options</p>}
            <div className="locationModalinstruction" onClick={getLocation}>
              <img src={navigationBlue} alt="current-loc" />
              <p>{t("location.current-loc")}</p>
            </div>
            <p className="locationModalpincode">or Enter a Pincode</p>
            <form className="locationModalInputContainer" onChange={() => validateForm()} onSubmit={formSubmit}>
              <input
                type="tel"
                name="Phone"
                id="phone"
                maxLength={6}
                className="locationModalinput"
                value={pincode}
                autoComplete="false"
                placeholder={`${t("location.pincode")}...`}
                onChange={(e) => {
                  validateNumber(e);
                  handleLength(e.target.value.length);
                }}
                // onpaste={(e) => {
                //   validateNumber(e);
                //   handleLength(e.target.value.length);
                // }}
              />
              <button type="submit" onClick={() => setOpenModal(false)} className="locationModalbutton" disabled={btnDisable}>
                Apply{" "}
              </button>
            </form>
            {userLoggedIn && (
              <div style={{ marginBlock: "0.25rem", marginLeft: "1.5rem" }}>
                <FormControlLabel
                  control={<Checkbox checked={checked} size="small" onChange={handleCheckBoxChange} inputProps={{ "aria-label": "controlled" }} />}
                  label="Update Pincode in the Default Address"
                />
              </div>
            )}
            <CloseIcon onClick={() => setOpenModal(false)} className="locationmodalCloseIcon" />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default LocationModal;
