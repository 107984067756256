import React from 'react';
import "./styles.css";

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-container'>
            <section className='secondSection'>
                <span className='secondSectionHeader'> PRIVACY POLICY </span>
                <p>
                    This Privacy Policy (“Privacy Policy”) sets forth how <b>OLINEO NEXUS INDIA PRIVATE LIMITED</b> uses and protects the personal information that the user provides to us when the user uses our mobile application or accesses and visits our website. This privacy policy applies only to personal information collected on the mobile application and our website. Please read this privacy policy carefully. By continuing to use the services or accessing our mobile application and website, the user agrees to this privacy policy. If the user does not agree to this privacy policy, the user may not use or avail of the services or access the mobile application or website.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'> INTRODUCTION </span>
                <p>
                    We, <b>Olineo Nexus India Private Limited</b> (“Company”; “We”; “us”; “our”) have created and developed a mobile application known as [●] (“App”) and also own and operate a website, namely, [●] (together the “Platform”). The Platform facilitates the service of buying and selling various goods (“Goods”) between certain Partners and Buyers (as defined below) who are associated with us and are registered on the Platform. Wherein “Partners” shall mean and include the retail entities that offer the goods for sale, through the Platform. “Buyers” shall mean and include individuals who purchase the goods from the Partners using the Platform. “Users” shall mean and include the Partners and Buyers registered on the App or Website, as well as visitors to our Website.
                </p>
                <p>
                    For the provision of the Services, the Company shall collect the Personal Information (defined below) of the Users.
                </p>
                <p>
                    If you (“you”) are using the Services or accessing the Platform on behalf of a third party, you represent that you have the authority to bind such third-party to the terms and conditions of this Privacy Policy and, in such an event your use of the Services or the Platform shall refer to use by such third party. If you do not have such authority (to provide any Personal Information of a third party) or do not agree to the terms of this Privacy Policy, then you should refrain from using the Services. Additionally, you agree that you have obtained prior written consent from such third-party before uploading their Personal Information on the Platform.
                    Use of the Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. We do not knowingly solicit or collect personal information from children under the age of 18 years. If you have shared any personal information of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.
                </p>
                <p>
                    We are committed to protecting Personal Information and have made this Privacy Policy to describe the procedures we adhere to for collecting, using, and disclosing Personal Information. We recommend that Users read this Privacy Policy carefully so that Users understand our approach towards the use of their Personal Information.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'> GOVERNING STATUTE </span>
                <p>
                    This Privacy Policy is governed by and is compliant with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, which is designed to protect the Personal Information; and other applicable rules and regulations related to privacy.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'> PERSONAL INFORMATION COLLECTED </span>
                <p>
                    This Privacy Policy applies to Personal Information collected and controlled by the Company, through the Platform about the Users, for providing the Services. For the provision of the Services, User is required to register with the App or on the Website by using the sign-up option available (“User Account”). During the registration process and for using the Services, User shall be required to share/upload certain Personal Information. For purposes of this Privacy Policy, “Personal Information” means information that can be used to personally identify the User, including but not limited to User’s name, delivery address, residential/commercial address, mobile number, e-mail ID, and in case of Partner, the shop details such as address, photographs, registration number, license number of the Partner’s shop, PAN Card number, GSTIN, Partner’s bank details such as account name, account number, account type and IFSC code.
                </p>
                <p>
                    The User undertakes and confirms that all the information disclosed/ to be disclosed by you is disclosed willingly and without any coercion and that he shall be solely responsible for the accuracy, correctness, or truthfulness of the Personal Information shared with us whether of its own or any third party. In the event the User is sharing any Personal Information on behalf of a third person, the User represents and warrants that he has the necessary authority to share such Personal Information with the Company, obtained written consent from such third party and the Company shall not be responsible for verifying the same. The User may choose to withdraw his consent provided hereunder at any point in time by written communication to the Company at <a href='mailto:contactus@olineoindia.com' target='_blank' rel="noreferrer" >contactus@olineoindia.com</a> . Upon receipt of such communication, the Company shall be entitled to store the data in an anonymized manner without reference to the User and complete the anonymizing of the data within 7 days from the date of the communication. The User understands and acknowledges that such Personal Information shall be subject to the terms and conditions of this Privacy Policy.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>NON-PERSONAL INFORMATION </span>
                <p>
                    We collect User’s Internet Protocol (“IP”) addresses when the User visits the Platform to track and aggregate non-personal information. Non-personal information may include the browser name, your usage and log-related information, the type of electronic device, manufacturer, make, model, unique ID of the electronic device and other technical information about the User’s means of connection to our Platform, such as the operating system and the Internet service providers utilized and other similar information. For example, we may use IP addresses to monitor the regions from which User navigates our Platform. User understands that this information shall be non-personal information and could be used by the Company, for its own business purposes and to improve the Services.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>USE OF PERSONAL INFORMATION </span>
                <p>
                    We shall use the Personal Information in the following cases:
                    <ol type='i'>
                        <li>to provide the User with the Services and to assist the User in the event the User needs any additional support</li>
                        <li>for the creation or development of business intelligence or data analytics in relation to the Services provided by the Company,</li>
                        <li>to more effectively provide the Services to User,</li>
                        <li>to improve the Services.</li>
                        <li>to maintain and manage User Account.</li>
                        <li>to assist User with technical difficulties that may arise in relation to User’s use and access of the Platform.</li>
                        <li>to manage our relationship with User.</li>
                        <li>for internal record keeping. and</li>
                        <li>to comply with our legal or statutory obligations.</li>
                    </ol>
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>ANONYMIZED DATA </span>
                <p>
                    We may also use your Personal Information collected to create aggregate anonymized data. We shall ensure that such anonymized data will not be directly identifiable to you or to the Personal Information shared with us. We may use this anonymized data for any purpose including but not limited to conducting research, analytical purposes, and improving our Services. By using the Services and/or visiting our website, you provide us with the right to use your Personal Information to create anonymized data and use it for our business purposes.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>DISCLOSURES</span>
                <p>
                    In the course of providing Services to you or access to the App or Website we may share your Personal Information with certain parties (for consideration or otherwise). Accordingly, you expressly give your free consent to us to disclose or share your Personal Information in the following cases:
                </p>
                <p>
                    <span>Administrators:</span>  We may provide access to User's Personal information to any of our authorized administrators for an internal business purpose, who shall be under confidentiality obligations towards the same.
                </p>
                <p>
                    <span>Affiliates:</span> We may provide Personal Information we collect to our affiliates. For example, we may disclose Personal Information to our affiliates in order to respond to User's requests for information or Services.
                </p>
                <p>
                    <span>Service Providers:</span>  We may share User's Personal Information with the service providers, who are working with us in connection with the operation of the Services or the App, so long as such service providers are subject to confidentiality restrictions consistent with this Privacy Policy.
                </p>
                <p>
                    <span>Joint Marketing Arrangements:</span>  Where permitted by law, we may share User’s Personal Information with joint marketers with whom we have a marketing arrangement, we would require all such joint marketers to have written contracts with us that specify the appropriate use of User's Personal Information, require them to safeguard User’s Personal Information, and prohibit them from making unauthorized or unlawful use of User's Personal Information.
                </p>

                <p>
                    <span>Merger or Acquisition:</span> We may transfer User's Personal Information if we are acquired by or we acquire or merge with another company or transfer a part of our business, including the App, to a third party. Any third party or resultant entity that receives the User's Personal Information pursuant to a merger, demerger, amalgamation, reconstruction, change in control or business transfer shall have the right to continue to use User's Personal Information. In the event of such a sale or transfer, we may notify the Users.
                </p>
                <p>
                    <span>Legal and Regulatory Authorities:</span> We may be required to disclose User's Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose User's Personal Information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, or discovery requests. We may also disclose User's  Personal Information to:
                    <ol type='a'>
                        <li>law enforcement officers or others,</li>
                        <li>Credit Information Companies,</li>
                        <li>to comply with a judicial proceeding, court order, or legal process served on us or the App,</li>
                        <li>to enforce or apply this Privacy Policy or our other policies or agreements,</li>
                        <li>for an insolvency proceeding involving all or part of the business or asset to which the information pertains,</li>
                        <li>respond to claims that any Personal Information violates the rights of third-parties,</li>
                        <li>or protect the rights, property, or personal safety of the Company, or the general public. User agrees and acknowledges that we may not inform User prior to or after disclosures made according to this section.</li>
                    </ol>
                </p>
                <p>
                    Notwithstanding anything mentioned hereinabove, the Company shall not be responsible for the actions or omissions of the parties (including but not limited to the entities listed above) with whom the Personal Information is shared, nor shall the Company be responsible and/or liable for any additional information User may choose to provide directly to any third party
                </p>
                <p>
                    The User expressly agrees that the User will be communicating directly through the Website or the App with the Partners, Buyers and other third party service providers and will willfully be sharing its information with Partners and other third party service providers, including payment apps, delivery agencies, and Buyers etc. The Company does not itself store any payment card account information other than Virtual Payment Address (VPA) that may be shared by you, does not have direct control over or responsibility for such information shared with third parties.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>DATA RETENTION </span>
                <p>
                    We will retain User’s Personal Information as long as it is required to be retained for the purpose of provision of the Services. We may also retain and use User’s Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>SECURITY  </span>
                <p>
                    User’s Personal Information is stored on our database which is either hosted on a private cloud or public cloud. Although we provide appropriate firewalls and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not hack proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible, and we take no liability or responsibility for it. Users are required to be careful to avoid “phishing” scams, where someone may send User an e-mail that looks like it is from the Company asking for User’s personal information. Hence, Company cannot guarantee that transmissions of such information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by Company or Company’s third-party service providers. The Company assumes no liability or responsibility for disclosure of User information due to any reason, including but not limited to errors in transmission, unauthorized third-party access, or other causes beyond its control. Although the Company shall try its best to protect the information. The Company cannot take any guarantee for the security of the User data transmitted through the Platform.
                </p>
                <p>
                    The User is responsible for all the actions that take place under the User Account. If User chooses to share User Account details and password or any Personal Information with third parties, the User is solely responsible for the same. If the User loses control of the User Account, User may lose substantial control over its Personal Information and may be subject to legally binding actions. It is User’s responsibility to keep User’s password confidential and secure.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>ACCESSING AND MODIFYING PERSONAL INFORMATION  </span>
                <p>
                    In case User needs to access, review, and/or make changes to the Personal Information, User may do so on his/her User Account. To change/modify the User's mobile number, he/she has to log in to their User Account and update the new mobile number and confirm using a One-Time Password (OTP). The User shall keep User’s Personal Information updated to help us improve our Services. If User updates, modifies or corrects his Personal Information, we may continue to keep copies of the Personal Information prior to such update, modification or correction for uses provided for in this Privacy Policy. We shall not verify any such modifications or corrections made by the User. User shall be solely liable for such modifications or corrections
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>INDEMNIFICATION  </span>
                <p>
                    User agrees to indemnify us, our subsidiaries, affiliates, officers, agents and employees (each, an “Indemnified Party”) and hold the Indemnified Party harmless from and against any claims and demand, including reasonable attorneys' fees, made by any third party due to or arising out of or relating to:
                    <ol type='i'>
                        <li>
                            accuracy and correctness of Personal Information and contents that User submits or shares through the App.
                        </li>
                        <li>User’s violation of this Privacy Policy,</li>
                        <li>or User’s violation of rights of another User. </li>
                    </ol>
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>LIMITATION OF LIABILITY  </span>
                <p>User expressly understands and agrees that the company shall not be liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, details, or other intangible losses (even if the company has been advised of the possibility of such damages), arising out of this privacy policy. The foregoing limitation of liability shall apply notwithstanding the failure of essential purpose of any limited remedy herein.</p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>RESERVATION OF RIGHTS </span>
                <p>
                    All rights not expressly granted in this Privacy Policy are reserved by the Company and its licensors, as the case may be. Nothing contained in this Privacy Policy shall be construed as conferring by implication, estoppels or otherwise any license or right under any copyright, patent, trademark or other intellectual property right of Company or any other person or to User.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>GOVERNING LAWS AND DISPUTES </span>
                <p>
                    This Privacy Policy shall be construed and governed by the laws of India without regard to principles of conflict of laws. Any dispute arising, between the User and the Company shall be submitted to arbitration to be conducted in Navi Mumbai, Maharashtra, India in English language, in accordance with the rules of Arbitration and Conciliation Act of 1996 (as amended from time to time), by a sole arbitrator, who shall be appointed by the Company and the award made in pursuance thereof shall be binding on the Users and the Company. The dispute resolution and arbitration process mentioned in this section shall not prohibit parties from approaching the courts for appropriate interim reliefs. Parties further agree that the courts in Navi Mumbai, Maharashtra, India shall have exclusive jurisdiction over such disputes.
                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>CHANGES TO THIS POLICY</span>
                <p>
                    We may update this Privacy Policy without notice to the User. Users are encouraged to check this Privacy Policy on a regular basis to be aware of the changes made to it.
                    This Privacy Policy was last modified on July, 2023.

                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>CONTACT US</span>
                <p>
                    If the User has any questions, concern or grievance regarding this Privacy Policy, User can email us at our grievance email address <a href='mailto:contactus@olineoindia.com' target='_blank' rel="noreferrer" >contactus@olineoindia.com</a>.
                    In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:

                </p>
            </section>
            <section className='secondSection'>
                <span className='secondSectionHeader'>Grievance Officer:</span>
                <ul>
                    <li>
                        Name: <b><i>Anjali Jha</i></b>
                    </li>
                    <li>
                        Designation: <b><i>Customer Care Executive</i></b>
                    </li>
                    <li>Address:<b><i>A-501, Neelkanth Business Park, Near Vidyavihar Skywalk, Vidyavihar (W), Mumbai - 400086</i></b></li>
                    <li>   Email address: <b><i><a href='mailto:contactus@olineoindia.com' target='_blank' rel="noreferrer" >contactus@olineoindia.com</a></i></b></li>
                    <li>Support Timings: <b><i>From 10 AM - 6 PM (Except Sunday)</i></b></li>

                </ul>
            </section>
        </div>
    )
}

export default PrivacyPolicy
