import React from "react";
import { useTranslation } from "react-i18next";

// Component definitions
const OrderSection = ({
    totalOrders,
    statusSelected,
    trackOrder,
    handleInvoiceDownload,
    setStatusSelected,
    profile
}) => {
    const { t } = useTranslation();

    const active = {
        transition: "all 0.2s ease-in-out",
        backgroundColor: "#1b325e",
        color: "white"
    }

    return (
        <>
            {profile && <div className="order-Filter-Profile">
                <div style={statusSelected === "placed" ? active : null} onClick={() => statusSelected !== "placed" && setStatusSelected(() => "placed")}>{t('placed')}</div>
                <div style={statusSelected === "cancel" ? active : null} onClick={() => statusSelected !== "cancel" && setStatusSelected(() => "cancel")}>{t('canceled')}</div>
            </div>}
            {totalOrders[statusSelected]?.map((order, index) => (
                <div className="order-container" key={index}>
                    {order?.productDetails.map((details, i) => (
                        <div className="order-body">
                            <div>
                                <p className="order-name">{details?.name}</p>
                                <p className="order-color">{details?.color}</p>
                            </div>
                            <div className="order-price">
                                {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                }).format(order?.productPrice[i])}
                            </div>
                            <div className="order-quantity">{order?.item[i]}</div>
                            <div className="order-arrival">
                                <p className="order-arrival-text">
                                    {order?.isPlaced ? "Arriving at 21st March" : t('canceled')}
                                </p>
                                <p className="order-arrival-placed">
                                    {order?.isPlaced && t("orderPlaced")}
                                </p>
                            </div>
                            <div className="order-image">
                                <img
                                    src={details?.image}
                                    alt={details?.name ?? "Product Image"}
                                />
                            </div>
                        </div>
                    ))}
                    {order?.isPlaced && (
                        <div className="order-footer">
                            <div onClick={() => trackOrder(order?._id)}>
                                {t("track-order")}
                            </div>
                            <button onClick={() => handleInvoiceDownload(order._id)}>
                                {t("downloadInvoice")}
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default OrderSection;
