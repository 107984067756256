import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { saveUserDetails } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";

import FB from "../../assets/bharatmahostav/Asset 52.png";
import INSTA from "../../assets/bharatmahostav/Asset 54.png";
import TWITTER from "../../assets/bharatmahostav/Asset 53.png";
import YOUTUBE from "../../assets/bharatmahostav/youtube.png";
import SVGLoader from "./SVGLoader";

import Lakh5primzeimg from "../../assets/IFD/5 LAKH.png"


function Page1() {
  const { changeOfferId, IFDUserId, changeIFDUserId } = useContext(OfferContext);
  const [userInfo, setuserInfo] = useState({
    name: "",
    phone: "",
    email : "",
    dob : ""
  });

  const [isIconClicked, setIsIconClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function validateName(name) {
    let regex = /^[a-zA-Z ]{2,30}$/;
    return !regex.test(name);
  }

  function validatePhone(phone) {
    return !/^\d{10}$/.test(phone);
  }

  function validateEmail(email) {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !regex.test(email);
  }

  function validateDob(dob) {
    let regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    return !regex.test(dob);
  }

  const validateData = () => {
    let isValid = true;
    if (!userInfo.name || !userInfo.phone) {
      isValid = false;
    }
    return isValid;
  };

  const onContinueIFDsaveuser = async () => {
    const payload = {
      name: userInfo.name,
      phone: userInfo.phone,
      email: userInfo.email,
      dob: userInfo.dob
    };
    try {
      setLoading(true);
      if (validateName(userInfo.name)) {
        return toast.error("Invalid Name");
      }
      if (validatePhone(userInfo.phone)) {
        return toast.error("Invalid Phone Number");
      }
      if(validateEmail(userInfo.email)){
        return toast.error("Invalid Email");
      }
      // if(validateDob(userInfo.dob)){
      //   return toast.error("Invalid DOB");
      // }

      const data = await saveUserDetails(payload);
      if (data?.data?.status === "error" || data?.status === 403) {
        toast.error(data?.data?.message ?? "Something went wrong");
      } else {
        data.IFDUserId && changeIFDUserId(data.IFDUserId);
        // data.OfferUserID && changeOfferId(data.OfferUserID);
        toast.success(data?.message);
        navigate("/indianfestivaldays2024/2");
      }

    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  const onContinue = async () => {
    const payload = {
      name: userInfo.name,
      phone: userInfo.phone,
    };
    try {
      setLoading(true);
      if (validateName(userInfo.name)) {
        return toast.error("Invalid Name");
      }
      if (validatePhone(userInfo.phone)) {
        return toast.error("Invalid Phone Number");
      }

      if(validateEmail(userInfo.email)){
        return toast.error("Invalid Email");
      }

      const data = await saveUserDetails(payload);
      if (data?.data?.status === "error" || data?.status === 403) {
        toast.error(data?.data?.message ?? "Something went wrong");
      } else {
        data.OfferUserID && changeOfferId(data.OfferUserID);
        toast.success(data?.message);
        navigate("/quiz-Offer/2");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function handleIconCLick() {
    setIsIconClicked(true);
  }

  return (
    <div
      className="container position-relative conatiner-parent"
      style={{
        display: "grid",
        placeContent: "center",
      }}
    >
      {/* <img alt="thank-you" src={ThankYou}  className="thankYouImageMainPage" /> */}
      {/* <h3 >1. Start with filling your information here:</h3> */}
      <div className="input-group mt-2">
        <TextField
          type="text"
          size="small"
          className="ifd24text fw-700"
          label="Customer's Name *"
          variant="filled"
          value={userInfo.name}
          onChange={(e) => {
            setuserInfo({ ...userInfo, name: e.target.value });
          }}
          inputMode="text"
        />
      </div>
      <div className="input-group mt-2">
        <TextField
          type="number"
          size="small"
          className="ifd24text mt-2 fw-700"
          label="Customer's Number *"
          variant="filled"
          value={userInfo.phone}
          onChange={(e) => {
            setuserInfo({ ...userInfo, phone: e.target.value });
          }}
          inputMode="numeric"
        />
        <br />
      </div>

        {/* Added field for ifd 2024 */}
        <div className="input-group mt-2">
          <TextField
            type="email"
            size="small"
            className="ifd24text mt-2 fw-700"
            label="Customer's Email *"
            variant="filled"
            value={userInfo.email}
            onChange={(e) => {
              setuserInfo({ ...userInfo, email: e.target.value });
            }}
          />
          <br />
        </div>
        {/* <div className="input-group ">
          <TextField
          style={{height: '56px'}}
            type="date"
            size="small"
            className="form-control mt-2 fw-700"
            label="Customer's DOB"
            variant="filled"
            value={userInfo.dob}
            onChange={(e) => {
              setuserInfo({ ...userInfo, dob: e.target.value });
            }}
            fullWidth
            sx={{ 
              height: '31px',  // Adjust this value as needed
              '& input': { height: '31px' },  // Ensure the input inside adjusts to auto height
            }}
          />
          <br />
        </div> */}
        {/* Added field for ifd 2024 */}


      {/* <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
        <div className="fw-bolder text-uppercase" style={{ color: "#2e457d" }}>
          Follow Us
        </div>
        <div className="d-flex gap-2 justify-content-center align-items-center my-2">
          <a href="https://www.facebook.com/OLineOIndia/" target="_blank" rel="noreferrer" onClick={handleIconCLick}>
            <img alt="facebook" src={FB} width={40} />
          </a>
          <a onClick={handleIconCLick} className="pl-2 " href="https://www.instagram.com/olineoindia/" target="_blank" rel="noreferrer">
            <img alt="insta" src={INSTA} width={40} />
          </a>
          <a onClick={handleIconCLick} href="https://twitter.com/olineoindia" target="_blank" className="pl-2 " rel="noreferrer">
            <img alt="twitter" src={TWITTER} width={40} />
          </a>
          <a onClick={handleIconCLick} href="https://www.youtube.com/@OLineOIndia" target="_blank" className="pl-2 icon-container" rel="noreferrer">
            <img alt="youtube" src={YOUTUBE} width={40} />
          </a>
        </div>
      </div> */}


      <button onClick={onContinueIFDsaveuser} type="button" className="btn btn-warning mt-3 mb-2 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={!validateData() || loading}>
        {loading ? <SVGLoader /> : <span>NEXT</span>}
      </button>

      <div className="prize-logo d-flex justify-content-center" style={{ margin:"auto", marginTop: "0px" }}>
          <img src={Lakh5primzeimg} alt="prize-logo" style={{marginTop:"10px"}} />
      </div>

      {/* <button onClick={onContinue} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={!validateData() || loading}>
        {loading ? <SVGLoader /> : <span>Continue</span>}
      </button> */}
    </div>
  );
}

export default Page1;
