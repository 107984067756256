import React, { useEffect } from "react";

function Mappelmap({ stores }) {
  useEffect(() => {
    if (!stores) return;
    let map;
    const filteredStores = stores.filter((store) => store.latitude !== "" && store.longitude !== "");
    const geoJsonFeatures = filteredStores.map((store) => ({
      type: "Feature",
      properties: {
        icon: "https://apis.mapmyindia.com/map_v3/1.png",
        htmlPopup: `Store Name : ${store.display_name}`,
      },
      geometry: {
        type: "Point",
        coordinates: [Number.parseFloat(store.latitude), Number.parseFloat(store.longitude)], // Longitude first, then latitude
      },
    }));

    const mappls = window.mappls;

    map = new window.mappls.Map("map", {
      center: [18.61, 77.23],
      zoomControl: true,
      location: true,
    });

    const newGeoData = {
      type: "FeatureCollection",
      features: geoJsonFeatures,
    };

    const MultMarker = mappls.addGeoJson({
      map: map,
      data: newGeoData,
      fitbounds: true,
      cType: 0,
    });
  }, [stores]);

  return <div id="map" style={{ width: "100%", height: "100%" }}></div>;
}

export default Mappelmap;
