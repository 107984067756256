import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAddress } from "../../api/Address";
import AddressForm from "../../components/AddressForm/AddressForm";
import NewAddressForm from "../../components/NewAddress/NewAddress";
import useSiteCtx from "../../hooks/useSiteCtx";
import useUserCtx from "../../hooks/useUserCtx";

const EditAddress = ({ editID }) => {
    const { setHeaderData } = useSiteCtx();

    const loc = useLocation();
    const [, setEditAddress] = useState({});

    const { userAddress, setUserAddress } = useUserCtx();

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Edit Address",
            categoriesCond: false,
        });

        userAddress.address.forEach((address) => {
            if (address.id === editID) {
                setEditAddress(address);
            }
        });
    }, []);

    useEffect(() => {
        getAddress().then((res) => {
            if (res) {
                setUserAddress({
                    loaded: true,
                    no_of_address: res.no_of_address,
                    address: res.address,
                });
            }
        });
    }, []);
    
    return (
            <NewAddressForm
                editID={editID}
                addressProp={loc.state}
            />
    );
};

export default EditAddress;
