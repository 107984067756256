import React, { useTransition } from "react";
import CustomerSupportImage from "../../assets/aboutUs/CustomerSupportImage.svg";
import ChatBotDummyImage from "../../assets/aboutUs/ChatBotDummyImage.svg";
import "./CustomerSupport.css";
import WriteToUS from "./WriteToUs";
import { useMediaQuery } from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import SEO from "../../components/SEO/SEO";
import useSiteCtx from "../../hooks/useSiteCtx";

function NewCustomerSupport() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 800px)");
  const { seoData } = useSiteCtx();

  const breadCrumData = [
    {
      url: "/",
      text: t(`home`),
    },
    {
      text: t(`customerSupport`),
      isActive: true,
    },
    // {
    //   url: `/Category=${location.search.split("?")[1].split("=")[1]}`,
    //   text: decodedBrandName,
    // },
    // {
    //   isActive: true,
    //   text: location.pathname.split("/")[2],
    // },
  ];
  return (
    <div className="parentClassCustomerSupport" style={{ padding: "0px 50px" }}>
      <SEO seoData={seoData} />

      {/* <div className="breadCrumWrapper"> */}
      {/* </div>   */}
      <div className="CustomerSupportHeader">
        <BreadCrumbs data={breadCrumData} />
      </div>

      <div className="CustomerSupportMainSection" style={{ flexDirection: isMobile ? "column" : "row" }}>
        <div style={{ display: isMobile ? "flex" : "block" }}>
          <img className="CustSuppoLeftimg" style={{ width: !isMobile ? "80%" : "48%" }} src={CustomerSupportImage} alt="Customer support" />
        </div>

        <WriteToUS />
      </div>
    </div>
  );
}

export default NewCustomerSupport;
