import axios from "./axiosInstance";

// ORDER INITIALIZATION +++++++++++++++++++++++++++++++++++++++
export const initOrder = async (data) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  let initResponse;

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let initBody = {
    productId: data.productId,
    quantity: data.quantity,
    shippingAddressId: data.shippingAddressId,
    coupon: data.coupon,
    type: "",
    storeId: data.storeId,
    claimGst: data.claimGst,
    hasOnsitego: data.onsiteGoPlan,
    giftCoupon: data?.giftCoupon,
  };
  if (initBody.type === "Manual") {
    initBody.brand_store_id = data.brand_store_id;
    initBody.manualCnmtReceive = data.manualCnmtReceive;
  }
  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/order/init`, JSON.stringify(initBody), { headers }).then((res) => {
    initResponse = res.data.data.order;
  });

  return initResponse;
};

//Test comment to include file in commit - 01/10/2022

// ORDER PLACED +++++++++++++++++++++++++++++++++++++++++++++++++++
export const completeOrder = async (id) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  let completeOrderResponse;

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let completeBody = {
    orderId: id,
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/order`, JSON.stringify(completeBody), { headers }).then((res) => {
    completeOrderResponse = res;
  });

  return completeOrderResponse;
};

//GET ALL ORDERS +++++++++++++++++++++++++++++++++++++++++++
export const getAllOrder = async (token) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  let JWT = token ? token : userToken;

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${JWT}`,
  };

  let allOrderResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/order/`, { headers }).then((res) => {
    allOrderResponse = res?.data?.data;
  });
  // console.log(allOrderResponse);
  return allOrderResponse;
};

// GET INDIVIDUAL ORDER Details ++++++++++++++++++++++++++++++++++++++
export const getIndiOrder = async (id) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let indiOrderResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/order/${id}`, { headers }).then((res) => {
    indiOrderResponse = res;
    // console.log(indiOrderResponse);
  });

  return indiOrderResponse;
};

//Get Individual Order Status +++++++++++++++++++++++++++++
export const getOrderStatus = async (id) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let orderStatusResponse;
  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/order/status/${id}`, { headers }).then((res) => {
    orderStatusResponse = res.data.data;
    // console.log(orderStatusResponse);
  });

  return orderStatusResponse;
};

// Cancel Order
/* export const cancelOrder = async (order_Id, query) => {
    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    let data = {
        orderId: order_Id,
        reason: query,
    };

    let orderDeleteResponse;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/cancel`, JSON.stringify(data), { headers }).then((res) => {
        orderDeleteResponse = res;
    });
    return orderDeleteResponse;
}; */

//Payment Init
export const paymentInit = async (id) => {
  //returns a link for the payment page
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let paymentInitResponse;
  await axios.get(`${process.env.REACT_APP_BASE_URL}/payment/init/${id}`, { headers }).then((res) => {
    paymentInitResponse = res.data.link;
  });

  return paymentInitResponse;
};

export const downloadInvoice = async (orderId) => {
  let obj = { orderId: orderId };
  let data;
  await axios(`${process.env.REACT_APP_BASE_URL}/webhooks/getinvoice`, {
    method: "POST",
    responseType: "blob", //Force to receive data in a Blob Format,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(obj),
  }).then((res) => {
    data = res.data;
  });
  return data;
};

//GET PARTICULAR USER ORDERS +++++++++++++++++++++++++++++++++++++++++++
export const getUserOrders = async (token) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  let JWT = token ? token : userToken;

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${JWT}`,
  };

  let userOrders;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/order/user`, { headers })
    .then((res) => {
      userOrders = res.data;
    })
    .catch((err) => (userOrders = err.response));

  return userOrders;
};

export const sendSmsToRetailers = async (ConsignmentIds) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  let JWT = userToken ? userToken : "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${JWT}`,
  };

  let smsSent;

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/sendsmstoStore`, ConsignmentIds, { headers })
    .then((res) => {
      smsSent = res.data;
    })
    .catch((err) => (smsSent = err.response));

  return smsSent;
};

// Cancel Order Item / Consignment
export const cancelOrderItem = async (orderProdId, query, cancelled_by = "consignee") => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let data = {
    orderProdId,
    reason: query,
    cancelled_by,
  };

  let orderDeleteResponse;

  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/cancel/consignment`, JSON.stringify(data), { headers });
  orderDeleteResponse = response;
  return orderDeleteResponse;
};

// API to fetch tracking details of the order
export const getTrackingDetails = async (orderProdId) => {
  const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/track/consignment/${orderProdId}`, { headers });

  return response;
};

// API to fetch tracking details of the order
export const setOnsiteoConsignment = async (orderId) => {
  const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/order/consignment/${orderId}`, { headers });

  return response;
};
