import React, { useEffect, useState } from "react";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { addWalletCupon, deleteSingleWalletCupon, getSingleWalletCupon, getWalletCupon } from "../../api/AdminApis/Cupon";
import EditWalletCuponModal from "./EditWalletCuponModal";
import { FaRegEdit } from "react-icons/fa";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { formatDate } from "../../utils";
import { downloadUserWiseWalletCsv } from "../../api/Product";
import * as XLSX from "xlsx";

function WalletCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState([]);
  const [couponData, setCouponData] = useState({});
  const [singleCoupn, setSingleCoupn] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAllWalletCoupons();
  }, []);

  const getAllWalletCoupons = async () => {
    setLoading(true);
    const res = await getWalletCupon();
    if (res) {
      setCoupons(res);
    }
    setLoading(false);
  };

  const handleEdit = async (couponeId) => {
    getSingleWalletCupon(couponeId).then((res) => {
      setSingleCoupn(res);
      setShow(true);
    });
  };

  const deleteCouopne = async (couponeId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteSingleWalletCupon(couponeId).then((res) => {
          if (res.status === 201) {
            toast.success(res.data.message);
            getAllWalletCoupons();
          } else {
            toast.error("Fail To delete This Coupone");
          }
        });
      } else {
        return;
      }
    });
  };

  const handleCloseModal = () => {
    getAllWalletCoupons();
    setShow(false);
  };

  const handleOpenModal = () => {
    setSingleCoupn({});
    setShow(true);
  };

  const handleDownlaod = async () => {
    const res = await downloadUserWiseWalletCsv();
    if (res) {
      const csvData = res; // Replace this with your actual CSV data
      downloadFile(csvData);
    }
  };

  function downloadFile(data) {
    const filename = `User_Coupons.xlsx`;

    // Transform data into a format suitable for XLSX
    const userData = {};
    data.forEach((item) => {
      const { code, fullName, mobileNumber, orderId, status } = item;
      if (!userData[fullName]) {
        userData[fullName] = {
          fullName,
          mobileNumber,
          [code]: orderId || "Active",
        };
      } else {
        userData[fullName][code] = orderId || "Active";
      }
    });

    const formattedData = Object.values(userData).map((user) => ({
      fullName: user.fullName,
      mobileNumber: user.mobileNumber,
      ...user,
    }));

    // Create the XLSX file
    var ws = XLSX.utils.json_to_sheet(formattedData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "User Coupons");
    XLSX.writeFile(wb, filename);
  }

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end">
        <button className="btn-sm btn btn-primary" onClick={() => handleDownlaod()}>
          Download csv
        </button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-sm btn-success" onClick={() => handleOpenModal()}>
          Add New Coupon
        </button>
      </div>
      {!loading ? (
        <>
          <div className="table-responsive mb-2 mt-3">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sr.No.</th>
                  <th scope="col">Code</th>
                  <th scope="col">Value</th>
                  <th scope="col">Status</th>
                  <th scope="col">Text</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {coupons?.length > 0 &&
                  coupons?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item.code.toUpperCase() ?? "-"}</td>
                      <td>{item.value ?? "-"}</td>
                      <td>{item.status ?? "-"}</td>
                      <td>{item.text ?? "-"}</td>
                      <td style={{ width: "250px" }}>{formatDate(item.expiresAt) ?? "-"}</td>
                      <td className=" " style={{ gap: "10px", alignContent: "center", width: "90px" }}>
                        <button type="button" style={{ color: "green" }} class="btn btn-sm " onClick={() => handleEdit(item._id)}>
                          <FaRegEdit size={18} />
                        </button>
                        <button className="btn  btn-sm" style={{ color: "red" }} onClick={() => deleteCouopne(item._id)} title="Delete Notification">
                          <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <DashboardLoader />
      )}

      {show && <EditWalletCuponModal open={show} onClose={() => handleCloseModal()} data={singleCoupn} />}
    </div>
  );
}

export default WalletCoupons;
