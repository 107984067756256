// get all users

import axios from "../axiosInstance";

export const allUsers = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allUsers;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin?${query}`, { headers }).then((res) => {
    allUsers = res.data?.data;
  });

  return allUsers;
};

export const allUsersForNotificationPage = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allUsers;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/notification-page-user?${query}`, { headers }).then((res) => {
    allUsers = res.data?.data;
  });

  return allUsers;
};

// get Single user

export const singleUSer = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let singleUserRes;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/${id}`, { headers }).then((res) => {
    singleUserRes = res.data.data;
  });

  return singleUserRes;
};

//   Update User Info

export const updateUseer = async (id, Body) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let updateUser;
  await axios.patch(`${process.env.REACT_APP_BASE_URL}/user/admin/${id}`, Body, { headers }).then((res) => {
    updateUser = res.data.data;
  });
  return updateUser;
};

// delet user

export const deletUser = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let dltuser;
  let text = "Are you sure for delete?";
  if (window.confirm(text) === true) {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/admin/${id}`, { headers }).then((res) => {
      dltuser = res;
    });
  } else {
    alert("User save!");
  }

  return dltuser;
};

//Get User Addresses For Admin
export const getUserAddressForAdminAPI = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allAddresses;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/user-address?userId=${query}`, { headers }).then((res) => {
    allAddresses = res.data?.data;
  });

  return allAddresses;
};

//Get User Excell For Admin
export const downloadUserCsv = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let allAddresses;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/all/downloadUserCsv`, { headers }).then((res) => {
    allAddresses = res.data;
  });

  return allAddresses;
};
