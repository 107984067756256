import React from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import DashboardLoader from '../DashboardContent/DashboardLoader';

const AdminUserDetailsModal = (props) => {
    const { details, loading = false } = props;
    console.log({ details })

    return (
        <Modal
            {...props}
            size="lg"
            className=''
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    User Details
                </Modal.Title>
            </Modal.Header>
            {loading ? (
                <DashboardLoader />
            ) : (
                <Modal.Body>
                    <div className='row mt-2 mb-4'>
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Full Name</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.fullName}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Email Id</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.email}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Contact Number</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.mobileNumber}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='row my-3'>
                        <ListGroup as="ol">
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                Customer Name: <span className='fw-bold'>{details?.address_customerName}</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                Address Line: <span className='fw-bold'>{details?.address_address_line1}</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                City: <span className='fw-bold'>{details?.address_city}</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                Pincode: <span className='fw-bold'>{details?.address_zip}</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                State: <span className='fw-bold'>{details?.address_state}</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                Country: <span className='fw-bold'>{details?.address_country}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Modal.Body>
            )}
        </Modal>
    );
};

export default AdminUserDetailsModal;