import React from "react";

import person_outline from "../../assets/vector/person_outline.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopperSignIn from "./PopperSignIn";
import { useNavigate } from "react-router-dom";
import useSiteCtx from "../../hooks/useSiteCtx";

function SignInButton(props) {
  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const { userLoggedIn } = useSiteCtx();
  const handleClosePOP = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="right_login_comp" style={{ cursor: "pointer" }} onClick={() => navigate("/login")}>
      <div className="personIcon">
        <img
          alt="personIcon"
          src={person_outline}
          style={{
            width: "24px",
            height: "24px",
            marginRight: "6px",
          }}
        />
      </div>
      <p className="right_login">
        <p style={{ marginBottom: "2px", fontSize: "14px" }}>{props.t("header.login")}</p>
        {props.matches768 && props.matches1290 && userLoggedIn && (
          <span className="right_login_acc">
            <p style={{ marginBottom: "0", fontSize: "14px" }}>
              {props.t("login.create-account")}
              <span ref={anchorRef} id="composition-button" aria-controls={open ? "composition-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-haspopup="true" onClick={handleToggle}>
                <ExpandMoreIcon sx={{ color: "white", cursor: "pointer" }} />
              </span>
            </p>
          </span>
        )}
      </p>
      <PopperSignIn isMobile={props.isMobile} open={open} current={anchorRef.current} handleClosePOP={handleClosePOP} handleListKeyDown={handleListKeyDown} />
    </div>
  );
}

export default SignInButton;
