import { useNavigate } from "react-router-dom";
import { useContext, useRef, useMemo, useState, useEffect } from "react";
import { toast } from "react-toastify";
import OfferContext from "../../Contexts/OffersContext";
import spinWheelInner from "../../assets/IFD/spin.png";
// import spinWheelOuter from "../../assets/IFD/spin-outer.png";
import spinWheelOuter from "../../assets/IFD/spin-outer.png";
import spinArrow from "../../assets/IFD/arrow.png";
import spinlogo from "../../assets/IFD/spinlogo.png";
import { revealOffer } from "../../api/OfferAPIs";

// const audioUrl = "https://olineoindia.com/Offers/lagaAudio.mp3";
const audioUrl = "https://olineoindia.com/Offers/Ruk_Ruk_Ruk.mp3";

function SpinNWin() {
    console.log(useContext(OfferContext));
    const { OfferId, changeStoreInformation, IFDUserId } = useContext(OfferContext);
    const navigate = useNavigate();
    const [receivedPrize, setReceivedPrize] = useState(2);
    const wheelInnerRef = useRef(null);
    const [isOfferReceived, setIsOfferReceived] = useState(false);
    
    let audio = null;
    const [isPlayed, setIsPlayed] = useState(false);


    // 1} smartwatch - 10 pcs
    // 2} Bluetooth speaker - 15 pcs
    // 3] TWS - 65 pcs
    // 4] 100 cash back - 8 
    // 5] 200 cash back - 1
    // 6] 500 cash back - 1
    // 7} 1000 cash back 
    // 8} 2000 cash back 
    // 9] 5000 cash back 
    // 10] 25,000 cash back

    const rotationValues = useMemo(() => {
        return [
            {
                prizeIndex: 1, rotation: [2],
            },
            {
                prizeIndex: 2, rotation: [8],
            },
            {
                prizeIndex: 3, rotation: [5]
            },
            {
                prizeIndex: 4, rotation: [9],
            },
            {
                prizeIndex: 5, rotation: [4],
            },
            {
                prizeIndex: 6, rotation: [7],
            },
            {
                prizeIndex: 7, rotation: [1],
            },
            {
                prizeIndex: 8, rotation: [6],
            },
            {
                prizeIndex: 9, rotation: [3],
            },
            {
                prizeIndex: 10, rotation: [10],
            },
        ]
    }, []);

    // const rotate = rotationValues.find(e => e.prizeIndex === receivedPrize).rotation[0];
    const playAudioInLoop = () => {
        audio = new Audio(audioUrl);
        audio.volume = 0.8;
        audio.play();
    };

    useEffect(() => {
        // (async () => {
        //     try {
        //         // const data = await revealOffer(OfferId);
        //         const data = await revealOffer(IFDUserId);
        //         if (data?.status === "error") {
        //             toast.error(data?.message ?? "Something went wrong");
        //             navigate("/indianfestivaldays2024");
        //         }
        //         else {
        //             if (data?.offerIndex) {
        //                 setReceivedPrize(data.offerIndex);
        //                 changeStoreInformation({
        //                     userId: IFDUserId,
        //                     offerId: data.offerIndex,
        //                     offerLink: data.imageUrl
        //                 });
        //             }
        //             else toast.error("Something went wrong");
        //         }
        //     } catch (error) {
        //         toast.error("Something went wrong");
        //     }
        // })();

        return () => {
            if (audio) {
                audio.pause();
            }
        };
    }, []);

    const callrevelofferapi = async () => {
        try {
            // const data = await revealOffer(OfferId);
            const data = await revealOffer(IFDUserId);
            if (data?.status === "error") {
                toast.error(data?.message ?? "Something went wrong");
                navigate("/indianfestivaldays2024");
            }
            else {
                if (data?.offerIndex) {
                    setReceivedPrize(data.offerIndex);
                    changeStoreInformation({
                        userId: IFDUserId,
                        offerId: data.offerIndex,
                        offerLink: data.imageUrl
                    });
                    const rotate = rotationValues.find(e => e.prizeIndex === data.offerIndex).rotation[0];
                    spinFunc(rotate);
                }
                else toast.error("Something went wrong");
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    function spinFunc(rotate) {
        if (!wheelInnerRef.current) return
        wheelInnerRef.current.classList.toggle("wheel__moving");
        wheelInnerRef.current.style.setProperty('--new-rotate-value', `${Number(36 * rotate) + 3600 - 18}deg`);
        setIsOfferReceived(true);
        playAudioInLoop();
        setIsPlayed(true);
    }

    useEffect(() => {
        if (isOfferReceived === false) return
        const delay = 14000;

        const redirectTimeout = setTimeout(() => {
            navigate('/indianfestivaldays2024/5');
        }, delay);

        return () => {
            clearTimeout(redirectTimeout);
        };
    }, [isOfferReceived]);


    return (
        <div className="container mt-4 sub-container text-center">
            <div className="prize-container d-flex justify-content-center align-items-center" style={{height:"340px"}}>
                {/* <img alt="arrow" className="spinArrow" src={spinArrow} />
                <img alt="spinWheelOuter" className="spinWheelOuter" src={spinWheelOuter} />
                <img alt="spinWheelInner" ref={wheelInnerRef} className="spinWheelInner" src={spinWheelInner} />
                <img alt="spinlogo" className="spinlogo" src={spinlogo} /> */}
                <img alt="arrow" className="spar" src={spinArrow} />
                <img alt="spinWheelOuter" className="spwo" src={spinWheelOuter} />
                <img alt="spinWheelInner" ref={wheelInnerRef} className="spwi" src={spinWheelInner} />
                <img alt="spinlogo" className="splo" src={spinlogo} />

            <button
                onClick={callrevelofferapi}
                type="button"
                className="btn btn-warning fw-bolder page_1_submit_btn fourth-page-btn mb-4 mt-4" 
                style={{
                    width: "120px",
                    position: "absolute",
                    zIndex: 99999,
                    top: "325px"
                }}
                disabled={isPlayed}
                id="submit-otp-btn"
                >
                <span>SPIN NOW</span>
            </button>
                </div>
        </div>
    );
}



export default SpinNWin;