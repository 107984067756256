import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getOfferImageURLAPI } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";
import Stage from "../../assets/IFD/Stage.png";
import StageCircle from "../../assets/IFD/Stage_Circle.png";
import ReactCanvasConfetti from "react-canvas-confetti";

// const audioUrl = "https://olineoindia.com/Offers/IFD_Sound.mp3";
const audioUrl = "https://olineoindia.com/Offers/DUM_MARO_DUM_HARE_RAMA.mp3";

const LEFT_STYLE = {
    1: "8px",
    2: "-4px",
    3: "-4px",
    4: "-10px",
    5: "10px",
    6: "0px",
    7: "6px",
    8: "-10px",
    9: "-12px",
    10: "1px"
};

function OfferReveal() {
    const { OfferId, getOfferDetails, IFDUserId } = useContext(OfferContext);
    // const IFDUserId = "66fe5aceacc8a5b08dec24e0";
    const navigate = useNavigate();

    const [receivedPrize, setReceivedPrize] = useState('');
    const [prizeIndex, setPrizeIndex] = useState('');
    let audio = new Audio(audioUrl);

    useEffect(() => {
        (async () => {
            try {
                const data = await getOfferImageURLAPI(IFDUserId,"yes");
                if (data?.status === "error") {
                    toast.error(data?.message ?? "Something went wrong");
                    navigate("/indianfestivaldays2024");
                }
                else {
                    if (data?.imageURL) {
                        const offerURL = data.imageURL;
                        setReceivedPrize(offerURL);
                        setPrizeIndex(data.prizeIndex);
                        playAudioInLoop();
                    }
                    else toast.error("Something went wrong");
                }
            } catch (error) {
                toast.error("Something went wrong");
            }
        })();

        const offerDetails = getOfferDetails();

        const expectedKeys = ["userId", "offerId", "offerLink"];

        const hasExpectedKeys = Object.keys(offerDetails).every(key => expectedKeys.includes(key)) &&
            expectedKeys.every(key => offerDetails.hasOwnProperty(key));

        if (!hasExpectedKeys) {
            toast.error("Something went wrong");
            navigate("/indianfestivaldays2024");
        } else {
            const offerURL = offerDetails.offerLink;
            if (!offerURL) {
                toast.error("Something went wrong");
                navigate("/indianfestivaldays2024");
            }
            setReceivedPrize(offerURL);
            playAudioInLoop();
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.removeEventListener('ended', playAudioInLoop);
            }
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            navigate('/indianfestivaldays2024/6');
        }, 27000);
    });

    const playAudioInLoop = () => {
        audio.volume = 0.8;

        const playAudio = () => {
            audio.currentTime = 0;
            audio.play();
        };

        audio.addEventListener('ended', playAudio);
        audio.play();
    };


    const refAnimationInstance = useRef(null);

    const canvasStyles = {
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      };

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
      }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
          refAnimationInstance.current({
            ...opts,
            origin: { y: 0.55 },
            particleCount: Math.floor(500 * particleRatio),
          });
      }, []);


    useEffect(() => {
        setTimeout(() => {
          fire();
        }, 200);
        setTimeout(() => {
          fire();
        }, 450);
        setTimeout(() => {
          fire();
        }, 700);
      }, []);
    
    const fire = useCallback(() => {
    makeShot(0.6, {
        spread: 80,
        startVelocity: 70,
        // gravity: 0.2,
        ticks: 600,
        decay: 0.9,
    });

    makeShot(0.35, {
        startVelocity: 60,
        spread: 50,
        decay: 0.9,
        ticks: 600,
        scalar: 1.2,
    });

    makeShot(0.2, {
        spread: 80,
        startVelocity: 30,
        ticks: 600,
        decay: 0.92,
        scalar: 1,
    });
    }, [makeShot]);

    return (
        <>
            <div className="container sub-container text-center">

                <p className="m-0" style={{color:"#FBCF11", fontSize:"35px"}}><b>CONGRATULATIONS</b></p>
                <p style={{color:"#FBCF11", fontSize:"25px"}}>You are Eligible For</p>

                <div className="d-flex justify-content-center offer-reveal-wrapper offerrevelimgsetting">
                    <img src={receivedPrize} alt="stage_circle" className="prize-img relative" style={{
                        left: LEFT_STYLE[prizeIndex?.toString()] 
                    }} />
                    {/* <img src={StageCircle} alt="stage_circle" className="stage-circle" /> */}
                    {/* <img src={Stage} alt="stage" className="stage-img" /> */}
                </div>
                
                

                {/* <button
                    onClick={() => navigate('/ifd')}
                    type="button"
                    className="btn btn-warning mt-3 mb-5 fw-bolder relative page_3_btn"
                    id="submit-otp-btn"
                    style={{
                        width: "70%",
                        background: "var(--primaryYellow)",
                        color: "black"
                    }}
                >
                    <span>Follow Us</span>
                </button> */}
                <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
            </div>
        </>
    );
}



export default OfferReveal;