//CSS
import "./AddressBox.css";

//images
import editIcon from "../../assets/vector/edit_outline_blue.svg";
import deleteIcon from "../../assets/vector/delete_outline_blue.svg";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";

const AddressBox = ({ classes, address, setProfileState, deleteOption = true, defaultOption = true, border, fullWidth = false, handleDeleteAddress, handleSetAsDefaultAddress }) => {
  const { width } = useWindowDimensions();
  const {t} = useTranslation();
  const { editID, setEditID, addressSelected, setAddressSelected } = useUserCtx();

  const matches = width >= 768;

  return (
    <div className={`address section_Wrapper ${!border ? "border-0" : ""} ${fullWidth ? "w-100" : ""} ${classes ? classes.boxWrapperClass : ""} `}>
      <div className="address_Box">
        <div className="address_Box_Wrapper_Section">
          <div className="address_Box_Wrapper" >
            <p className="address_Box_Name">{address.customerName}</p>
            <p>
              {address.address_line1}, {address.city}, {address.state} - {address.zip}
            </p>
            <p>{address.phone}</p>
          </div>
        {defaultOption && (
          <div className={`address_Default_Button ${address.isDefault ? "disable" : ""}`} onClick={() => handleSetAsDefaultAddress(address._id)}>
            <button type="submit" className="submit-button">
              <p>{t('address.set-default')}</p>
            </button>
          </div>
        )}
        </div>

        <div className="address_Box_Footer">
          {deleteOption && (
            <div className="address_Footer_Delete" onClick={() => handleDeleteAddress(address._id)}>
              <img src={deleteIcon} alt="" />
              <p>{t('address.delete-address')}</p>
            </div>
          )}
          {matches ? (
            <Link
              to={"/editaddress"}
              state={address}
              className="address_Footer_Edit"
              onClick={() => {
                setEditID(address.id);
              }}
            >
              <img src={editIcon} alt="" />
              <p>{t('address.edit-address')}</p>
            </Link>
          ) : (
            <Link
              to={"/profile"}
              state={address}
              className="address_Footer_Edit"
              onClick={() => {
                setEditID(address.id);
                setProfileState(11);
              }}
            >
              <img src={editIcon} alt="" />
              <p>{t('address.edit-address')}</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressBox;
