import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "./Map";
import Marker from "./Marker";

const MapWrapper = ({ center, markerPositions, zoomLevel }) => {
    const zoom = zoomLevel ?? 5;
    return (
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY}>
            <Map
                center={center}
                zoom={zoom}>
                {/* <Marker position={center} /> */}
                {markerPositions.map((postion, index) => (
                    <Marker position={postion} key={`Map_${index}`} />
                ))}
            </Map>
        </Wrapper>
    );
};

export default MapWrapper;
