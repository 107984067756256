import { useState } from "react";

export const useToken = (token_name) => {
    const [token, setTokenInternal] = useState(() => {
        return localStorage.getItem(token_name)
    });

    const setToken = newToken => {
        localStorage.setItem(token_name, newToken)
        setTokenInternal(newToken)
    }

    return [token, setToken]
}