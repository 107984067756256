import { Box, Button, ButtonGroup, Fade, FormGroup, TextField } from "@mui/material";
import Modal from '@mui/material/Modal';
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from '@mui/material/Backdrop';
import { fetchAConsignment, sendOTPForManualOrder, verifyOTPForManualOrder } from "../../api/Retailer";
import { toast } from "react-toastify";

function PickUpFromStoreModal({ open, handleClose, setOpenModal, isMobile, pickupConsignmentId, setManualDeliveryId }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? "360px" : 420,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 0,
        padding: 0,
        outline: "none",
    };

    const [step, setStep] = useState(1);
    const [otp, setOTP] = useState(null);
    const [error, setError] = useState(true);
    const responseSuccessRef = useRef(null);
    const responseErrorRef = useRef(null);
    const [info, setInfo] = useState({
        name: null,
        mobileNumber: null,
        productEan: null,
        productTitle: null
    });

    useEffect(() => {
        setManualDeliveryId(null);
    }, [])

    useEffect(() => {
        if (!pickupConsignmentId) return
        fetchAConsignment(pickupConsignmentId).then(res => {
            if (res.data?.status !== "success") {
                responseErrorRef.current.innerHTML = `Error while Fetching Consignment: ${res.data?.message}`
                responseSuccessRef.current.innerHTML = ""
            }
            const details = res.data?.consignemtDetails;
            setInfo(prev => ({ ...prev, name: details?.consignee?.fullName, mobileNumber: details?.consignee?.mobileNumber, productEan: details?.product?.ean, productTitle: details?.product?.title }))
        })
    }, [pickupConsignmentId]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setError(true);
        setStep(1);
        setOTP(null)
    };

    const buttons = [
        <Button key="one" variant={step === 1 ? "contained" : "outlined"}>Send OTP</Button>,
        <Button key="two" variant={step === 2 ? "contained" : "outlined"} disabled={step === 1}>Verify OTP</Button>
    ];


    const handleLength = (length) => {
        if (length === 6) {
            setError(false);
        } else {
            setError(true);
        }
    };

    const validateNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setOTP(e.target.value);
        }
    };

    function handleSendOTP() {
        sendOTPForManualOrder(pickupConsignmentId).then(res => {
            if (res?.status === 200) {
                if(!res?.data?.responsePayload.success) {
                    //OTP Error
                    responseErrorRef.current.innerHTML = `Error while Sending OTP: ${res.data?.responsePayload?.message}`;
                    responseSuccessRef.current.innerHTML = "";
                } else {
                    //Success
                    responseErrorRef.current.innerHTML = "";
                    responseSuccessRef.current.innerHTML = `${res.data?.responsePayload?.message}`;
                    setStep(2);
                }
            } else if(res.data?.status === "error") {
                    //Custom Error
                    responseErrorRef.current.innerHTML = ` ${res.data?.message}`;
                    responseSuccessRef.current.innerHTML = "";
            }
            else {
                responseErrorRef.current.innerHTML = "Something went wrong"
            } 
        })
    }

    function handleVerifyOTP() {
        verifyOTPForManualOrder(otp, pickupConsignmentId).then(res => {
            if (res.data?.status === "error") {
                //Error
                responseSuccessRef.current.innerHTML = "";
                responseErrorRef.current.innerHTML = `${res.data?.message}`;
                setStep(1);
                setOTP(null);
            } else {
                    //Success
                    responseErrorRef.current.innerHTML = "";
                    responseSuccessRef.current.innerHTML = `${res.data?.message}`;
                    setStep(1);
                    setOTP(null);
                    setError(true);
                    setManualDeliveryId(pickupConsignmentId);
                    handleCloseModal();
                    toast.success("OTP Validation Successfully done! The Consignment will be marked as Delivered.")
            }
        })
        
    }

    return !pickupConsignmentId ? null
        :
        (
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ zIndex: "1000000" }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 200,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="locationModalContainer" id="modal-modal-title" >
                            <h1 className="locationModalHeader" >Deliver Consignment From Store</h1>
                            <p className="locationModalpara" id="modal-modal-description">
                                Verify OTP that will be sent to the Customer to complete the Delivery Process From Store
                            </p>
                            <p className="locationModalpara" style={{ fontSize: "1rem", marginBlock: "1rem" }}>Consignment ID: {pickupConsignmentId}</p>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    '& > *': {
                                        m: 1,
                                    },
                                    
                                    width: "80%",
                                    margin: "auto",
                                    marginBottom: "1rem",
                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"   
                                }}
                                noValidate
                                autoComplete="off"
                            >

                                <ButtonGroup size="medium" aria-label="medium button group">
                                    {buttons}
                                </ButtonGroup>

                                {step === 1 &&
                                    <div>
                                        <p>Name: {info.name}</p>
                                        <p>Phone Number: {info.mobileNumber}</p>
                                        <p>Product Ean: {info.productEan}</p>
                                        <p>Product Title: {info.productTitle}</p>
                                        <Button color="secondary" size="small" variant={"contained"} onClick={handleSendOTP}>Send OTP</Button>
                                    </div>
                                }
                                {step === 2 &&
                                    <FormGroup>
                                        <TextField
                                            onChange={(e) => {
                                                validateNumber(e);
                                                handleLength(e.target.value.length);
                                            }}
                                            value={otp}
                                            error={error}
                                            id="outlined-number"
                                            label="Enter OTP"
                                            type="number"
                                            autoFocus
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText="OTP is sent to the Registered Number"
                                        />
                                        <Button color="secondary" size="small" variant={"contained"} onClick={handleVerifyOTP} disabled={error}>Verify OTP</Button>
                                    </FormGroup>
                                }
                            </Box>

                            <CloseIcon onClick={handleCloseModal} className="locationmodalCloseIcon" />
                        </div>
                        <div id="response-success" ref={responseSuccessRef}></div>
                        <div id="response-error" ref={responseErrorRef}></div>
                    </Box>
                </Fade>
            </Modal>
        );
}

export default PickUpFromStoreModal;
