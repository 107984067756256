import axios from "./axiosInstance";

var userInfo = {
  id: "",
  fullName: "",
  mobileNumber: "",
  email: "",
  JWT: "",
  dob: null,
  addressData: [],
  cartData: [],
};

// function update(value) {
//   let prevData = JSON.parse(localStorage.getItem('user'));
//   Object.keys(value).forEach(function (val, key) {
//     prevData[val] = value[val];
//   })
//   localStorage.setItem('user', JSON.stringify(prevData));
// }

//SAVE ADDRESS
export const saveAddress = async (addressData, defaultAdd) => {
  let saveAddressResponse;
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let saveAddressBody = {
    customerName: addressData.customerName,
    phone: addressData.phone,
    address_line1: addressData.address_line1,
    city: addressData.city,
    state: addressData.state,
    zip: addressData.zip,
    landmark: addressData.landmark,
    isDefault: defaultAdd,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/user/address/`, JSON.stringify(saveAddressBody), { headers })
    .then((res) => {
      // console.log(res);
      saveAddressResponse = res;
    })
    .catch((err) => console.log("Error:", err));

  return saveAddressResponse;
};

//GET ADDRESS
export const getAddress = async (JWT) => {
  let getAddressResponse;
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  let tokenUsed = JWT ? JWT : userToken;
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenUsed}`,
  };

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/user/address/`, { headers })
    .then((res) => {
      getAddressResponse = res.data.data;
    })
    .catch((err) => (getAddressResponse = err.response));

  return getAddressResponse;
};

//DELETE ADDRESS
export const deleteAddress = async (addressID) => {
  let deleteAddressResponse;
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/user/address/${addressID}`, { headers })
    .then((res) => {
      deleteAddressResponse = res;
    })
    .catch((err) => console.log(err));

  return deleteAddressResponse;
};

//EDIT ADDRESS
export const editAddress = async (addressID, addressData, defaultAdd) => {
  let editAddressResponse;
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let editAddressBody = {
    customerName: addressData.customerName,
    phone: addressData.phone,
    address_line1: addressData.address_line1,
    city: addressData.city,
    state: addressData.state,
    zip: addressData.zip,
    landmark: addressData.landmark,
    isDefault: defaultAdd,
  };

  await axios
    .put(`${process.env.REACT_APP_BASE_URL}/user/address/${addressID}`, JSON.stringify(editAddressBody), { headers })
    .then((res) => {
      // console.log(res);
      editAddressResponse = res;
    })
    .catch((err) => console.log("Error:", err));

  return editAddressResponse;
};

export const fetchStateAndDisName = async (pinconde) => {
  try {
    let fetchedData;
    const response = await axios.get(`https://api.postalpincode.in/pincode/${pinconde}`);
    console.log("v ", response[0]);
    fetchedData = response.data[0]?.PostOffice[0];
    return fetchedData;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPinCode = async (cityName) => {
  try {
    let fetchedData;
    const response = await axios.get(`https://api.postalpincode.in/postoffice/${cityName}`, {
      headers: {
        "content-type": "application/json",
      },
    });
    // Handle the response data
    fetchedData = response?.data[0]?.PostOffice[0];
    return fetchedData;
  } catch (error) {
    console.error(error);
  }
};

//SET AS DEFAULT ADDRESS
export const setAddressDefault = async (addressId) => {
  let setDefaultAddressResponse;
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  let defaultBody = {
    isDefault: true,
  };

  await axios
    .put(`${process.env.REACT_APP_BASE_URL}/user/address/${addressId}`, JSON.stringify(defaultBody), { headers })
    .then((res) => {
      // console.log(res);
      setDefaultAddressResponse = res;
    })
    .catch((err) => console.log("Error:", err));

  return setDefaultAddressResponse;
};

export const checkAddressStatus = async (userId) => {
  let response;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user.id) {
    let userToken = user ? user.JWT : "";
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };

    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/address/check-default-address-exists/${userId}`, { headers })
      .then((res) => {
        response = res;
      })
      .catch((err) => console.log("Error:", err));
  }
  return response;
};
