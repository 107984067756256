import axios from "../axiosInstance";

export const getAllTerms = async () => {
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };

  let response;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/admin/termsAndConditions`, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const saveOrUpdateTerms = async (data) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/termsAndConditions`, data, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const deletPoint = async (data) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/deleteTermsAndConditions`, data, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};
export const getALLFAQS = async (query) => {
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };

  let response;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/faq/getAllFaq?${query}`, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const getFaqById = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/faq/getFaq/${id}`, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const createorUpdateFaq = async (Id = null, data) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .patch(`${process.env.REACT_APP_BASE_URL}/product/faq/createUpdate/${Id}`, data, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};
export const deleteFaqs = async (Id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/product/faq/DeleteFaq/${Id}`, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};
export const deleteFaqQuestions = async (query) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/faq/DeleteFaqQuestion/?${query}`, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const updateFaqCategoryName = async (id, category) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;
  await axios
    .patch(`${process.env.REACT_APP_BASE_URL}/product/faq/updateCategoryName/${id}`, { category }, { headers })
    .then((res) => {
      response = res.data;
    })
    .catch((err) => (response = err.response));

  return response;
};
