import { Switch } from "@mui/material";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import axios from "axios";


const Showifd24coupon = (props) => {

    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOffersstatus(); 
    }, []);
    const fetchOffersstatus = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/offers/fetchofferstatus`);
            setSwitchStates(response.data.offersstatus);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };
    

    const location = useLocation();
    const couponData = location.state?.couponData;
    console.log("couponData", couponData);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 25;

    // Filtered data based on the search term
    const filteredData = couponData.filter(item => {
        const storeName = item.fc_name.toLowerCase();
        const storeId = item.store_id ? item.store_id.toString() : '';  // Ensure store_id is treated as a string for comparison
        return storeName.includes(searchTerm.toLowerCase()) || storeId.includes(searchTerm);
    });

    // Paginated data
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const formatOffer = (offer, offerId) => {
        if (!offer || offer.offerRemaining == null || offer.offerTotalCount == null) {
            return '';  // Return an empty string if offer is missing
        }
    
        // Format as 'remaining/total' and add date if offerId > 7
        const offerInfo = `${offer.offerRemaining}/${offer.offerTotalCount}`;
        if (offerId > 7 && offer.offerDate) {
            return `${offerInfo} Date: ${offer.offerDate}`;
        }
        return offerInfo;
    };

    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(couponData.map(item => ({
            'Store Name': item.fc_name,
            'Store ID': item.store_id,
            '1-Smartwatch': formatOffer(item.offers.find(o => o.offerId === 1), 1),
            '2-Bluetooth Speaker': formatOffer(item.offers.find(o => o.offerId === 2), 2),
            '3-TWS': formatOffer(item.offers.find(o => o.offerId === 3), 3),
            '4-Cash Back 100': formatOffer(item.offers.find(o => o.offerId === 4), 4),
            '5-Cash Back 200': formatOffer(item.offers.find(o => o.offerId === 5), 5),
            '6-Cash Back 500': formatOffer(item.offers.find(o => o.offerId === 6), 6),
            '7-Cash Back 1000': formatOffer(item.offers.find(o => o.offerId === 7), 7),
            '8-Cash Back 2000': formatOffer(item.offers.find(o => o.offerId === 8), 8),
            '9-Cash Back 5000': formatOffer(item.offers.find(o => o.offerId === 9), 9),
            '10-iPhone 16': formatOffer(item.offers.find(o => o.offerId === 10), 10),
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Coupons');

        // Create a downloadable file
        XLSX.writeFile(wb, 'Coupons_Data.xlsx');
      };

    const [switchStates, setSwitchStates] = useState([]);
    // { offerID: 1, offerName: 'Smartwatch', active: true },
    // { offerID: 2, offerName: 'Bluetooth Speaker', active: false },
    // { offerID: 3, offerName: 'TWS', active: false },
    // { offerID: 4, offerName: 'Cash Back 100', active: false },
    // { offerID: 5, offerName: 'Cash Back 200', active: false },
    // { offerID: 6, offerName: 'Cash Back 500', active: false },
    // { offerID: 7, offerName: 'Cash Back 1000', active: false },
    // { offerID: 8, offerName: 'Cash Back 2000', active: false },
    // { offerID: 9, offerName: 'Cash Back 5000', active: false },
    // { offerID: 10, offerName: 'iPhone 16', active: false },

    const handleChange = (offerID) => (event) => {
        setSwitchStates((prevStates) =>
            prevStates.map((offer) =>
                offer.offerID === offerID
                    ? { ...offer, active: event.target.checked }
                    : offer
            )
        );
    };

    const handleSubmit = async () => {
        // Log or handle the state of all switches
        // console.log(switchStates);
        const statusdata = {
            offersArray : switchStates
        }
        try {
            const submitstatus = await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/updateofferstatus`, statusdata);
            console.log('File successfully uploaded:', submitstatus.data);
            fetchOffersstatus();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <div>

            <div>
                <table className="table table-bordered">
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="10" className="text-center">Loading...</td> {/* Adjust colSpan to fit your table structure */}
                            </tr>
                        ) : (
                            <tr>
                                {switchStates.map((offer) => (
                                    <td key={offer.offerID}>
                                        {offer.offerID}: {offer.offerName}<br />
                                        <Switch
                                            checked={offer.active}
                                            onChange={handleChange(offer.offerID)}
                                            inputProps={{ 'aria-label': `${offer.offerID} controlled` }}
                                        />
                                    </td>
                                ))}
                            </tr>
                        )}
                        {/* <tr>
                        <td>
                            1-Smartwatch
                            <Switch
                                checked={switchStates.smartwatch}
                                onChange={handleChange('smartwatch')}
                                inputProps={{ 'aria-label': 'smartwatch controlled' }}
                            />
                        </td>
                        <td>
                            2-Bluetooth Speaker
                            <Switch
                                checked={switchStates.speaker}
                                onChange={handleChange('speaker')}
                                inputProps={{ 'aria-label': 'speaker controlled' }}
                            />
                        </td>
                        <td>
                            3-TWS
                            <Switch
                                checked={switchStates.tws}
                                onChange={handleChange('tws')}
                                inputProps={{ 'aria-label': 'tws controlled' }}
                            />
                        </td>
                        <td>
                            4-Cash Back 100
                            <Switch
                                checked={switchStates.cashback100}
                                onChange={handleChange('cashback100')}
                                inputProps={{ 'aria-label': 'cashback100 controlled' }}
                            />
                        </td>
                        <td>
                            5-Cash Back 200
                            <Switch
                                checked={switchStates.cashback200}
                                onChange={handleChange('cashback200')}
                                inputProps={{ 'aria-label': 'cashback200 controlled' }}
                            />
                        </td>
                        <td>
                            6-Cash Back 500
                            <Switch
                                checked={switchStates.cashback500}
                                onChange={handleChange('cashback500')}
                                inputProps={{ 'aria-label': 'cashback500 controlled' }}
                            />
                        </td>
                        <td>
                            7-Cash Back 1000
                            <Switch
                                checked={switchStates.cashback1000}
                                onChange={handleChange('cashback1000')}
                                inputProps={{ 'aria-label': 'cashback1000 controlled' }}
                            />
                        </td>
                        <td>
                            8-Cash Back 2000
                            <Switch
                                checked={switchStates.cashback2000}
                                onChange={handleChange('cashback2000')}
                                inputProps={{ 'aria-label': 'cashback2000 controlled' }}
                            />
                        </td>
                        <td>
                            9-Cash Back 5000
                            <Switch
                                checked={switchStates.cashback5000}
                                onChange={handleChange('cashback5000')}
                                inputProps={{ 'aria-label': 'cashback5000 controlled' }}
                            />
                        </td>
                        <td>
                            10-iPhone 16
                            <Switch
                                checked={switchStates.iphone}
                                onChange={handleChange('iphone')}
                                inputProps={{ 'aria-label': 'iphone controlled' }}
                            />
                        </td>
                        </tr> */}
                    </tbody>
                </table>
                <Button
                    variant="contained"
                    className="btn btn-sm btn-primary mx-2 my-2"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>


            <h3>All IFD 2024 Coupons List</h3>
            
            <div className="d-flex justify-content-between">
                <input
                    type="text"
                    className="form-control w-50"
                    placeholder="Search by Store Name or ID"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1); // Reset to first page on search
                    }}
                />
                <button className="btn btn-primary btn-sm h-50 mt-1" onClick={downloadExcel}>Download Excel</button>
            </div>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Store Name</th>
                        <th>Store ID</th>
                        <th>1-Smartwatch</th>
                        <th>2-Bluetooth Speaker</th>
                        <th>3-TWS</th>
                        <th>4-Cash Back 100</th>
                        <th>5-Cash Back 200</th>
                        <th>6-Cash Back 500</th>
                        <th>7-Cash Back 1000</th>
                        <th>8-Cash Back 2000</th>
                        <th>9-Cash Back 5000</th>
                        <th>10-iPhone 16</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((item, index) => (
                        <tr key={index}>
                            <td>{item.fc_name}</td>
                            <td>{item.store_id}</td>
                            {[...Array(10)].map((_, index) => {
                                const offerId = index + 1; // offerId will range from 1 to 10
                                const matchingOffer = item.offers.find(offer => offer.offerId === offerId);

                                return (
                                    <td key={offerId}>
                                        {matchingOffer && matchingOffer.offerRemaining != null && matchingOffer.offerTotalCount != null ? (
                                            offerId > 6 
                                                ? (<>{`${matchingOffer.offerRemaining}/${matchingOffer.offerTotalCount}`}<br />Date: {matchingOffer.offerDate}</>)
                                                : `${matchingOffer.offerRemaining}/${matchingOffer.offerTotalCount}`
                                        ) : ''}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
                {Array.from({ length: Math.ceil(filteredData.length / rowsPerPage) }, (_, index) => (
                    <button
                        className="btn btn-sm"
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        disabled={index + 1 === currentPage}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>




            {/* <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Store Name</th>
                        <th>Store ID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        couponData.map((item, index) => {
                            return (
                                    <tr>
                                        <td>{item.fc_name}</td>
                                        <td>{item.store_id}</td>
                                        <td className="p-3">
                                        {
                                            item.offers && item.offers.length > 0 ? (
                                                <>
                                                    <div className="row mb-2 border border-1 bg-light">
                                                        <div className="col-1 border-end"><strong>Offer ID</strong></div>
                                                        <div className="col-5 border-end"><strong>Offer Name</strong></div>
                                                        <div className="col-2 border-end"><strong>Offer Remaining</strong></div>
                                                        <div className="col-2"><strong>Offer Total Count</strong></div>
                                                        <div className="col-2"><strong>Offer Date</strong></div>
                                                    </div>
                                                    {
                                                        item.offers.map((offer, offerIndex) => (
                                                            <div key={`${index}-${offerIndex}`} className="row mb-2 border border-1">
                                                                <div className="col-1 border-end">{offer.offerId}</div>
                                                                <div className="col-5 border-end">{offer.offerName}</div>
                                                                <div className="col-2 border-end">{offer.offerRemaining}</div>
                                                                <div className="col-2 border-end">{offer.offerTotalCount}</div>
                                                                <div className="col-2">{offer.offerDate}</div>
                                                            </div>
                                                    ))
                                                    }
                                                </>
                                            ) : (
                                                <tr key={index}>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>No offers available</td>
                                                </tr>
                                            )
                                        }
                                        </td>
                                    </tr>
                            )
                        })
                    }
                </tbody>
            </table> */}
        </div>
    );
}

export default Showifd24coupon;