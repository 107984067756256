import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { faPenToSquare, faTrashCan, faFileZipper } from "@fortawesome/free-solid-svg-icons";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteIndiStore, getAllStore, getAllStoreIds } from "../../api/AdminApis/AdminStore";
import EditStoreModal from "../../components/EditStoreModal/EditStoreModal";

import UpdateStoreCoupon from "../../components/EditStoreModal/UpdateStoreCoupon";

import Pagination from "../../components/Pagination/Pagination";

import QRCode from 'qrcode.react';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import axios from "axios";

import { addCouponsToStores } from '../../api/OfferAPIs';

// Component definition
const DashboardShop = () => {
    // jszip instance
    const zip = new JSZip();

    // state definitions
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowusc, setModalShowusc] = useState(false);
    const [singleShopData, setSingleShopData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalShops, setTotalShops] = useState(1);
    const [storeIds, setStoreIds] = useState([]);

    const nav = useNavigate();
    const productsPerPage = 200;

    useEffect(() => {
        getAllStoreIds().then(res => {
            if (res?.status === "success") {
                const d = res.data;
                setStoreIds(d)
            } else toast.error("Something went wrong in fetching Store ids")
        })
    }, [])

    useEffect(() => {
        setLoader(true);
        getAllStore(`limit=${productsPerPage}&page=${currentPage}`).then((res) => {
            if (res) {
                setData(res.stores);
                setTotalShops(res.total_stores);
            }
        }).finally(() => setLoader(false));
    }, [currentPage]);

    // Constants
    const storePath = `${window.location.origin}/store/`;

    /** Handler functions - starts */

    const handleStoreDelete = (id) => {
        deleteIndiStore(id).then((res) => {
            getAllStore(`limit=${productsPerPage}&page=${currentPage}`).then((res) => {
                setData(res.stores);
                setLoader(false);
            });
        });
    };
    const editStore = (item) => {
        setSingleShopData(item);
        setModalShow(true);
    };

    const viewStoreCoupon = (item) => {
        setSingleShopData(item);
        setModalShowusc(true);
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const generateBulkQR = () => {
        const qrFiles = [];
        for (const store of storeIds) {
            const dataURL = document.getElementById(store.brand_store_id)?.toDataURL();
            if (dataURL) {
                qrFiles.push(new File([dataURLtoBlob(dataURL)], `${store.brand_store_id}.png`));
            }
        }
        downloadZip(qrFiles);
    }

    // converts base64 to Blob
    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    // downloads a zip of qr files
    const downloadZip = (qrFiles) => {
        const qrZip = zip.folder('olineo_qr')
        for (const file of qrFiles) {
            qrZip.file(file.name, file);
        }

        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, "olineo_qr.zip");
        });
    }

    const addStore = () => {
        setSingleShopData({});
        setModalShow(true);
    }

    const navigate = useNavigate();


    /** Handler functions - ends */

    const [excelfile, setexcelFile] = useState(null);
    const handleFileChange = (e) => {
        setexcelFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!excelfile) {
        //   alert('Please upload an Excel file first!');
        //   return;
        // }
        const formData = new FormData();
        formData.append('file', excelfile);
        try {
            // addCouponsToStores(formData).then((res) => {
            //     if (res.data.status === "success") {toast.success("Added Coupon to Store!!!");} else {toast.error(res.data.message);}
            // });
          const responseexcelup = await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/uploadcoupons`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        console.log('File successfully uploaded:', responseexcelup.data);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      };

    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <EditStoreModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                singleShopData={singleShopData}
                setSingleShopData={setSingleShopData}
                setModalShow={setModalShow}
                setData={setData}
                setLoader={setLoader}
            />
            <UpdateStoreCoupon
                show={modalShowusc}
                onHide={() => setModalShowusc(false)}
                singleShopData={singleShopData}
                setSingleShopData={setSingleShopData}
                setModalShow={setModalShowusc}
                setData={setData}
                setLoader={setLoader}
            />
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>All Shops</h3>
                    <div>
                        <Button className="btn-sm me-2" style={{ marginBottom: 20 }} onClick={() => navigate("../showifd24coupon", { state: { couponData: data } }) }>Show IFD 2024 Coupons</Button>
                        <Button
                            disabled={!data.length}
                            className="btn-sm me-2"
                            onClick={generateBulkQR}
                            style={{ marginBottom: 20 }}
                        >
                            Generate Bulk QR &nbsp;<FontAwesomeIcon icon={faFileZipper} />
                        </Button>

                        <Button
                            className="btn-sm me-2"
                            style={{ marginBottom: 20 }}
                            onClick={addStore}
                        >
                            Add Shop
                        </Button>

                        <Button
                            className="btn-sm"
                            onClick={() => nav("../admin-add-shop")}
                            style={{ marginBottom: 20 }}
                        >
                            Add Bulk Shops
                        </Button>
                    </div>
                </div>
                <div className="table-responsive">
                    {data?.length === 0 ? (
                        <>
                            <div>
                                <h5>No Shops in Database</h5>
                            </div>
                        </>
                    ) : (
                        <>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Brand Store Id</th>
                                        <th scope="col">Store Sap Code</th>
                                        <th scope="col">Store Name</th>
                                        <th scope="col">Store City</th>
                                        <th scope="col">Store Pincode</th>
                                        <th scope="col">Serviceable Pincode</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 &&
                                        data?.map((item, index) => (
                                            <tr key={index}>
                                                <td> {index + 1} </td>
                                                <td> {item?.brand_store_id} </td>
                                                <td> {item?.sap_code} </td>
                                                <td>{item?.fc_name}</td>
                                                <td>{item?.city}</td>
                                                <td>{item?.pincode}</td>
                                                <td
                                                    style={{
                                                        maxWidth: '100px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                    title={item?.serviceablePincodes && Array.isArray(item?.serviceablePincodes)
                                                        ? item?.serviceablePincodes.join(', ')
                                                        : '-'
                                                    }
                                                >
                                                    {item?.serviceablePincodes && Array.isArray(item?.serviceablePincodes)
                                                        ? item?.serviceablePincodes.join(', ')
                                                        : '-'
                                                    }
                                                </td>
                                                <td>
                                                    <button className="btn" onClick={() => editStore(item)}>
                                                        <FontAwesomeIcon className={"table-icon"} icon={faPenToSquare} />
                                                    </button>
                                                    <button className="btn" onClick={() => handleStoreDelete(item._id)}>
                                                        <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                                    </button>
                                                    <button className="btn btn-primary" onClick={() => viewStoreCoupon(item)}>View Coupons</button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
                <div style={{ display: 'none' }}>
                    {
                        storeIds.map(item => (
                            <QRCode value={`${storePath}${item.brand_store_id}`} id={item.brand_store_id} />
                        ))
                    }
                </div>
                <div className="pagination_Container">
                    <Pagination productsPerPage={productsPerPage} totalProducts={totalShops} pageChange={handlePageChange} currentPage={currentPage} />
                </div>

                {/* <div>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="file">Upload Excel File:</label>
                        <input type="file" id="file" accept=".xls,.xlsx" onChange={handleFileChange} />
                        <button type="submit" className="btn btn-sm btn-primary">Submit</button>
                    </form>
                </div> */}

            </div>
        </>
    );
};

export default DashboardShop;
