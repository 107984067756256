import React, { useEffect, useMemo, useRef, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./HeaderDropDown.css";
import DropDownItemModal from "../DropDownItemModal/DropDownItemModal";
import { Box, Modal } from "@mui/material";
import useScreenSize from "../../utils/ScreenSizeCalculation";

import apple from "../../assets/companyLogo/appleLogo.svg";
import samsung from "../../assets/companyLogo/samsungLogo.svg";
import realMe from "../../assets/companyLogo/realMeLogo.svg";
import google from "../../assets/companyLogo/googleLogo.svg";
import oppo from "../../assets/companyLogo/oppoLogo.svg";
import vivo from "../../assets/companyLogo/vivoLogo.svg";
import iqoo from "../../assets/companyLogo/iqooLogo.svg";
import onePlus from "../../assets/companyLogo/onePlusLogo.svg";
import poco from "../../assets/companyLogo/pocoLogo.svg";
import asus from "../../assets/companyLogo/asusLogo.svg";
import lava from "../../assets/companyLogo/lavaLogo.svg";
import nokia from "../../assets/companyLogo/nokiaLogo.svg";
import techno from "../../assets/companyLogo/tecnoLogo.svg";
import boat from "../../assets/companyLogo/boatLogo.svg";
import stuffcool from "../../assets/companyLogo/stuffcool-logo.svg";
import jbl from "../../assets/companyLogo/JBL.svg";
import motrola from "../../assets/companyLogo/motrola.svg";
import noise from "../../assets/companyLogo/Noise.svg";
import xiaomi from "../../assets/companyLogo/Xiaomi.svg";
import fireboltt from "../../assets/companyLogo/fireboltt.svg";
import infinix from "../../assets/companyLogo/Infinix.svg";
import nothing from "../../assets/companyLogo/Nothing.svg";
import honor from "../../assets/companyLogo/Honor.svg";
import Itel from "../../assets/companyLogo/Itel.svg";
import { useNavigate } from "react-router-dom";

const HeaderDropDown = ({ open, setOpen }) => {
  const [rotate, setRotate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  const screenType = useScreenSize();

  useEffect(() => {
    const handleClickOutside = (event, type) => {
      if (type === "scroll") handleClose();
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        !event.target.closest(".hamburger") && handleClose();
      }
    };

    document.addEventListener("mousedown", (e) => handleClickOutside(e, "click"));
    document.addEventListener("scroll", (e) => handleClickOutside(e, "scroll"));

    return () => {
      document.removeEventListener("mousedown", (e) => handleClickOutside(e, "click"));
      document.removeEventListener("scroll", (e) => handleClickOutside(e, "scroll"));
    };
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    setRotate("");
    setOpen(false);
  };

  const handleOpen = (item) => {
    if (openModal === true && rotate === item) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
    if (rotate === item) {
      setRotate("");
    } else {
      setRotate(item);
    }
  };

  const itemArray = useMemo(
    () => [
      {
        display: "Smartphones",
        name: "Smartphone",
        children: [
          [
            { image: apple, name: "Apple" },
            { image: samsung, name: "Samsung" },
            { image: realMe, name: "Realme" },
            { image: google, name: "Google" },
          ],
          [
            { image: oppo, name: "Oppo" },
            { image: vivo, name: "Vivo" },
            { image: Itel, name: "Itel" },
            { image: iqoo, name: "Iqoo" },
          ],
          [
            { image: onePlus, name: "OnePlus" },
            { image: poco, name: "Poco" },
            { image: xiaomi, name: "Xiaomi" },
            { image: asus, name: "Asus" },
          ],
          [
            { image: lava, name: "Lava" },
            { image: nokia, name: "Nokia" },
            { image: motrola, name: "Motorola" },
            { image: techno, name: "Techno" },
          ],
          [
            { image: honor, name: "Honor" },
            { image: infinix, name: "Infinix" },
            { image: nothing, name: "Nothing" },
          ],
        ],
      },
      {
        display: "Laptops",
        name: "Laptop",
        children: [
          [
            { image: xiaomi, name: "Redmi" },
            { image: samsung, name: "Samsung" },
          ],
        ],
      },
      {
        display: "Tablets",
        name: "Tablet",
        children: [[{ image: samsung, name: "Samsung" }]],
      },
      {
        display: "Smart TV",
        name: "Smart TV",
        children: [
          [
            { image: realMe, name: "Realme" },
            { image: xiaomi, name: "Xiaomi" },
            { image: onePlus, name: "OnePlus" },
          ],
        ],
      },
      {
        display: "TWS",
        name: "True Wireless Earbuds",
        children: [
          [
            { image: boat, name: "Boat" },
            { image: realMe, name: "Realme" },
            { image: oppo, name: "Oppo" },
            { image: noise, name: "Noise" },
          ],
          [
            { image: jbl, name: "Jbl" },
            { image: samsung, name: "Samsung" },
            { image: onePlus, name: "OnePlus" },
            { image: honor, name: "Honor" },
          ],
        ],
      },
      {
        display: "Powerbanks",
        name: "Powerbank",
        children: [
          [
            { image: stuffcool, name: "Stuffcool" },
            { image: realMe, name: "Realme" },
          ],
        ],
      },
      {
        display: "Speakers",
        name: "Bluetooth Speaker",
        children: [[{ image: realMe, name: "Realme" }]],
      },
      // {
      //   display: "Headphone",
      //   name: "headphones",
      //   children: [
      //     [
      //       { image: realMe, name: "realme" },
      //       { image: noise, name: "noise" },
      //       { image: jbl, name: "jbl" },
      //     ],
      //   ],
      // },

      {
        display: "Smartwatch",
        name: "Smartwatch",
        children: [
          [
            { image: fireboltt, name: "Fire-Boltt" },
            { image: realMe, name: "Realme" },
            { image: boat, name: "Boat" },
            { image: samsung, name: "Samsung" },
          ],
          [
            { image: apple, name: "Apple" },
            { image: onePlus, name: "OnePlus" },
            { image: honor, name: "Honor" },
            { image: noise, name: "Noise" },
          ],
        ],
      },
    ],
    []
  );

  const navigate = useNavigate();
  const handleClick = (value) => {
    var searchURL = "Category=" + value;
    navigate(`/${searchURL}`);
    setOpen(false);
  };
  return (
    open && (
      <>
        <div className="dropDownContainer" ref={modalRef} data-categorydropdownopen={open ? "open" : "close"}>
          <div className="dropDownItemsCon">
            {itemArray.map((item, index) => (
              <div
                key={index}
                className="dropDownItem"
                onMouseEnter={screenType === "desktop" ? () => handleOpen(item.name) : () => {}}
                onPointerEnter={screenType !== "desktop" ? () => handleOpen(item.name) : () => {}}
              >
                {/* <Link style={{ textDecoration: "none", color: "black"  }} to={`Category=${item.name}`}> */}

                <h4 onClick={() => handleClick(item.name)}>{item.display}</h4>

                {/* </Link> */}
                {rotate === item.name && <DropDownItemModal index={index} open={openModal} handleClose={handleClose} item={item.name} logos={item.children} />}
                {/* <span className="rightArrowIcon">
                  <ArrowRightIcon className={rotate === item.name ? "rotate" : ""} />
                </span> */}
              </div>
            ))}
          </div>
        </div>
        <div>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box></Box>
          </Modal>
        </div>
      </>
    )
  );
};

export default HeaderDropDown;
