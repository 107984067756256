import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

// Custom Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
import { debounce } from "../../utils";
// - API
import { changeStoreCommentStatus, getAdminStoreComments } from "../../api/AdminApis/AdminReviews";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// Component definitions
const AdminStoreComments = () => {
  // state definitions
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [filterObj, setFilterObj] = useState({
    status: "All",
    category: "all",
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [totalComments, setTotalComments] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const commentsPerPage = 10;
  const { isOpen: isSidebarOpen = false } = useOutletContext();

  const statusArr = [
    { name: "All", value: null },
    { name: "Approved", value: true },
    { name: "Rejected", value: false },
  ];
  const [statusName, setStatusName] = useState("");
  const [statusValue, setStatusValue] = useState();

  useEffect(() => {
    setLoading(true);
    (async () => {
      let url = `?limit=${commentsPerPage}&page=${currentPage}`;
      // add filter params
      if (filterObj["status"] !== "All") {
        url += `&status=${filterObj["status"]}`;
      }

      if (filterObj["category"].toLocaleLowerCase() !== "all") {
        url += `&category=${filterObj["category"]}`;
      }

      if (filterObj["searchText"]) {
        url += `&searchText=${filterObj["searchText"]}`;
      }

      if (filterObj["startDate"]) {
        url += `&startDate=${filterObj["startDate"]}`;
      }

      if (filterObj["endDate"]) {
        url += `&endDate=${filterObj["endDate"]}`;
      }
      fetchData(url);
    })();
  }, [currentPage, filterObj]);

  /** Handler funcions - starts */

  const fetchData = (url = "") => {
    setLoading(true);
    getAdminStoreComments(url)
      .then((res) => {
        if (res?.status === "success") {
          setTotalComments(res.data.total_comments);
          setComments(res.data.comments);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong! Internal Server Error");
      })
      .finally(() => setLoading(false));
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleStatusChange = (commentId, e, value) => {
    if (window.confirm("Sure?")) {
      changeStoreCommentStatus(commentId).then((res) => {
        if (res?.status === "success") {
          toast.success(res.status?.message);
          fetchData();
        } else {
          toast.error("Oops! Some Error occured.");
        }
      });
    }
  };

  // handles filter change and updates filterObj
  const handleFilterChange = (key, value) => {
    if (!Object.keys(filterObj).includes(key)) return;
    setCurrentPage(1);
    setFilterObj((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelectedStatusChange = (e, value) => {
    if (value) {
      setCurrentPage(1);
      setStatusName(value.name);
      console.log(value.value);
      setStatusValue(value.value);
      setFilterObj((prev) => ({ ...prev, status: value.value }));
    }
  };

  // handles date change filter
  const handleDateChange = (dateKey, dateVal) => {
    if (filterObj[dateKey] === dateVal) return;
    setCurrentPage(1);
    setFilterObj((prev) => ({ ...prev, [dateKey]: dateVal }));
  };

  const handleFreeTextChange = debounce((event) => {
    handleFilterChange("searchText", event.target.value);
  });

  /** Handler funcions - ends */

  return (
    <div className="container" style={{ maxWidth: isSidebarOpen ? "1140px" : "100%" }}>
      <div className="d-flex justify-content-between mb-2">
        <h3>Store Comments And Reviews</h3>
      </div>

      {/** Filter Sections */}
      <div className="row">
        {/** Free text filter */}
        <div className="col-lg-3">
          <label className="">Store ID </label>
          <input id="standard-basic" onChange={handleFreeTextChange} placeholder="Enter Store ID" className="form-control" />
        </div>

        {/** Date filters */}
        <div className="col-lg-3">
          <label className="">Start Date </label>
          <DatePicker
            name="startDate"
            format="dd/MM/yyyy"
            selected={filterObj["startDate"]}
            className={"input-field custom-date-picker form-control"}
            onChange={(dateVal) => handleDateChange("startDate", dateVal)}
            placeholderText="Select Date"
          />
        </div>
        <div className="col-lg-3">
          <label className="">End Date </label>
          <DatePicker
            name="endDate"
            format="dd/MM/yyyy"
            selected={filterObj["endDate"]}
            className={"input-field custom-date-picker form-control"}
            onChange={(dateVal) => handleDateChange("endDate", dateVal)}
            placeholderText="Select Date"
          />
        </div>
        {/** Status Filter */}
        <div className="col-lg-3">
          <label className=" ">Status</label>
          <Autocomplete
            id="statusSelected"
            className="form-control "
            size="small"
            options={statusArr}
            onChange={(e, value) => handleSelectedStatusChange(e, value)}
            getOptionLabel={(option) => option.name || ""}
            value={{
              name: statusName,
              value: statusValue,
            }}
            clearOnEscape={false}
            renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" value={statusName} placeholder="Select Status" />}
          />
        </div>
      </div>

      {!loading ? (
        <>
          <div className="table-responsive mb-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Store ID</th>
                  <th scope="col">Store Rating</th>
                  <th scope="col">Store Review</th>
                  <th scope="col">Has user visited Store?</th>
                  <th scope="col">Approved Status</th>
                  <th scope="col">Likes</th>
                </tr>
              </thead>
              <tbody>
                {comments?.length > 0 &&
                  comments?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * commentsPerPage + index + 1}.</td>
                      <td>{item?.user?.fullName ?? "-"}</td>
                      <td>
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(item?.createdAt))}
                      </td>
                      <td>{item?.storeId ?? "-"}</td>
                      <td>{item?.storeRating ?? "-"}</td>
                      <td>{item?.storeReview ?? "-"}</td>
                      <td>{item?.userHasVisited ? "True" : "False"}</td>
                      <td>
                        <FormControlLabel control={<Switch checked={item?.visible} />} onChange={(e) => handleStatusChange(item?._id, e, item?.visible)} />
                      </td>
                      <td>{item?.likes?.length ?? "0"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination_Container">
            <Pagination productsPerPage={commentsPerPage} totalProducts={totalComments} pageChange={handlePageChange} currentPage={currentPage} />
          </div>
        </>
      ) : (
        <DashboardLoader />
      )}
    </div>
  );
};

export default AdminStoreComments;
