import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { QRCodeCanvas } from "qrcode.react";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { addStoreBulk, getAllStore, updateIndiStore } from "../../api/AdminApis/AdminStore";

const EditStoreModal = (props) => {
  // constants
  const requiredFields = [
    "fc_name",
    "brand_store_id",
    "warehouse_name",
    "warehouse_batch_name",
    "sale_ledger_name",
    "sale_voucher_type",
    "gst_sales_account_ledger_name",
    "credit_note_ledger_name",
    "credit_note_voucher_type",
    "pincode",
    "serviceablePincodes",
  ];

  // state definitions
  const [shopStatus, setShopStatus] = useState("");

  // Props destructuring
  const { singleShopData = null } = props;
  // whether it is editing or adding a store
  const isNew = !singleShopData?._id;

  // Url of the store that will be encoded in the QR
  const qr_value = `${window.location.origin}/store/${props.singleShopData?.brand_store_id ?? ""}`;

  useEffect(() => {
    if (props && props.singleShopData && props.singleShopData.store_status) {
      setShopStatus(props.singleShopData.store_status);
    }
  }, [props]);

  const shopStatusOptions = ["Active", "Not Active"];

  /** Handler functions - starts */

  // generates store payload
  const generatePayload = (e) => {
    return {
      brand_store_id: e.target.brand_store_id.value,
      fc_name: e.target.fc_name.value,
      sap_code: e.target.sap_code.value,
      street_name: e.target.street_name.value,
      pincode: e.target.pincode.value,
      serviceablePincodes: e.target.serviceablePincodes.value?.split(",").map((el) => el.trim()) ?? [],
      town: e.target.town.value,
      city: e.target.city.value,
      state: e.target.state.value,
      country: e.target.country.value,
      opening_hours: e.target.opening_hours.value,
      opening_mins: e.target.opening_mins.value,
      closing_hours: e.target.closing_hours.value,
      closing_mins: e.target.closing_mins.value,
      contact_person: e.target.contact_person.value,
      contact_no: e.target.contact_no.value,
      store_email_id: e.target.store_email_id.value,
      gstn_code: e.target.gstn_code.value,
      store_status: shopStatus,
      qr_value: e.target.qr_value.value,
      warehouse_name: e.target.warehouse_name.value,
      warehouse_batch_name: e.target.warehouse_batch_name.value,
      sale_ledger_name: e.target.sale_ledger_name.value,
      sale_voucher_type: e.target.sale_voucher_type.value,
      gst_sales_account_ledger_name: e.target.gst_sales_account_ledger_name.value,
      credit_note_ledger_name: e.target.credit_note_ledger_name.value,
      credit_note_voucher_type: e.target.credit_note_voucher_type.value,
      latitude: e.target.latitude.value,
      longitude: e.target.longitude.value,
    };
  };

  // updates an existing source
  const updateUser = (e) => {
    const data = generatePayload(e);

    for (const prop of Object.keys(data)) {
      if (requiredFields.includes(prop) && !data[prop]) {
        toast.error(`Required prop - ${prop} missing`);
        break;
      }
    }

    updateIndiStore(props.singleShopData?._id, data).then((res) => {
      props.setSingleShopData(res);
      props.setModalShow(false);
      toast.success("Successfully Updated!");
      getAllStore(`limit=150&page=1`).then((res) => {
        props.setData(res.stores);
        props.setLoader(false);
      });
    });
  };

  const handleDownloadQR = () => {
    const storeId = props.singleShopData.brand_store_id;
    if (!storeId) return;

    const qrCodeURL = document.getElementById(storeId)?.toDataURL();

    if (qrCodeURL) {
      const aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = `QR_Code_${storeId}.png`;
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    }
  };

  // creates a new Store
  const saveStore = (event) => {
    const data = generatePayload(event);

    addStoreBulk([data])
      .then((res) => {
        props.setSingleShopData(res);
        toast.success("Store added successfully!");
        getAllStore(`limit=150&page=1`).then((res) => {
          props.setData(res.stores);
          props.setLoader(false);
        });
      })
      .catch((err) => {
        toast.error("Something went wrong! Please fill valid data");
      })
      .finally(() => {
        props.setModalShow(false);
      });
  };

  // handles form submit and calls edit/add API
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isNew) {
      saveStore(event);
    } else {
      updateUser(event);
    }
  };

  /** Handler functions - ends */

  // Main renderer
  return (
    <div>
      {!props.singleShopData ? (
        <p>Loading...</p>
      ) : (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{isNew ? "Add" : "Update"} Shop Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <p className="catalogue_Hint">Shop Brand Id</p>
              <input className="input-field" type="text" placeholder="Shop Brand Id" defaultValue={props.singleShopData?.brand_store_id} name="brand_store_id" id="Shop Brand Id" />
              <p className="catalogue_Hint">Shop SAP Code</p>
              <input className="input-field" type="text" placeholder="Shop SAP Code" defaultValue={props.singleShopData?.sap_code} name="sap_code" id="Shop SAP Code" />
              <p className="catalogue_Hint">Shop Name</p>
              <input className="input-field mt-2" type="text" placeholder="Shop Name" defaultValue={props.singleShopData?.fc_name} name="fc_name" id="" />
              <p className="catalogue_Hint">Shop Street Name</p>
              <input className="input-field mt-2" type="text" placeholder="Shop Street Name" defaultValue={props.singleShopData?.street_name} name="street_name" id="" />
              <p className="catalogue_Hint">Shop Pincode</p>
              <input className="input-field mt-2" type="text" placeholder="Shop Pincode" defaultValue={props.singleShopData?.pincode} name="pincode" />
              <p className="catalogue_Hint">Shop Serviceable Pincode (Comma Seperated)</p>
              <input className="input-field mt-2" type="text" placeholder="Add comma seperated pincodes" defaultValue={props.singleShopData?.serviceablePincodes} name="serviceablePincodes" />
              <p className="catalogue_Hint">Shop Town</p>
              <input className="input-field mt-2" type="text" placeholder="Shop Town" defaultValue={props.singleShopData?.town} name="town" id="" />
              <p className="catalogue_Hint">Shop City</p>
              <input className="input-field mt-2" placeholder="Shop City" type="text" defaultValue={props.singleShopData?.city} name="city" />
              <p className="catalogue_Hint">Shop State</p>
              <input className="input-field mt-2" placeholder="Shop State" type="text" defaultValue={props.singleShopData?.state} name="state" />
              <p className="catalogue_Hint">Shop Country</p>
              <input className="input-field mt-2" placeholder="Shop Country" type="text" defaultValue={props.singleShopData?.country} name="country" />
              <p className="catalogue_Hint">Shop Latitude</p>
              <input className="input-field mt-2" placeholder="Shop Latitude" type="text" defaultValue={props.singleShopData?.latitude} name="latitude" />
              <p className="catalogue_Hint">Shop Longitude</p>
              <input className="input-field mt-2" placeholder="Shop Longitude" type="text" defaultValue={props.singleShopData?.longitude} name="longitude" />

              <p className="catalogue_Hint">Shop Opening Hours</p>
              <input className="input-field mt-2" placeholder="Shop Opening Hours" type="number" defaultValue={props.singleShopData?.opening_hours} name="opening_hours" />
              <p className="catalogue_Hint">Shop Opening Minutes</p>
              <input className="input-field mt-2" placeholder="Shop Opening Minutes" type="number" defaultValue={props.singleShopData?.opening_mins} name="opening_mins" />
              <p className="catalogue_Hint">Shop Closing Hours</p>
              <input className="input-field mt-2" placeholder="Shop Closing Hours" type="number" defaultValue={props.singleShopData?.closing_hours} name="closing_hours" />
              <p className="catalogue_Hint">Shop Closing Minutes</p>
              <input className="input-field mt-2" placeholder="Shop Closing Minutes" type="number" defaultValue={props.singleShopData?.closing_mins} name="closing_mins" />
              <p className="catalogue_Hint">Shop Contact Number</p>
              <input className="input-field mt-2" placeholder="Shop Contact Number" type="text" defaultValue={props.singleShopData?.contact_no} name="contact_no" />
              <label className="catalogue_Hint">Shop Contact Person</label>
              <input className="input-field mt-2" placeholder="Shop Contact Person" type="text" defaultValue={props.singleShopData?.contact_person} name="contact_person" />
              <p className="catalogue_Hint">Shop Contact Email</p>
              <input className="input-field mt-2" placeholder="Shop Contact Email" type="text" defaultValue={props.singleShopData?.store_email_id} name="store_email_id" />
              <p className="catalogue_Hint">Shop GSTN Code</p>
              <input className="input-field mt-2" placeholder="Shop GSTN Code" type="text" defaultValue={props.singleShopData?.gstn_code} name="gstn_code" />
              <fieldset className="catelogue_Fieldset mt-2">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="catalogue_Dropdown">{shopStatus ? <p>{shopStatus}</p> : <p>Is Advance Payment Available?</p>}</div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {shopStatusOptions.map((item, index) => (
                      <Dropdown.Item key={index} value={item} onClick={() => setShopStatus(item)}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </fieldset>

              {/** QR Code */}
              <p className="catalogue_Hint">QR Code Value</p>
              <input className="input-field mt-2" placeholder="QR Code Value" type="text" readOnly disabled defaultValue={qr_value} name="qr_value" />
              <QRCodeCanvas value={qr_value} id={props.singleShopData?.brand_store_id} />
              <button type="button" className="btn btn-info mb-4 ms-2" title="Download QR" onClick={handleDownloadQR}>
                <FontAwesomeIcon icon={faDownload} color="white" />
              </button>

              {/** Shop godown info */}
              <p className="catalogue_Hint">Warehouse name</p>
              <input className="input-field mt-2" placeholder="Warehouse name" type="text" defaultValue={props.singleShopData?.warehouse_name} name="warehouse_name" />
              <p className="catalogue_Hint">Warehouse batch name</p>
              <input className="input-field mt-2" placeholder="Warehouse batch name" type="text" defaultValue={props.singleShopData?.warehouse_batch_name} name="warehouse_batch_name" />

              {/** Warehouse sale info */}
              <p className="catalogue_Hint">Sale ledger name</p>
              <input className="input-field mt-2" placeholder="Sale ledger name" type="text" defaultValue={props.singleShopData?.sale_ledger_name} name="sale_ledger_name" />
              <p className="catalogue_Hint">Sale voucher type</p>
              <input className="input-field mt-2" placeholder="Sale voucher type" type="text" defaultValue={props.singleShopData?.sale_voucher_type} name="sale_voucher_type" />
              <p className="catalogue_Hint">GST sales account ledger name</p>
              <input
                className="input-field mt-2"
                placeholder="GST sales account ledger name"
                type="text"
                defaultValue={props.singleShopData?.gst_sales_account_ledger_name}
                name="gst_sales_account_ledger_name"
              />

              {/** Shop Credit info */}
              <p className="catalogue_Hint">Credit note ledger name</p>
              <input className="input-field mt-2" placeholder="Credit note ledger name" type="text" defaultValue={props.singleShopData?.credit_note_ledger_name} name="credit_note_ledger_name" />
              <p className="catalogue_Hint">Credit note voucher type</p>
              <input className="input-field mt-2" placeholder="Credit note voucher type" type="text" defaultValue={props.singleShopData?.credit_note_voucher_type} name="credit_note_voucher_type" />

              <div className="d-flex justify-content-end">
                <Button type="submit" className="btn btn-primary mr-auto float-right mt-2 px-5">
                  {isNew ? "Add" : "Update"} Shop Data
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default EditStoreModal;
