import React, { useEffect } from "react";
import useSiteCtx from "../../hooks/useSiteCtx";
import Update from "./Update";

// Component definitions
const UpdateNumber = () => {
    const { modalDataMobile, setHeaderData } = useSiteCtx();

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Update Phone Number",
            categoriesCond: false,
        });
    }, []);

    return (
        <Update
            number={true}
            oldInfo={modalDataMobile.oldData.user_ph_Number}
            newInfo={modalDataMobile.newData.user_ph_Number}
        />
    );
};

export default UpdateNumber;
