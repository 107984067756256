import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faTimes, faTags, faTruck, faGift } from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo_desk from "../../assets/png/navbar_logo_desk.png";

import logo from "../../assets/png/O-LINE-Osymbol.png";
import control from "../../assets/png/control.png";
import Chart_fill from "../../assets/png/dashboard (2).png";

const RetailerSideBar = ({ isOpen, toggle }) => {
  const location = useLocation();
  const handleToggle = () => {
    if (isOpen) {
      toggle();
    }
  };
  const navigate = useNavigate();
  const Menus = [
    { title: "Dashboard", src: Chart_fill, targetLink: "", isActive: location.pathname === "/retailer" },
    { title: "Consignments", src: Chart_fill, targetLink: "consignments", isActive: location.pathname === "consignments" },
    { title: "Delivered Consignments", src: Chart_fill, targetLink: "delivered-consignments", isActive: location.pathname.includes("delivered-consignments") },
  ];

  return (
    <div
      className={`mainNavDiv  p-3  duration-300 text-white `}
      style={{ position: "fixed", height: "100vh", zIndex: "999", transitionDuration: "300ms", backgroundColor: "#081A4F", width: `${isOpen ? "18rem" : "5rem"}` }}
    >
      <img
        src={control}
        className={` cursor-pointer border-dark-purple
           border-2 rounded-full `}
        style={{ position: "absolute", right: "-0.75rem", top: "2.25rem", transform: `${isOpen ? "" : "rotate(180deg)"}`, width: "1.75rem", border: "1px solid #081A4F", borderRadius: "9999px" }}
        onClick={() => toggle()}
      />
      <div className=" items-center d-flex cursor-pointer" style={{ alignItems: "center", columnGap: "1rem", height: "40px" }} onClick={() => navigate("/")}>
        <img
          src={logo}
          className={`cursor-pointer duration-500 ${isOpen && "rotate-360"}`}
          style={{ width: "40px", height: "40px", transitionDuration: "500ms", transform: `${isOpen ? "rotate(360deg)" : ""}` }}
        />
        <h1
          className={`text-white origin-left font-medium text-xl duration-200 ${!isOpen && "scale-0"}`}
          style={{ marginBottom: "0rem", transform: `${!isOpen ? "scale(0)" : ""}`, transitionDuration: "200ms", fontWeight: "500", fontSize: "1.25rem", lineHeight: "1.75rem" }}
        >
          O-LINE-O
        </h1>
      </div>
      <ul className="" style={{ paddingTop: "1.5rem", paddingLeft: "0rem", overflowY: "auto" }}>
        {Menus.map((Menu, index) => (
          <Link className="no-underline" to={Menu.targetLink} onClick={() => handleToggle()} style={{ textDecoration: "none" }}>
            <li
              key={index}
              className={`  adminNavLink ${Menu.isActive ? "bgActive" : ""} flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white text-sm items-center gap-x-4`}
              style={{ display: "flex", color: "grey", fontSize: "0.875rem", lineHeight: "1.25rem", borderRadius: "0.375rem", width: `${!isOpen ? "40px" : "100%"}`, transitionDuration: "200ms" }}
            >
              <img src={Menu.src} />
              <span className={`${!isOpen ? "hidden" : ""} origin-left `}>{Menu.title}</span>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default RetailerSideBar;
