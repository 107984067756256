import { Box, CardContent, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllCouponsOffers } from '../../api/OfferAPIs';
import DashboardLoader from '../../components/DashboardContent/DashboardLoader';
import { Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';


function AdminOfferStoreIndividualCoupons() {
    const [loader, setLoader] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const { couponId } = useParams();
    const nav = useNavigate();

    console.log(couponId)

    useEffect(() => {
        if(!couponId) return
        fetchTotalCoupons(`couponId=${couponId}`);
    }, [couponId]);

    const fetchTotalCoupons = (value = '') => {
        getAllCouponsOffers(value).then((res) => {
            setCoupons(res);
            setLoader(false);
        });
    }

    const CARD = (coupon, index) => (
        <React.Fragment>
            <CardContent key={index} className='mt-2'>
                <Typography variant="h5" component="div" className='mb-2'>
                    Coupons: <b>{coupon?.prizeText}</b>
                </Typography>
                < TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Coupon ID</TableCell>
                                <TableCell>Coupon Name</TableCell>
                                <TableCell align='center'>Coupons Remaining</TableCell>
                                <TableCell align='center'>Total Coupons</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {coupon.prizeIndex}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {coupon.prizeText}
                                </TableCell>
                                <TableCell align="center">{coupon.coupons_remaining}</TableCell>
                                <TableCell align="center">{coupon.total_no_of_coupons}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </React.Fragment >
    );

    return loader ? (
        <DashboardLoader />
    ) : (
        <div className="container">
            <Box>
                {coupons.map((coupon, index) => (
                    CARD(coupon, index)
                ))}
            </Box>
        </div>

    )
}

export default AdminOfferStoreIndividualCoupons