// top level imports
import { useState, useEffect } from "react";

// Moment
import Moment from "react-moment";

// React-Toastify
import { toast } from "react-toastify";

// API
import { getRefundRequests, getRefunds, initCnmtRefund } from "../../api/AdminApis/Refund";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
// constants
import { APP_DATE_TIME_FORMAT } from "../../utils/Constants";
import AdminConsignmentDetailsModal from "../../components/OrderProductModal/AdminConsignmentDetailsModal";
import { getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";

// Component definition
export default function AdminRefunds() {
  // Constants
  const refundTypes = [
    { key: "requests", label: "Requests" },
    { key: "settled", label: "Settled" },
  ];

  // state definitions
  const [refundRequests, setRefundRequests] = useState([]);
  const [refunds, setRefunds] = useState([]); // refunds that have been either initiated or settled
  const [selectedRefundType, setSelectedRefundType] = useState("requests");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [consignmentSelected, setConsignmentSelected] = useState(null);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    fetchRefundRequests();
    fetchRefunds();
  }, []);

  /** Handler functions - starts */

  const fetchRefundRequests = async () => {
    try {
      const response = await getRefundRequests();
      const payload = response.data;
      if (payload.status === "success") {
        setRefundRequests(payload.data);
      }
    } catch {
      // handle error
    }
  };

  const fetchRefunds = async () => {
    try {
      const response = await getRefunds();
      const payload = response.data;
      if (payload.status === "success") {
        setRefunds(payload.data);
      }
    } catch {
      // handle error
    }
  };

  const changeRefundType = (key) => {
    setSelectedRefundType(key);
  };

  // Initiates refund for a consignment
  const initRefund = async (consignment) => {
    try {
      showLoader();
      const response = await initCnmtRefund(consignment?._id);
      const payload = response.data;
      if (payload?.status === "success") {
        toast.success(payload.message);
        fetchRefundRequests();
        fetchRefunds();
        setSelectedRefundType("requests");
      } else {
        toast.error(payload.message);
      }
    } catch {
      // handle error
    } finally {
      hideLoader();
    }
  };

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  const getAConsignmentFunc = (id) => {
    getAdminIndividualConsignment(id).then((res1) => {
      if (res1?.details) {
        setDetails(res1.details);
        setShow(true);
        setConsignmentSelected(res1.details._id);
      } else toast.error("Something went wrong in fetching Individual Consignment");
    });
  };

  /** Handler functions - ends */

  // main renderer
  return (
    <div className="container">
      {show && <AdminConsignmentDetailsModal show={show} onHide={() => setShow(false)} details={details} consignmentSelected={consignmentSelected} />}
      <div className="catelogue_Page section_Wrapper">
        <h4 className="mb-4">Refunds</h4>

        <div className="offers_Toggle_Container mb-2" style={{ maxWidth: "unset" }}>
          {refundTypes.map(({ key, label }) => (
            <p className={selectedRefundType === key ? "offer_Selected" : ""} onClick={() => changeRefundType(key)}>
              {label}
            </p>
          ))}
        </div>
        {loading && <DashboardLoader />}
        {!loading && (
          <div>
            {selectedRefundType === "requests" ? (
              <Requested refundRequests={refundRequests} initRefund={initRefund} getAConsignmentFunc={getAConsignmentFunc} />
            ) : (
              <Refunds refunds={refunds} getAConsignmentFunc={getAConsignmentFunc} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Requested({ refundRequests, initRefund, getAConsignmentFunc }) {
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-bordered ">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Consignment ID</th>
            <th>Refund Amount</th>
            <th>Retailer</th>
            <th>Customer</th>
            <th>Requested</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {refundRequests.length > 0 &&
            refundRequests.map((refund, index) => (
              <tr key={refund?._id}>
                <td>{index + 1}</td>
                <td className="text-decoration-underline cursor-pointer" onClick={() => getAConsignmentFunc(refund._id)}>
                  {refund._id}
                </td>
                <td>{refund.refundAmount}</td>
                <td>{refund.retailer?.brand_store_id ?? "N.A"}</td>
                <td>{refund.customer?.fullName ?? "N.A"}</td>
                <td>{refund?.cancelled_at ? <Moment date={refund.cancelled_at} format={APP_DATE_TIME_FORMAT} /> : "-"}</td>
                <td>
                  <button className="btn btn-danger" onClick={() => initRefund(refund)}>
                    Refund
                  </button>
                </td>
              </tr>
            ))}
        </tbody>

        {!refundRequests.length && (
          <tbody>
            <tr className="text-center">
              <td colSpan={6}>No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

function Refunds({ refunds, getAConsignmentFunc }) {
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-bordered ">
        <thead>
          <tr>
            <th>Sr. No.</th>
            {/* <th>Refund ID</th> */}
            <th>Payu Refund ID</th>
            <th>Consignment ID</th>
            <th>Refund Amount</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {refunds.length > 0 &&
            refunds.map((refund, index) => (
              <tr key={refund?._id}>
                <td>{index + 1}</td>
                {/* <td>{refund._id}</td> */}
                <td>{refund.payu_refund_id}</td>
                <td className="text-decoration-underline cursor-pointer" onClick={() => getAConsignmentFunc(refund.consignment?._id ?? refund.consignment)}>
                  {refund.consignment?._id ?? refund.consignment}
                </td>
                <td>{refund.refundAmount}</td>
                <td>{refund.status.toUpperCase()}</td>
              </tr>
            ))}
        </tbody>

        {!refunds.length && (
          <tbody>
            <tr className="text-center">
              <td colSpan={7}>No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}
