import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/dashboard.css";
import SideBar from "../../components/DashboardSidebar/SideBar";
import Content from "../../components/DashboardContent/Content";
import { useAdminOrRetailer } from "../../utils/useAdminOrRetailer";
import { useNavigate } from "react-router-dom";

// Component definition
function Dashboard() {
  // state definitions
  const [isOpen, setIsOpen] = useState(false);
  const [previousWidth, setPrevWidth] = useState(-1);
  const admin = useAdminOrRetailer(process.env.REACT_APP_ADMIN_TOKEN_KEY);
  const navigate = useNavigate();

  const updateWidth = useCallback(() => {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      setIsOpen(!isMobile);
    }

    setPrevWidth(width);
  }, [previousWidth]);

  /**
   * Add event listener
   */
  useEffect(() => {
    if (!admin) {
      navigate("/admin-login");
    }
    updateWidth();
    window.addEventListener("resize", updateWidth());

    return () => {
      window.addEventListener("resize", updateWidth());
    };
  }, [admin, updateWidth, navigate]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="dashboard_Whole_Wrapper d-flex">
        <SideBar toggle={toggle} isOpen={isOpen} />
        <div className="DashboardWrapper">
          <Content toggle={toggle} isOpen={isOpen} userType={"admin"} />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
