import axios from "../axiosInstance";

export const getStockDetails = async (query = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/fetch?${query}`, { headers });

  return response?.data ?? null;
};

export const handleDelteStock = async (stockId) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/delete/${stockId}`, { headers });

  return response?.data ?? null;
};

export const addStockQty = async (payload) => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/create`, payload, { headers });

  return response?.data ?? null;
};

export const getIndividualProductStockForAdmin = async (query) => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/fetch/get-one?${query}`, { headers });
  return response.data;
};

export const updateStockQtyForAdmin = async (payload) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/admin/update-qty`, payload, { headers });

  return response?.data ?? null;
};

export const getIndividualProductDetailsbyEAN = async (query) => {
  const adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/admin/get-product-by-ean?${query}`, { headers });
  return response.data;
};
